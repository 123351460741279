import { Vector3, Vector3Like } from 'three'
import { useModifiers } from '~/components/DrawingCanvas/hooks/modifiers'
import { CANVAS_BOUNDS } from '~/components/DrawingCanvas/Products/constants'
import { snapToCardinals } from '~/components/DrawingCanvas/util/snaplines'


export const useCardinalSnaplines = ({ x, y, z }: Vector3Like) => {
  const { shift } = useModifiers()
  const base = new Vector3(x, y, z)
  const start = new Vector3()
  const end = new Vector3()
  return (exterior: Vector3) => {
    if (!shift) return
    const snapPoint = snapToCardinals(base, exterior)
    const isVerticalSnap = base.x === snapPoint.x
    if (isVerticalSnap) {
      start.set(base.x, -CANVAS_BOUNDS, z)
      end.set(base.x, CANVAS_BOUNDS, z)
    } else {
      start.set(-CANVAS_BOUNDS, base.y, 0)
      end.set(CANVAS_BOUNDS, base.y, 0)
    }
    const positions = [...start.toArray(), ...end.toArray()]
    return { point: snapPoint, positions }
  }
}
