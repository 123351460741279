import { MeshProps, useLoader } from '@react-three/fiber'
import { Suspense } from 'react'
import { Group, MathUtils, Mesh, Object3DEventMap, Plane, TextureLoader, Vector3 } from 'three'
import { POSITIVE_Z } from '~/components/DrawingCanvas/constants/dimensions'

type RotationControlMeshProps = {
  radius?: number
  offset?: number
  targetElement: Group<Object3DEventMap> | Mesh
  onCommit(newRotation: number): void
} & MeshProps

const RotateIconTexture = () => {
  const texture = useLoader(
    TextureLoader,
    new URL(`/src/assets/textures/drag_handles.jpg`, import.meta.url).href
  )
  return (
    <>
      <meshBasicMaterial color="black" attach="material-0" />
      <meshBasicMaterial map={texture} toneMapped={false} attach="material-1" />
      <meshBasicMaterial map={texture} toneMapped={false} attach="material-2" />
    </>
  )
}

export const RotationHandle = ({
  radius = 3,
  offset = 0,
  targetElement,
  onCommit,
  ...meshProps
}: RotationControlMeshProps) => {
  const plane = new Plane()
  const intersection = new Vector3()
  let origin: Vector3 | null = null
  return (
    <mesh
      {...meshProps}
      position-x={offset + radius}
      onPointerDown={e => {
        e.stopPropagation()
        const isLeftClick = e.button === 0
        if (!isLeftClick) return
        const target: HTMLElement = e.target as HTMLElement
        target.setPointerCapture(e.pointerId)
        origin = new Vector3().setFromMatrixPosition(targetElement.matrixWorld)
      }}
      onPointerMove={e => {
        e.stopPropagation()
        if (!origin) return
        plane.setFromNormalAndCoplanarPoint(POSITIVE_Z, origin)
        e.ray.intersectPlane(plane, intersection)
        intersection.sub(origin)
        const deltaAngle = Math.atan2(intersection.y, intersection.x)
        const clampedDegrees = Math.round(MathUtils.radToDeg(deltaAngle))
        targetElement.rotation.y = MathUtils.degToRad(clampedDegrees)
      }}
      onPointerUp={e => {
        e.stopPropagation()
        origin = null
        const target: HTMLElement = e.target as HTMLElement
        target.releasePointerCapture(e.pointerId)
        onCommit(MathUtils.radToDeg(targetElement.rotation.y))
      }}
    >
      <cylinderGeometry args={[radius, radius]} />
      <Suspense fallback={<meshBasicMaterial color="white" />}>
        <RotateIconTexture />
      </Suspense>
    </mesh>
  )
}
