import { forwardRef, useImperativeHandle, useMemo, useState } from "react"
import type { ToolManagerRef } from "../ToolManager"
import { useProject, useWallSegmentSnapLines } from "../hooks"
import { snapToLine } from "../util/snaplines"
import { Vector3 } from "three"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { addElevationPoint } from "~/store/objects"
import { ElevationPoint } from "~/store/objects/types"
import { guid } from "~/lib/utils"
import { SNAP_THRESHOLD } from "."
import { SnapLine } from "../util/snaplineVisuals"
import { Sphere } from "@react-three/drei"
import theme from "~/config/theme"
import { memoizedRoofMeshes } from "../lib/productDistanceEngine"
import { modelToUI, uiToModel } from "../util/units"
import { setActiveTool } from "~/store/tools/actions"
import { computeRoofIntersection } from "./util"

export const ElevationPointTool = forwardRef<ToolManagerRef>(function ElevationPointTool(_props, ref) {
  const project = useProject()
  const [[x, y], setPoint] = useState([0, 0])
  const doSnap = useAppSelector(state => state.tools.isSnapEnabled)
  const snaplines = useWallSegmentSnapLines()
  const snapPoint = snapToLine(snaplines, new Vector3(x, y, 0))
  const dispatch = useAppDispatch()
  const roofs = useAppSelector(state => state.objects.present.roofs)
  const lines = useAppSelector(state => state.objects.present.elevationLines)
  const points = useAppSelector(state => state.objects.present.elevationPoints)
  const roofMeshes = memoizedRoofMeshes(roofs, lines, points)
  const targetedPoint = useMemo(() => {
    return computeRoofIntersection(uiToModel(x), uiToModel(y), roofMeshes)
  }, [x, y, roofMeshes])
  const targetedZ = targetedPoint !== null ? modelToUI(targetedPoint[1].z) : 0
  const continuousMode = useAppSelector(it => it.tools.isContinuousModeEnabled)

  useImperativeHandle(ref, () => ({
    onMouseMove(event) {
      const point = project(event)
      if (point) {
        setPoint([point.x, point.y])
      }
    },
    onMouseDown() {
      return true
    },
    onMouseUp(e) {
      const isLeftClick = e.button === 0
      if (!isLeftClick || targetedPoint === null) {
        return true
      }
      dispatch(addElevationPoint({
        elevationPoint: {
          id: guid(),
          position: {
            x,
            y,
            z: targetedZ,
          },
          roofId: targetedPoint[0],
        } satisfies ElevationPoint,
      }))
      if (!continuousMode) {
        dispatch(setActiveTool({
          tool: 'SELECT_TOOL',
          props: {},
        }))
      }
      return true
    },
  }), [project, continuousMode, targetedPoint])

  const point = doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD ?
    snapPoint.snapPoint :
    new Vector3(x, y, 0)
  point.setZ(targetedZ)

  return (
    <>
      {doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD && <SnapLine snapPoint={snapPoint} zOverride={point.z + 10}/>}
      <Sphere args={[5, 10, 10]} position={point} material-color={theme.colors.three.valid}/>
    </>
  )
})
