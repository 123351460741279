import { DragControlsProps, DragControls } from '@react-three/drei/web/DragControls'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Matrix4, Vector3, Vector3Like } from 'three'
import { HeightIndicator, HeightRef } from './HeightIndicator'
import { scaleUIVectorToModel, vectorModelToUI } from '~/components/DrawingCanvas/util/units'
import { useAppSelector } from '~/store/hooks'
import { Product } from '~/store/objects/types'
import { ArrowHandle, DimensionGuides } from './DimensionGuides'
import { MODEL_X_ROTATION_OFFSET } from '~/components/DrawingCanvas/Products/constants'

type ProductDragControlsProps = {
  product: Product
  onDrag?: (modelPosition: Matrix4, isMultipleSelected?: boolean) => void
  /** Returns a boolean indicating whether or not the new position is valid */
  onUpdatePosition: (newPosition: Vector3Like) => boolean
} & Omit<DragControlsProps, 'onDrag' | 'onDragEnd' | 'onDragStart'>
export const ProductDragControls = ({
  product,
  onDrag,
  onUpdatePosition,
  children,
  ...dragControlProps
}: ProductDragControlsProps) => {
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const dragMatrix = useRef(new Matrix4().setPosition(vectorModelToUI(product.position)))
  const [isDragging, setIsDragging] = useState(false)
  const arrowsRef = useRef<ArrowHandle>(null)
  const heightIndicatorRef = useRef<HeightRef>(null)

  const isSelected = Boolean(selectedObjects.find(({ id }) => id === product.id))
  const isOnlySelected = isSelected && selectedObjects.length === 1
  const { layerKey, isDirectionalOverhead, isForcedWallMount } = product
  const isVisible = useAppSelector(state => state.layers.layers[layerKey].visible)
  const isHeater = layerKey === 'PRODUCTS_HEATERS'
  const isOverhead = layerKey === 'PRODUCTS_OVERHEAD'
  const isShowHeightIndicator =
    Boolean(isHeater || isOverhead || isDirectionalOverhead || isForcedWallMount) && isVisible

  const origin = new Vector3()
  const { x, y, z } = product.position

  useEffect(() => {
    if (!isSelected) return
    const controller = new AbortController()
    const newPosition = new Vector3()
    window.addEventListener(
      'multidrag',
      ({ detail }) => {
        newPosition.setFromMatrixPosition(dragMatrix.current)
        newPosition.add(detail.delta)
        dragMatrix.current.setPosition(newPosition)
        onDrag?.(dragMatrix.current, true)
      },
      { signal: controller.signal }
    )
    return () => controller.abort()
  }, [isSelected, onDrag])

  useLayoutEffect(() => {
    dragMatrix.current.setPosition(vectorModelToUI({ x, y, z }))
  }, [x, y, z])

  const handleDragStart = () => setIsDragging(true)

  const handleDrag: DragControlsProps['onDrag'] = local => {
    onDrag?.(local)
    origin.setFromMatrixPosition(local)
    arrowsRef.current?.setOrigin(origin)
    heightIndicatorRef.current?.updateHeight(origin.z)
    dragMatrix.current.copy(local)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
    const { x, y, z } = scaleUIVectorToModel(
      new Vector3().setFromMatrixPosition(dragMatrix.current)
    )
    const isSuccessfulUpdate = onUpdatePosition({ x, y, z })
    if (!isSuccessfulUpdate) dragMatrix.current.setPosition(vectorModelToUI(product.position))
  }

  return (
    <>
      <DragControls
        {...dragControlProps}
        {...(isOnlySelected ? {} : { dragConfig: { enabled: false } })}
        axisLock="z"
        autoTransform={false}
        matrix={dragMatrix.current}
        onDragStart={handleDragStart}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
      >
        {isOnlySelected && (
          <DimensionGuides
            {...product}
            ref={arrowsRef}
            isDragging={isDragging}
            onUpdatePosition={onUpdatePosition}
          />
        )}
        {isShowHeightIndicator && (
          <HeightIndicator height={product.position.z} ref={heightIndicatorRef} />
        )}
        <group rotation-x={MODEL_X_ROTATION_OFFSET}>{children}</group>
      </DragControls>
    </>
  )
}
