import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import type { ToolManagerRef } from "../ToolManager"
import { useProject, useWallSegmentSnapLines } from "../hooks"
import { useAppDispatch, useAppSelector, useAppStore } from "~/store/hooks"
import { memoizedRoofMeshes } from "../lib/productDistanceEngine"
import { computeRoofIntersection } from "../ElevationPoints/util"
import { modelToUI, uiToModel } from "../util/units"
import { snapToCardinals, snapToLine } from "../util/snaplines"
import { Vector3 } from "three"
import { SNAP_THRESHOLD } from "../ElevationPoints"
import { Line, Sphere } from "@react-three/drei"
import { SnapLine } from "../util/snaplineVisuals"
import theme from "~/config/theme"
import { addElevationLine } from "~/store/objects"
import { ElevationLine, ElevationPoint } from "~/store/objects/types"
import { guid } from "~/lib/utils"
import LayerKeys from "~/config/layerKeys"
import { SnapLine as ElevationLinePreview } from "~/components/DrawingCanvas/Products/components/SnapLine"
import { useModifiers } from "~/components/DrawingCanvas/hooks/modifiers"

const RULE_BOUNDS = 5000

export const ElevationLineTool = forwardRef<ToolManagerRef>(function ElevationLineTool(_props, ref) {
  const project = useProject()
  const [[x, y], setPoint] = useState([0, 0])

  const store = useAppStore()
  const dispatch = useAppDispatch()

  const ortho = useAppSelector(state => state.tools.isOrthoModeEnabled)
  const shift = useModifiers(it => it.shift)

  const becomeOrthogonal = ortho || shift

  const doSnap = useAppSelector(state => state.tools.isSnapEnabled)
  const snaplines = useWallSegmentSnapLines()
  const snapPoint = snapToLine(snaplines, new Vector3(x, y, 0))

  const roofs = useAppSelector(state => state.objects.present.roofs)
  const lines = useAppSelector(state => state.objects.present.elevationLines)
  const points = useAppSelector(state => state.objects.present.elevationPoints)
  const roofMeshes = memoizedRoofMeshes(roofs, lines, points)

  const targetedPoint = computeRoofIntersection(uiToModel(x), uiToModel(y), roofMeshes)
  const targetedZ = targetedPoint !== null ? modelToUI(targetedPoint[1].z) : 0

  const [pendingPoints, setPoints] = useState<Vector3[]>([])
  const [roofID, setRoofID] = useState<string>()

  useImperativeHandle(ref, () => ({
    onMouseMove(event) {
      const point = project(event)
      if (point) {
        if (becomeOrthogonal && pendingPoints.length) {
          const lastPendingPoint = pendingPoints[pendingPoints.length - 1]
          const snappedToCardinals = snapToCardinals(lastPendingPoint, new Vector3(point.x, point.y, 0))
          setPoint([snappedToCardinals.x, snappedToCardinals.y])
        } else {
          setPoint([point.x, point.y])
        }
      }
    },
    onMouseUp(e) {
      const isLeftClick = e.button === 0
      if (!isLeftClick || targetedPoint === null || (roofID !== undefined && roofID !== targetedPoint[0])) {
        return true
      }

      if (roofID === undefined) {
        setRoofID(targetedPoint[0])
      }

      const point = doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD ?
        snapPoint.snapPoint :
        new Vector3(x, y, 0)

      if (becomeOrthogonal && pendingPoints.length >= 1) {
        point.copy(snapToCardinals(pendingPoints[pendingPoints.length-1], point))
      }

      setPoints(pts => pts.concat([new Vector3(point.x, point.y, targetedZ)]))
    },
  }))

  useEffect(() => () => {
    if (pendingPoints.length <= 1 || roofID === undefined) {
      return
    }
    if (store.getState().tools.activeTool === 'ELEVATION_LINE_TOOL') {
      return
    }

    const storePendingPoints = pendingPoints.map<ElevationPoint>(pt => ({
      id: guid(),
      position: pt,
      roofId: roofID,
    }))
    dispatch(addElevationLine({
      elevationLine: {
        id: guid(),
        layerKey: LayerKeys.ELEVATION_LINE,
        elevationPointIds: storePendingPoints.map(pt => pt.id),
        roofId: roofID,
      } satisfies ElevationLine,
      elevationPoints: storePendingPoints,
    }))
  }, [pendingPoints, roofID])

  const point = doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD ?
    snapPoint.snapPoint :
    new Vector3(x, y, 0)
  point.setZ(targetedZ)

  const lastPendingPoint = pendingPoints[pendingPoints.length - 1]
  const previewPoints = lastPendingPoint ? [lastPendingPoint, point] : undefined
  const ruleStart = new Vector3()
  const ruleEnd = new Vector3()
  if (lastPendingPoint && becomeOrthogonal) {
    const dx = Math.abs(lastPendingPoint.x - point.x)
    const dy = Math.abs(lastPendingPoint.y - point.y)
    const isHorizontalRule = dx >= dy
    if (isHorizontalRule) {
      ruleStart.copy(lastPendingPoint).setX(-RULE_BOUNDS)
      ruleEnd.copy(lastPendingPoint).setX(RULE_BOUNDS)
    } else {
      ruleStart.copy(lastPendingPoint).setY(-RULE_BOUNDS)
      ruleEnd.copy(lastPendingPoint).setY(RULE_BOUNDS)
    }
  }

  return (
    <>
      {doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD && <SnapLine snapPoint={snapPoint}/>}
      <Sphere args={[5, 10, 10]} position={point} material-color={theme.colors.three.valid}/>
      <ElevationLinePreview lineWidth={4} points={previewPoints} worldUnits/>
      {becomeOrthogonal && <Line points={[ruleStart, ruleEnd]} lineWidth={4} color={theme.colors.three.valid}/>}
      {pendingPoints.map((pt, idx) => <Sphere args={[5, 10, 10]} position={pt} key={idx} material-color={theme.colors.three.valid}/>)}
      {pendingPoints.length >= 2 && <Line points={pendingPoints} lineWidth={4} worldUnits={true} color={theme.colors.three.valid}/>}
    </>
  )
})
