import Facility from './facility'
import { SnapLine, SnapPoint } from './snapRegion'
import Util from './util'
import Units from './units'
import { isTouchUI } from 'store/userInterface/selectors'
import flatMap from 'lodash-es/flatMap'

const TOUCH_SNAP_RANGE_MULTIPLIER = 1.5

class SnapQueries {
  /*
    Gather snap lines for vertical and horizontal axes rooted at each
    pre-existing elevation point.
  */
  static getElevationPointLinesForRoof(roof) {
    const roofPos = roof.obj3d.position.clone()
    const snapLines = flatMap(roof.getAllElevationPoints(), ep =>
      Util.snapLinesForOrthogonalAxesAtPoint(
        { x: roofPos.x + ep.position.x, y: roofPos.y + ep.position.y },
        Units.inchesToNative(12)
      )
    )

    return snapLines
  }

  /*
    Gather snap lines for vertical and horizontal axes rooted at the
    midpoint of each edge for the given roof.
  */
  static getEdgeMidpointLinesForRoof(roof) {
    let snapLines = []
    const roofPos = roof.obj3d.position
    const perimeterPoints = roof.perimeterPoints

    // Gether snap points for each perimeter midpoint
    for (let i = 1; i < perimeterPoints.length + 1; i++) {
      const lastPoint = perimeterPoints[i - 1]
      const point = perimeterPoints[i % perimeterPoints.length]
      const x = roofPos.x + lastPoint[0] + (point[0] - lastPoint[0]) * 0.5
      const y = roofPos.y + lastPoint[1] + (point[1] - lastPoint[1]) * 0.5
      const midpoint = { x, y }
      snapLines = snapLines.concat(
        Util.snapLinesForOrthogonalAxesAtPoint(
          midpoint,
          Units.inchesToNative(12)
        )
      )
    }

    return snapLines
  }

  static getPerimeterPointsForRoof(roof) {
    const roofPos = roof.obj3d.position
    const perimeterPoints = roof.perimeterPoints

    const snapPoints = perimeterPoints.map(
      point =>
        new SnapPoint(
          { x: roofPos.x + point[0], y: roofPos.y + point[1] },
          Units.inchesToNative(12)
        )
    )

    return snapPoints
  }

  static getAllWallCenterLines(
    excludedWallIds = [],
    exteriorOnly = false,
    snapRange = 36
  ) {
    if (isTouchUI()) snapRange *= TOUCH_SNAP_RANGE_MULTIPLIER

    // Gather snap lines running through each existing wall segment
    return Facility.current.getWalls().reduce((array, curWall) => {
      // Ignore excluded wall IDs
      if (excludedWallIds.includes(curWall.id)) return array

      // When in exterior only mode ignore all non-exterior walls
      if (exteriorOnly && curWall.layerKey !== 'EXTERIOR_WALLS') return array

      // Snap lines based on the two points comprising each wall segment
      const segmentExtensionSnapLines = []
      curWall.segments.forEach(segment => {
        const points = segment.getCenterLinePoints()

        // There might be more than two points since polyline wall segments
        // have two edges per segment
        for (let i = 0; i < points.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(points[i]),
              Util.arrayPointToObjectPoint(points[i + 1]),
              Units.inchesToNative(snapRange)
            )
          )
        }
      })

      return array.concat(segmentExtensionSnapLines)
    }, [])
  }

  static getAllWallInsetAndOutsetLines(
    excludedWallIds = [],
    isLineSegment = false,
    snapRange = 12
  ) {
    if (isTouchUI()) snapRange *= TOUCH_SNAP_RANGE_MULTIPLIER

    return Facility.current.getWalls().reduce((array, curWall) => {
      if (excludedWallIds.includes(curWall.id)) {
        return array
      }

      // Snap lines based on the two points comprising each wall segment
      const segmentExtensionSnapLines = []
      curWall.segments.forEach(segment => {
        const points = segment.getOutsetPoints()

        // There might be more than two points since polyline wall segments
        // have two edges per segment
        for (let i = 0; i < points.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(points[i]),
              Util.arrayPointToObjectPoint(points[i + 1]),
              Units.inchesToNative(snapRange),
              isLineSegment
            )
          )
        }

        const insetPoints = segment.getInsetPoints()
        for (let i = 0; i < insetPoints.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(insetPoints[i]),
              Util.arrayPointToObjectPoint(insetPoints[i + 1]),
              Units.inchesToNative(snapRange),
              isLineSegment
            )
          )
        }
      })

      return array.concat(segmentExtensionSnapLines)
    }, [])
  }

  static getAllWallOutsetLines(excludedWallIds = []) {
    // Gather snap lines running through each existing wall segment
    return Facility.current.getWalls().reduce((array, curWall) => {
      if (excludedWallIds.includes(curWall.id)) {
        return array
      }

      // Snap lines based on the two points comprising each wall segment
      const segmentExtensionSnapLines = []
      curWall.segments.forEach(segment => {
        const points = segment.getOutsetPoints()

        // There might be more than two points since polyline wall segments
        // have two edges per segment
        for (let i = 0; i < points.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(points[i]),
              Util.arrayPointToObjectPoint(points[i + 1]),
              Units.inchesToNative(6)
            )
          )
        }
      })

      return array.concat(segmentExtensionSnapLines)
    }, [])
  }

  static getOpenWallOutsetLines(
    excludedWallIds = [],
    snapRange = 12,
    thickness = 0
  ) {
    // Gather snap lines running through each existing wall segment
    return Facility.current.getWalls().reduce((array, curWall) => {
      if (excludedWallIds.includes(curWall.id)) {
        return array
      }

      let isClosed = true
      curWall.segments.forEach(seg => {
        if (!seg.nextSegment || !seg.previousSegment) {
          isClosed = false
        }
      })

      if (isClosed) return array

      // Snap lines based on the two points comprising each wall segment
      const segmentExtensionSnapLines = []
      curWall.segments.forEach(segment => {
        const points = segment.getOutsetPoints()

        // There might be more than two points since polyline wall segments
        // have two edges per segment
        for (let i = 0; i < points.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(points[i]),
              Util.arrayPointToObjectPoint(points[i + 1]),
              snapRange,
              false,
              thickness
            )
          )
        }
      })

      return array.concat(segmentExtensionSnapLines)
    }, [])
  }

  static getAllWallInsetLines(
    excludedWallIds = [],
    isLineSegment = false,
    distance = Units.inchesToNative(6),
    thickness = 0
  ) {
    // Gather snap lines running through each existing wall segment
    return Facility.current.getWalls().reduce((array, curWall) => {
      if (excludedWallIds.includes(curWall.id)) {
        return array
      }

      // Snap lines based on the two points comprising each wall segment
      const segmentExtensionSnapLines = []
      curWall.segments.forEach(segment => {
        const points = segment.getInsetPoints()

        // There might be more than two points since polyline wall segments
        // have two edges per segment
        for (let i = 0; i < points.length; i += 2) {
          segmentExtensionSnapLines.push(
            new SnapLine(
              Util.arrayPointToObjectPoint(points[i]),
              Util.arrayPointToObjectPoint(points[i + 1]),
              distance,
              isLineSegment,
              thickness
            )
          )
        }
      })

      return array.concat(segmentExtensionSnapLines)
    }, [])
  }

  static getAllObstructionOutsetLines(
    excludedObstructionIds = [],
    isLineSegment = false
  ) {
    // Gather snap lines running through each existing obstruction
    return Facility.current.getObstructions().reduce((array, curObs) => {
      // We don't want to snap to the edges of the object being dragged
      if (excludedObstructionIds.includes(curObs.id)) {
        return array
      }

      const obstructionSnapLines = []
      const points = curObs.getSnappableEdgePoints()

      for (let i = 0; i < points.length; i += 2) {
        obstructionSnapLines.push(
          new SnapLine(
            Util.arrayPointToObjectPoint(points[i]),
            Util.arrayPointToObjectPoint(points[i + 1]),
            Units.inchesToNative(6),
            isLineSegment
          )
        )
      }

      return array.concat(obstructionSnapLines)
    }, [])
  }

  static getAllColumnOutsetLines(
    excludedColumnIds = [],
    isLineSegment = false,
    snapRange = 12
  ) {
    if (isTouchUI()) snapRange *= TOUCH_SNAP_RANGE_MULTIPLIER

    // Gather snap lines running through each existing column
    return Facility.current.getRoofSections().reduce((array, curRoof) => {
      // We don't want to snap to the edges of the object being dragged
      if (excludedColumnIds.includes(curRoof.id)) {
        return array
      }

      const columnSnapLines = []
      const points = curRoof.getSnappableColumnEdgePoints()

      for (let i = 0; i < points.length; i += 2) {
        columnSnapLines.push(
          new SnapLine(
            Util.arrayPointToObjectPoint(points[i]),
            Util.arrayPointToObjectPoint(points[i + 1]),
            Units.inchesToNative(snapRange),
            isLineSegment
          )
        )
      }

      return array.concat(columnSnapLines)
    }, [])
  }

  static getFilteredSnapRegions(snapRegions) {
    return snapRegions.reduce((acc, current) => {
      const x = acc.find(item => {
        if (item.point1 && item.point2 && current.point1 && current.point2) {
          return Util.linesAreCollinear2D(item, current, 0.75)
        } else {
          return false
        }
      })
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])
  }
}

export default SnapQueries
