import { Line, LineProps } from '@react-three/drei'
import { forwardRef } from 'react'
import { Vector3 } from 'three'
import { Line2 } from 'three/addons/lines/Line2.js'
import theme from '~/config/theme'

export const SnapLine = forwardRef<Line2, Partial<LineProps>>(
  ({ lineWidth = 3, points = [new Vector3(), new Vector3()], ...props }, ref) => {
    return (
      <Line
        ref={ref}
        {...props}
        lineWidth={lineWidth}
        points={points}
        color={theme.colors.three.objects.snapRegion.default}
      />
    )
  }
)
