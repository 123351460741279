import get from 'lodash-es/get'
import { HEATER_QUERY } from '.'

import globalClient from '~/client'

const heaterProductIds = ['24', '25', '26']

export async function getHeaterProducts(client = globalClient) {
  let prods = []
  for (let i = 0; i < heaterProductIds.length; ++i) {
    const id = heaterProductIds[i]
    const productData = await client.query({
      query: HEATER_QUERY,
      variables: { productId: id },
    })
    prods.push(get(productData, 'data.Product'))
  }

  return prods
}

