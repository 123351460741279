import { MenuTrigger } from 'react-aria-components'
import { Button } from '~/ui/Button'
import { motion } from 'framer-motion'
import { startTransition, useState } from 'react'
import { Icon } from '~/ui/Icon'
import { Menu, MenuItem } from '~/ui/Menu'
import { useApolloClient, useQuery } from '@apollo/client'
import {
  GET_ALL_PRODUCTS,
  GET_ALL_VARIATIONS_ON_PRODUCT,
  GET_EXTENSION_TUBES_ON_VARIATIONS,
} from '~/client/queries'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { PRODUCT_CATEGORIES, PRODUCT_TYPES } from '~/config/product'
import { captureEvent, captureException } from '@sentry/react'
import { showAlert } from '~/store/alert'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { Product } from '~/store/objects/types'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'

const { EVAP, FAN, HEAT } = PRODUCT_CATEGORIES
const { DIRECTIONAL, OVERHEAD } = PRODUCT_TYPES

const getThumbnail = (name: string) => {
  return new URL(`/src/assets/thumbnails/${name}.jpg`, import.meta.url).href
}

export const ProductDropDown = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const { data: products, loading } = useQuery(GET_ALL_PRODUCTS)
  const { selectedProducts, productType } = useSelectedProductContext()
  const getFanHeight = useGetFanHeightInches()
  const [isOpen, setIsOpen] = useState(false)

  const productOptions = (products?.Products ?? [])
    .filter(({ category, type, model }) => {
      if (productType === 'PRODUCTS_OVERHEAD') return category === FAN && type === OVERHEAD
      else if (productType === 'PRODUCTS_DIRECTIONAL')
        return category === FAN && type === DIRECTIONAL
      else if (productType === 'PRODUCTS_EVAP') return category === EVAP
      else if (productType === 'PRODUCTS_RADIANT_HEATERS')
        return category === HEAT && model.includes('IRH')
      else if (productType === 'PRODUCTS_UNIT_HEATERS')
        return category === HEAT && !model.includes('IRH')
      else if (productType === 'PRODUCTS') return false
      else {
        captureEvent({ message: `Unexpected product type in product drop down: ${productType}` })
        return false
      }
    })
    .sort((a, b) => (a.sortIndex ?? Infinity) - (b.sortIndex ?? Infinity))
  const selectedProductLabels = Array.from(
    new Set(selectedProducts.map(({ variation }) => variation.model))
  ).join(', ')
  const isDisabled = isLocked || loading

  const handleChangeProduct = (product: typeof productOptions[0]) => async () => {
    try {
      const isHeater = product.category === PRODUCT_CATEGORIES.HEAT
      const { data } = await client.query({
        query: GET_ALL_VARIATIONS_ON_PRODUCT,
        variables: { isHeater, productId: product.id },
      })
      const { variations } = data.Product ?? {}
      const defaultVariationId = data.Product?.defaultVariation?.id
      const defaultVariation = variations?.find(({ id }) => id === defaultVariationId)
      if (!defaultVariation) {
        throw new Error(`Cannot change to product with no default variation: ${product.model}`)
      }
      const variationIds = Array.from(
        new Set(selectedProducts.map(({ product }) => product.variationId))
      )
      const {
        data: { ProductVariations },
      } = await client.query({
        query: GET_EXTENSION_TUBES_ON_VARIATIONS,
        variables: { variationIds },
      })
      const updatedProducts: Product[] = selectedProducts.map(selectedProduct => {
        const matchingVariation =
          variations?.find(variation => {
            const isIRH = selectedProduct.variation.model.includes('IRH')
            const { label, size } = selectedProduct.variation
            return isIRH ? label === variation.label : size === variation.size
          }) ?? defaultVariation

        const defaultVoltage = matchingVariation.voltages?.[0]
        if (!defaultVoltage) {
          throw new Error(`${matchingVariation.product.model} is missing a default voltage`)
        }
        const matchingVoltage =
          matchingVariation.voltages?.find(({ id }) => id === selectedProduct.product.voltageId) ??
          defaultVoltage
        const defaultMountingOption = matchingVoltage.mountingOptions?.[0]

        const currentVariationVoltage = ProductVariations.find(
          ({ id }) => id === selectedProduct.product.variationId
        )?.voltages.find(({ id }) => id === selectedProduct.product.voltageId)
        const currentMountingOption = currentVariationVoltage?.mountingOptions?.find(
          ({ id }) => id === selectedProduct.product.mountingOptionId
        )
        const matchingMountingOption = matchingVoltage.mountingOptions?.find(
          ({ tubeLength }) =>
            currentMountingOption && currentMountingOption?.tubeLength === tubeLength
        )
        const newMountingOption = matchingMountingOption ?? defaultMountingOption

        const defaultMountingOptionAdder = matchingVariation.mountingOptionAdders?.[0]
        const matchingMountingOptionAdder =
          matchingVariation.mountingOptionAdders?.find(
            ({ id }) => selectedProduct.product.mountingOptionAdderId === id
          ) ?? defaultMountingOptionAdder

        const { variation, product } = selectedProduct
        const { position, isDirectionalOverhead } = product
        const { category, type } = variation
        const isOverhead = isDirectionalOverhead || (category === 'FAN' && type === 'OVERHEAD')
        const fullHeight = newMountingOption?.fullHeight ?? 0
        const newPosition = isOverhead
          ? { ...position, z: getFanHeight({ origin: position, fullHeight }) }
          : position
        return {
          ...selectedProduct.product,
          position: newPosition,
          variationId: matchingVariation.id,
          voltageId: matchingVoltage.id,
          mountingOptionId: newMountingOption?.id,
          mountingOptionAdderId: matchingMountingOptionAdder?.id,
        }
      })
      startTransition(() => dispatch(updateProducts(updatedProducts)))
    } catch (error) {
      captureException(error)
      dispatch(showAlert({ text: 'Failed to change product', type: 'error' }))
    }
  }

  return (
    <MenuTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <Button
        isDisabled={isDisabled}
        className="rounded-none flex gap-5 items-center bg-transparent border-0 py-4 hover:bg-black/[5%] pressed:bg-black/10"
      >
        <img
          className="w-16 h-16 object-contain mix-blend-darken"
          src={getThumbnail(selectedProducts[0].variation.model ?? '')}
        />
        <span className="font-bold mr-auto text-ellipsis overflow-hidden text-nowrap">
          {selectedProductLabels}
        </span>
        <motion.div
          initial={{ rotate: 0 }}
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.25 }}
        >
          <Icon size="14" name="arrowDown" color="black" />
        </motion.div>
      </Button>
      <Menu className="max-h-96 w-[250px]" popoverProps={{ crossOffset: 8 }}>
        {productOptions.map(productOption => (
          <MenuItem
            key={productOption.id}
            isDisabled={
              selectedProducts.length === 1 &&
              selectedProducts[0].variation.productId === productOption.id
            }
            onAction={handleChangeProduct(productOption)}
          >
            <img
              className="w-8 h-8 object-contain mix-blend-darken"
              src={getThumbnail(productOption.model)}
            />
            <span>{productOption.model}</span>
          </MenuItem>
        ))}
      </Menu>
    </MenuTrigger>
  )
}
