import { createContext, useContext } from 'react'
import { Box3 } from 'three'
import { useProductMesh } from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import {
  useTransformOverheadFanNode,
  animateOverheadFanNode,
  useTransformDirectionalFanNode,
  animateDirectionalFanNode,
  useTransformRadiantHeaterNode,
} from '~/components/DrawingCanvas/Products/hooks/useTransformNode'
import {
  HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS,
  OVERHEAD_PIVOT_2_0,
} from '~/components/DrawingCanvas/Products/modelNames'
import { vectorModelToUI } from '~/components/DrawingCanvas/util/units'
import { ProductVariation } from '~/gql/graphql'
import { Product } from '~/store/objects/types'

export type ProductPreviewProps = {
  tubeLength: number
  fullHeight: number
  size: number
  model: string
  layerKey: Product['layerKey']
  heaterData?: Pick<
    ProductVariation['heaterData'][number],
    'boxDepthF' | 'boxHeightA' | 'boxWidthB'
  >
}
const ProductPreviewContext = createContext<ProductPreviewProps | null>(null)

const useProductPreviewContext = () => {
  const value = useContext(ProductPreviewContext)
  if (!value) throw new Error('Must use within ProductPreviewContext')
  return value
}

const OverheadFanPreview = () => {
  const { model, tubeLength, size } = useProductPreviewContext()
  const modelName = model === 'Pivot 2.0' ? OVERHEAD_PIVOT_2_0 : model
  const transformNode = useTransformOverheadFanNode(tubeLength, size)
  const productMesh = useProductMesh(modelName, transformNode, animateOverheadFanNode)
  return <primitive object={productMesh} />
}

const DirectionalFanPreview = () => {
  const { model, size, tubeLength } = useProductPreviewContext()
  const isHeightVariable = HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS.has(model)
  const transformNode = useTransformDirectionalFanNode({
    isHeightVariable,
    isDirectionalOverhead: false,
    isForcedWallMount: false,
    diameter: size,
    tubeLength: tubeLength ?? 0,
  })
  const productMesh = useProductMesh(model, transformNode, animateDirectionalFanNode)
  return <primitive object={productMesh} />
}

const EvaporativeCoolerPreview = () => {
  const { model } = useProductPreviewContext()
  const productMesh = useProductMesh(model)
  return <primitive object={productMesh} />
}

const RadiantHeaterPreview = () => {
  const { size, model } = useProductPreviewContext()
  const transformNode = useTransformRadiantHeaterNode({ size })
  const productMesh = useProductMesh(model, transformNode)
  const boundingBox = new Box3().setFromObject(productMesh)
  const { min, max } = boundingBox
  const widthOffset = -Math.max(Math.abs(max.x - min.x), Math.abs(max.y - min.y)) / 2
  return <primitive object={productMesh} position-x={widthOffset} />
}

const UnitHeaterPreview = () => {
  const { heaterData, model } = useProductPreviewContext()
  const { boxDepthF, boxHeightA, boxWidthB } = heaterData!
  const productMesh = useProductMesh(model)
  const scale = vectorModelToUI({ x: boxWidthB!, y: boxHeightA!, z: boxDepthF! })
  return <primitive object={productMesh} scale={scale} />
}

export const ProductPreview = (props: ProductPreviewProps) => {
  const { layerKey, model } = props
  const isIRH = model.includes('IRH')
  return (
    <ProductPreviewContext.Provider value={props}>
      {(() => {
        if (layerKey === 'PRODUCTS_OVERHEAD') return <OverheadFanPreview />
        else if (layerKey === 'PRODUCTS_DIRECTIONAL') return <DirectionalFanPreview />
        else if (layerKey === 'PRODUCTS_EVAP') return <EvaporativeCoolerPreview />
        else if (layerKey === 'PRODUCTS_HEATERS') {
          return isIRH ? <RadiantHeaterPreview /> : <UnitHeaterPreview />
        } else return <></>
      })()}
    </ProductPreviewContext.Provider>
  )
}
