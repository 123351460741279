import { ExtrudeGeometry, Mesh, Raycaster, Shape, Vector2, Vector3, Vector3Like } from "three"
import { createAppAsyncThunk } from "../hooks"
import { Dimension } from "./types"
import LayerKeys from "~/config/layerKeys"
import { guid } from "~/lib/utils"
import { objectModelToUI } from "~/components/DrawingCanvas/util/units"
import { addDimension } from "./actions"
import { productDistanceEngine } from "~/components/DrawingCanvas/lib/productDistanceEngine"
import { getIgnoringIds } from "~/components/DrawingCanvas/Products/util"

const VERTICAL_DIMENSIONS = [
  new Vector3(0, 1, 0),
  new Vector3(0, -1, 0),
]
const HORIZONTAL_DIMENSIONS = [
  new Vector3(1, 0, 0),
  new Vector3(-1, 0, 0),
]
const DIMENSIONS = [...VERTICAL_DIMENSIONS, ...HORIZONTAL_DIMENSIONS]

function makeDimension(from: Vector3Like, to: Vector3Like): Dimension {
  return {
    id: guid(),
    layerKey: LayerKeys.DIMENSIONS,
    startPos: { ...from, z: 0 },
    endPos: { ...to, z: 0 },
    labelPos: { ...new Vector3().addVectors(from, to).divideScalar(2), z: 0 },
    labelOffset: 0.5,
    anchorStartPos: { ...objectModelToUI(from), z: 0 },
    anchorEndPos: { ...objectModelToUI(to), z: 0 },
    snapToMousePosition: true,
    dimensionFinished: true,
  }
}

export const generateProductDimensions = createAppAsyncThunk(
  'objects/generateProductDimensions',
  async (payload: { productIDs: string[]; flags: { walls: boolean; products: boolean; obstructions: boolean }}, { getState, dispatch, extra }) => {
    const state = getState()

    const exteriorWalls =
      Object.values(state.objects.present.segments)
        .filter(segment =>
          segment.layerKey === 'EXTERIOR_WALLS')
        .map(segment => {
          const shape = new Shape()
            .moveTo(segment.insetPoints.start.x, segment.insetPoints.start.y)
            .lineTo(segment.insetPoints.end.x, segment.insetPoints.end.y)
            .lineTo(segment.outsetPoints.end.x, segment.outsetPoints.end.y)
            .lineTo(segment.outsetPoints.start.x, segment.outsetPoints.start.y)
            .closePath()

          const mesh = new Mesh(new ExtrudeGeometry(shape, {
            steps: 1,
            depth: 1,
            bevelEnabled: false,
          }))

          return { ...segment, mesh }
        })

    const obstructions =
      Object.values(state.objects.present.obstructions)
        .map(obstruction => {
          const shape = new Shape(obstruction.positions.map(pos => new Vector2().copy(pos).sub(obstruction.position))).closePath()

          const mesh = new Mesh(new ExtrudeGeometry(shape, {
            steps: 1,
            depth: obstruction.height,
            bevelEnabled: false,
          }))
          mesh.position.copy(obstruction.position)
          mesh.rotation.set(0, 0, (obstruction.rotation.z * Math.PI) / 180)
          mesh.geometry.computeBoundingSphere()
          mesh.updateMatrixWorld()

          return {
            ...obstruction,
            mesh,
          }
        })

    for (const productID of payload.productIDs) {
      const { products } = state.objects.present
      const product = products[productID]
      if (product === undefined) {
        continue
      }

      const position = new Vector3().copy(product.position)
      const ray = new Raycaster(position)

      if (payload.flags.walls) {
        position.copy(product.position).setZ(0)

        let horizontalDistance = Infinity
        let horizontalTarget = new Vector3()
        let verticalDistance = Infinity
        let verticalTarget = new Vector3()

        exteriorWalls.forEach(wall => {
          HORIZONTAL_DIMENSIONS.forEach(direction => {
            ray.set(position, direction)

            const intersections = ray.intersectObject(wall.mesh)

            for (const intersection of intersections) {
              if (intersection.distance <= horizontalDistance) {
                horizontalTarget.copy(intersection.point)
                horizontalDistance = intersection.distance
              }
            }
          })
          VERTICAL_DIMENSIONS.forEach(direction => {
            ray.set(position, direction)

            const intersections = ray.intersectObject(wall.mesh)

            for (const intersection of intersections) {
              if (intersection.distance <= verticalDistance) {
                verticalTarget.copy(intersection.point)
                verticalDistance = intersection.distance
              }
            }
          })
        })

        if (isFinite(horizontalDistance)) {
          dispatch(addDimension({ dimension: makeDimension(position, horizontalTarget) }))
        }
        if (isFinite(verticalDistance)) {
          dispatch(addDimension({ dimension: makeDimension(position, verticalTarget) }))
        }
      }
      if (payload.flags.obstructions) {
        position.copy(product.position)

        let distance = Infinity
        let target = new Vector3()

        obstructions.forEach(obstruction => {
          DIMENSIONS.forEach(dimension => {
            ray.set(position, dimension)

            const intersections = ray.intersectObject(obstruction.mesh)

            for (const intersection of intersections) {
              if (intersection.distance <= distance) {
                target.copy(intersection.point)
                distance = intersection.distance
              }
            }
          })
        })

        if (isFinite(distance)) {
          dispatch(addDimension({ dimension: makeDimension(position, target) }))
        }
      }
      if (payload.flags.products) {
        let distance = Infinity
        let target = new Vector3()

        const ignoring = getIgnoringIds(products, productID)
        for (const direction of DIMENSIONS) {
          const result = productDistanceEngine.getNearestOnAxisProductDistance({ origin: product.position, direction, ignoring })
          if (!result || result.distance > distance) continue
          distance = result.distance
          target.copy(result.productPosition)
        }

        if (isFinite(distance)) {
          position.copy(product.position)
          dispatch(addDimension({ dimension: makeDimension(position, target) }))
        }
      }
    }

    return true
  }
)
