import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withProps } from 'recompact'

import LAYER_KEYS from 'config/layerKeys'
import { appendURL } from 'lib/utils'

import { hasPermission } from 'components/RequiredPermission'

import TouchPopover from '../styled/TouchPopover'
import ItemMenu from '../ItemMenu'
import AddItemMenuItem from '../AddItemMenuItem'
import withStandardProps from '../withStandardProps'

const AnnotationsMenu = ({
  history,
  hideComfortZone,
  insertItem,
  match,
}) => {
  const items = []

  items.push({
    title: 'Measurement',
    icon: {
      name: 'ruler',
      size: '14',
    },
    onClick: () =>
      insertItem({
        layerKey: LAYER_KEYS.DIMENSIONS,
        tool: 'DIMENSION_TOOL',
      }),
  })

  if (!hideComfortZone) {
    items.push({
      title: 'Comfort Zone',
      icon: {
        name: 'comfortZone',
        size: '14',
      },
      onClick: () =>
        insertItem({
          layerKey: LAYER_KEYS.COMFORT_ZONES,
          tool: 'COMFORT_ZONE_TOOL',
        }),
    })
  }
  items.push({
    title: 'Background Image',
    icon: {
      name: 'image',
      size: '14',
    },
    variant: 'light',
    subMenu: {
      position: 'bottom',
      items: [
        {
          title: 'Upload Image',
          icon: {
            name: 'cloudUpload',
            size: '14',
          },
          onClick: () =>
            history.push(appendURL(match.url, 'upload-image/background')),
        },
        {
          title: 'Take a Picture',
          icon: {
            name: 'camera',
            size: '14',
          },
          onClick: () =>
            history.push(appendURL(match.url, 'webcam-capture/background')),
        },
      ],
    },
  })

  return (
    <TouchPopover
      renderMenu={({ isDropdownVisible, handleTriggerClick }) => (
        <ItemMenu
          title="Annotations"
          isVisible={isDropdownVisible}
          items={items}
          closeMenu={handleTriggerClick}
        />
      )}
      renderTrigger={({ isDropdownVisible }) => (
        <AddItemMenuItem
          icon="ruler"
          isDropdownVisible={isDropdownVisible}
          label="Annotations"
        />
      )}
    />
  )
}

AnnotationsMenu.propTypes = {
  history: PropTypes.object,
  hideComfortZone: PropTypes.bool,
  insertItem: PropTypes.func,
  match: PropTypes.object,
}

export default compose(
  hasPermission({
    name: 'Comfort Zones',
    withPermissions: true,
  }),
  withProps(props => ({
    hideComfortZone: props.hideComfortZone || !props.permissions.isAllowed,
    loading: props.loading || props.permissions.isLoading,
  })),
  withStandardProps
)(AnnotationsMenu)
