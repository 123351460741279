import { Vector3 } from 'three'

export const POSITIVE_Z = new Vector3(0, 0, 1)

export const NEGATIVE_Z = new Vector3(0, 0, -1)

export const FLOOR = 0

export const CARDINALS = [
  new Vector3(0, 1, 0),
  new Vector3(0, -1, 0),
  new Vector3(1, 0, 0),
  new Vector3(-1, 0, 0),
] as const

export const DIMENSIONS: Readonly<Vector3[]> = [
  new Vector3(1, 1, 0),
  new Vector3(1, -1, 0),
  new Vector3(-1, 1, 0),
  new Vector3(-1, -1, 0),
]
  .map(v => v.normalize())
  .concat(CARDINALS)
