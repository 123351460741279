import { createContext } from 'react'
import { Product, ProductVariation } from '~/gql/graphql'
import { Product as StoreProduct } from '~/store/objects/types'

export type SelectedProductContextProps = {
  selectedProducts: {
    product: StoreProduct
    variation: {
      productId: string
      label: string | null | undefined
      isTiltable: boolean
    } & Pick<ProductVariation, 'canMountOnWall' | 'size' | 'canMountOverhead'> &
      Pick<Product, 'model' | 'category' | 'type'>
  }[]
  productType:
    | Exclude<StoreProduct['layerKey'], 'PRODUCTS_HEATERS'>
    | 'PRODUCTS_UNIT_HEATERS'
    | 'PRODUCTS_RADIANT_HEATERS'
}

type SelectedProductContextValue = {
  productId: string | null
  variationId: string | null
  voltageId: string | null
  isForcedWallMount: boolean
  isDirectionalOverhead: boolean
  isHeater: boolean
} & SelectedProductContextProps

export const SelectedProductContext = createContext<SelectedProductContextValue | null>(null)
