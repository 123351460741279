import { useApolloClient, useQuery } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { useState, useTransition } from 'react'
import { Key } from 'react-aria-components'
import { Box3, Vector3 } from 'three'
import { GET_PRODUCT_VARIATION, GET_VARIATION_SIZES } from '~/client/queries'
import { CARDINALS } from '~/components/DrawingCanvas/constants/dimensions'
import { getHeaterDimensions, productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useFormatLength } from '~/hooks/useFormatLength'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { setStatus } from '~/store/status'
import { Select, SelectItem } from '~/ui/Select'

export const ProductSizeSelect = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()

  const formatSize = useFormatLength()
  const { isHeater, productId, selectedProducts, variationId } = useSelectedProductContext()
  const { data } = useQuery(GET_VARIATION_SIZES, {
    variables: { productId: productId ?? '', isHeater },
    skip: !productId,
  })

  const [isPendingTransition, startTransition] = useTransition()
  const [isPending, setIsPending] = useState(false)

  const label = isHeater ? 'Size' : 'Diameter'
  const variations = data?.Product.variations ?? []
  const isDisabled = isLocked || isPending || isPendingTransition

  const handleChangeVariation = async (newVariationId: Key) => {
    if (typeof newVariationId !== 'string' || isDisabled || !data) return
    try {
      setIsPending(true)
      const { ProductVariation } = (
        await client.query({
          query: GET_PRODUCT_VARIATION,
          variables: { variationId: newVariationId, isHeater },
        })
      ).data
      const defaultVoltage = ProductVariation.voltages[0]
      const defaultMountingOption = defaultVoltage?.mountingOptions?.[0]
      const updatedProducts = selectedProducts.map(({ product }) => {
        const { mountingOptionId, voltageId, variationId } = product
        const currentVariation = data.Product.variations.find(({ id }) => id === variationId)
        const currentVoltage = currentVariation?.voltages.find(({ id }) => id === voltageId)
        const currentTubeLength = currentVoltage?.mountingOptions?.find(
          ({ id }) => id === mountingOptionId
        )?.tubeLength
        const matchingVoltage = ProductVariation.voltages.find(({ id }) => id === voltageId)
        const newVoltage = matchingVoltage ?? defaultVoltage
        const matchingMountingOption = newVoltage?.mountingOptions?.find(
          ({ tubeLength }) => tubeLength === currentTubeLength
        )
        const newMountingOption = matchingMountingOption ?? defaultMountingOption
        return {
          ...product,
          id: product.id,
          variationId: ProductVariation.id,
          voltageId: newVoltage.id,
          mountingOptionId: newMountingOption?.id ?? mountingOptionId,
          mountingOptionAdderId: ProductVariation.mountingOptionAdders[0]?.id ?? 'Unknown',
        }
      })
      const isRadiantHeater = ProductVariation.product.model.includes('IRH')
      const isTouchingWallOrRoof = updatedProducts.some(({ position, rotation }) => {
        if (isHeater) {
          const heaterRotation = isRadiantHeater ? rotation.y : undefined
          const {
            width,
            depth,
            height
          } = getHeaterDimensions(ProductVariation.heaterData, heaterRotation)
          const size = isRadiantHeater
            ? heaterRotation === 90 || heaterRotation === 270
              ? new Vector3(depth, width, height)
              : new Vector3(width, height, depth)
            : new Vector3(width, depth, height)
          const heaterBox = new Box3().setFromCenterAndSize(new Vector3().copy(position), size)
          return CARDINALS.some(direction => {
            const distance = productDistanceEngine.getNearestCollisionDistance({
              origin: new Vector3().copy(position),
              direction,
              ignoring: new Set(updatedProducts.map(({ id }) => id))
            })
            if (!distance) return false 
            const target = new Vector3().copy(direction).multiplyScalar(distance).add(position)
            return heaterBox.containsPoint(target)
          })
        } else {
          const distance = productDistanceEngine.getNearestRoofOrWallCollisionDistance(position)
          const radius = ProductVariation.size / 2
          return distance < radius
        }
      })
      if (isTouchingWallOrRoof) {
        const text = 'Resizing Fan Not Allowed Due To Size Restraints'
        dispatch(setStatus({ text, type: 'error' }))
      } else {
        startTransition(() => dispatch(updateProducts(updatedProducts)))
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : `Missing variation: ${newVariationId}`
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change product size' }))
    } finally {
      setIsPending(false)
    }
  }

  return (
    <Select
      label={label}
      selectedKey={variationId}
      onSelectionChange={handleChangeVariation}
      items={variations}
      isDisabled={isDisabled}
    >
      {variation => (
        <SelectItem value={variation}>
          {isHeater ? variation.label! : formatSize(variation.size)}
        </SelectItem>
      )}
    </Select>
  )
}
