import { Html } from '@react-three/drei'
import { forwardRef, useRef, useImperativeHandle } from 'react'
import { ArrowHelper, Mesh, Vector3 } from 'three'
import { NEGATIVE_Z } from '~/components/DrawingCanvas/constants/dimensions'
import { ARROW_SIZE, LINE_COLOR } from '~/components/DrawingCanvas/Products/constants'
import { modelToUI, uiToModel } from '~/components/DrawingCanvas/util/units'
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions'
import { useAppSelector } from '~/store/hooks'

export type HeightRef = {
  updateHeight(newZ: number): void
}

export const HeightIndicator = forwardRef<HeightRef, { height: number }>(({ height }, ref) => {
  const { displayFormattedValue } = useDistanceDimensions()
  const isVisibleLayer = useAppSelector(state => state.layers.layers.PRODUCT_HEIGHTS.visible)
  const is3D = useAppSelector(state => state.camera.is3D)
  const arrowRef = useRef<ArrowHelper>(null!)
  const htmlMeshRef = useRef<Mesh>(null)
  const divRef = useRef<HTMLDivElement>(null)

  const isVisible = height > 0 && isVisibleLayer
  const uiHeight = modelToUI(height)
  const divHeight = -uiHeight / 2

  useImperativeHandle(
    ref,
    () => ({
      updateHeight(newZ: number) {
        arrowRef.current.setLength(newZ, ARROW_SIZE, ARROW_SIZE)
        htmlMeshRef.current?.position.setZ(-newZ / 2)
        if (divRef.current) divRef.current.textContent = displayFormattedValue(uiToModel(newZ))
      },
    }),
    []
  )

  return (
    <group visible={isVisible}>
      {is3D && isVisible && (
        <mesh ref={htmlMeshRef} position-z={divHeight}>
          <Html
            zIndexRange={[0, 0]}
            center
            ref={divRef}
            className="bg-white whitespace-nowrap rounded text-xs text-gray-500 px-0.5"
          >
            {displayFormattedValue(height)}
          </Html>
        </mesh>
      )}
      <arrowHelper
        ref={arrowRef}
        args={[NEGATIVE_Z, new Vector3(), uiHeight, LINE_COLOR, ARROW_SIZE, ARROW_SIZE]}
      />
    </group>
  )
})
