import React, { useReducer } from 'react'
import { Helmet } from 'react-helmet'
import { appConnect } from "~/store/hooks";
import { func, object, string, bool, array } from 'prop-types'
import { compose } from 'redux'
import get from 'lodash-es/get'
import qs from 'qs'

import { getTitle } from 'config/titles'

import { updateObjects } from 'store/objects'
import { SYSTEMS } from 'store/units/constants'
import { withUnits } from 'store/units/decorators'
import { getDistanceUnits } from 'store/units/selectors'
import { Distance } from 'store/units/types'

import Button from 'components/UIKit/Button'
import Checkbox from 'components/UIKit/Checkbox'
import DimensionInput from 'components/UIKit/DimensionInput'
import Flex from 'components/UIKit/Flex'
import FormLabel from 'components/UIKit/FormLabel'
import Modal from 'components/UIKit/Modal'
import Select from 'components/UIKit/Select'
import Space from 'components/UIKit/Space'
import TextField from 'components/UIKit/TextField'
import Tooltip from 'components/UIKit/Tooltip'
import VariantText from 'components/UIKit/VariantText'
import { isComplete } from 'components/AddInstallInfoButton'
import Facility from 'components/DrawingCanvas/lib/facility'

import AddersColumn from './AddersColumn'
import { useSelectedProducts } from '~/hooks/useSelectedProducts';

const makeOptions = arr => arr.map(val => ({ label: val, key: val }))

const fanAdderOptions = [
  'Z-Purlin Kit',
  'L-Bracket Kit',
  'Wood Frame Kit',
  'LED Light Kit',
  'No Ass Packaging',
  'Move a light',
  'Other',
]

const installAdderOptions = [
  'After Hours Install',
  'Custom colors',
  'Electrical runs outside of scope',
  'Painted conduit',
  'Permits required',
  'Prevailing wage',
  'Union labor',
  'Rigid conduit',
  'Structural Engineering',
  'Training classes required',
  'Wall puncture',
  'Other',
]

// The adder checkboxes are displayed in two columns,
// so we split them here.
const getAdderOptionsColumns = options => {
  const halfSize = options.length / 2
  const leftColumn = options.filter((_, index) => index <= halfSize)
  const rightColumn = options.filter((_, index) => index > halfSize)

  return {
    leftColumn,
    rightColumn,
  }
}

const fireRelayTypes = ['Within Scope', 'Outside of Scope']

const fireRelayTypeOptions = makeOptions(fireRelayTypes)

const NOT_REQUIRED_FIELDS = [
  'adders',
  'installAdders',
  'fireRelay',
  'fireRelayType',
  'level',
  'liftType',
]

/**
 * Returns true or false based on what data
 * has been filled out.
 */
const validateFields = state =>
  Object.keys(state.fields)
    // We're filtering out the `NOT_REQUIRED_FIELDS` from the check.
    .filter(f => !NOT_REQUIRED_FIELDS.includes(f))
    .every(key => {
      const fieldValue = state.fields[key]
      const fieldCompleted = isComplete(fieldValue)
      /**
       * `adderOther` is only required when the "Other" `adder`
       * option is checked.
       * `liftType` is only required when `liftNeeded` is checked.
       * `level` is only required when `level` is not "Select...".
       */
      switch (key) {
        case 'adderOther':
          return state.fields.adders.includes('Other') ? fieldCompleted : true
        case 'fireRelayType':
          return state.fields.fireRelay ? fieldCompleted : true
        case 'installTube':
          return state.fields.installTube === 0 ? true : fieldCompleted
        default: {
          return fieldCompleted
        }
      }
    })

function reducer(state, action) {
  switch (action.type) {
    case 'update': {
      const newFields = { ...state.fields, ...action.payload }
      const _state = {
        ...state,
        fields: newFields,
      }
      const completed = validateFields(_state)
      return {
        ...state,
        completed,
        fields: newFields,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

/**
 * Initial state before lazily setting `state.completed`
 * via lazyInitialState function
 */
const initState = selectedProduct => ({
  completed: false,
  fields: {
    heightToAttachPoint: selectedProduct.heightToAttachPoint || 0,
    deckHeight: selectedProduct.deckHeight || 0,
    installTube: selectedProduct.installTube || 0,
    adders: selectedProduct.adders || [],
    adderOther: selectedProduct.adderOther || null,
    installAdders: selectedProduct.installAdders || [],
    level: selectedProduct.level || '',
    liftNeeded: selectedProduct.liftType ? true : false,
    liftType: selectedProduct.liftType || '',
    fireRelay: selectedProduct.fireRelay || false,
    fireRelayType: selectedProduct.fireRelayType || '',
  },
})

// Lazily initialize state to correctly set `state.completed`
const lazyInitState = state => ({
  ...state,
  completed: validateFields(state),
})

const AddProductInstallInfoModal = ({
  distanceUnits,
  onUpdateProducts,
  parentRoute,
  selectedProduct,
  selectedObjects,
  online,
  ...props
}) => {
  const selectedProducts = useSelectedProducts()
  const [state, dispatch] = useReducer(
    reducer,
    initState(selectedProduct),
    lazyInitState
  )
  const handleSubmit = event => {
    event.preventDefault()
    if (!state.completed) return false
    /**
     * We only want to include the `adderOther` value in the data when
     * "Other" is selected as an `adder` option. We only want to include
     * `liftType` when `liftNeeded` is checked.
     */
    const _adderOther = state.fields.adders.includes('Other')
      ? state.fields.adderOther
      : null
    const _liftType = state.fields.liftType || null

    const updatedProducts = selectedProducts
      .map(prod => {
        return {
          ...prod,
          ...state.fields,
          adderOther: _adderOther,
          liftType: _liftType,
        }
      })

    onUpdateProducts(updatedProducts)
    props.history.push(parentRoute)
  }

  function handleInputChange(key, value) {
    dispatch({
      type: 'update',
      payload: { [key]: value },
    })
  }

  function handleDimensionInputChange(distance, key) {
    const value = distance.imperial() || 0
    handleInputChange(key, value)
  }

  const getDimensionInputProps = key => ({
    inline: true,
    labelWidth: '130px',
    name: key,
    units: distanceUnits,
    distance: new Distance({
      value: state.fields[key],
      system: SYSTEMS.IMPERIAL,
    }),
    onChange: ({ distance }) => handleDimensionInputChange(distance, key),
  })

  const handleAddersInputChange = (option, key) => {
    const isChecked = state.fields[key].includes(option)
    if (isChecked) {
      handleInputChange(
        key,
        state.fields[key].filter(a => a !== option)
      )
    } else {
      handleInputChange(key, [...state.fields[key], option])
    }
  }

  const dimensionFields = [
    { key: 'heightToAttachPoint', label: 'Height to Attachment Point' },
    { key: 'deckHeight', label: 'Deck Height' },
  ]

  const disableSubmit = !state.completed || !online

  const fanAdderColumns = getAdderOptionsColumns(fanAdderOptions)
  const installAdderColumns = getAdderOptionsColumns(installAdderOptions)

  return (
    <div>
      {/*
        Providing children to the Helmet component when using the
        useEffect hook causes the app to crash.
        https://github.com/nfl/react-helmet/issues/437
      */}
      <Helmet title={getTitle('installInfo')} />
      <Modal
        title="Install Information"
        onSubmit={handleSubmit}
        parentRoute={parentRoute}
        primaryAction={
          disableSubmit ? (
            <Tooltip
              content={
                online
                  ? 'All highlighted fields are required'
                  : 'Internet connection required to update install information'
              }
            >
              {/*
                Tooltips around a disabled Button require a wrapping element
                because of how browsers handle events on disabled buttons.
              */}
              <div>
                <Button primary label="Submit" disabled={true} />
              </div>
            </Tooltip>
          ) : (
            <Button primary label="Submit" />
          )
        }
        secondaryAction={<Button to={parentRoute} label="Cancel" />}
        {...props}
      >
        <Space bottom="s">
          <VariantText color="light">Structure & Measurements</VariantText>
        </Space>
        <>
          {dimensionFields.map((field, idx) => (
            <Space key={idx} bottom="base">
              <DimensionInput
                label={field.label}
                {...getDimensionInputProps(field.key)}
                required={!get(state.fields, field.key)}
              />
            </Space>
          ))}
          <Space>
            <DimensionInput
              label="Extention Tube Length"
              {...getDimensionInputProps('installTube')}
              required={false}
            />
          </Space>
          <Space bottom="s" top="base">
            <VariantText color="light">Install & Adders</VariantText>
          </Space>
          <Space bottom="base">
            <Space bottom="s">
              <FormLabel>Fan Adders</FormLabel>
            </Space>
            <Flex>
              <AddersColumn
                options={fanAdderColumns.leftColumn}
                onChange={handleAddersInputChange}
                addersValue={state.fields.adders}
                type="adders"
              />
              <AddersColumn
                options={fanAdderColumns.rightColumn}
                onChange={handleAddersInputChange}
                addersValue={state.fields.adders}
                type="adders"
              />
            </Flex>

            {state.fields.adders.includes('Other') && (
              <Space top="s">
                <TextField
                  name="adderOther"
                  label="Please specify:"
                  value={state.fields.adderOther}
                  width="50%"
                  onChange={event =>
                    handleInputChange(event.target.name, event.target.value)
                  }
                />
              </Space>
            )}
          </Space>

          {state.fields.level !== '' && (
            <Space bottom="xs">
              <Checkbox
                name="fireRelay"
                label="Fire Relay"
                onChange={event =>
                  handleInputChange(event.target.name, !state.fields.fireRelay)
                }
                value={state.fields.fireRelay}
                checked={state.fields.fireRelay}
              />
            </Space>
          )}
          {state.fields.fireRelay && (
            <Space bottom="base">
              <Select
                name="fireRelayType"
                label="Type of Fire Relay"
                onChange={event =>
                  handleInputChange(event.target.name, event.target.value)
                }
                value={state.fields.fireRelayType}
                options={fireRelayTypeOptions}
              />
            </Space>
          )}
          {state.fields.level !== '' && (
            <Space bottom="base">
              <Space bottom="s">
                <FormLabel>Install Adders</FormLabel>
              </Space>
              <Flex>
                <AddersColumn
                  options={installAdderColumns.leftColumn}
                  onChange={handleAddersInputChange}
                  addersValue={state.fields.installAdders}
                  type="installAdders"
                />
                <AddersColumn
                  options={installAdderColumns.rightColumn}
                  onChange={handleAddersInputChange}
                  addersValue={state.fields.installAdders}
                  type="installAdders"
                />
              </Flex>
            </Space>
          )}
        </>
      </Modal>
    </div>
  )
}

AddProductInstallInfoModal.propTypes = {
  distanceUnits: string,
  history: object,
  onUpdateProducts: func,
  parentRoute: string,
  selectedProduct: object,
  selectedObjects: array,
  online: bool,
}

const mapStateToProps = ({ objects, selectedObjects, ...store }, ownProps) => {
  const queryParams = qs.parse(get(ownProps, 'location.search'), {
    ignoreQueryPrefix: true,
  })

  const objectId = get(queryParams, 'objectId')
  const selectedProduct = get(objects.present, `products[${objectId}]`)

  const distanceUnits = getDistanceUnits(store)

  return {
    distanceUnits,
    selectedProduct,
    selectedObjects,
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateProducts(products) {
    dispatch(updateObjects(products))
  },
})

export default compose(
  appConnect(mapStateToProps, mapDispatchToProps),
  withUnits
)(AddProductInstallInfoModal)
