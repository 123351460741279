import { useAppSelector } from '~/store/hooks'
import { Product as IProduct } from '~/store/objects/types'
import { Suspense } from 'react'
import { OverheadFan } from '~/components/DrawingCanvas/Products/components/OverheadFan'
import { DirectionalFan } from '~/components/DrawingCanvas/Products/components/DirectionalFan'
import { EvaporativeCooler } from '~/components/DrawingCanvas/Products/components/EvaporativeCooler'
import { UnitHeater } from '~/components/DrawingCanvas/Products/components/UnitHeater'
import { RadiantHeater } from '~/components/DrawingCanvas/Products/components/RadiantHeater'
import { LoadingIndicator } from '~/components/DrawingCanvas/Products/components/LoadingIndicator'
import { Vector3 } from 'three'
import { scaleModelVectorToUI } from '~/components/DrawingCanvas/util/units'
import { ErrorIndicatorsProvider } from '~/components/DrawingCanvas/Products/contexts/ErrorIndicatorsContext'
import { useSuspenseQuery } from '@apollo/client'
import { graphql } from '~/gql'
import { captureEvent } from '@sentry/react'
import {
  DIRECTIONAL_3D_MODELS,
  HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS,
  OVERHEAD_3D_MODELS,
} from '~/components/DrawingCanvas/Products/modelNames'
import { MultiProductDragControls } from '~/components/DrawingCanvas/Products/components/MultiProductDragControls'

const Product = (product: IProduct) => {
  const { variationId } = product
  const { data } = useSuspenseQuery(
    graphql(`
      query ProductCategoryQuery($variationId: ID!) {
        ProductVariation(id: $variationId) {
          id
          product {
            id
            model
            category
            type
          }
        }
      }
    `),
    { variables: { variationId } }
  )
  const {
    product: { category, model },
  } = data.ProductVariation
  // Pivot 2.0 is a special case because it can be considered overhead or directional, depending on configuration
  const isOverheadPivot2 = model === 'Pivot 2.0' && product.isDirectionalOverhead
  if (OVERHEAD_3D_MODELS.has(model) || isOverheadPivot2) {
    return <OverheadFan {...product} />
  } else if (DIRECTIONAL_3D_MODELS.has(model) || HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS.has(model)) {
    const isHeightVariable = HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS.has(model)
    return <DirectionalFan isHeightVariable={isHeightVariable} {...product} />
  } else if (category === 'EVAP') {
    return <EvaporativeCooler {...product} />
  } else if (category === 'HEAT') {
    const isRadiantHeater = model.includes('IRH')
    return isRadiantHeater ? <RadiantHeater {...product} /> : <UnitHeater {...product} />
  } else {
    captureEvent({ message: `Invalid model passed to Product component: ${model}` })
    return <></>
  }
}

export const Products = () => {
  const products = useAppSelector(state => state.objects.present.products)
  return (
    <ErrorIndicatorsProvider>
      <MultiProductDragControls>
        {Object.values(products).map(product => {
          const { x, y, z } = product.position
          const position = scaleModelVectorToUI(new Vector3(x, y, z))
          return (
            <Suspense key={product.id} fallback={<LoadingIndicator position={position} />}>
              <Product {...product} />
            </Suspense>
          )
        })}
      </MultiProductDragControls>
    </ErrorIndicatorsProvider>
  )
}
