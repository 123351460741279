import { Box3, BoxGeometry, BufferGeometry, CylinderGeometry, DoubleSide, ExtrudeGeometry, Line3, MathUtils, Mesh, MeshBasicMaterial, Object3D, Ray, Raycaster, Shape, Triangle, Vector2Like, Vector3, Vector3Like } from "three"
import store, { AppStore } from "~/store"
import { Door, ElevationPoint, Obstruction, Product, Roof, UtilityBox, Wall, WallSegment, ElevationLine, RoofSection } from "~/store/objects/types"
import { weakMapMemoize } from "@reduxjs/toolkit"
import { ApolloClient, NormalizedCache } from "@apollo/client"
import { graphql } from "~/gql"
import client from "~/client"
import cdt2d from 'cdt2d'
import Primitives from "./primitives"
import { modelToUI, vectorUIToModel } from "../util/units"
import { CARDINALS, DIMENSIONS, FLOOR, POSITIVE_Z } from "~/components/DrawingCanvas/constants/dimensions"
import Util from "~/components/DrawingCanvas/lib/util"
import { ProductDistanceEngineHeaterDataQuery } from "~/gql/graphql"

type Tuple<T, L extends number> = L extends L ? number extends L ? T[] : _TupleOf<T, L, []> : never
type _TupleOf<T, L extends number, R extends unknown[]> = R['length'] extends L ? R : _TupleOf<T, L, [T, ...R]>

function slidingWindow<T, L extends number>(array: T[], windowSize: L): Tuple<T, L>[] {
  return Array.from(
    { length: array.length - (windowSize - 1) },
    (_, idx) => array.slice(idx, idx+windowSize)
  ) as Tuple<T, L>[]
}

function negate({ x, y, z }: Vector3Like) {
  return { x: -x, y: -y, z: -z }
}
function add({ x: x1, y: y1, z: z1 }: Vector3Like, { x: x2, y: y2, z: z2 }: Vector3Like) {
  return { x: x1+x2, y: y1+y2, z: z1+z2 }
}
function sub(a: Vector3Like, b: Vector3Like) {
  return add(a, negate(b))
}
function magnitude({ x, y, z }: Vector3Like) {
  return Math.sqrt(x*x + y*y + z*z)
}

function pointInPolygon(point: Vector2Like, points: Vector2Like[]) {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  const x = point.x
  const y = point.y

  let inside = false
  let i = 0
  for (
    let j = points.length - 1;
    i < points.length;
    j = i - 1
  ) {
    const xi = points[i].x
    const yi = points[i].y
    const xj = points[j].x
    const yj = points[j].y

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside

    i += 1
  }

  return inside
}

function isWithinFacility(position: Vector3Like, walls: Readonly<Record<string, Wall>>, segments: Readonly<Record<string, WallSegment>>): boolean {
  for (const wall of Object.values(walls)) {
    // if it's not a wall we don't care
    if (wall.layerKey !== "EXTERIOR_WALLS") {
      continue
    }
    // if it's not enclosed we also don't care
    if (!wall.isEnclosed) {
      continue
    }
    const segmentIDs = wall.segments

    // search for a discontinuity
    const windows = slidingWindow(segmentIDs, 2)
    let discontinuity: [string, string] | undefined = undefined
    for (const [prevID, nextID] of windows) {
      const nextSegment = segments[nextID]
      const prevSegment = segments[prevID]

      if (magnitude(sub(prevSegment.endPoint, nextSegment.startPoint)) >= 0.01) {
        discontinuity = [prevID, nextID]
        break
      }
    }

    // if there's a discontinuity we obviously can't test it for inclusion
    if (discontinuity !== undefined) {
      continue
    }

    // we reconstruct the walls the way the DrawingCanvas/lib/wall.js does them
    const polygonPoints: Vector3Like[] = []

    segmentIDs.forEach(segment => {
      polygonPoints.push(segments[segment].startPoint)
    })

    const lastSegment = segmentIDs[segmentIDs.length - 1]
    if (lastSegment) {
      polygonPoints.push(segments[lastSegment].endPoint)
    }

    if (pointInPolygon(position, polygonPoints)) {
      return true
    }
  }
  return false
}

function segmentMesh(segment: Readonly<WallSegment>) {
  const shape = new Shape()
    .moveTo(segment.insetPoints.start.x, segment.insetPoints.start.y)
    .lineTo(segment.insetPoints.end.x, segment.insetPoints.end.y)
    .lineTo(segment.outsetPoints.end.x, segment.outsetPoints.end.y)
    .lineTo(segment.outsetPoints.start.x, segment.outsetPoints.start.y)
    .closePath()

  const mesh = new Mesh(new ExtrudeGeometry(shape, {
    steps: 1,
    depth: segment.height,
    bevelEnabled: false,
  }))

  mesh.userData["segmentID"] = segment.id

  return mesh
}

const memoizedSegmentMesh = weakMapMemoize(segmentMesh)

function wallMeshes(segments: Readonly<Record<string, WallSegment>>) {
  return new Map(Object.values(segments).map(segment => [segment.id, memoizedSegmentMesh(segment)]))
}

const memoizedWallMeshes = weakMapMemoize(wallMeshes)

export const getHeaterDimensions = (
  heaterQueryData: ProductDistanceEngineHeaterDataQuery['ProductVariation']['heaterData'],
  radiantHeaterRotation?: number
) => {
  const isUnitHeater = radiantHeaterRotation === undefined
  if (isUnitHeater) {
    const {
      uhClearanceNonAccessSide,
      boxWidthB, 
      uhClearanceAccessPanel,
      uhClearanceFlueConnector,
      blowerDepthE,
      uhClearanceRear,
      uhClearanceTop,
      boxHeightA,
      uhClearanceBottom
    } = heaterQueryData[0]
    return {
      width : uhClearanceNonAccessSide! + boxWidthB! + uhClearanceAccessPanel!,
      depth : uhClearanceFlueConnector! + blowerDepthE! + uhClearanceRear!,
      height : uhClearanceTop! + boxHeightA! + uhClearanceBottom!
    }
  } else {
    const {
      minTubeLength,
      burnerBoxWidth,
      burnerBoxClearanceWidth,
      irhClearanceB,
      tubeDiameter,
      irhClearanceD,
      irhClearanceC
    } = heaterQueryData.find(
      ({ angle }) => angle === Math.abs(radiantHeaterRotation) || (angle === 90 && radiantHeaterRotation === 0)
    )!
    const sixInchClearance = 6
    return {
      width: minTubeLength! + burnerBoxWidth! + burnerBoxClearanceWidth! + sixInchClearance,
      depth: irhClearanceB! + tubeDiameter! + irhClearanceD!,
      height: irhClearanceC!
    }
  }
}

async function productMesh(client: ApolloClient<NormalizedCache>, product: Readonly<Product>): Promise<Mesh> {
  const { data: { ProductVariation }} = await client.query({
    query: graphql(`
      query ProductDistanceEngineCategoryAndModel($variationID: ID!) {
        ProductVariation(id: $variationID) {
          id
          product {
            category
            model
          }
        }
      }
    `),
    variables: {variationID: product.variationId}
  })
  const { product: { category, model } } = ProductVariation
  if (category === 'HEAT') {
    const { data } = await client.query({
      query: graphql(`
        query ProductDistanceEngineHeaterData($variationID: ID!) {
          ProductVariation(id: $variationID) {
            id
            heaterData {
              id
              angle
              blowerDepthE
              boxHeightA
              boxWidthB
              burnerBoxClearanceWidth
              burnerBoxWidth
              irhClearanceB
              irhClearanceC
              irhClearanceD
              minTubeLength
              tubeDiameter
              uhClearanceAccessPanel
              uhClearanceBottom
              uhClearanceFlueConnector
              uhClearanceNonAccessSide
              uhClearanceRear
              uhClearanceTop
            }
          }
        }
      `),
      variables: {
        variationID: product.variationId,
      }
    })
    const {
      width,
      depth,
      height
    } = getHeaterDimensions(data.ProductVariation.heaterData, model === 'Unit Heater' ? undefined : product.rotation.x)
    const clearanceBox = new BoxGeometry(width, depth, height, 32)

    if (product.rotation.y === 90 || product.rotation.y === 270)
      clearanceBox.rotateZ(Math.PI / 2)
    const mesh = new Mesh(clearanceBox)
    mesh.position.copy(product.position)
    mesh.geometry.computeBoundingSphere()
    mesh.updateMatrixWorld()

    return mesh
  } else {
    const { data } = await client.query({
      query: graphql(`
        query ProductDistanceEngineFanData($variationID: ID!) {
          ProductVariation(id: $variationID) {
            id
            size
          }
        }
      `),
      variables: {
        variationID: product.variationId,
      }
    })

    const radius = data.ProductVariation.size / 2
    const clearanceHeight = data.ProductVariation.size * 2
    const clearanceCylinder = new CylinderGeometry(
      radius,
      radius,
      clearanceHeight,
      32
    )

    clearanceCylinder.rotateX(Math.PI / 2)
    const mesh = new Mesh(clearanceCylinder)
    mesh.position.copy(product.position)
    mesh.geometry.computeBoundingSphere()
    mesh.updateMatrixWorld()

    return mesh
  }
}

const memoizedProductMesh = weakMapMemoize(productMesh)

async function productMeshes(client: ApolloClient<NormalizedCache>, products: Readonly<Record<string, Product>>) {
  const entries =
    await Promise.all(
        Object.values(products)
          .map(async product => [product.id, await memoizedProductMesh(client, product)] as const)
        )
  return new Map(entries)
}

const memoizedProductMeshes = weakMapMemoize(productMeshes)

function doorMesh(door: Readonly<Door>, wall: Readonly<WallSegment>) {
  const { x, y } = door.position
  const z = door.height / 2

  const { width, height } = door
  const thickness = wall.thickness + 0.2

  if (door.doorType === "rollbackDoor") {
    const offset = Math.abs(height / 2.0)

    const geometry = new BoxGeometry(width, height, thickness / 2)
    const mesh = new Mesh(geometry)
    mesh.position.set(x - offset, y, z + offset)

    return mesh
  } else if (door.doorType === "verticalDoor") {
    const offset = Math.abs(thickness / 2.0)

    const geometry = new BoxGeometry(thickness / 2, width, height)
    const mesh = new Mesh(geometry)
    mesh.position.set(x - offset, y, z + height)

    return mesh
  } else {
    return new Object3D()
  }
}

const memoizedDoorMesh = weakMapMemoize(doorMesh)

function doorMeshes(doors: Readonly<Record<string, Door>>, walls: Readonly<Record<string, WallSegment>>) {
  return new Map(
    Object.values(doors)
      .filter(door => (door.doorType === "rollbackDoor" || door.doorType === "verticalDoor") && door.wallSegmentId in walls)
      .map(door => [door.id, memoizedDoorMesh(door, walls[door.wallSegmentId])])
  )
}

const memoizedDoorMeshes = weakMapMemoize(doorMeshes)

function obstructionMesh(obstruction: Readonly<Obstruction>) {
  return Primitives.getCustomMesh(obstruction.positions, obstruction.height)
}

const memoizedObstructionMesh = weakMapMemoize(obstructionMesh)

function obstructionMeshes(obstructions: Readonly<Record<string, Obstruction>>) {
  return new Map(
    Object.values(obstructions)
      .map(obstruction => [obstruction.id, memoizedObstructionMesh(obstruction)])
  )
}

const memoizedObstructionMeshes = weakMapMemoize(obstructionMeshes)

const UTILITY_BOX_DEFAULT_THICKNESS = 24

function utilityBoxMesh(box: Readonly<UtilityBox>) {
  const thickness = (box.thickness || UTILITY_BOX_DEFAULT_THICKNESS) / 2 + 24
  const {width, height} = box

  const geometry = new BoxGeometry(
    width,
    height,
    thickness
  )

  const mesh = new Mesh(geometry)
  mesh.position.set(box.position.x, box.position.y, box.centerPointHeight)

  const z = 'z' in box.rotation ? box.rotation.z : box.rotation._z
  mesh.rotation.set(0, 0, z)

  return mesh
}

const memoizedUtilityBoxMesh = weakMapMemoize(utilityBoxMesh)

function utilityBoxMeshes(boxes: Readonly<Record<string, UtilityBox>>) {
  return new Map(
    Object.values(boxes)
      .map(box => [box.id, memoizedUtilityBoxMesh(box)])
  )
}

const memoizedUtilityBoxMeshes = weakMapMemoize(utilityBoxMeshes)

function roofMesh(roof: Readonly<Roof>, lines: Readonly<Record<string, ElevationLine>>, points: Readonly<Record<string, ElevationPoint>>) {
  const perimeterPoints: [number, number, number][] = roof.perimeterPoints.map(([x, y]) => [x, y, roof.height])
  perimeterPoints.pop() // Remove duplicate start/end point
  const perimeterEdgeIndices = perimeterPoints.reduce<[number, number][]>((indices, _, i, { length }) => {
    indices.push([i, (i + 1) % length])
    return indices
  }, [])
  const elevationPointIndices = new Map(
    Object.values(points).map(({ id }, i) => [id, i + perimeterPoints.length])
  )
  const edgeIndices = [...perimeterEdgeIndices]
  Object.values(lines).forEach(({ elevationPointIds }) => {
    const [idA, idB] = elevationPointIds
    const indexA = elevationPointIndices.get(idA ?? '')
    const indexB = elevationPointIndices.get(idB ?? '')
    if (!indexA || !indexB) return
    edgeIndices.push([indexA, indexB])
  })
  const elevationPoints = Object.values(points).reduce<[number, number, number][]>(
    (transformedPoints, point) => {
      let wasOnPerimeter = false
      let xChange = 0
      let yChange = 0
      let basePerimeter = [0, 0]
      const position = vectorUIToModel(point.position)
      const { x, y, z } = position

      for (let i = 0; i < perimeterPoints.length; i++) {
        const p1Index = i
        const p2Index = (i + 1) % perimeterPoints.length 
        const p1 = perimeterPoints[p1Index]
        const p2 = perimeterPoints[p2Index]

        const distance = Util.distanceToLineSegment(p1[0], p1[1], p2[0], p2[1], x, y)
        if (distance > modelToUI(3)) {
          continue
        }

        const p1x = Math.round(p1[0])
        const p1y = Math.round(p1[1])
        const p2x = Math.round(p2[0])
        const p2y = Math.round(p2[1])
        // If the point is on a perimeter point, its a corner. Set a tiny offset away from the corner
        if (Util.pointsAreEqual2D(p1, position, modelToUI(3))) {
          if (p1x === p2x) {
            p1y > p2y ? (yChange += -0.1) : (yChange += 0.1)
          }
          if (p1y === p2y) {
            p1x > p2x ? (xChange += -0.1) : (xChange += 0.1)
          }
          basePerimeter = p1
        } else if (
          Util.pointsAreEqual2D(p2, position, modelToUI(3))
        ) {
          if (p1x === p2x) {
            p1y < p2y ? (yChange += -0.1) : (yChange += 0.1)
          }
          if (p1y === p2y) {
            p1x < p2x ? (xChange += -0.1) : (xChange += 0.1)
          }
          basePerimeter = p2
        } else {
          // If the point is on or very close to a perimeter line, find the centroid of the closest
          // triangle in the polygon and move one vector unit towards it.
          const start = new Vector3().fromArray(p1).setZ(0)
          const end = new Vector3().fromArray(p2).setZ(0)
          const edge = new Line3(start, end)
          const closetPointOnEdge = edge.closestPointToPoint(new Vector3().copy(position), true, new Vector3())
          const triangles = cdt2d(perimeterPoints, perimeterEdgeIndices, { exterior: false })
          const closestTriangle = triangles.find((indices) => indices.includes(p1Index) && indices.includes(p2Index))
          const [a, b, c] = closestTriangle!
          const centroid = new Triangle(
            new Vector3().fromArray(perimeterPoints[a]).setZ(0),
            new Vector3().fromArray(perimeterPoints[b]).setZ(0),
            new Vector3().fromArray(perimeterPoints[c]).setZ(0),
          ).getMidpoint(new Vector3())
          const offset = new Vector3().subVectors(centroid, closetPointOnEdge).normalize()
          basePerimeter = [closetPointOnEdge.x, closetPointOnEdge.y]
          xChange = modelToUI(offset.x)
          yChange = modelToUI(offset.y)
        }
        wasOnPerimeter = true
        break
      }

      if (!wasOnPerimeter) {
        transformedPoints.push([x, y, z])
      } else {
        const interiorPoint = {
          x: (xChange && basePerimeter[0] + xChange) || x,
          y: (yChange && basePerimeter[1] + yChange) || y,
          z: z,
        }
        // If point is outside of roof polygon after x and y change has been applied,
        // then it is an interior corner inside the bounds of the facility. Reverse the
        // direction of x and y changes to inverse direction of displacement
        if (
          (xChange || yChange) &&
          !Util.isPointInPolygon(interiorPoint, perimeterPoints) // Inside roof bounds
        ) {
          interiorPoint.x =
            (xChange && basePerimeter[0] - xChange) || x
          interiorPoint.y =
            (yChange && basePerimeter[1] - yChange) || y
        }
        transformedPoints.push([interiorPoint.x, interiorPoint.y, interiorPoint.z])
      }
      return transformedPoints
    },
    []
  )

  const allPoints = perimeterPoints.concat(elevationPoints)
  const points3d = allPoints.map(([x, y, z]) => new Vector3(x, y, z))
  const triangles = cdt2d(allPoints, edgeIndices, { exterior: false })

  const geometry = new BufferGeometry().setFromPoints(points3d)
  geometry.setIndex(triangles.flat())
  geometry.computeVertexNormals()

  return new Mesh(geometry, new MeshBasicMaterial({ side: DoubleSide }))
}

const memoizedRoofMesh = weakMapMemoize(roofMesh)

function roofMeshes(roofs: Readonly<Record<string, Roof>>, lines: Readonly<Record<string, ElevationLine>>, points: Readonly<Record<string, ElevationPoint>>) {
  return new Map(
    Object.values(roofs)
      .map(roof => [roof.id, memoizedRoofMesh(roof, lines, points)])
  )
}

export const memoizedRoofMeshes = weakMapMemoize(roofMeshes)

function columnsMesh({ hasColumns, height, beamDepth, isCylinder, columnWidth, beamModels, columnLineModels }: Readonly<RoofSection>) {
  if (!hasColumns) return
  const columnHeight = height - beamDepth
  const columnPositions = beamModels?.reduce<Vector3[]>((intersections, beam) => {
    columnLineModels?.forEach(columnLine => {
      intersections.push(new Vector3(beam.position.x, columnLine.position.y, columnHeight / 2))
    }) 
    return intersections
  }, [])
  const scene = new Object3D()
  const geometry = isCylinder
    ? new CylinderGeometry(columnWidth / 2, columnWidth / 2, columnHeight)
    : new BoxGeometry(columnWidth, columnWidth, columnHeight)
  columnPositions?.forEach((position) => {
    const mesh = new Mesh(geometry)
    mesh.position.copy(position)
    scene.add(mesh)
  })
  scene.updateMatrixWorld()
  return scene
}

const memoizedColumnsMesh = weakMapMemoize(columnsMesh)

function columnsMeshes(roofSections: Readonly<Record<string, RoofSection>>) {
  return new Map(
    Object.values(roofSections)
      .map(roofSection => [roofSection.id, memoizedColumnsMesh(roofSection)])
  )
}

const memoizedColumnsMeshes = weakMapMemoize(columnsMeshes)

export type IntersectionData = [id: string, distance: number, location: Vector3]

class ProductDistanceEngine {
  walls: Readonly<Record<string, Wall>>
  segmentMeshes: Readonly<Map<string, Mesh>>
  segments: Readonly<Record<string, WallSegment>>
  productMeshes: Readonly<Map<string, Mesh>>
  doorClearanceMeshes: Readonly<Map<string, Object3D>>
  obstructionMeshes: Readonly<Map<string, Object3D>>
  utilityBoxMeshes: Readonly<Map<string, Object3D>>
  roofMeshes: Readonly<Map<string, Object3D>>
  columnsMeshes: Readonly<Map<string, Object3D | undefined>>
  raycaster = new Raycaster()
  ray = new Ray()
  origin = new Vector3()
  box = new Box3()
  boxSize = new Vector3(5, 5, 5) 
  boxCenter = new Vector3()

  constructor(store: AppStore) {
    const { objects, segments, doors, obstructions, utilityBoxes, roofs, roofSections, elevationLines, elevationPoints } = store.getState().objects.present

    this.walls = objects
    this.segments = segments
    this.segmentMeshes = memoizedWallMeshes(this.segments)
    this.productMeshes = new Map()
    this.doorClearanceMeshes = memoizedDoorMeshes(doors, this.segments)
    this.obstructionMeshes = memoizedObstructionMeshes(obstructions)
    this.utilityBoxMeshes = memoizedUtilityBoxMeshes(utilityBoxes)
    this.roofMeshes = memoizedRoofMeshes(roofs, elevationLines, elevationPoints)
    this.columnsMeshes = memoizedColumnsMeshes(roofSections)
    memoizedProductMeshes(client, store.getState().objects.present.products).then(it => {
      this.productMeshes = it
    })

    store.subscribe(() => {
      const { objects, segments, doors, obstructions, utilityBoxes, roofs, roofSections, elevationLines, elevationPoints } = store.getState().objects.present

      this.walls = objects
      this.segments = segments
      this.segmentMeshes = memoizedWallMeshes(this.segments)
      this.doorClearanceMeshes = memoizedDoorMeshes(doors, this.segments)
      this.obstructionMeshes = memoizedObstructionMeshes(obstructions)
      this.utilityBoxMeshes = memoizedUtilityBoxMeshes(utilityBoxes)
      this.roofMeshes = memoizedRoofMeshes(roofs, elevationLines, elevationPoints)
      this.columnsMeshes = memoizedColumnsMeshes(roofSections)
      memoizedProductMeshes(client, store.getState().objects.present.products).then(it => {
        this.productMeshes = it
      })
    })
  }
  isWithinFacility(position: Vector3Like): boolean {
    return isWithinFacility(position, this.walls, this.segments)
  }
  nearestHeaterWallIntersection(pos: Vector3Like, boxDimensions: { width: number, depth: number }): [string, number, Vector3] | null {
    this.origin.copy(pos)
    
    const { depth, width } = boxDimensions

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    this.segmentMeshes.forEach((segment, id) => {
      CARDINALS.forEach(direction => {
        this.raycaster.set(this.origin, direction)
        const isXAxisDirection = direction.y === 0
        const offset = (isXAxisDirection ? width : depth) / 2
        const intersection = this.raycaster.intersectObject(segment)[0]
        if (!intersection || intersection.distance - offset > distance) return
        target.copy(intersection.point)
        distance = intersection.distance
        objectID = id
      })
    })
    if (objectID === undefined) {
      return null
    }
    return [objectID, distance, target]
  }
  nearestWallIntersection(pos: Vector3Like): IntersectionData | null {
    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    for (const [id, segment] of this.segmentMeshes) {
      for (const direction of DIMENSIONS) {
        this.origin.copy(pos)
        this.raycaster.set(this.origin, direction)
        let intersection = this.raycaster.intersectObject(segment)[0]
        if (!intersection) {
          this.origin.setZ(FLOOR)
          this.raycaster.set(this.origin, direction)
          intersection = this.raycaster.intersectObject(segment)[0]
        }
        if (!intersection || intersection.distance > distance) continue
        target.copy(intersection.point)
        distance = intersection.distance
        objectID = id
      }
    }
    if (objectID === undefined) return null
    return [objectID, distance, target]
  }
  nearestProductIntersection(pos: Vector3Like, ignoring?: Set<string>): IntersectionData | null {
    this.origin.copy(pos)

    let distance = Infinity
    const target = new Vector3()
    let objectID: undefined | string = undefined

    for (const [id, product] of this.productMeshes) {
      if (ignoring?.has(id)) continue
      const productDistance = this.origin.distanceTo(product.position)
      if (distance > productDistance) {
        objectID = id
        target.copy(product.position)
        distance = productDistance
      }
    }
    if (objectID === undefined) return null
    return [objectID, distance, target]
  }
  nearestObstructionIntersection(pos: Vector3Like): IntersectionData | null {
    const position = new Vector3().copy(pos)
    const ray = new Raycaster(position)

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined

    for (const meshCollection of [this.doorClearanceMeshes, this.obstructionMeshes, this.utilityBoxMeshes]) {
      meshCollection.forEach((product, id) => {
        DIMENSIONS.forEach(direction => {
          ray.set(position, direction)

          const intersections = ray.intersectObject(product)
          for (const intersection of intersections) {
            if (intersection.distance <= distance) {
              target.copy(intersection.point)
              distance = intersection.distance
              objectID = id
            }
          }
        })
      })
    }

    if (objectID === undefined) {
      return null
    }
    return [objectID, distance, target]
  }
  nearestRoofIntersection(pos: Vector3Like, productDimensions: { width: number, depth: number }): IntersectionData | null {
    this.origin.copy(pos)

    let distance = Infinity
    let target = new Vector3()
    let objectID: undefined | string = undefined


    const { width, depth } = productDimensions
    for (const [id, roof] of this.roofMeshes) {
      for (const direction of CARDINALS) {
        const isVertical = direction.x === 0
        const offset = (isVertical ? width : depth) / 2
        const raycasterOrigin = direction.clone().multiplyScalar(offset).add(this.origin)
        this.raycaster.set(raycasterOrigin, POSITIVE_Z)
        const intersection = this.raycaster.intersectObject(roof)[0]
        if (!intersection?.face) continue
        const angle = intersection.face.normal.clone().angleTo(POSITIVE_Z)
        const distanceToRoof = Math.cos(angle) * intersection.distance
        if (distanceToRoof > distance) continue
        distance = distanceToRoof
        target.copy(intersection.face.normal).multiplyScalar(intersection.distance).add(raycasterOrigin)
        objectID = id
      }
    }
    if (objectID === undefined) return null
    return [objectID, distance, target]
  }
  getNearestOnAxisProductDistance({ origin, direction, ignoring = new Set() }: { origin: Vector3Like; direction: Vector3; ignoring?: Set<string> } ) {
    let distance = Infinity
    let productPosition: Vector3 | undefined = undefined
    this.origin.copy(origin).setZ(0)
    const target = new Vector3()

    for (const [id, mesh] of this.productMeshes) {
      if (ignoring.has(id)) continue
      this.boxCenter.copy(mesh.position).setZ(FLOOR)
      this.box.setFromCenterAndSize(this.boxCenter, this.boxSize)
      this.ray.set(this.origin, direction)
      const intersection = this.ray.intersectBox(this.box, target)
      const productDistance = intersection && (intersection.distanceTo(this.origin) + (this.boxSize.x / 2))
      if (!productDistance|| productDistance > distance) continue
      distance = productDistance 
      productPosition = new Vector3().copy(this.boxCenter)
    }

    return isFinite(distance) && productPosition ? {distance, productPosition} : null
  }
  getNearestCollisionDistance({ origin, direction, ignoring = new Set() }: { origin: Vector3; direction: Vector3; ignoring?: Set<string> } ) {
    let distance = this.getNearestOnAxisProductDistance({ origin, direction, ignoring })?.distance ?? Infinity
    this.origin.copy(origin)
    this.raycaster.set(this.origin, direction)

    for (const meshCollection of [this.obstructionMeshes, this.segmentMeshes]) {
      meshCollection.forEach((mesh) => {
        const intersection = this.raycaster.intersectObject(mesh)[0]
        if (!intersection || intersection.distance > distance) return
        distance = intersection.distance
      })
    }
    const isZeroIntersections = distance === Infinity
    if (isZeroIntersections) {
      this.origin.setZ(FLOOR)
      this.raycaster.set(this.origin, direction)
      this.segmentMeshes.forEach((mesh) => {
        const intersection = this.raycaster.intersectObject(mesh)[0]
        if (!intersection || intersection.distance > distance) return
        distance = intersection.distance
      })
    }
    const isStillZeroIntersections = distance === Infinity
    if (isStillZeroIntersections) return null
    return distance
  }

  getDistanceToMountPoint(pos: Vector3Like): number | undefined {
    this.origin.copy(pos)
    this.origin.setZ(FLOOR)
    this.raycaster.set(this.origin, POSITIVE_Z)
    return this.raycaster.intersectObjects(Array.from(this.roofMeshes.values()))[0]?.distance
  }
  getNearestWallOrColumnPoint(position: Vector3, intersectionOut: Vector3, directionOut: Vector3) {
    this.origin.copy(position) 

    let distance = Infinity
    
    for (const meshCollection of [this.segmentMeshes, this.columnsMeshes]) {
      meshCollection.forEach((mesh) => {
        if (!mesh) return
        CARDINALS.forEach(direction => {
          this.raycaster.set(this.origin, direction)
          const intersection = this.raycaster.intersectObject(mesh)[0]
          if (!intersection || intersection.distance > distance) return
          distance = intersection.distance
          intersectionOut.copy(intersection.point)
          directionOut.copy(direction)
        })
      })
    }
    return distance 
  }
  getNearestRoofOrWallCollisionDistance(pos: Vector3Like): number {
    this.origin.copy(pos)

    let distance = Infinity

    for (const meshCollection of [this.segmentMeshes, this.roofMeshes]) {
      meshCollection.forEach((mesh) => {
        if (!mesh) return
        DIMENSIONS.forEach(direction => {
          this.raycaster.set(this.origin, direction)
          const intersection = this.raycaster.intersectObject(mesh)[0]
          if (!intersection || intersection.distance > distance) return
          distance = intersection.distance
        })
      })
    }
    return distance
  }
}

export const productDistanceEngine = new ProductDistanceEngine(store)
