import { useEffect, useRef } from 'react'
import { Group, MathUtils, Matrix4, Vector3, Vector3Like } from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { ClearanceMesh } from '~/components/DrawingCanvas/Products/components/ClearanceMesh'
import { DirectionArrow } from '~/components/DrawingCanvas/Products/components/DirectionArrow'
import { ErrorIndicators } from '~/components/DrawingCanvas/Products/components/ErrorIndicators'
import { ProductDragControls } from '~/components/DrawingCanvas/Products/components/ProductDragControls'
import { RotationHandle } from '~/components/DrawingCanvas/Products/components/RotationHandle'
import { SnapLine } from '~/components/DrawingCanvas/Products/components/SnapLine'
import { useCardinalSnaplines } from '~/components/DrawingCanvas/Products/hooks/useCardinalSnaplines'
import { useDimensionErrors } from '~/components/DrawingCanvas/Products/hooks/useDimensionErrors'
import { useErrorIndicatorsContext } from '~/components/DrawingCanvas/Products/hooks/useErrorIndicatorsContext'
import { useEvapVariationData } from '~/components/DrawingCanvas/Products/hooks/useEvapVariationData'
import { useProductMesh } from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import { useSelectProduct } from '~/components/DrawingCanvas/Products/hooks/useSelectProduct'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
} from '~/components/DrawingCanvas/util/units'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProduct } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'

export const EvaporativeCooler = (product: Product) => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const isVisible = useAppSelector(state => state.layers.layers.PRODUCTS_EVAP.visible)
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const dispatch = useAppDispatch()
  const cursorHandlers = useCursor()

  const { id, rotation, position, ignoreErrors, variationId } = product
  const isSelected = Boolean(selectedObjects.find(object => object.id === id))

  const selectProduct = useSelectProduct(id)
  const snapToCardinals = useCardinalSnaplines(position)
  const groupRef = useRef<Group>(null!)
  const snapLine = useRef<Line2>(null!)
  const origin = new Vector3()

  const { model, size } = useEvapVariationData({ variationId })
  const productMesh = useProductMesh(model)
  const clearanceRadius = modelToUI(size) / 2
  const DIRECTION_ARROW_OFFSET = -(clearanceRadius + 1)

  const checkDimensions = useDimensionErrors(product.variationId)
  const { errors } = checkDimensions({ origin: position, productId: id })
  const { setShowErrorIndicators, showErrorIndicators } = useErrorIndicatorsContext()
  const isErrorsVisible = errors.length > 0 && showErrorIndicators && !ignoreErrors

  useEffect(() => {
    groupRef.current.rotation.y = MathUtils.degToRad(rotation.z)
  }, [rotation.z])

  const handleDrag = (local: Matrix4, isMultipleSelected = false) => {
    setShowErrorIndicators(false)
    origin.setFromMatrixPosition(local)
    scaleUIVectorToModel(origin)
    const cardinalSnaps = snapToCardinals(origin)
    if (cardinalSnaps && !isMultipleSelected) {
      const { point, positions } = cardinalSnaps
      snapLine.current.geometry.setPositions(positions.map(p => modelToUI(p)))
      snapLine.current.visible = true
      origin.copy(point)
    }
    scaleModelVectorToUI(origin)
    local.setPosition(origin)
  }

  const handleUpdatePosition = (newPosition: Vector3Like) => {
    setShowErrorIndicators(true)
    const { errors, isValidPosition } = checkDimensions({ origin: newPosition, productId: id })
    if (errors.length) dispatch(setStatus({ text: errors[0].message, type: 'error' }))
    if (!isValidPosition) return false
    dispatch(
      updateProduct({
        product: {
          id,
          position: newPosition,
        },
      })
    )
    return true
  }

  const handleCommitRotation = (newRotation: number) => {
    dispatch(updateProduct({ product: { id, rotation: { ...rotation, z: newRotation } } }))
  }

  return (
    <>
      <ErrorIndicators visible={isSelected && isErrorsVisible} errors={errors} origin={position} />
      <SnapLine ref={snapLine} />
      <ProductDragControls
        product={product}
        dragConfig={{ enabled: !isLocked && isSelected }}
        onDrag={handleDrag}
        onUpdatePosition={handleUpdatePosition}
      >
        <group
          ref={groupRef}
          visible={isVisible}
          {...cursorHandlers}
          onClick={selectProduct}
          dispose={null}
          rotation-y={MathUtils.degToRad(-rotation.z)}
        >
          <ClearanceMesh
            isSelected={isSelected}
            radius={clearanceRadius}
            height={clearanceRadius * 2}
            isError={isErrorsVisible}
          />
          <primitive object={productMesh} />
          {isSelected && (
            <RotationHandle
              offset={clearanceRadius}
              onCommit={handleCommitRotation}
              targetElement={groupRef.current}
            />
          )}
          <DirectionArrow position-z={DIRECTION_ARROW_OFFSET} visible={isSelected} size={3} />
        </group>
      </ProductDragControls>
    </>
  )
}
