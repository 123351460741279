import { useQuery } from '@apollo/client'
import { captureException } from '@sentry/react'
import { forwardRef, Suspense, useImperativeHandle, useMemo, useRef } from 'react'
import { Group, Vector3 } from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { ADD_PRODUCT_QUERY } from '~/client/queries'
import { useProject } from '~/components/DrawingCanvas/hooks'
import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import {
  ArrowHandle,
  DimensionGuides,
} from '~/components/DrawingCanvas/Products/components/DimensionGuides'
import { LoadingIndicator } from '~/components/DrawingCanvas/Products/components/LoadingIndicator'
import {
  ProductPreview,
  ProductPreviewProps,
} from '~/components/DrawingCanvas/Products/components/ProductPreview'
import { SnapLine } from '~/components/DrawingCanvas/Products/components/SnapLine'
import {
  MODEL_X_ROTATION_OFFSET,
  OVERHEAD_SNAP_THRESHOLD,
} from '~/components/DrawingCanvas/Products/constants'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { useSnapLines } from '~/components/DrawingCanvas/Products/hooks/useSnapLines'
import { isTiltingDirectional } from '~/components/DrawingCanvas/Products/modelNames'
import { ToolManagerRef } from '~/components/DrawingCanvas/ToolManager'
import { snapToLine } from '~/components/DrawingCanvas/util/snaplines'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
  vectorUIToModel,
} from '~/components/DrawingCanvas/util/units'
import LayerKeys from '~/config/layerKeys'
import { LETTERS } from '~/config/letters'
import { LOUVER_ANGLES } from '~/config/louverAngles'
import { DEFAULT_COOLING_FAN_SPEED, DEFAULT_DESTRAT_FAN_SPEED } from '~/lib/airflow/airflow'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { addProduct } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'
import { ProductToolProps } from '~/store/tools/types'

const getLayerKey = ({ category, type, model }: ProductToolProps) => {
  let layerKey: Product['layerKey'] = LayerKeys.PRODUCTS
  if (category === 'HEAT') layerKey = LayerKeys.PRODUCTS_HEATERS
  else if (category === 'EVAP') layerKey = LayerKeys.PRODUCTS_EVAP
  else if (type === 'OVERHEAD') layerKey = LayerKeys.PRODUCTS_OVERHEAD
  else if (category === 'FAN' || type === 'DIRECTIONAL') layerKey = LayerKeys.PRODUCTS_DIRECTIONAL
  else {
    if (import.meta.env.DEV) {
      throw new Error(`unknown layer key for product: ${JSON.stringify(model)}`)
    }
    captureException(new Error(`unknown layer key for product: ${JSON.stringify(model)}`))
  }
  return layerKey
}

const useDetailedLabel = () => {
  const products = useAppSelector(state => state.objects.present.products)
  const productCount = Object.values(products).length
  const labelLength = Math.floor(productCount / LETTERS.length) + 1
  let detailedLabel = ''
  for (let i = 0; i < labelLength; i++) {
    detailedLabel += LETTERS[productCount % LETTERS.length]
  }
  return detailedLabel
}

const useDefaultVariation = (props: ProductToolProps) => {
  const detailedLabel = useDetailedLabel()
  const { data } = useQuery(ADD_PRODUCT_QUERY, {
    variables: { productId: props.id, isHeater: props.category === 'HEAT' },
  })

  return useMemo(():
    | { previewProps: ProductPreviewProps; productInput: Omit<Product, 'id'> }
    | undefined => {
    const { category, type, model } = props
    if (!data) return
    const { defaultVariation } = data.Product ?? {}
    if (!defaultVariation) {
      throw new Error(`Product ${model} is missing a default variation`)
    }
    const { pedestals, canMountOverhead, minFloorClearance, size, cageHeight } = defaultVariation
    const pedestalOffset = pedestals.reduce(
      (maxHeight, pedestal) => Math.max(maxHeight, pedestal.height ?? 0),
      0
    )
    const isDirectional = type === 'DIRECTIONAL'
    const isFan = category === 'FAN'
    const isNonMountableDirectionalFan = isDirectional && isFan && !canMountOverhead
    const directionalCageOffset = isNonMountableDirectionalFan ? size / 2 : 0
    const isEvapCooler = category === 'EVAP'
    const evapHeightOffset = isEvapCooler ? (cageHeight ?? 0) / 2 : 0
    const height = minFloorClearance + pedestalOffset + directionalCageOffset + evapHeightOffset

    const defaultVoltage = defaultVariation.voltages[0]
    const defaultMountingOption = defaultVoltage?.mountingOptions?.[0]

    const { distinctFanSpeeds } = data.Product
    const fanSpeeds = [...(distinctFanSpeeds ?? [])].sort((a, b) => a.power - b.power)
    const coolingFanSpeedId = fanSpeeds[fanSpeeds.length - 2]?.speed ?? DEFAULT_COOLING_FAN_SPEED
    const layerKey = getLayerKey(props)
    const isUnitHeater = model === 'Unit Heater'
    const rotationX = (isFan && isTiltingDirectional(model)) ? 90 : 0

    return {
      productInput: {
        detailedLabel,
        angle: isUnitHeater ? LOUVER_ANGLES[0].id : undefined,
        position: { x: 0, y: 0, z: height },
        rotation: { x: rotationX, y: 0, z: 0 },
        layerKey,
        variationId: defaultVariation.id,
        voltageId: defaultVariation.voltages?.[0].id,
        mountingOptionAdderId: defaultVariation.mountingOptionAdders?.[0]?.id ?? 'Unknown',
        mountingOptionId: defaultVariation.voltages?.[0]?.mountingOptions?.[0]?.id,
        includedInQuote: false,
        adderOther: null,
        destratFanSpeedId: DEFAULT_DESTRAT_FAN_SPEED,
        coolingFanSpeedId,
        hasUnknownVoltage: true,
        label: null,
        wallSegmentId: null,
      },
      previewProps: {
        heaterData: defaultVariation.heaterData?.[0],
        model,
        size,
        layerKey,
        tubeLength: defaultMountingOption?.tubeLength ?? 0,
        fullHeight: defaultMountingOption?.fullHeight ?? 0,
      },
    }
  }, [data, props, detailedLabel])
}

export const ProductTool = forwardRef<ToolManagerRef, { activeToolProps: ProductToolProps }>(
  ({ activeToolProps }, ref) => {
    const isSnapEnabled = useAppSelector(state => state.tools.isSnapEnabled)
    const dispatch = useAppDispatch()

    const project = useProject()
    const defaultVariation = useDefaultVariation(activeToolProps)
    const getFanHeightInches = useGetFanHeightInches()
    const snaplines = useSnapLines()

    const groupRef = useRef<Group>(null!)
    const snapLine = useRef<Line2>(null!)
    const arrowsRef = useRef<ArrowHandle>(null!)
    const origin = new Vector3()

    useImperativeHandle(
      ref,
      () => ({
        onMouseUp(event) {
          const isLeftClick = event.button === 0
          if (!isLeftClick || !defaultVariation) return
          const { x, y, z } = vectorUIToModel(groupRef.current.position)
          const isInFacility = productDistanceEngine.isWithinFacility({ x, y, z })
          if (!isInFacility) {
            const text = 'Product must be placed within facility bounds!'
            dispatch(setStatus({ text, type: 'error' }))
            return
          }
          const product = { ...defaultVariation.productInput, position: { x, y, z } }
          dispatch(addProduct({ product }))
        },
        onMouseMove(event) {
          const point = project(event)
          if (!point) return
          const { x, y } = point
          origin.setX(x)
          origin.setY(y)
          scaleUIVectorToModel(origin)
          origin.setZ(defaultVariation?.productInput.position.z ?? 0)
          const { category, type } = activeToolProps
          const isOverhead = category === 'FAN' && type === 'OVERHEAD'
          if (isOverhead) {
            const fullHeight = defaultVariation?.previewProps.fullHeight ?? 0
            origin.setZ(getFanHeightInches({ origin, fullHeight }))
            if (isSnapEnabled) {
              const snapData = snapToLine(snaplines, origin)
              if (snapData && snapData.distance < OVERHEAD_SNAP_THRESHOLD) {
                const { objectLine, snapPoint } = snapData
                const { start, end } = objectLine
                const positions = [...start.toArray(), ...end.toArray()].map(p => modelToUI(p))
                snapLine.current.geometry.setPositions(positions)
                snapLine.current.visible = true
                origin.copy(snapPoint)
              } else {
                snapLine.current.visible = false
              }
            }
          }
          scaleModelVectorToUI(origin)
          groupRef.current.position.copy(origin)
          arrowsRef.current.setOrigin(origin)
        },
      }),
      [project, activeToolProps, isSnapEnabled, defaultVariation, getFanHeightInches]
    )
    return (
      <>
        <SnapLine ref={snapLine} />
        <group ref={groupRef}>
          <DimensionGuides isDragging position={new Vector3()} ref={arrowsRef} />
          <group rotation-x={MODEL_X_ROTATION_OFFSET}>
            <Suspense fallback={<LoadingIndicator size={5} />}>
              {!defaultVariation ? (
                <LoadingIndicator size={5} />
              ) : (
                <ProductPreview {...defaultVariation.previewProps} />
              )}
            </Suspense>
          </group>
        </group>
      </>
    )
  }
)
