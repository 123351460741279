import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import ApolloClient from 'client'
import { PRODUCT_VARIATION_FRAGMENT } from 'client/fragments'

import Space from 'components/UIKit/Space'

import VoltageSelect from 'components/Panels/SelectedProductPanel/VoltageSelect'
import MountingOptionsSelect from 'components/Panels/SelectedProductPanel/MountingOptionsSelect'
import MountingStructuresSelect from 'components/Panels/SelectedProductPanel/MountingStructuresSelect'
import DiameterSelect from 'components/Panels/SelectedProductPanel/DiameterSelect'
import Checkbox from 'components/UIKit/Checkbox'
import TextField from 'components/UIKit/TextField'
import Flex, { FlexItem } from 'components/UIKit/Flex'
import Button from 'components/UIKit/Button'
import Collapsible from 'components/UIKit/Collapsible'
import Grid, { GridItem } from 'components/UIKit/Grid'
import Field from 'components/UIKit/TextField/styled/Field'

import Switch from 'components/UIKit/Switch'
import InstallTubeSelect from './InstallTubeSelect'

import { fanAdderOptions, LETTERS } from './constants'
import Loader from 'components/UIKit/Loader'
import { useApolloClient } from '@apollo/client'

const FanProductsTab = ({
  productsArray,
  onUpdateProducts,
  handleMountingOptionChange,
  handleChangeVariation,
}) => {
  const client = useApolloClient()

  return (
    <>
      <Space>
        <Grid>
          <GridItem size="1of6">
            <div>
              <b>Detailed Label</b>
              <Button
                noBorder
                icon="refresh"
                responsive
                onClick={e => {
                  const newProducts = productsArray.map((p, j) => {
                    let newLabel = ''
                    const labelLength = Math.floor(j / 26) + 1
                    for (let i = 0; i < labelLength; i++) {
                      newLabel += LETTERS[j % LETTERS.length]
                    }
                    return { ...p, detailedLabel: newLabel }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
            </div>
          </GridItem>
          <GridItem size="1of6">
            <b>Model</b>
          </GridItem>
          <GridItem size="1of6">
            <b>Diameter</b>
          </GridItem>
          <GridItem size="1of6">
            <Grid>
              <b>Voltage</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  if (get(productsArray[0], 'hasUnknownVoltage')) {
                    const newProducts = productsArray.map(p => {
                      return {
                        ...p,
                        hasUnknownVoltage: true,
                      }
                    })
                    onUpdateProducts(newProducts)
                  } else {
                    const variation = ApolloClient.readFragment({
                      id: `ProductVariation:${productsArray[0].variationId}`,
                      fragment: PRODUCT_VARIATION_FRAGMENT,
                    })
                    const voltages = variation.voltages || []
                    const voltage = voltages.find(
                      v => v.id === productsArray[0].voltageId
                    )
                    const newProducts = productsArray.map(p => {
                      const variation = ApolloClient.readFragment({
                        id: `ProductVariation:${p.variationId}`,
                        fragment: PRODUCT_VARIATION_FRAGMENT,
                      })
                      const newVoltage = variation.voltages.find(
                        v => v.inputPower === voltage.inputPower
                      )
                      return {
                        ...p,
                        voltageId: newVoltage ? newVoltage.id : p.voltageId,
                        hasUnknownVoltage: newVoltage
                          ? false
                          : p.hasUnknownVoltage,
                      }
                    })
                    onUpdateProducts(newProducts)
                  }
                }}
              />
            </Grid>
          </GridItem>
          <GridItem size="1of6">
            <div>
              <b>ET Length</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  if (!get(productsArray[0], 'connectInstallET')) {
                    const newProducts = productsArray.map(p => ({
                      ...p,
                      installTube: get(productsArray[0], 'installTube'),
                    }))
                    onUpdateProducts(newProducts)
                    return
                  }
                  const variation = ApolloClient.readFragment({
                    id: `ProductVariation:${productsArray[0].variationId}`,
                    fragment: PRODUCT_VARIATION_FRAGMENT,
                  })
                  const voltages = variation.voltages || []
                  const voltage = voltages.find(
                    v => v.id === productsArray[0].voltageId
                  )
                  const mountingOption = voltage.mountingOptions.find(
                    o => o.id === productsArray[0].mountingOptionId
                  )

                  const newProducts = productsArray.map(p => {
                    const variation = ApolloClient.readFragment({
                      id: `ProductVariation:${p.variationId}`,
                      fragment: PRODUCT_VARIATION_FRAGMENT,
                    })
                    const newVoltage = variation.voltages.find(
                      v => v.id === p.voltageId
                    )
                    const matchingMountOption = newVoltage.mountingOptions.find(
                      m => m.tubeLength === mountingOption.tubeLength
                    )

                    return {
                      ...p,
                      mountingOptionId: matchingMountOption
                        ? matchingMountOption.id
                        : p.mountingOptionId,
                      installTube: matchingMountOption
                        ? matchingMountOption.tubeLength
                        : p.installTube,
                    }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
              <Space bottom="base">
                <b>Link to Model</b>
                <Switch
                  onClick={() => {
                    const value = get(productsArray[0], 'connectInstallET')

                    const newProducts = productsArray.map(p => ({
                      ...p,
                      connectInstallET: !value,
                    }))

                    onUpdateProducts(newProducts)
                  }}
                  name="ortho"
                  label="Ortho"
                  variant="dark"
                  responsive
                  isChecked={get(productsArray[0], 'connectInstallET', false)}
                />
              </Space>
            </div>
          </GridItem>
          <GridItem size="1of6">
            <div>
              <b>Structure</b>
              <Button
                noBorder
                icon="duplicate"
                responsive
                onClick={e => {
                  const {
                    variationId,
                    mountingOptionAdderId,
                  } = productsArray[0]

                  const productVariation = ApolloClient.readFragment({
                    id: `ProductVariation:${variationId}`,
                    fragment: PRODUCT_VARIATION_FRAGMENT,
                  })

                  const mountingOptionAdders =
                    get(productVariation, 'mountingOptionAdders') || []
                  const currentMountingOption = mountingOptionAdders.find(
                    item => item.id === mountingOptionAdderId
                  )

                  const newProducts = productsArray.map(p => {
                    const productVariation = ApolloClient.readFragment({
                      id: `ProductVariation:${p.variationId}`,
                      fragment: PRODUCT_VARIATION_FRAGMENT,
                    })

                    const childMountingOptionAdders =
                      get(productVariation, 'mountingOptionAdders') || []
                    const childMountingOption = childMountingOptionAdders.find(
                      item => item.id === p.mountingOptionAdderId
                    )

                    if (childMountingOption !== currentMountingOption) {
                      if (
                        currentMountingOption === 'unknown' ||
                        !currentMountingOption
                      ) {
                        return {
                          ...p,
                          mountingOptionAdderId: currentMountingOption,
                          mountingOptionAdder: currentMountingOption,
                        }
                      }
                      const match = childMountingOptionAdders.find(
                        item => item.id === mountingOptionAdderId
                      )

                      return {
                        ...p,
                        mountingOptionAdderId: get(match, 'id', 'unknown'),
                        mountingOptionAdder: get(
                          match,
                          'mountingType',
                          'unknown'
                        ),
                      }
                    }

                    return {
                      ...p,
                    }
                  })
                  onUpdateProducts(newProducts)
                }}
              />
            </div>
          </GridItem>
        </Grid>
      </Space>
      {productsArray.map((p, index) => {
        const voltageValue = p.hasUnknownVoltage ? 'unknown' : p.voltageId || ''
        const productVariation = client.readFragment({fragment: PRODUCT_VARIATION_FRAGMENT, id: `ProductVariation:${p.variationId}`})

        return (
          <Space bottom="base" key={index}>
            <Grid>
              <GridItem size="1of6">
                <Field
                  width="80%"
                  value={p.detailedLabel || ''}
                  onChange={e => {
                    const value = e.target.value
                    const newProduct = { ...p, detailedLabel: value }
                    onUpdateProducts([newProduct])
                  }}
                />
              </GridItem>
              <GridItem size="1of6">
                <b>{p.model}</b>
              </GridItem>
              <GridItem size="1of6">
                <DiameterSelect
                  name="product-size"
                  placeholder=""
                  productId={p.productId}
                  onChange={event => {
                    handleChangeVariation({
                      id: event.target.value,
                      product: p,
                      onUpdateProducts,
                    })
                  }}
                  value={p.variationId || ''}
                />
              </GridItem>
              <GridItem size="1of6">
                <VoltageSelect
                  name="product-voltage"
                  placeholder=""
                  productVariationId={p.variationId}
                  value={voltageValue}
                  onChange={event => {
                    const value = event.target.value
                    if (value === 'unknown') {
                      onUpdateProducts([{ ...p, hasUnknownVoltage: true }])
                    } else {
                      onUpdateProducts([
                        {
                          ...p,
                          voltageId: event.target.value,
                          hasUnknownVoltage: false,
                        },
                      ])
                    }
                  }}
                  isHeater={p.category === 'HEAT'}
                />
              </GridItem>
              <GridItem size="1of6">
                {p.connectInstallET ? (
                  <MountingOptionsSelect
                    placeholder=""
                    voltageId={p.voltageId}
                    value={p.mountingOptionId}
                    productId={productVariation.product.id}
                    mountingOptions={productVariation.voltages.find(v => v.id === p.voltageId)?.mountingOptions}
                    onChange={e => {
                      handleMountingOptionChange({
                        value: e.target.value,
                        product: p,
                        onUpdateProducts,
                      })
                    }}
                  />
                ) : (
                  <InstallTubeSelect
                    product={p}
                    value={get(p, 'installTube')}
                    onUpdateProducts={onUpdateProducts}
                  />
                )}
              </GridItem>
              <GridItem size="1of6">
                <MountingStructuresSelect
                  placeholder=""
                  productVariationId={p.variationId}
                  value={p.mountingOptionAdderId}
                  onChange={e => {
                    const value = e.target.value
                    onUpdateProducts([{ ...p, mountingOptionAdderId: value }])
                  }}
                />
              </GridItem>
            </Grid>
            <Collapsible trigger="Fan Adders">
              <Space bottom="base">
                <Flex>
                  {fanAdderOptions.map((item, j) => {
                    const value = get(p, 'adders', []).includes(item)
                    return (
                      <FlexItem key={j} width="300px" marginRight="base">
                        <Checkbox
                          label={item}
                          value={value}
                          checked={value}
                          onChange={e => {
                            const newAdders = get(p, 'adders', [])
                            if (value) {
                              const index = newAdders.indexOf(item)
                              if (index > -1) {
                                newAdders.splice(index, 1)
                              }
                            } else {
                              newAdders.push(item)
                            }
                            onUpdateProducts([{ ...p, adders: newAdders }])
                          }}
                        />
                        {item === 'Other' && value && (
                          <TextField
                            label="Please specify:"
                            value={p.adderOther}
                            onChange={e => {
                              const value = e.target.value
                              const newProduct = {
                                ...p,
                                adderOther: value,
                              }
                              onUpdateProducts([newProduct])
                            }}
                          />
                        )}
                      </FlexItem>
                    )
                  })}
                  <FlexItem width="150px" marginRight="base">
                    <Button
                      icon="duplicate"
                      onClick={e => {
                        const newAdders = get(p, 'adders', [])
                        const newProducts = productsArray.map(p => ({
                          ...p,
                          adders: [...newAdders],
                        }))
                        onUpdateProducts(newProducts)
                      }}
                    />
                  </FlexItem>
                </Flex>
              </Space>
            </Collapsible>
          </Space>
        )
      })}
    </>
  )
}

FanProductsTab.propTypes = {
  productsArray: PropTypes.array,
  onUpdateProducts: PropTypes.func,
  handleMountingOptionChange: PropTypes.func,
  handleChangeVariation: PropTypes.func,
  loading: PropTypes.bool,
  products: PropTypes.array,
}

export default FanProductsTab
