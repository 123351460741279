import { Select, SelectItem } from "~/ui/Select"

export const ControllerTypeSelect = ({ value, onChange, label }: { value: string | undefined; onChange: (key: string) => void; label: string | undefined }) => {
  return (
    <Select label={label} selectedKey={value} onSelectionChange={key => onChange(key.toString())}>
      <SelectItem>Standard Controller</SelectItem>
      <SelectItem>BAFCon</SelectItem>
      <SelectItem>BAFCon Multi-Fan</SelectItem>
      <SelectItem>BAFWorks</SelectItem>
      <SelectItem>Dewtect</SelectItem>
    </Select>
  )
}
