import React, { Component } from 'react'
import PropTypes from 'prop-types'

import routes from 'config/routes'
import LAYER_KEYS from 'config/layerKeys'

import Facility from 'components/DrawingCanvas/lib/facility'
import Util from 'components/DrawingCanvas/lib/util'

import TouchPopover from '../styled/TouchPopover'

import ItemMenu from '../ItemMenu'
import AddItemMenuItem from '../AddItemMenuItem'
import withStandardProps from '../withStandardProps'

class RoofsAndCeilingsMenu extends Component {
  state = {
    rectangularFacility: false,
  }

  get roofTemplateItem() {
    const { history, insertItem, match } = this.props

    return [
      {
        title: 'Roof Templates',
        icon: {
          name: 'crop',
          size: '14',
        },
        onClick: () => {
          history.push(`${match.url}${routes.modals.roofTemplates}`)
          insertItem({
            layerKey: LAYER_KEYS.ROOFS,
            tool: 'TEMPLATE_TOOL',
          })
        },
      },
    ]
  }

  get items() {
    const { insertItem } = this.props
    return [
      {
        title: 'Elevation Line',
        icon: {
          name: 'caretUp',
          size: '14',
        },
        onClick: () =>
          insertItem({
            layerKey: LAYER_KEYS.ROOFS,
            tool: 'ELEVATION_LINE_TOOL',
          }),
      },
      {
        title: 'Elevation Point',
        icon: {
          name: 'elevationPoint',
          size: '14',
        },
        onClick: () =>
          insertItem({
            layerKey: LAYER_KEYS.ROOFS,
            tool: 'ELEVATION_POINT_TOOL',
          }),
      },
      {
        title: 'Mounting Structure',
        icon: {
          name: 'mountingStructures',
          size: '14',
        },
        onClick: () =>
          insertItem({
            layerKey: LAYER_KEYS.ROOF_SECTIONS,
            tool: 'SELECT_TOOL',
          }),
      },
      {
        title: 'Ceiling',
        icon: {
          name: 'ceilings',
          size: '14',
        },
        onClick: () =>
          insertItem({
            layerKey: LAYER_KEYS.CEILINGS,
            tool: 'SELECT_TOOL',
          }),
      },
    ]
  }

  get rectangularFacility() {
    return Util.isFacilityRectangle()
  }

  render() {
    const { rectangularFacility } = this.state

    return (
      <TouchPopover
        renderMenu={({ isDropdownVisible, handleTriggerClick }) => (
          <ItemMenu
            title="Roofs & Ceilings"
            isVisible={isDropdownVisible}
            closeMenu={handleTriggerClick}
            items={this.roofTemplateItem.concat(this.items)}
          />
        )}
        renderTrigger={({ isDropdownVisible }) => (
          <AddItemMenuItem
            icon="home"
            isDropdownVisible={isDropdownVisible}
            label="Roofs & Ceilings"
            onClick={() => {
              if (Facility.current) {
                if (this.rectangularFacility) {
                  this.setState({
                    rectangularFacility: true,
                  })
                }
              }
            }}
          />
        )}
      />
    )
  }
}

RoofsAndCeilingsMenu.propTypes = {
  history: PropTypes.object,
  insertItem: PropTypes.func,
  match: PropTypes.object,
}

export default withStandardProps(RoofsAndCeilingsMenu)
