import { Fragment } from 'react'
import { Vector3Like } from 'three'
import { DimensionError } from '../hooks/useDimensionErrors'
import { modelToUI, vectorModelToUI } from '~/components/DrawingCanvas/util/units'
import { ERROR_COLOR, ARROW_SIZE } from '~/components/DrawingCanvas/Products/constants'
import { GroupProps } from '@react-three/fiber'

type ErrorIndicatorsProps = { errors: DimensionError[]; origin: Vector3Like } & GroupProps

export const ErrorIndicators = ({ errors, origin, ...props }: ErrorIndicatorsProps) => {
  const { x, y, z } = origin
  return (
    <group {...props}>
      {errors.map(({ location }, i) => {
        if (!location) return <Fragment key={i} />
        const { direction, distance } = location
        return (
          <arrowHelper
            key={i}
            args={[
              direction,
              vectorModelToUI({ x, y, z }),
              modelToUI(distance),
              ERROR_COLOR,
              ARROW_SIZE,
              ARROW_SIZE,
            ]}
          />
        )
      })}
    </group>
  )
}
