import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Util from './util'
import Units from './units'
import Offset from './offset'
import WallSegment from './wallSegment'
import Primitives from './primitives'
import Facility from './facility'
import FloatingElementManager from './floatingElementManager'
import store from '~/store'
import { setStatus } from 'store/status'
import { updateObjects } from 'store/objects'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import OBJECT_TYPES from 'config/objectTypes'
import LAYER_KEYS from 'config/layerKeys'
import CLASS_NAMES from 'config/objectClassNames'
import { getThreeHexFromTheme } from 'lib/utils'
import CLICK_PRIORITY from 'config/clickPriority'

import * as THREE from 'three'
import { handles } from '../CompatibilityObject3DHandles'
import { vectorUIToModel } from '../util/units'

const DEFAULT_WALL_SIZES = {
  EXTERIOR_WALLS: {
    thickness: 24,
    height: 288,
  },
  INTERIOR_WALLS: {
    thickness: 17,
    height: 96,
  },
  OBSTRUCTIONS: {
    thickness: 17,
    height: 48,
  },
}

class Wall {
  constructor(model, units) {
    this.units = units
    this.className = CLASS_NAMES.WALL
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.id = model.id
    this.roofId = model.roofId
    this.roofSectionIds = model.roofSectionIds || []

    if (!this.id) {
      this.id = Util.guid()
    }

    this.update(model)

    this.linkNeighborSegments()

    // Used by Interactifier
    this.selectable = true
    this.draggable = false
    this.multiDraggable = true

    if (objectIsSelected(this.id)) {
      this.select()
    }

    this.lastValidPos = this.obj3d.position.clone()
  }

  /*
    SceneBuilder event
  */
  sceneDidRebuild(facility /* thisChanged */) {}

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    if (this.obj3d) this.obj3d.visible = visible
  }

  select(draggable = true) {
    this.draggable = draggable
    const isLocked = store.getState().layers.layers[this.layerKey].locked
    this.draggable = !isLocked

    if (this.floorMesh !== undefined) {
      this.floorMesh.material.uniforms.selected.value = true
    }

    if (draggable) {
      this.segments.forEach(segment => segment.showTextInput())
    }
  }

  deselect() {
    if (this.floorMesh !== undefined) {
      this.floorMesh.material.uniforms.selected.value = false
    }

    this.segments.forEach(segment =>
      FloatingElementManager.hideFloatingElement(segment.id)
    )

    this.draggable = false
  }

  update(model) {
    this.segments = model.segments.map(segmentModel =>
      this.wrapperForSegmentModel(segmentModel)
    )

    let position = model.position
    // We do this check for backwards compatibility
    if (!position) {
      position = this.segments[0].startPoint
    }

    const convertedPosition = Units.unitsToNativeV(this.units, position)

    this.repairSegments(this.segments)
    this.convertSegmentsToLocalCoords(this.segments, convertedPosition)

    this.updateCenterLinesFromSegments()
    this.updateOffsetData()

    this.createWallMesh(convertedPosition)
    this.createFloorMesh()
  }

  /*
    Returns a WallSegment for the given segment model. If a WallSegment
    for that the segmentModel.id has been created before, it will return
    the previously created object rather than constructing a new one.
  */
  wrapperForSegmentModel(segmentModel) {
    let wallSegment
    if (this.segments) {
      wallSegment = this.segments.find(
        segment => segment.id === segmentModel.id
      )
    }

    if (!wallSegment) {
      wallSegment = new WallSegment(segmentModel, this.units)
    }

    wallSegment.updateCenterLinePoints([
      [
        Units.unitsToNative(this.units, segmentModel.startPoint.x),
        Units.unitsToNative(this.units, segmentModel.startPoint.y),
      ],
      [
        Units.unitsToNative(this.units, segmentModel.endPoint.x),
        Units.unitsToNative(this.units, segmentModel.endPoint.y),
      ],
    ])

    return wallSegment
  }

  createWallMesh(position) {
    let oldParent

    // Remove pre-existing obj3d from parent
    if (this.obj3d && this.obj3d.parent) {
      oldParent = this.obj3d.parent
      this.obj3d.parent.remove(this.obj3d)
    }

    // Re-create mesh
    this.obj3d = Primitives.getWallMesh(
      this.centerLinePoints,
      this.segments.map(seg => seg.thickness),
      this.segments.map(seg => seg.height),
      this.segments.map(seg => seg.materialIndex)
    )

    // Used by Interactifier
    this.obj3d.wrapperId = this.id
    this.obj3d.userData.objectType = OBJECT_TYPES.WALL

    // Check for invalid geometry
    this.obj3d.geometry.computeBoundingSphere()
    if (isNaN(this.obj3d.geometry.boundingSphere.radius)) {
      this.useDebugObj3d()
    }

    // Make sure segments have references to the new vertex groups
    // and that their obj3ds are children of this Wall's.
    this.segments.forEach((segment, i) => {
      segment.updateVertexGroup(this.obj3d.geometry.groups[i])
      this.obj3d.add(segment.obj3d)
    })

    this.obj3d.position.copy(position)

    if (oldParent) {
      oldParent.add(this.obj3d)
    }
  }

  getSegmentWithVertexIndex(index) {
    // We're passing the faceIndex, but vertexGroup data uses points
    // Each face is 3 vertices, so faceIndex will be 1/3 the value of the vertexGroup values
    // So we multiply index by 3
    return this.segments.find(
      segment =>
        index * 3 >= segment.vertexGroup.start &&
        index * 3 < segment.vertexGroup.start + segment.vertexGroup.count
    )
  }

  static getGreatestSegmentHeight(segmentModels) {
    let greatest = 0

    segmentModels.forEach(segmentModel => {
      if (segmentModel.height > greatest) {
        greatest = segmentModel.height
      }
    })

    return greatest
  }

  convertSegmentUnits(segments) {
    segments.forEach(segment => {
      segment.startPoint.x = Units.unitsToNative(
        this.units,
        segment.startPoint.x
      )
      segment.startPoint.y = Units.unitsToNative(
        this.units,
        segment.startPoint.y
      )
      segment.endPoint.x = Units.unitsToNative(this.units, segment.endPoint.x)
      segment.endPoint.y = Units.unitsToNative(this.units, segment.endPoint.y)
    })
  }

  convertSegmentsToLocalCoords(segmentModels, position) {
    segmentModels.forEach(segModel => {
      segModel.startPoint.x -= position.x
      segModel.startPoint.y -= position.y
      segModel.endPoint.x -= position.x
      segModel.endPoint.y -= position.y
    })
  }

  /*
    There are certain constraints on how the segments are structured
    in order for algorithms which operate on the center line to
    work correctly. For example, they must be in counter-clockwise order.
    This method modifies the segments in the given array to conform to
    those constraints.
  */
  repairSegments(segments) {
    const centerLinePoints = Wall.getCenterLinePointsFromSegments(segments)

    // Ensure segments are in counter-clockwise order
    if (
      this.isPolygon(centerLinePoints) &&
      !Util.pointsAreCounterClockwise(centerLinePoints)
    ) {
      // Reverse segment end points
      segments.forEach(segment => {
        const startPointCopy = segment.startPoint.clone()
        segment.startPoint = segment.endPoint
        segment.endPoint = startPointCopy
      })

      if (segments.length > 2) segments.reverse()
    }
  }

  /*
    If we have invalid geometry in our wall's obj3d, we can replace it with
    a group of line segments so the program doesn't crash and we can find
    the problem area.
  */
  useDebugObj3d() {
    const points = []
    this.segments.forEach(segment => {
      points.push([segment.startPoint.x, segment.startPoint.y])
      points.push([segment.endPoint.x, segment.endPoint.y])
    })

    this.obj3d = Primitives.getPolyline(
      points,
      true,
      getThreeHexFromTheme('three.invalid'),
      Units.inchesToNative(8),
      true
    )
    this.obj3d.geometry = new THREE.PlaneGeometry(1, 1)
    let last = 0
    for (let i = 0; i < this.segments.length; i += 1) {
      const end = Math.round(i / this.segments.length)
      this.obj3d.geometry.addGroup(last, end, 0)
      last = end + 1
    }
  }

  updateCenterLinesFromSegments(segments = this.segments) {
    this.centerLinePoints = Wall.getCenterLinePointsFromSegments(segments)

    segments.forEach((segment, i) => {
      segment.updateCenterLinePoints([
        this.centerLinePoints[i],
        this.centerLinePoints[i + 1],
      ])
    })
  }

  /*
    Export this Wall object as a wall model in 'real' units and global coords
  */
  toModel() {
    // create segment models
    const segments = this.segments.map(seg => seg.toModel())

    // create wall model
    const model = {
      id: this.id,
      segments,
      layerKey: this.layerKey,
      isEnclosed: this.isPolygon(),
      roofId: this.roofId,
      roofSectionIds: this.roofSectionIds,
      position: Units.nativeToUnitsV(this.units, this.obj3d.position),
    }

    return model
  }

  static isCenterLineValid(model) {
    const centerLinePoints = Wall.getCenterLinePointsFromSegments(
      model.segments
    )
    return Util.isPolygonValid(centerLinePoints)
  }

  static getDenormalizedModel(model) {
    const currentState = store.getState().objects.present

    let segments = []
    if (model.segments && model.segments.length > 0) {
      segments = model.segments.map(
        segmentId => currentState.segments[segmentId]
      )
    }

    return {
      ...model,
      segments,
    }
  }

  getUpdatedRoofModel() {
    const oldRoofModel = store.getState().objects.present.roofs[this.roofId]
    const newRoofModel = {
      ...oldRoofModel,
      perimeterPoints: this.centerLinePoints,
      position: this.obj3d.position,
    }

    return newRoofModel
  }

  static isModelValid(model) {
    if (model.segments.length === 0) return false

    let valid = true

    // Make sure the segments are connected in sequence
    for (let i = 0; i < model.segments.length - 1; i += 1) {
      if (i > 0) {
        const seg = model.segments[i]
        const nextSeg = model.segments[i + 1]

        if (!Util.pointsAreEqual2D(seg.endPoint, nextSeg.startPoint)) {
          valid = false
        }
      }
    }

    // Make sure no segments have zero length
    model.segments.forEach(segment => {
      if (
        Util.pointsAreEqual2D(
          segment.startPoint,
          segment.endPoint,
          Units.inchesToNative(1)
        )
      ) {
        valid = false
      }
    })

    return valid
  }

  /*
    A convenience method for obtaining a wall model object.

    Thickness and height are optional; defaults for layerKey will be used
    if not provided. If thickness and height are provided, they should be
    in the correct units already: no conversion will be done.

    Expects the centerLinePoints to be in native units and it will convert
    to the given units.
  */
  static createModel(centerLinePoints, units, layer, options) {
    // If the layer is somehow not a wall layer, default to exterior wall
    let layerKey = layer
    if (
      layerKey !== LAYER_KEYS.EXTERIOR_WALLS &&
      layerKey !== LAYER_KEYS.INTERIOR_WALLS
    ) {
      layerKey = LAYER_KEYS.EXTERIOR_WALLS
    }

    options = options || {}
    let { convertFromNativeUnits, thickness, height } = options

    const model = { id: Util.guid(), layerKey, segments: [] }

    if (convertFromNativeUnits) {
      centerLinePoints = centerLinePoints.map(point => [
        Units.nativeToUnits(units, point[0]),
        Units.nativeToUnits(units, point[1]),
      ])
    }

    // Build segment models
    for (let i = 0; i < centerLinePoints.length - 1; i += 1) {
      if (!thickness) {
        thickness = Wall.thicknessForLayerKey(layerKey)
      }
      if (!height) {
        height = Wall.heightForLayerKey(layerKey)
      }

      const startPoint = new THREE.Vector3(
        centerLinePoints[i][0],
        centerLinePoints[i][1],
        0
      )
      const endPoint = new THREE.Vector3(
        centerLinePoints[i + 1][0],
        centerLinePoints[i + 1][1],
        0
      )
      const segmentModel = WallSegment.createModel(
        model.id,
        layerKey,
        startPoint,
        endPoint,
        height,
        thickness
      )

      model.segments.push(segmentModel)
    }

    // We arbitarily choose the first point to be the point
    // which all others are relative to. We do this in order to create
    // a local coordinate system which walls can work with.
    const position = model.segments[0].startPoint

    return {
      ...model,
      isEnclosed: Offset.isPolygon(centerLinePoints),
      roofSectionIds: [],
      position,
    }
  }

  /*
    Sets the values of insetPoints, outsetPoints, insetEdges, outsetEdges, and
    lineOffset in the case of polyline walls.
  */
  updateOffsetData() {
    if (this.isPolygon()) {
      const offsetData = Offset.offset(
        this.centerLinePoints,
        this.segments.map(segment => segment.thickness)
      )
      this.insetPoints = offsetData.insetPoints
      this.insetEdges = offsetData.insetEdges
      this.outsetPoints = offsetData.outsetPoints
      this.outsetEdges = offsetData.outsetEdges
    } else {
      const offsetData = Offset.offset(
        this.centerLinePoints,
        this.segments.map(segment => segment.thickness)
      )
      this.lineOffset = offsetData.outsetPoints

      this.insetPoints = this.lineOffset
        .slice(0, this.lineOffset.length / 2)
        .reverse()
      this.outsetPoints = this.lineOffset.slice(
        this.lineOffset.length / 2,
        this.lineOffset.length
      )

      this.outsetEdges = Offset.pointsToEdges(this.outsetPoints)
      this.insetEdges = Offset.pointsToEdges(this.insetPoints)

      // On polyline walls these are 'end cap' segments (i.e. they run along the transverse axis of
      // the segment, rather than the longitudinal, and their length is equal to the wall thickness),
      // and we don't want to include these.
      this.insetEdges.shift()
      this.outsetEdges.pop()
    }

    this.segments.forEach((segment, i) => {
      const lastIndex = this.segments.length - 1
      const index = this.isPolygon() ? i : lastIndex - i
      segment.updateOffsetPoints(
        this.insetEdges[index],
        this.outsetEdges[index]
      )
    })
  }

  linkNeighborSegments() {
    if (this.isPolygon()) {
      this.segments.forEach((segment, i) => {
        if (i === 0) {
          segment.previousSegment = this.segments[this.segments.length - 1]
        } else {
          segment.previousSegment = this.segments[i - 1]
        }

        segment.nextSegment = this.segments[(i + 1) % this.segments.length]
      })
    } else {
      this.segments.forEach((segment, i) => {
        segment.previousSegment = this.segments[i - 1]
        segment.nextSegment = this.segments[i + 1]
      })
    }

    this.segments.forEach((segment, i) => {
      if (objectIsSelected(segment.id)) {
        segment.select()
      }
    })
  }

  createFloorMesh() {
    if (this.isPolygon()) {
      this.obj3d.remove(this.floorMesh)
      this.floorMesh = Primitives.getFloorMesh(this.insetPoints)
      if (this.layerKey === LAYER_KEYS.INTERIOR_WALLS) {
        this.floorMesh.position.z += 0.01
      }
      this.floorMesh.wrapperId = this.id
      this.floorMesh.userData.objectType = OBJECT_TYPES.FLOOR
      this.floorMesh.isFloor = true
      this.obj3d.add(this.floorMesh)
    }
  }

  static pointSequenceIsLoop(points) {
    return Util.pointsAreEqual2D(points[0], points[points.length - 1])
  }

  /*
    Allows us to reconstruct the centerLinePoints array from existing WallSegments. Typically
    this would either be this Wall's segments array, or an array of segments found in a model
    object.
  */
  static getCenterLinePointsFromSegments(segments) {
    const centerLinePoints = []

    segments.forEach(segment => {
      centerLinePoints.push([
        segment.startPoint.x,
        segment.startPoint.y,
        segment.startPoint.z,
      ])
    })

    const lastSegment = segments[segments.length - 1]
    if (lastSegment) {
      const lastSegmentEndPoint = lastSegment.endPoint
      centerLinePoints.push([
        lastSegmentEndPoint.x,
        lastSegmentEndPoint.y,
        lastSegmentEndPoint.z,
      ])
    }

    return centerLinePoints
  }

  toGlobalPoints(points) {
    return points.map(point => [
      point[0] + this.obj3d.position.x,
      point[1] + this.obj3d.position.y,
    ])
  }

  globalCenterLinePoints() {
    return this.toGlobalPoints(this.centerLinePoints)
  }

  isPolygon(centerLinePoints = this.centerLinePoints) {
    return Offset.isPolygon(centerLinePoints)
  }

  getUpdatedElevationPoints() {
    if (!this.dragDeltaBeforeDrop) return []

    const elevationPoints = Object.values(store.getState().objects.present.elevationPoints)
    const dragDelta = this.dragDeltaBeforeDrop
    const elevationPointModels = []

    // Move elevation points that belong to this walls segments
    this.segments.forEach(seg => {
      const matchedElevationPoints = elevationPoints.filter(
        point => point.wallSegmentId === seg.id
      )

      if (!matchedElevationPoints.length) return

      matchedElevationPoints.forEach(ep => {
        elevationPointModels.push({
          ...ep,
          position: new THREE.Vector3().copy(ep.position).add(dragDelta),
        })
      })
    })

    // Move elevation points that belong to this wall but not its wall segments
    elevationPoints.forEach(ep => {
      if (this.id === ep.wallId && !ep.wallSegmentId) {
        elevationPointModels.push({
          ...ep,
          position: new THREE.Vector3().copy(ep.position).add(dragDelta),
        })
      }
    })

    return elevationPointModels
  }

  getUpdatedObjects() {
    // Get all attached objects
    const attachedDoors = this.getAttachedDoors()
    const attachedBoxes = this.getAttachedUtilityBoxes()

    // Build models from attached objects
    const doors = attachedDoors.map(door => {
      const offset = vectorUIToModel(handles.get(door.id).position)
      handles.get(door.id).position.set(0, 0, 0)
      const ret = {
        ...door,
        position: offset.add(door.position)
      }
      return ret
    })
    const boxes = attachedBoxes.map(box => {
      return { ...box.toModel() }
    })

    return [...doors, ...boxes]
  }

  getAttachedDoors() {
    const segmentIds = new Set(this.segments.map(seg => seg.id))
    return Object.values(store.getState().objects.present.doors)
      .filter(door => segmentIds.has(door.wallSegmentId))
  }

  getAttachedUtilityBoxes() {
    const segmentIds = this.segments.map(seg => seg.id)
    return Facility.current
      .getUtilityBoxes()
      .filter(box => segmentIds.includes(box.wallSegmentId))
  }

  static thicknessForLayerKey(layerKey, native = false) {
    const units =
      get(DEFAULT_WALL_SIZES, `[${layerKey}].thickness`) ||
      DEFAULT_WALL_SIZES.EXTERIOR_WALLS.thickness

    if (native) {
      return Units.inchesToNative(units)
    }

    return units
  }

  static heightForLayerKey(layerKey, native = false) {
    const units =
      get(DEFAULT_WALL_SIZES, `[${layerKey}].height`) ||
      DEFAULT_WALL_SIZES.EXTERIOR_WALLS.height

    if (native) {
      return Units.inchesToNative(units)
    }

    return units
  }

  // ///
  // Interactable methods
  // ///

  getSnappableEdgePoints() {
    const allEdgePoints = this.segments.reduce(
      (array, segment) => array.concat(segment.getOutsetPoints()),
      []
    )

    return allEdgePoints
  }

  drop(_, __, saveModel = true) {
    const pos = this.obj3d.position.clone()
    const positions = []
    positions.push(pos)
    this.segments.forEach(seg => {
      positions.push(pos.clone().add(seg.startPoint))
      positions.push(pos.clone().add(seg.endPoint))
    })
    const isInsideFacility = Util.isPositionsOverFacility(positions)
    const isExteriorWall = this.layerKey === 'EXTERIOR_WALLS'

    if (isInsideFacility || isExteriorWall) {
      const elevationPoints = this.getUpdatedElevationPoints()
      const updatedObjects = this.getUpdatedObjects()
      const allUpdatedObjects = [...elevationPoints, ...updatedObjects]
      this.dragDeltaBeforeDrop = null

      if (saveModel) {
        updatedObjects.push({ object: this.toModel() })
      }

      if (allUpdatedObjects.length) {
        store.dispatch(updateObjects(allUpdatedObjects))
      }
    } else {
      this.obj3d.position.copy(this.lastValidPos)

      // Use timeout so status message isn't prematurely cleared
      setTimeout(() => {
        const error = 'Interior walls must be placed inside the facility!'
        store.dispatch(setStatus({ text: error, type: 'error' }))
      }, 500)
    }
  }

  drag({ dragDelta, newPosition, objectWithCursor }) {
    this.obj3d.position.add(dragDelta)

    if (!this.dragDeltaBeforeDrop) {
      this.dragDeltaBeforeDrop = new THREE.Vector3()
    }
    this.dragDeltaBeforeDrop.add(dragDelta)

    // Update any attached doors position
    this.getAttachedDoors().forEach(door => {
      handles.get(door.id)?.position.add(dragDelta)
    })

    // Update any attached utility boxes position
    this.getAttachedUtilityBoxes().forEach(box => {
      box.obj3d.position.add(dragDelta)
    })
  }

  snap(snapDelta) {
    this.obj3d.position.add(snapDelta)

    // Add snap delta to any attached doors
    this.getAttachedDoors().forEach(door => {
      handles.get(door.id)?.position.add(snapDelta)
    })

    // Add snap delta to any attached utility boxes
    this.getAttachedUtilityBoxes().forEach(box => {
      box.obj3d.position.add(snapDelta)
    })
  }

  destroy() {
    this.segments.forEach(segment => {
      segment.destroy()
    })
  }
}

export default Wall
