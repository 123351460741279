import { useMemo } from 'react'
import { Line3, Vector3 } from 'three'
import { CANVAS_BOUNDS } from '~/components/DrawingCanvas/Products/constants'
import { SnapLine } from '~/components/DrawingCanvas/util/snaplines'
import { useAppSelector } from '~/store/hooks'
import { Product } from '~/store/objects/types'

export const useSnapLines = (props?: { filter?: (product: Product) => boolean }): SnapLine[] => {
  const { filter = () => true } = props ?? {}
  const products = useAppSelector(state => state.objects.present.products)
  return useMemo(() => {
    const otherProducts = Object.values(products).filter(filter)
    return otherProducts.reduce<SnapLine[]>((snapLines, { position, id }) => {
      const { x, y, z } = position
      const xAxis = new Line3(new Vector3(-CANVAS_BOUNDS, y, z), new Vector3(CANVAS_BOUNDS, y, z))
      const yAxis = new Line3(new Vector3(x, -CANVAS_BOUNDS, z), new Vector3(x, CANVAS_BOUNDS, z))
      snapLines.push({ line: xAxis, id: `${id}-horizontal` })
      snapLines.push({ line: yAxis, id: `${id}-vertical` })
      return snapLines
    }, [])
  }, [products])
}
