import { graphql } from '~/gql'

export const SELECTED_PRODUCTS_VARIATIONS_QUERY = graphql(`
  query SelectedProductsVariationsQuery($variationIds: [ID!]!) {
    ProductVariations(ids: $variationIds) {
      id
      canMountOnWall
      canMountOverhead
      size
      label
      degreesOfFreedom {
        id
      }
      product {
        id
        model
        category
        type
      }
    }
  }
`)
