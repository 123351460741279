import { MeshProps } from '@react-three/fiber'
import { useMemo } from 'react'
import { DoubleSide, Shape } from 'three'
import theme from '~/config/theme'

export const DirectionArrow = ({ size = 1, ...props }: MeshProps & { size?: number }) => {
  const arrowShape = useMemo(() => {
    const shape = new Shape()
    shape.moveTo(0, size * 2)
    shape.lineTo(size, -(size / 2))
    shape.lineTo(0, 0)
    shape.lineTo(-size, -(size / 2))
    shape.lineTo(0, size * 2)
    return shape
  }, [size])
  return (
    <mesh {...props} rotation-x={(3 * Math.PI) / 2}>
      <meshBasicMaterial
        transparent
        color={theme.colors.three.objects.product.directionArrow}
        side={DoubleSide}
        opacity={0.9}
      />
      <shapeGeometry args={[arrowShape]} />
    </mesh>
  )
}
