import { useAppDispatch, useAppSelector } from '~/store/hooks'
import get from 'lodash-es/get'

import { updateProducts } from 'store/objects'
import { SELECTED_PRODUCT_PANEL } from 'store/panel/types'

import Panel from 'components/UIKit/Panel'

import { Icon } from '~/ui/Icon'
import { MountingDetails } from '~/components/Panels/SelectedProductPanel/components/MountingDetails'
import {
  SelectedProductContext,
  SelectedProductContextProps,
} from '~/components/Panels/SelectedProductPanel/components/SelectedProductContext'
import { ProductDropDown } from '~/components/Panels/SelectedProductPanel/components/ProductDropDown'
import { ProductDetails } from '~/components/Panels/SelectedProductPanel/components/ProductDetails.tsx'
import { AddProductInstallInfoButton } from '~/components/Panels/SelectedProductPanel/components/AddProductInstallInfoButton'
import { MetadataFields } from '~/components/MetadataFields'
import { useSelectedProducts } from '~/hooks/useSelectedProducts'
import { ComponentProps, PropsWithChildren, Suspense } from 'react'
import { useSuspenseQuery } from '@apollo/client'
import { SELECTED_PRODUCTS_VARIATIONS_QUERY } from '~/client/queries'

export function existingVoltage<T extends { id?: string | null; inputPower?: string | null }>(
  voltages: T[],
  curVoltage: Partial<T> = {}
) {
  return voltages.find(v => {
    const hasSameId = v.id === curVoltage.id
    // Some voltages have a power using –
    // and others have a power using -
    // 😊
    const hasSamePower =
      (get(v, 'inputPower') || '').replace('–', '-') ===
      (get(curVoltage, 'inputPower') || '').replace('–', '-')

    return hasSameId || hasSamePower
  })
}

const WarningMessage = ({
  children,
  icon = 'warn',
}: PropsWithChildren<{
  icon?: ComponentProps<typeof Icon>['name']
}>) => {
  return (
    <div className="opacity-35 flex flex-col items-center gap-5 my-10">
      <Icon name={icon} size="30" />
      <p>{children}</p>
    </div>
  )
}

const PanelContent = () => {
  const dispatch = useAppDispatch()
  const selectedProducts = useSelectedProducts()
  const variationIds = Array.from(new Set(selectedProducts.map(p => p.variationId)))
  const { data } = useSuspenseQuery(SELECTED_PRODUCTS_VARIATIONS_QUERY, {
    variables: { variationIds },
  })

  const variations = new Map(data?.ProductVariations.map(variation => [variation.id, variation]))
  const selectedProductVariations = selectedProducts.reduce<
    SelectedProductContextProps['selectedProducts']
  >((products, product) => {
    const variation = variations.get(product.variationId)
    if (variation) {
      const { canMountOnWall, canMountOverhead, size, label, degreesOfFreedom } = variation
      const { model, category, type, id } = variation.product
      products.push({
        product,
        variation: {
          productId: id,
          canMountOnWall,
          canMountOverhead,
          model,
          category,
          type,
          size,
          label,
          isTiltable: Boolean(degreesOfFreedom),
        },
      })
    }
    return products
  }, [])
  const selectedTypes = Array.from(
    selectedProductVariations.reduce((types, { variation, product }) => {
      const { layerKey } = product
      const { category, model, type } = variation
      const isHeater = layerKey === 'PRODUCTS_HEATERS' || category === 'HEAT'
      if (isHeater) {
        types.add(model.includes('IRH') ? 'PRODUCTS_RADIANT_HEATERS' : 'PRODUCTS_UNIT_HEATERS')
      } else if (layerKey === 'PRODUCTS') {
        types.add(
          category === 'EVAP'
            ? 'PRODUCTS_EVAP'
            : type === 'OVERHEAD'
            ? 'PRODUCTS_OVERHEAD'
            : 'PRODUCTS_DIRECTIONAL'
        )
      } else {
        types.add(layerKey)
      }
      return types
    }, new Set<SelectedProductContextProps['productType']>())
  )
  const selectedProductType = selectedTypes.length === 1 ? selectedTypes[0] : null
  const isMultipleTypesSelected = selectedProducts.length > 0 && selectedTypes.length > 1

  const reducedProducts = selectedProductVariations.reduce(
    (prev, { variation, product }) => {
      prev.productIds.add(variation.productId)
      product.voltageId && prev.voltageIds.add(product.voltageId)
      return prev
    },
    {
      productIds: new Set<string>(),
      voltageIds: new Set<string>(),
    }
  )
  const productIds = Array.from(reducedProducts.productIds)
  const productId = productIds.length === 1 ? productIds[0] : null
  const variationId = variationIds.length === 1 ? variationIds[0] : null
  const voltageIds = Array.from(reducedProducts.voltageIds)
  const voltageId = voltageIds.length === 1 ? voltageIds[0] : null
  const isForcedWallMount = selectedProducts.every(p => p.isForcedWallMount)
  const isDirectionalOverhead = selectedProducts.every(p => p.isDirectionalOverhead)
  const isHeater =
    selectedProductType === 'PRODUCTS_RADIANT_HEATERS' ||
    selectedProductType === 'PRODUCTS_UNIT_HEATERS'

  // RAC Collections (used in Select) don't play well with suspense
  // https://github.com/adobe/react-spectrum/issues/6801#issuecomment-2261024008
  // https://github.com/adobe/react-spectrum/issues/6182
  const RAC_SUSPENSE_WORKAROUND_KEY = variationIds.join(":")
  
  return (
    <>
      {isMultipleTypesSelected || !selectedProductType ? (
        <WarningMessage icon="duplicate">Multiple product types selected.</WarningMessage>
      ) : (
        <div className="flex flex-col h-full pb-10 overflow-y-auto">
          <SelectedProductContext.Provider
            key={RAC_SUSPENSE_WORKAROUND_KEY}
            value={{
              productId,
              productType: selectedProductType,
              selectedProducts: selectedProductVariations,
              variationId,
              voltageId,
              isDirectionalOverhead,
              isForcedWallMount,
              isHeater,
            }}
          >
            <ProductDropDown />
            <MountingDetails />
            <ProductDetails />
            <AddProductInstallInfoButton />
            <MetadataFields
              onChange={(fieldName, newValue) => {
                const updatedProducts = selectedProducts.map(product => ({
                  ...product,
                  metadata: { ...product.metadata, [fieldName]: newValue },
                }))
                dispatch(updateProducts(updatedProducts))
              }}
              onDeleteImage={updatedProduct => dispatch(updateProducts([updatedProduct]))}
              selectedObjects={selectedProducts}
            />
          </SelectedProductContext.Provider>
        </div>
      )}
    </>
  )
}

const SelectedProductPanel = () => {
  const isTouchUI = useAppSelector(state => state.userInterface.isTouchUI)

  return (
    <Panel
      title="Product"
      alignment={isTouchUI ? 'left' : 'right'}
      docked
      panelKey={SELECTED_PRODUCT_PANEL}
      scrollable
      hasToolbar={isTouchUI}
    >
      <Suspense fallback={<WarningMessage icon="fan">Loading product data...</WarningMessage>}>
        <PanelContent />
      </Suspense>
    </Panel>
  )
}

export default SelectedProductPanel
