import get from 'lodash-es/get'
import Tool from './tool'
import SnapQueries from './snapQueries'
import Facility from './facility'
import ObstructionUtil from './obstructionUtil'
import Units from './units'
import Util from './util'
import Primitives from './primitives'
import CLASS_NAMES from 'config/objectClassNames'
import theme from 'config/theme'
import store from 'store'
import {
  getSelectedFacilityObjects,
  getSelectedObjects,
} from 'store/selectedObjects/selectors'
import { deselectObjects, selectObjects } from 'store/selectedObjects'

import * as THREE from 'three'

const SPACING_THRESHOLD = Units.inchesToNative(36)

class MultiSelectTouchTool extends Tool {
  constructor() {
    super()

    this.name = 'MULTI_SELECT_TOUCH_TOOL'
    this.isMouseDown = false
    this.selectionBox = null
    this.lastMouseDownPos = null
    this.selectedObjects = []
  }

  updateVisual(startPos, endPos) {
    if (this.selectionBox) {
      this.obj3d.remove(this.selectionBox)
      this.selectionBox = null
    }

    const startVector = new THREE.Vector3().copy(startPos)
    const endVector = new THREE.Vector3().copy(endPos)
    const positions = Util.getBoxPositions(startVector, endVector)

    // Get selection box mesh
    this.selectionBox = Primitives.getCustomMesh(positions, 250)
    this.selectionBox.material.transparent = true
    this.selectionBox.material.opacity = 0.1
    this.selectionBox.material.depthTest = false
    this.selectionBox.material.color.set(theme.colors.three.selectionBox)

    // Add selection box outline
    const edges = new THREE.EdgesGeometry(this.selectionBox.geometry)
    const lineMaterial = new THREE.LineBasicMaterial({
      color: theme.colors.three.selectionBoxOutline,
    })
    const line = new THREE.LineSegments(edges, lineMaterial)
    this.selectionBox.add(line)

    this.obj3d.add(this.selectionBox)
  }

  isLayerEnabled(type) {
    const layers = store.getState().layers.layers
    return layers[type].visible && !layers[type].locked
  }

  getSelectableObjects() {
    const facility = Facility.current
    const obstructions = this.isLayerEnabled('OBSTRUCTIONS')
      ? facility.getObstructions()
      : []
    const utilityBoxes = this.isLayerEnabled('UTILITY_BOXES')
      ? facility.getUtilityBoxes()
      : []
    const walls = this.isLayerEnabled('INTERIOR_WALLS')
      ? facility.getWalls()
      : []
    const interiorWalls = walls.filter(seg => seg.layerKey === 'INTERIOR_WALLS')

    return [
      ...interiorWalls,
      ...obstructions,
      ...utilityBoxes,
    ]
  }

  toolMoved(mousePos, snappedMousePos, _, objectUnderTool) {
    if (this.selectionBox) {
      this.obj3d.remove(this.selectionBox)
      this.selectionBox = null
    }

    const currentSelectedObjects = getSelectedObjects()

    if (this.isMouseDown) {
      this.updateVisual(this.lastMouseDownPos, mousePos)
      const bbox = new THREE.Box3().setFromObject(this.selectionBox)
      const allObjects = this.getSelectableObjects()

      // Remove all selected objects from app state
      if (!this.shiftModifier && currentSelectedObjects.length) {
        store.dispatch(deselectObjects({}))
      }

      // Deselect all selected objects
      this.selectedObjects.forEach(obj => {
        const isSelected = currentSelectedObjects.find(
          selectedObj => selectedObj.id === obj.id
        )
        if (!this.shiftModifier || !isSelected) obj.deselect()
      })

      // Clear objects for selection
      this.selectedObjects = []

      // Select any valid objects
      allObjects.forEach(obj => {
        const z = get(obj, 'obj3d.rotation.z')
        const isRotatedObstruction = z !== 0 && obj.className === 'Obstruction'
        const isWall = obj.className === 'Wall'

        if (isRotatedObstruction || isWall) {
          const collisionPoints = isRotatedObstruction
            ? this.getObstructionCollisionPoints(obj)
            : this.getWallCollisionPoints(obj)

          // Check if any collision points are in selection box
          collisionPoints.forEach(pos => {
            if (bbox.containsPoint(pos)) {
              if (!obj.draggable) obj.select(false)
              this.selectedObjects.push(obj)
            }
          })
        } else {
          // clone of our object to use for collision detection
          const clone = obj.obj3d.clone()

          // Remove children from obstructions (drag handle) before making box
          if (obj.className === 'Obstruction') clone.children = []

          // Use bounding box to test collision
          const testBox = new THREE.Box3().setFromObject(clone)
          if (bbox.intersectsBox(testBox)) {
            if (!obj.draggable) obj.select(false)
            this.selectedObjects.push(obj)
          }
        }
      })
    }
  }

  toolDown(mousePos, snappedMousePos, _, objectUnderTool) {
    this.isMouseDown = true
    this.lastMouseDownPos = mousePos
    if (!this.shiftModifier) this.selectedObjects = []
    this.lastMouseDownObject = objectUnderTool
  }

  toolUp({ mousePos, snappedMousePos, objectUnderTool }) {
    if (this.selectionBox) {
      this.obj3d.remove(this.selectionBox)
      this.selectionBox = null
    }

    this.isMouseDown = false

    const distanceChange = mousePos.distanceTo(this.lastMouseDownPos)
    if (this.selectedObjects.length && distanceChange > 1) {
      const selectedObjects = this.shiftModifier ? getSelectedObjects() : []
      const allSelectedObjects = [...selectedObjects, ...this.selectedObjects]
      store.dispatch(selectObjects({ objects: allSelectedObjects }))
    }
  }

  getWallCollisionPoints(obj) {
    const collisionPoints = []
    obj.segments.forEach(seg => {
      const worldStartPoint = obj.obj3d.position.clone().add(seg.startPoint)
      const worldEndPoint = obj.obj3d.position.clone().add(seg.endPoint)
      const distance = worldStartPoint.distanceTo(worldEndPoint)
      const count = distance / SPACING_THRESHOLD
      const points = Util.getEvenlySpacedPointsOnLine(
        worldStartPoint,
        worldEndPoint,
        count
      )

      collisionPoints.push(worldEndPoint)
      collisionPoints.push(...points)
    })

    return collisionPoints
  }

  getObstructionCollisionPoints(obj) {
    // Get rotated positions for the obstruction
    const positions = ObstructionUtil.getRotatedPositions(
      obj.positions,
      obj.position,
      obj.rotation && obj.rotation.z
    )
    const collisionPoints = [...positions]

    // Add collision points along object edges
    positions.forEach((pos, i) => {
      if (i < positions.length - 1) {
        const startPoint = pos
        const endPoint = positions[i + 1]
        const distance = startPoint.distanceTo(endPoint)
        const count = distance / SPACING_THRESHOLD
        const points = Util.getEvenlySpacedPointsOnLine(
          startPoint,
          endPoint,
          count
        )

        collisionPoints.push(...points)
      }
    })

    // Add the obstruction's position to be checked
    collisionPoints.push(obj.obj3d.position)

    return collisionPoints
  }

  getSnapRegions(facility, draggedObject) {
    const draggedObjectId = draggedObject ? draggedObject.id : ''

    if (draggedObject.className === CLASS_NAMES.DOOR) {
      const wallSnapRegions = SnapQueries.getAllWallCenterLines([
        draggedObjectId,
      ])
      return wallSnapRegions
    } else if (draggedObject.className === CLASS_NAMES.UTILITY_BOX) {
      const wallSnapRegions = SnapQueries.getAllWallInsetAndOutsetLines(
        [draggedObjectId],
        true
      )

      let columnSnapRegions = []
      if (draggedObject.canMountOnColumn) {
        columnSnapRegions = SnapQueries.getAllColumnOutsetLines(
          [draggedObjectId],
          true
        )
      }

      return wallSnapRegions.concat(columnSnapRegions)
    } else {
      const wallSnapRegions = SnapQueries.getAllWallOutsetLines([
        draggedObjectId,
      ])
      const obstructionSnapRegions = SnapQueries.getAllObstructionOutsetLines([
        draggedObjectId,
      ])
      return wallSnapRegions.concat(obstructionSnapRegions)
    }
  }

  getArrowDescriptions() {}

  getOrthoReferencePoint() {
    const allSelectedObjects = getSelectedFacilityObjects(Facility.current)
    const selectedObject =
      allSelectedObjects.length > 0 ? allSelectedObjects[0] : null

    if (selectedObject) {
      return selectedObject.obj3d.position
    } else {
      return null
    }
  }
}

export default MultiSelectTouchTool
