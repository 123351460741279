import { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react'

export const ErrorIndicatorsContext = createContext<{
  showErrorIndicators: boolean
  setShowErrorIndicators: Dispatch<SetStateAction<boolean>>
} | null>(null)

export const ErrorIndicatorsProvider = ({ children }: PropsWithChildren) => {
  const [showErrorIndicators, setShowErrorIndicators] = useState(true)
  return (
    <ErrorIndicatorsContext.Provider value={{ showErrorIndicators, setShowErrorIndicators }}>
      {children}
    </ErrorIndicatorsContext.Provider>
  )
}
