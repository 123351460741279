import { useSuspenseQuery } from '@apollo/client'
import { graphql } from '~/gql'

export const useFanVariationData = ({
  variationId,
  voltageId,
  mountingOptionId,
}: {
  variationId: string
  voltageId: string
  mountingOptionId?: string
}) => {
  const { data } = useSuspenseQuery(
    graphql(`
      query FanDimensionsQuery($variationId: ID!) {
        ProductVariation(id: $variationId) {
          id
          size
          product {
            id
            model
          }
          voltages {
            id
            mountingOptions {
              id
              tubeLength
              fullHeight
            }
          }
        }
      }
    `),
    { variables: { variationId } }
  )
  const voltage = data.ProductVariation.voltages.find(({ id }) => id === voltageId)
  const mountingOption = voltage?.mountingOptions?.find(({ id }) => id === mountingOptionId)
  const fullHeight = mountingOption?.fullHeight ?? 0
  const tubeLength = mountingOption?.tubeLength ?? 0

  return {
    fullHeight,
    tubeLength,
    size: data.ProductVariation.size,
    model: data.ProductVariation.product.model,
  }
}
