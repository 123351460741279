import { useRef } from 'react'
import { Box3, MathUtils, Matrix4, Vector3, Vector3Like } from 'three'
import { Line2 } from 'three/examples/jsm/lines/Line2.js'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { ClearanceMesh } from '~/components/DrawingCanvas/Products/components/ClearanceMesh'
import { DirectionArrow } from '~/components/DrawingCanvas/Products/components/DirectionArrow'
import { ErrorIndicators } from '~/components/DrawingCanvas/Products/components/ErrorIndicators'
import { ProductDragControls } from '~/components/DrawingCanvas/Products/components/ProductDragControls'
import { SnapLine } from '~/components/DrawingCanvas/Products/components/SnapLine'
import { useCardinalSnaplines } from '~/components/DrawingCanvas/Products/hooks/useCardinalSnaplines'
import { useHeaterDimenionErrors } from '~/components/DrawingCanvas/Products/hooks/useDimensionErrors'
import { useErrorIndicatorsContext } from '~/components/DrawingCanvas/Products/hooks/useErrorIndicatorsContext'
import { useHeaterVariationData } from '~/components/DrawingCanvas/Products/hooks/useHeaterVariationData'
import { useProductMesh } from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import { useSelectProduct } from '~/components/DrawingCanvas/Products/hooks/useSelectProduct'
import { useTransformRadiantHeaterNode } from '~/components/DrawingCanvas/Products/hooks/useTransformNode'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
} from '~/components/DrawingCanvas/util/units'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProduct } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'

export const RadiantHeater = (product: Product) => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const isVisible = useAppSelector(state => state.layers.layers.PRODUCTS_HEATERS.visible)
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const dispatch = useAppDispatch()
  const cursorHandlers = useCursor()

  const { id, rotation, position, ignoreErrors, variationId } = product
  const isSelected = Boolean(selectedObjects.find(object => object.id === id))

  const selectProduct = useSelectProduct(id)
  const snapToCardinals = useCardinalSnaplines(position)
  const origin = new Vector3()
  const snapLine = useRef<Line2>(null!)

  const { heaterData, size, model } = useHeaterVariationData({ variationId, isUnitHeater: false })
  const transformNode = useTransformRadiantHeaterNode({ size })
  const productMesh = useProductMesh(model, transformNode)
  const boundingBox = new Box3().setFromObject(productMesh)
  const { min, max } = boundingBox
  const widthOffset = -Math.max(Math.abs(max.x - min.x), Math.abs(max.y - min.y)) / 2

  const {
    minTubeLength,
    burnerBoxWidth,
    burnerBoxClearanceWidth,
    irhClearanceB,
    tubeDiameter,
    irhClearanceD,
    irhClearanceC,
  } = heaterData?.find(
    data => data.angle === Math.abs(rotation.x) || (data.angle === 90 && rotation.x === 0)
  )!
  const width = minTubeLength! + burnerBoxWidth! + burnerBoxClearanceWidth!
  const depth = irhClearanceC!
  const dimensions = {
    width: modelToUI(width),
    height: modelToUI(irhClearanceB! + tubeDiameter! + irhClearanceD!),
    depth: modelToUI(depth),
  }
  const isPositiveTilt = rotation.x >= 0
  const DIRECTION_ARROW_OFFSET = (dimensions.depth / 2 + 1) * (isPositiveTilt ? -1 : 1)
  const DIRECTION_ARROW_ROTATION = isPositiveTilt ? 0 : Math.PI

  const { y } = rotation
  const checkDimensions = useHeaterDimenionErrors(variationId, { width, depth, rotation: y })
  const { errors } = checkDimensions({ origin: position, productId: id })
  const { setShowErrorIndicators, showErrorIndicators } = useErrorIndicatorsContext()
  const isErrorsVisible = errors.length > 0 && showErrorIndicators && !ignoreErrors

  const handleDrag = (local: Matrix4, isMultipleSelected = false) => {
    setShowErrorIndicators(false)
    origin.setFromMatrixPosition(local)
    scaleUIVectorToModel(origin)
    const cardinalSnaps = snapToCardinals(origin)
    if (cardinalSnaps && !isMultipleSelected) {
      const { point, positions } = cardinalSnaps
      snapLine.current.geometry.setPositions(positions.map(p => modelToUI(p)))
      snapLine.current.visible = true
      origin.copy(point)
    }
    scaleModelVectorToUI(origin)
    local.setPosition(origin)
  }

  const handleUpdatePosition = (newPosition: Vector3Like) => {
    setShowErrorIndicators(true)
    const { errors, isValidPosition } = checkDimensions({ origin: newPosition, productId: id })
    if (errors.length) dispatch(setStatus({ text: errors[0].message, type: 'error' }))
    if (!isValidPosition) return false
    dispatch(
      updateProduct({
        product: {
          id,
          position: newPosition,
        },
      })
    )
    return true
  }

  return (
    <>
      <ErrorIndicators visible={isSelected && isErrorsVisible} errors={errors} origin={position} />
      <SnapLine ref={snapLine} />
      <ProductDragControls
        product={product}
        dragConfig={{ enabled: !isLocked && isSelected }}
        onDrag={handleDrag}
        onUpdatePosition={handleUpdatePosition}
      >
        <group
          visible={isVisible}
          {...cursorHandlers}
          onClick={selectProduct}
          rotation-y={MathUtils.degToRad(rotation.y)}
        >
          <ClearanceMesh isSelected={isSelected} {...dimensions} isError={isErrorsVisible} />
          <primitive
            object={productMesh}
            position-x={widthOffset}
            rotation-x={MathUtils.degToRad(rotation.x)}
          />
          {rotation.x !== 0 && (
            <DirectionArrow
              position-z={DIRECTION_ARROW_OFFSET}
              rotation-z={DIRECTION_ARROW_ROTATION}
              visible={isSelected}
              size={3}
            />
          )}
        </group>
      </ProductDragControls>
    </>
  )
}
