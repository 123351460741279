import { useRef } from 'react'
import { MathUtils, Matrix4, Vector3, Vector3Like } from 'three'
import { Line2 } from 'three/addons/lines/Line2.js'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { ClearanceMesh } from '~/components/DrawingCanvas/Products/components/ClearanceMesh'
import { ErrorIndicators } from '~/components/DrawingCanvas/Products/components/ErrorIndicators'
import { ProductDragControls } from '~/components/DrawingCanvas/Products/components/ProductDragControls'
import { SnapLine } from '~/components/DrawingCanvas/Products/components/SnapLine'
import { OVERHEAD_SNAP_THRESHOLD } from '~/components/DrawingCanvas/Products/constants'
import { useCardinalSnaplines } from '~/components/DrawingCanvas/Products/hooks/useCardinalSnaplines'
import { useDimensionErrors } from '~/components/DrawingCanvas/Products/hooks/useDimensionErrors'
import { useErrorIndicatorsContext } from '~/components/DrawingCanvas/Products/hooks/useErrorIndicatorsContext'
import { useFanVariationData } from '~/components/DrawingCanvas/Products/hooks/useFanVariationData'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { useProductMesh } from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import { useSelectProduct } from '~/components/DrawingCanvas/Products/hooks/useSelectProduct'
import { useSnapLines } from '~/components/DrawingCanvas/Products/hooks/useSnapLines'
import {
  animateOverheadFanNode,
  useTransformOverheadFanNode,
} from '~/components/DrawingCanvas/Products/hooks/useTransformNode'
import {
  OVERHEAD_3D_MODELS,
  OVERHEAD_PIVOT_2_0,
} from '~/components/DrawingCanvas/Products/modelNames'
import { snapToLine } from '~/components/DrawingCanvas/util/snaplines'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
} from '~/components/DrawingCanvas/util/units'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProduct } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'

export const OverheadFan = (product: Product) => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const isVisible = useAppSelector(state => state.layers.layers.PRODUCTS_OVERHEAD.visible)
  const isSnapEnabled = useAppSelector(state => state.tools.isSnapEnabled)
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const dispatch = useAppDispatch()
  const cursorHandlers = useCursor()

  const { position, id, ignoreErrors } = product
  const isSelected = Boolean(selectedObjects.find(object => object.id === id))

  const { fullHeight, size, tubeLength, model } = useFanVariationData(product)
  const isPivot2 = model === 'Pivot 2.0'
  const modelName = isPivot2 ? OVERHEAD_PIVOT_2_0 : model
  const rotationXOffset = isPivot2 ? MathUtils.degToRad(product.rotation.x) : undefined
  const rotationYOffset = isPivot2 ? MathUtils.degToRad(product.rotation.z) : undefined
  const transformNode = useTransformOverheadFanNode(tubeLength, size, rotationXOffset)
  const productMesh = useProductMesh(modelName, transformNode, animateOverheadFanNode)
  const tiltGroup = isPivot2 ? productMesh.getObjectByName('tilt_group-default') : undefined
  const positionYOffset = tiltGroup?.position.y ?? 0

  const getFanHeightInches = useGetFanHeightInches()
  const selectProduct = useSelectProduct(id)
  const snapLines = useSnapLines({ filter: p => p.id !== id && OVERHEAD_3D_MODELS.has(model) })
  const snapToCardinals = useCardinalSnaplines(position)
  const snapLine = useRef<Line2>(null!)
  const origin = new Vector3()

  const checkDimensions = useDimensionErrors(product.variationId)
  const { errors } = checkDimensions({ origin: position, productId: id })
  const { setShowErrorIndicators, showErrorIndicators } = useErrorIndicatorsContext()
  const isErrorsVisible = errors.length > 0 && showErrorIndicators && !ignoreErrors

  const handleDrag = (local: Matrix4, isMultipleSelected = false) => {
    setShowErrorIndicators(false)
    origin.setFromMatrixPosition(local)
    scaleUIVectorToModel(origin)
    const cardinalSnaps = snapToCardinals(origin)
    if (cardinalSnaps && !isMultipleSelected) {
      const { point, positions } = cardinalSnaps
      snapLine.current.geometry.setPositions(positions.map(p => modelToUI(p)))
      snapLine.current.visible = true
      origin.copy(point)
    } else if (isSnapEnabled && !isMultipleSelected) {
      const snapData = snapToLine(snapLines, origin)
      if (snapData && snapData.distance < OVERHEAD_SNAP_THRESHOLD) {
        const { objectLine, snapPoint } = snapData
        const { start, end } = objectLine
        const positions = [...start.toArray(), ...end.toArray()].map(p => modelToUI(p))
        snapLine.current.geometry.setPositions(positions)
        snapLine.current.visible = true
        origin.copy(snapPoint)
      } else {
        snapLine.current.visible = false
      }
    }
    const newHeight = getFanHeightInches({ origin, fullHeight })
    origin.setZ(newHeight)
    scaleModelVectorToUI(origin)
    local.setPosition(origin)
  }

  const handleUpdatePosition = (newPosition: Vector3Like) => {
    setShowErrorIndicators(true)
    const { errors, isValidPosition } = checkDimensions({ origin: newPosition, productId: id })
    if (errors.length) dispatch(setStatus({ text: errors[0].message, type: 'error' }))
    if (!isValidPosition) return false
    dispatch(
      updateProduct({
        product: {
          id,
          position: newPosition,
        },
      })
    )
    return true
  }

  return (
    <>
      <ErrorIndicators visible={isSelected && isErrorsVisible} errors={errors} origin={position} />
      <SnapLine ref={snapLine} />
      <ProductDragControls
        product={product}
        dragConfig={{ enabled: !isLocked && isSelected }}
        onDrag={handleDrag}
        onUpdatePosition={handleUpdatePosition}
      >
        <group visible={isVisible} onClick={selectProduct} {...cursorHandlers} rotation-y={rotationYOffset}>
          <ClearanceMesh
            isSelected={isSelected}
            radius={modelToUI(size / 2)}
            height={modelToUI(fullHeight - tubeLength)}
            isError={isErrorsVisible}
            rotation-x={rotationXOffset}
            position-y={positionYOffset}
          />
          <primitive object={productMesh} />
        </group>
      </ProductDragControls>
    </>
  )
}
