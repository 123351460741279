import { ThreeEvent } from '@react-three/fiber'
import { useErrorIndicatorsContext } from '~/components/DrawingCanvas/Products/hooks/useErrorIndicatorsContext'
import LayerKeys from '~/config/layerKeys'
import ObjectClassName from '~/config/objectClassNames'
import { useAppDispatch, useAppSelector, useAppStore } from '~/store/hooks'
import { selectObjects } from '~/store/selectedObjects'

export const useSelectProduct = (id: string) => {
  const dispatch = useAppDispatch()
  const { setShowErrorIndicators } = useErrorIndicatorsContext()
  const product = useAppSelector(state => state.objects.present.products[id])
  const store = useAppStore()
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const isSelected = Boolean(selectedObjects.find(object => object.id === id))

  return (e: ThreeEvent<MouseEvent>) => {
    if (!product) return
    const isVisible = store.getState().layers.layers[product.layerKey]?.visible
    const isLocked = store.getState().layers.layers[LayerKeys.PRODUCTS]?.locked
    const isSelectTool = store.getState().tools.activeTool === 'SELECT_TOOL'
    if (!isVisible || !isSelectTool || isLocked) return
    e.stopPropagation()
    setShowErrorIndicators(true)
    if (e.shiftKey) {
      const newSelectedObjects = isSelected
        ? selectedObjects.filter(object => object.id !== id)
        : [...selectedObjects, { id: product.id, className: ObjectClassName.PRODUCT }]
      dispatch(selectObjects({ objects: newSelectedObjects }))
    } else {
      dispatch(selectObjects({ objects: [{ id: product.id, className: ObjectClassName.PRODUCT }] }))
    }
  }
}
