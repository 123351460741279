import OBJECT_TYPES from 'config/objectTypes'

import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import uniqWith from 'lodash-es/uniqWith'
import isEqual from 'lodash-es/isEqual'
import Util from './util'
import Facility from './facility'
import * as THREE from 'three'

class RoofToolUtil {
  /*
    Finds the nearest roof to the given point. If the point is actually
    within the roof polygon or on its perimeter, the roof is returned.
    Otherwise null is returned.
  */
  static getRoofUnderCursor(cursorPoint) {
    const measurements = Facility.current.measureObjectsInRangeOfPoint(
      cursorPoint,
      null, // No range limit
      { sort: true, includedTypes: [OBJECT_TYPES.ROOF] }
    )
    const roof = measurements[0] ? measurements[0].wrapper : null

    if (roof) {
      const pointWithinRoof = Util.isPointInPolygon(
        cursorPoint,
        roof.perimeterPoints
      )
      const pointOnRoofBorder = Util.isPointOnPolygonPerimeter(
        cursorPoint,
        roof.perimeterPoints
      )

      if (pointWithinRoof || pointOnRoofBorder) {
        return roof
      } else {
        return null
      }
    }

    return null
  }

  static getRoofSectionUnderCursor(cursorPoint) {
    const measurements = Facility.current.measureObjectsInRangeOfPoint(
      cursorPoint,
      null, // No range limit
      { sort: true, includedTypes: [OBJECT_TYPES.SUB_MOUNTING_STRUCTURE] }
    )
    const roofSection = measurements[0] ? measurements[0].wrapper : null

    if (roofSection) {
      const pointWithinRoof = Util.isPointInPolygon(
        cursorPoint,
        roofSection.perimeterPoints
      )
      const pointOnRoofBorder = Util.isPointOnPolygonPerimeter(
        cursorPoint,
        roofSection.perimeterPoints
      )

      if (pointWithinRoof || pointOnRoofBorder) {
        return roofSection
      } else {
        return null
      }
    }

    return null
  }

  static getGableRoofModel(
    roofId,
    isHorizontal,
    height = 72,
    wallId,
    type,
    offset
  ) {
    const roof = Facility.current.roofs.find(roof => roof.id === roofId)
    const startPos = new THREE.Vector3()
    const endPos = new THREE.Vector3()
    const elevationPoints = []

    if (!roof) return

    // Get roof perimeter points
    const points = roof.perimeterPoints.map(point => {
      return {
        x: point[0],
        y: point[1],
        z: height,
      }
    })

    // Get the two elevation points for the gable roof based on type
    const perimeterPoints = get(roof, 'perimeterPoints', [])
    if (type === 'centered') {
      const gablePoints = []
      perimeterPoints.forEach((points, index) => {
        if (perimeterPoints.length === index + 1) return
        const pos = new THREE.Vector3(points[0], points[1], height)
        const nextPos = new THREE.Vector3(
          perimeterPoints[index + 1][0],
          perimeterPoints[index + 1][1],
          height
        )

        const distance = pos.distanceTo(nextPos) / 2
        gablePoints.push(
          Util.getPositionBetweenTwoVectors(pos, nextPos, distance)
        )
      })
      if (isHorizontal) {
        const points = sortBy(gablePoints, 'x')
        startPos.copy(points[0])
        endPos.copy(points[3])
      } else {
        const points = sortBy(gablePoints, 'y')
        startPos.copy(points[3])
        endPos.copy(points[0])
      }
    } else {
      const startOffset = new THREE.Vector3()
      const endOffset = new THREE.Vector3()
      if (type === 'left') {
        const sortedPoints = sortBy(uniqWith(points, isEqual), 'x')
        if (sortedPoints[0].y > sortedPoints[1].y) {
          startPos.copy(sortedPoints[0])
          endPos.copy(sortedPoints[1])
        } else {
          startPos.copy(sortedPoints[1])
          endPos.copy(sortedPoints[0])
        }
        startOffset.set(offset, 0, 0)
        endOffset.set(offset, 0, 0)
      } else if (type === 'right') {
        const sortedPoints = sortBy(uniqWith(points, isEqual), 'x')
        if (sortedPoints[2].y > sortedPoints[3].y) {
          startPos.copy(sortedPoints[3])
          endPos.copy(sortedPoints[2])
        } else {
          startPos.copy(sortedPoints[2])
          endPos.copy(sortedPoints[3])
        }
        startOffset.set(-offset, 0, 0)
        endOffset.set(-offset, 0, 0)
      } else if (type === 'top') {
        const sortedPoints = sortBy(uniqWith(points, isEqual), 'y')
        if (sortedPoints[2].x < sortedPoints[3].x) {
          startPos.copy(sortedPoints[3])
          endPos.copy(sortedPoints[2])
        } else {
          startPos.copy(sortedPoints[2])
          endPos.copy(sortedPoints[3])
        }
        startOffset.set(0, -offset, 0)
        endOffset.set(0, -offset, 0)
      } else if (type === 'bottom') {
        const sortedPoints = sortBy(uniqWith(points, isEqual), 'y')
        if (sortedPoints[0].x < sortedPoints[1].x) {
          startPos.copy(sortedPoints[0])
          endPos.copy(sortedPoints[1])
        } else {
          startPos.copy(sortedPoints[1])
          endPos.copy(sortedPoints[0])
        }
        startOffset.set(0, offset, 0)
        endOffset.set(0, offset, 0)
      }

      startPos.add(startOffset)
      endPos.add(endOffset)
    }

    elevationPoints.push(startPos)
    elevationPoints.push(endPos)

    const gableRoofModel = {
      ...roof,
      roofId,
      height,
      elevationPoints,
      wallId,
    }

    return gableRoofModel
  }

  static getMonoslopeRoofModel(roofId, side, height = 72, wallId) {
    const roof = Facility.current.roofs.find(roof => roof.id === roofId)

    if (!roof) return

    // Get roof perimeter points
    const points = roof.perimeterPoints.map(point => {
      return {
        x: point[0],
        y: point[1],
        z: height,
      }
    })

    // Get the two elevation points for the monoslope roof based on direction
    const elevationPoints = []
    let point1
    let point2
    if (side === 'right') {
      const sortedPoints = sortBy(uniqWith(points, isEqual), 'x')
      if (sortedPoints[2].y > sortedPoints[3].y) {
        point1 = sortedPoints[2]
        point2 = sortedPoints[3]
      } else {
        point1 = sortedPoints[3]
        point2 = sortedPoints[2]
      }
    } else if (side === 'left') {
      const sortedPoints = sortBy(uniqWith(points, isEqual), 'x')
      if (sortedPoints[0].y > sortedPoints[1].y) {
        point1 = sortedPoints[0]
        point2 = sortedPoints[1]
      } else {
        point1 = sortedPoints[1]
        point2 = sortedPoints[0]
      }
    } else if (side === 'top') {
      const sortedPoints = sortBy(uniqWith(points, isEqual), 'y')
      if (sortedPoints[2].x < sortedPoints[3].x) {
        point1 = sortedPoints[2]
        point2 = sortedPoints[3]
      } else {
        point1 = sortedPoints[3]
        point2 = sortedPoints[2]
      }
    } else if (side === 'bottom') {
      const sortedPoints = sortBy(uniqWith(points, isEqual), 'y')
      if (sortedPoints[0].x < sortedPoints[1].x) {
        point1 = sortedPoints[0]
        point2 = sortedPoints[1]
      } else {
        point1 = sortedPoints[1]
        point2 = sortedPoints[0]
      }
    }

    elevationPoints.push(point1)
    elevationPoints.push(point2)

    const monoslopeRoofModel = {
      ...roof,
      roofId,
      height,
      elevationPoints,
      wallId,
    }

    return monoslopeRoofModel
  }
}

export default RoofToolUtil
