import { forwardRef, useState, useImperativeHandle, useMemo } from "react";
import { Vector3 } from "three";
import { guid } from "~/lib/utils";
import { useAppDispatch, useAppSelector, useAppStore } from "~/store/hooks";
import { addDoor } from "~/store/objects";
import { Door } from "~/store/objects/types";
import { setActiveTool } from "~/store/tools";
import { useProject, useWallSegmentSnapLines } from "../hooks";
import type { ToolManagerRef } from "../ToolManager";
import { snapToLine } from "../util/snaplines";
import { uiToModel } from "../util/units";
import { SnapPreview } from "./SnapPreview";
import { parallelVectorOfWallSegment } from "../util/walls";

export const DoorTool = forwardRef<ToolManagerRef, { activeToolProps: Door; }>(function DoorTool(props, ref) {
  const project = useProject();
  const [[x, y], setPoint] = useState([0, 0]);
  const snaplines = useWallSegmentSnapLines();
  const snapPoint = useMemo(() => snapToLine(snaplines, new Vector3(x, y, 0)), [x, y, snaplines]);
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const continuousMode = useAppSelector(it => it.tools.isContinuousModeEnabled);
  useImperativeHandle(ref, () => ({
    onMouseMove(event) {
      const point = project(event);
      if (point) {
        setPoint([point.x, point.y]);
      }
      return true;
    },
    onMouseDown() {
      return true;
    },
    onMouseUp() {
      if (snapPoint === undefined) {
        return true;
      }
      const wallSegment = store.getState().objects.present.segments[snapPoint.objectID]
      const rotation = parallelVectorOfWallSegment(wallSegment)
      dispatch(addDoor({
        door: {
          ...props.activeToolProps,
          id: guid(),
          position: {
            x: uiToModel(snapPoint.snapPoint.x),
            y: uiToModel(snapPoint.snapPoint.y),
            z: 0,
          },
          wallSegmentId: snapPoint.objectID,
          rotation,
          layerKey: "DOORS",
        },
      }));
      if (!continuousMode) {
        dispatch(setActiveTool({
          tool: 'SELECT_TOOL',
          props: {},
        }));
      }
      return true;
    },
  }), [project, snapPoint, continuousMode]);

  return (
    <>
      {snapPoint && <SnapPreview door={props.activeToolProps} snapPoint={snapPoint} />}
    </>
  );
});
