import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import findKey from 'lodash-es/findKey'
import includes from 'lodash-es/includes'
import Util from './util'
import Units from './units'
import Facility from './facility'
import FloatingElementManager from './floatingElementManager'
import store from '~/store'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { updateObject, updateObjects } from 'store/objects'
import {
  objectIsSelected,
  getSelectedObjects,
} from 'store/selectedObjects/selectors'
import CLASS_NAMES from 'config/objectClassNames'
import CLICK_PRIORITY from 'config/clickPriority'

import * as THREE from 'three'
import { vectorUIToModel } from '../util/units'
import { handles } from '../CompatibilityObject3DHandles'

class WallSegment {
  constructor(model, units) {
    if (!model.parentId) {
      const state = store.getState()
      const parents = get(state, 'objects.present.objects')
      const parentKey = findKey(parents, p => includes(p.segments, model.id))
      const parent = parents[parentKey]
      this.parentId = get(parent, 'id')
    } else {
      this.parentId = model.parentId
    }

    this.className = CLASS_NAMES.WALL_SEGMENT
    this.id = model.id
    this.units = units
    this.thickness = Units.unitsToNative(units, model.thickness)
    this.height = Units.unitsToNative(units, model.height)
    this.isFullHeight = model.isFullHeight || false
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.materialIndex = model.materialIndex
    this.clickPriority = CLICK_PRIORITY[this.layerKey]

    if (model.materialIndex === undefined) {
      this.materialIndex = 0
    }

    if (!this.id) {
      this.id = Util.guid()
    }

    this.obj3d = new THREE.Object3D()

    // Used by Interactifier
    this.selectable = true
    this.draggable = false
    this.multiDraggable = true

    if (objectIsSelected(this.id)) {
      this.select()
    } else {
      this.deselect()
    }
  }

  getParent() {
    return this.wall || Facility.current.getWallWithId(this.parentId)
  }

  select(draggable) {
    const isLocked = store.getState().layers.layers[this.layerKey].locked
    this.draggable = !isLocked
    if (draggable === false) {
      this.draggable = false
    }

    if (this.vertexGroup) this.vertexGroup.materialIndex = 11

    if (this.previousSegment && this.nextSegment) {
      this.showTextInput()
    }

    if (this.previousSegment) {
      this.previousSegment.showTextInput()
    }
    if (this.nextSegment) {
      this.nextSegment.showTextInput()
    }
  }

  deselect() {
    this.draggable = false
    this.hideLengthLabel()
    if (this.vertexGroup) this.vertexGroup.materialIndex = this.materialIndex
    if (this.previousSegment) {
      this.previousSegment.hideTextInput()
    }
    if (this.nextSegment) {
      this.nextSegment.hideTextInput()
    }
  }

  updateCenterLinePoints(centerLinePoints) {
    this.centerLinePoints = centerLinePoints

    this.startPoint = new THREE.Vector3(
      this.centerLinePoints[0][0],
      this.centerLinePoints[0][1],
      0
    )
    this.endPoint = new THREE.Vector3(
      this.centerLinePoints[1][0],
      this.centerLinePoints[1][1],
      0
    )
    const edgeVec = this.endPoint.clone().sub(this.startPoint)
    const edgeCenter = this.startPoint
      .clone()
      .add(edgeVec.clone().multiplyScalar(0.5))
    this.obj3d.position.copy(edgeCenter)
  }

  updateOffsetPoints(insetPoints, outsetPoints) {
    this.insetPoints = insetPoints
    this.outsetPoints = outsetPoints

    // Since the inset and outset points are ordered in the same way, and we want
    // offsetPoints to form a polygon, we reverse the order of the outset points
    // before joining them to the inset points.
    this.offsetPoints = this.insetPoints.concat(
      this.outsetPoints.slice().reverse()
    )
  }

  isVertical() {
    return Math.round(this.startPoint.x) === Math.round(this.endPoint.x)
  }

  isHorizontal() {
    return Math.round(this.startPoint.y) === Math.round(this.endPoint.y)
  }

  getUpdatedObjects() {
    // Get all attached objects
    const attachedDoors = this.getAttachedDoors()
    const attachedBoxes = this.getAttachedUtilityBoxes()

    // Build models from attached objects
    const doors = attachedDoors.map(door => {
      const offset = vectorUIToModel(handles.get(door.id).position)
      handles.get(door.id).position.set(0, 0, 0)
      const ret = {
        ...door,
        position: offset.add(door.position)
      }
      return ret
    })
    const boxes = attachedBoxes.map(box => {
      return { ...box.toModel() }
    })

    return [...doors, ...boxes]
  }

  updateVertexGroup(vertexGroup) {
    this.vertexGroup = vertexGroup

    getSelectedObjects().some(({ id: selectedObjectId }) => {
      if (selectedObjectId === this.id) {
        this.select()
        return true
      }
      return false
    })
  }

  static createModel(
    parentId,
    layerKey,
    startPoint,
    endPoint,
    height,
    thickness,
    materialIndex
  ) {
    return {
      id: Util.guid(),
      parentId,
      layerKey,
      thickness,
      height,
      startPoint,
      endPoint,
      materialIndex,
    }
  }

  toModel() {
    // Shift into global coords
    const start = this.startPoint.clone().add(this.getParent().obj3d.position)
    const end = this.endPoint.clone().add(this.getParent().obj3d.position)
    const insetPoints = Util.toVec3Array(this.insetPoints).map(p =>
      p.clone().add(this.getParent().obj3d.position)
    )
    const outsetPoints = Util.toVec3Array(this.outsetPoints).map(p =>
      p.clone().add(this.getParent().obj3d.position)
    )
    return {
      id: this.id,
      parentId: this.getParent().id,
      startPoint: Units.nativeToUnitsV(this.getParent().units, start),
      endPoint: Units.nativeToUnitsV(this.getParent().units, end),
      thickness: Units.nativeToUnits(this.getParent().units, this.thickness),
      height: Units.nativeToUnits(this.getParent().units, this.height),
      isFullHeight: this.isFullHeight,
      materialIndex: this.materialIndex,
      layerKey: this.layerKey,
      insetPoints: {
        start: Units.nativeToUnitsV(this.getParent().units, insetPoints[0]),
        end: Units.nativeToUnitsV(this.getParent().units, insetPoints[1]),
      },
      outsetPoints: {
        start: Units.nativeToUnitsV(this.getParent().units, outsetPoints[0]),
        end: Units.nativeToUnitsV(this.getParent().units, outsetPoints[1]),
      },
    }
  }

  showTextInput() {
    if (this.textInput !== undefined) {
      this.hideTextInput()
    }

    this.textInputAnchor = new THREE.Object3D()
    this.textInputAnchor.position.copy(this.getOffsetCenterPoint(1, true))
    this.obj3d.add(this.textInputAnchor)

    const keyDownHandler = e => {
      if (e.which === 13) {
        // Enter was pressed
        this.extendParentWallAccordingToTextInput()
      }
    }

    this.textInput = FloatingElementManager.showFloatingElement(
      this.id,
      this.textInputAnchor,
      { keypressHandler: keyDownHandler }
    )

    this.textInput.addEventListener('input', () =>
      Units.validateTextInput(this.textInput)
    )

    this.updateTextInput()
  }

  updateTextInput() {
    if (this.textInput !== undefined) {
      this.textInput.value = this.getInteriorWallLengthString()
    }
  }

  hideTextInput() {
    if (this.textInput !== undefined) {
      this.obj3d.remove(this.textInputAnchor)
      this.textInput = undefined
      FloatingElementManager.hideFloatingElement(this.id)
    }
  }

  showLengthLabel() {
    if (!this.endPoint || !this.startPoint) return

    const labelMargin = 0.4
    const edgeVec = this.endPoint.clone().sub(this.startPoint)
    const orthoVec = edgeVec
      .clone()
      .normalize()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)

    const labelPos = orthoVec
      .clone()
      .multiplyScalar(this.thickness + labelMargin)

    this.textAnchor = new THREE.Object3D()
    this.textAnchor.position.copy(labelPos)
    this.obj3d.add(this.textAnchor)
    this.textLabel = FloatingElementManager.showFloatingElement(
      this.id,
      this.textAnchor,
      undefined,
      'label'
    )

    const lengthString = this.getInteriorWallLengthString()
    this.textLabel.innerHTML = lengthString
  }

  hideLengthLabel() {
    if (this.textLabel !== undefined) {
      this.obj3d.remove(this.textLabel)
      this.textLabel = undefined
    }
    FloatingElementManager.hideFloatingElement(this.id)
  }

  // Use inset points to get accurate measurement of walls
  getInteriorWallLengthString() {
    const insetPoints = this.insetPoints.map(Util.arrayPointToObjectPoint)
    const distance = insetPoints[0].distanceTo(insetPoints[1])
    return Units.toDistanceString(distance)
  }

  getOffsetCenterPoint(distance, inside) {
    return Util.getPointOffsetFromLineCenter(
      this.startPoint,
      this.endPoint,
      distance,
      inside
    )
  }

  getCenterPoint() {
    const globalCenterLinePoints = this.getParent().toGlobalPoints(
      this.centerLinePoints
    )

    return Util.getLineSegmentCenter(
      globalCenterLinePoints[0],
      globalCenterLinePoints[1]
    )
  }

  getOutsetPoints() {
    const parent = this.getParent()
    if (!parent) return []
    if (parent && parent.isPolygon()) {
      return this.getParent().toGlobalPoints(this.outsetPoints)
    }
    return parent.toGlobalPoints(this.offsetPoints)
  }

  getInsetPoints() {
    if (this.getParent().isPolygon()) {
      return this.getParent().toGlobalPoints(this.insetPoints)
    }
    return []
  }

  getCenterLinePoints() {
    return this.getParent().toGlobalPoints(this.centerLinePoints)
  }

  getAttachedDoors() {
    return Object.values(store.getState().objects.present.doors)
      .filter(door => door.wallSegmentId === this.id)
  }

  getAttachedUtilityBoxes() {
    return Facility.current
      .getUtilityBoxes()
      .filter(box => box.wallSegmentId === this.id)
  }

  // ///
  // Interactable methods
  // ///

  drop(_, __, saveModel = true) {
    const updatedObjects = this.getUpdatedObjects()
    const allUpdatedObjects = [...updatedObjects]
    this.dragDeltaBeforeDrop = null

    if (saveModel) {
      updatedObjects.push({ object: this.getParent().toModel() })
    }

    if (updatedObjects.length) store.dispatch(updateObjects(allUpdatedObjects))
  }

  drag({ dragDelta, objectWithCursor }) {
    if (!this.dragDeltaBeforeDrop) {
      this.dragDeltaBeforeDrop = new THREE.Vector3()
    }
    this.dragDeltaBeforeDrop.add(dragDelta)

    const selectedObjects = getSelectedObjects()
    const selectedIds = selectedObjects.map(obj => obj.id)
    const parent = this.getParent()
    const wallSegmentIds = parent.segments.map(seg => seg.id)

    // If parent is also selected, ignore wall segment drag
    if (selectedIds.includes(this.parentId)) return

    // If parent is not selected but all its segments are, drag parent if this
    // is the first segment to prevent repeat drags per segment
    let allSegmentsSelected = true
    wallSegmentIds.forEach(id => {
      if (!selectedIds.includes(id)) allSegmentsSelected = false
    })
    const isFirstSegment = get(parent, 'segments[0].id') === this.id
    if (allSegmentsSelected && wallSegmentIds.length > 1) {
      if (isFirstSegment) parent.obj3d.position.add(dragDelta)
      return
    }

    if (selectedObjects.length > 1) {
      this.extendParentWallWithDelta(dragDelta)
    } else {
      this.extendParentWall(dragDelta)
    }

    // Update any attached doors position
    this.getAttachedDoors().forEach(door => {
      handles.get(door.id)?.position.add(dragDelta)
    })

    // Update any attached utility boxes position
    this.getAttachedUtilityBoxes().forEach(box => {
      box.obj3d.position.add(dragDelta)
    })

  }

  snap(snapDelta) {
    this.obj3d.position.add(snapDelta)

    // Add snap delta to any attached doors
    this.getAttachedDoors().forEach(door => {
      handles.get(door.id)?.position.add(snapDelta)
    })

    // Add snap delta to any attached utility boxes
    this.getAttachedUtilityBoxes().forEach(box => {
      box.obj3d.position.add(snapDelta)
    })
  }

  getSnappableEdgePoints() {
    return this.outsetPoints
  }

  // ///
  // END Interactable methods
  // ///

  /*
    Extend this segment so that it's length matches the length entered in its
    floating text field. The length should be extended by moving the currently
    selected segment in the direction of its normal (i.e. perpendicular to
    the direction it runs in).
  */
  extendParentWallAccordingToTextInput() {
    if (this.textInput === undefined) return
    const inputDistance = Units.fromDistanceString(this.textInput.value)

    const insetPoints = Util.toVec3Array(this.insetPoints)
    const distance = new Distance({
      value: insetPoints[0].distanceTo(insetPoints[1]),
      system: SYSTEMS.NATIVE,
    })
    const isDistanceDifferent =
      distance.native({ round: true }) !== inputDistance.native({ round: true })

    // Do nothing if we're given an invalid string or the distance didn't change
    if (inputDistance === null || !isDistanceDifferent) return

    let start = insetPoints[0]
    let end = insetPoints[1]
    let length = inputDistance.native()

    // Use start and end points offset by wall thickness if not enclosed
    if (!this.getParent().isPolygon()) {
      start = this.startPoint
      end = this.endPoint
      length += this.thickness / 2
    }

    const selectedSibling = this.getSelectedSibling()
    const selectedEdgeNormal = selectedSibling.getNormal()
    const isAfterSelectedSibling = this === selectedSibling.nextSegment
    const prevSeg = this.previousSegment
    const nextSeg = this.nextSegment

    const originalEdgeVec = this.startPoint.clone().sub(this.endPoint)
    let edgeVec = end.clone().sub(start)
    let useInvertedScalar = false

    // Check if the new edge vector is inverted
    let didInvert = false
    if (this.isHorizontal()) {
      didInvert = Math.sign(originalEdgeVec.x) !== Math.sign(edgeVec.x)
    } else if (this.isVertical()) {
      didInvert = Math.sign(originalEdgeVec.y) !== Math.sign(edgeVec.y)
    }

    // A series of checks to help make sure the wall segments
    // do not invert their direction or distance values
    if (this.isHorizontal() && didInvert) {
      const wallIsLeftToRight = this.startPoint.x > this.endPoint.x
      const nextSegStartY = get(nextSeg, 'startPoint.y')
      const nextSegEndY = get(nextSeg, 'endPoint.y')
      const nextIsDownToUp = nextSegStartY < nextSegEndY
      const prevSegStartY = get(prevSeg, 'startPoint.y')
      const prevSegEndY = get(prevSeg, 'endPoint.y')
      const prevIsUptoDown = prevSegStartY > prevSegEndY
      if (wallIsLeftToRight) {
        if (nextIsDownToUp) {
          if (!prevIsUptoDown) {
            edgeVec = start.clone().sub(end)
            if (!isAfterSelectedSibling) useInvertedScalar = true
          } else {
            useInvertedScalar = true
            if (!isAfterSelectedSibling) edgeVec.multiplyScalar(-1)
          }
        } else {
          if (prevIsUptoDown) {
            if (isAfterSelectedSibling) useInvertedScalar = true
          } else {
            didInvert = false
          }
        }
      } else {
        if (nextIsDownToUp) {
          if (prevIsUptoDown) {
            if (isAfterSelectedSibling) edgeVec = start.clone().sub(end)
          } else {
            if (isAfterSelectedSibling) useInvertedScalar = true
          }
        } else {
          if (!prevIsUptoDown) {
            useInvertedScalar = true
            if (!isAfterSelectedSibling) edgeVec = start.clone().sub(end)
          } else {
            if (isAfterSelectedSibling) {
              didInvert = false
            } else {
              edgeVec = start.clone().sub(end)
              useInvertedScalar = true
            }
          }
        }
      }
    } else if (this.isVertical() && didInvert) {
      const wallIsUpToDown = this.startPoint.y > this.endPoint.y
      const nextSegStartX = get(nextSeg, 'startPoint.x')
      const nextSegEndX = get(nextSeg, 'endPoint.x')
      const nextIsLeftToRight = nextSegStartX < nextSegEndX
      const prevSegStartX = get(prevSeg, 'startPoint.x')
      const prevSegEndX = get(prevSeg, 'endPoint.x')
      const prevIsRightToLeft = prevSegStartX > prevSegEndX
      if (wallIsUpToDown) {
        if (nextIsLeftToRight) {
          if (prevIsRightToLeft) {
            if (isAfterSelectedSibling) edgeVec = start.clone().sub(end)
          } else {
            if (isAfterSelectedSibling) useInvertedScalar = true
          }
        } else {
          if (!prevIsRightToLeft) {
            useInvertedScalar = true
            if (!isAfterSelectedSibling) edgeVec = start.clone().sub(end)
          } else {
            if (isAfterSelectedSibling) {
              didInvert = false
            } else {
              edgeVec = start.clone().sub(end)
              useInvertedScalar = true
            }
          }
        }
      } else {
        if (nextIsLeftToRight) {
          if (!prevIsRightToLeft) {
            edgeVec = start.clone().sub(end)
            if (!isAfterSelectedSibling) useInvertedScalar = true
          } else {
            useInvertedScalar = true
            if (!isAfterSelectedSibling) edgeVec = start.clone().sub(end)
          }
        } else {
          if (!prevIsRightToLeft) {
            didInvert = false
          } else {
            if (isAfterSelectedSibling) useInvertedScalar = true
          }
        }
      }
    }

    // It simplifies things if we can assume the edge vectors are pointing the same way
    // for next/previous siblings.
    if (isAfterSelectedSibling && !didInvert) edgeVec.multiplyScalar(-1)

    // The following is based on a formula developed in-house which expresses the amount which
    // the selected edge should move in order to grow/shrink this segment to the correct size.
    // (We need to do it this way in order to preserve the behavior where the selected/dragged
    // segment only moves--it does not change size). The formula is:
    //    length(V) = newLength, where V = A + N * s = (A.x+N.x*s, A.y+N.y*s, A.z+N.z*s)
    // It's important that A + N * s is combined into V before the length is computed. 'A' is
    // the original edge (as a vector), N is a unit vector representing the normal of
    // the selected segment (the one we're moving), 'x' is a scalar saying how much we should
    // move the selected segment by.
    // If we expand the formula for vector length, we have:
    //    sqrt((A.x+N.x*s)^2 + (A.y+N.y*s)^2 + (A.z+N.z*s)^2) = newLength
    // I used Wolfram Alpha to solve for s in that equation (I solve for 'x' in Wolfram though):
    // (https://www.wolframalpha.com/input/?i=sqrt((a+%2B+b*x)%5E2+%2B+(c+%2B+d*x)%5E2+%2B+(e+%2B+f*x)%5E2)+%3D+g)
    // The following is largely a javascript version of that result:

    const a = edgeVec.clone()
    const n = selectedEdgeNormal.clone()

    const radicand =
      (2 * a.x * n.x + 2 * a.y * n.y + 2 * a.z * n.z) ** 2 -
      4 * (a.x ** 2 + a.y ** 2 + a.z ** 2 - length ** 2)

    // The result is invalid
    if (radicand < 0) return

    const x =
      (Math.sqrt(radicand) + (-2 * a.x * n.x - 2 * a.y * n.y - 2 * a.z * n.z)) /
      2

    const extensionVector = selectedEdgeNormal.clone()

    // Only scale the vector if x is valid
    if (x) {
      const scalar = useInvertedScalar ? -x : x
      extensionVector.multiplyScalar(scalar)
    }

    selectedSibling.extendParentWall(extensionVector)

    store.dispatch(updateObject({ object: this.getParent().toModel() }))
  }

  extendParentWallWithDelta(delta) {
    this.startPoint.add(delta)
    this.endPoint.add(delta)

    if (this.previousSegment) {
      this.previousSegment.endPoint.add(delta)
    }
    if (this.nextSegment) {
      this.nextSegment.startPoint.add(delta)
    }

    const parentModel = this.getParent().toModel()

    this.getParent().update(parentModel)
  }

  extendParentWall(extensionVector) {
    const edgeNormal = this.getNormal()
    const projectedDelta = extensionVector.projectOnVector(edgeNormal)

    this.extendParentWallWithDelta(projectedDelta)

    if (this.previousSegment) this.previousSegment.updateTextInput()
    if (this.nextSegment) this.nextSegment.updateTextInput()
  }

  getNormal() {
    const edgeVec = this.endPoint.clone().sub(this.startPoint)
    const edgeNormal = edgeVec
      .normalize()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 2)

    return edgeNormal
  }

  getSelectedSibling() {
    const segments = this.getParent().segments
    const selectedSegment = segments.find(seg => objectIsSelected(seg.id))

    const lastSegmentId = segments[segments.length - 1].id
    const thisIsLastSegment = this.id === lastSegmentId

    if (thisIsLastSegment) return this.nextSegment || this.previousSegment

    const thisIsSelected = selectedSegment && selectedSegment.id === this.id
    if ((!selectedSegment || thisIsSelected) && this.previousSegment) {
      return this.previousSegment
    }

    if (selectedSegment) return selectedSegment

    return this.nextSegment
  }

  destroy() {
    this.hideLengthLabel()
    this.hideTextInput()
  }
}

export default WallSegment
