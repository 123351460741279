import { useAppDispatch, useAppSelector } from "~/store/hooks"
import { DragControls, Line, Sphere } from "@react-three/drei"
import type { ElevationPoint } from "~/store/objects/types"
import { Group, Line3, Matrix4, Vector3, Vector3Like } from "three"
import theme from "~/config/theme"
import { selectObjects } from "~/store/selectedObjects"
import ObjectClassName from "~/config/objectClassNames"
import { startTransition, useEffect, useRef, useState } from "react"
import { updateElevationPoint } from "~/store/objects"
import { useWallSegmentSnapLines } from "../hooks"
import { snapToCardinals, snapToLine } from "../util/snaplines"
import { SnapLine } from "../util/snaplineVisuals"
import { memoizedRoofMeshes } from "../lib/productDistanceEngine"
import { computeRoofIntersection } from "./util"
import { modelToUI, uiToModel, vectorUIToModel } from "../util/units"
import { useModifiers } from "../hooks/modifiers"
import { setStatus } from "~/store/status"
import { isPointInPolygon, Edge } from "~/utils/pointInPolygon"
import LayerKeys from "~/config/layerKeys"

export function ElevationPoints() {
  const elevationPoints = useAppSelector(it => it.objects.present.elevationPoints)

  return (
    <>
      {Object.entries(elevationPoints).map(([key, value]) => <ElevationPoint key={key} point={value}/>)}
    </>
  )
}

export const SNAP_THRESHOLD = 100

const RULE_BOUNDS = 5000

const POINT_ON_WALL_THRESHOLD = 0.0000001

const useIsInFacility = () => {
  const segments = Object.values(useAppSelector(state => state.objects.present.segments)).filter(({layerKey}) => layerKey === LayerKeys.EXTERIOR_WALLS)
  const edges: Edge[] = segments.map(({startPoint, endPoint}) => ({ p1: [startPoint.x, startPoint.y], p2: [endPoint.x, endPoint.y]}))
  return ({x, y}: Vector3Like) => {
    const input = new Vector3(x, y, 0)
    const target = new Vector3()
    const isOnWall = !!segments.find(({startPoint, endPoint}) => {
      const line = new Line3(new Vector3().copy(startPoint), new Vector3().copy(endPoint))
      line.closestPointToPoint(input, false, target)
      return target.distanceToSquared(input) < POINT_ON_WALL_THRESHOLD
    })
    return isPointInPolygon([x, y], edges) || isOnWall
  }
}

function ElevationPoint(props: { point: ElevationPoint }) {
  const position = new Vector3().copy(props.point.position)
  const isSelected = useAppSelector(it => it.selectedObjects.some(sel => sel.id === props.point.id))
  const isVisible = useAppSelector(it => it.layers.layers.ELEVATION_POINT.visible)
  const isLocked = useAppSelector(it => it.layers.layers.ELEVATION_POINT.locked)
  const dispatch = useAppDispatch()
  const wallLines = useWallSegmentSnapLines()
  const isInFacility = useIsInFacility()
  const update = (elevationPoint: ElevationPoint) => dispatch(updateElevationPoint({ elevationPoint }))

  const roofs = useAppSelector(state => state.objects.present.roofs)
  const lines = useAppSelector(state => state.objects.present.elevationLines)
  const points = useAppSelector(state => state.objects.present.elevationPoints)
  const roofMeshes = memoizedRoofMeshes(roofs, lines, points)

  const parentLine = Object.values(lines).find(line => line.elevationPointIds.includes(props.point.id))
  const ourIndex = parentLine?.elevationPointIds.indexOf(props.point.id)
  const antecedentID = parentLine?.elevationPointIds.find((id, idx) => idx === ourIndex! - 1)
  const postcedentID = parentLine?.elevationPointIds.find((id, idx) => idx === ourIndex! + 1)
  const antecedentPosition = Object.values(points).filter(pt => pt.id == antecedentID).map(pt => new Vector3().copy(pt.position)).find(_ => true)
  const postcedentPosition = Object.values(points).filter(pt => pt.id == postcedentID).map(pt => new Vector3().copy(pt.position)).find(_ => true)

  const ortho = useAppSelector(state => state.tools.isOrthoModeEnabled)
  const shift = useModifiers(it => it.shift)

  const becomeOrthogonal = ortho || shift

  const dragControls = useRef<Group>(null!)
  useEffect(() => {
    dragControls.current.matrix = new Matrix4()
  }, [props.point.position])
  const [isDragging, setDragging] = useState(false)
  const [deltaAccumulator, setDeltaAccumulator] = useState<[number, number]>([0, 0])
  const [hovered, setHovered] = useState(false)

  const doSnap = useAppSelector(state => state.tools.isSnapEnabled)
  const snapPoint = isDragging ? snapToLine(wallLines, new Vector3(props.point.position.x + deltaAccumulator[0], props.point.position.y + deltaAccumulator[1], 0)) : undefined

  const color =
    isSelected ?
      hovered ? theme.colors.three.validSelectedHovered : theme.colors.three.validSelected :
      hovered ? theme.colors.three.validHovered : theme.colors.three.valid

  return (
    <group visible={isVisible}>
      <DragControls
        axisLock="z"
        ref={dragControls}
        dragConfig={{ enabled: isVisible && !isLocked }}
        onDragStart={() => {
          setDragging(true)
        }}
        onDrag={(_, deltaMatrix) => {
          const position = new Vector3()
          position.setFromMatrixPosition(deltaMatrix)
          startTransition(() => {
            setDeltaAccumulator(([x, y]) => [x + position.x, y + position.y])
          })
        }}
        onDragEnd={() => {
          const { x, y, z } = props.point.position

          setDragging(false)
          let newX: number, newY: number
          if (doSnap && snapPoint && snapPoint.distance <= SNAP_THRESHOLD) {
            newX = snapPoint.snapPoint.x
            newY = snapPoint.snapPoint.y
          } else {
            newX = x + deltaAccumulator[0]
            newY = y + deltaAccumulator[1]
          }
          setDeltaAccumulator([0, 0])

          if (becomeOrthogonal) {
            const pt = snapToCardinals(position, new Vector3(newX, newY, 0))
            newX = pt.x
            newY = pt.y
          }

          const newPosition = { x: newX, y: newY, z }
          if (!isInFacility(vectorUIToModel(newPosition))) {
            dispatch(setStatus({ text: "Elevation point must fall within facility bounds.", type: 'error' }))
            dragControls.current.matrix = new Matrix4()
          } else {
            update({ ...props.point, position: newPosition})
          }
        }}
      >
        <Sphere args={[5, 10, 10]} position={position} onPointerEnter={() => setHovered(true)} onPointerLeave={() => setHovered(false)} onClick={(e) => {
          if (!isVisible) return
          e.stopPropagation()
          dispatch(selectObjects({
            objects: [{ id: props.point.id, className: ObjectClassName.ELEVATION_POINT }]
          }))
        }} material-color={color} visible={!isDragging}/>
      </DragControls>
      {snapPoint && (() => {
        const point = doSnap && snapPoint.distance <= SNAP_THRESHOLD ?
          snapPoint.snapPoint.clone() :
          new Vector3(props.point.position.x + deltaAccumulator[0], props.point.position.y + deltaAccumulator[1], props.point.position.z)

        const targetedPoint = computeRoofIntersection(uiToModel(point.x), uiToModel(point.y), roofMeshes)
        if (targetedPoint !== null) {
          point.setZ(modelToUI(targetedPoint[1].z))
        } else {
          point.setZ(props.point.position.z)
        }

        const ruleStart = new Vector3()
        const ruleEnd = new Vector3()
        if (becomeOrthogonal) {
          point.copy(snapToCardinals(position, point))
          if (becomeOrthogonal) {
            const dx = Math.abs(position.x - point.x)
            const dy = Math.abs(position.y - point.y)
            const isHorizontalRule = dx >= dy
            if (isHorizontalRule) {
              ruleStart.copy(position).setX(-RULE_BOUNDS)
              ruleEnd.copy(position).setX(RULE_BOUNDS)
            } else {
              ruleStart.copy(position).setY(-RULE_BOUNDS)
              ruleEnd.copy(position).setY(RULE_BOUNDS)
            }
          }
        }

        return (
          <>
            <Sphere args={[5, 10, 10]} position={point} material-color={color}/>
            <Line points={[antecedentPosition, point, postcedentPosition].filter(it => it !== undefined)} lineWidth={4} worldUnits={true} color="cyan"/>
            {doSnap && snapPoint.distance <= SNAP_THRESHOLD && <SnapLine snapPoint={snapPoint} zOverride={point.z + 10}/>}
            {becomeOrthogonal && <Line points={[ruleStart, ruleEnd]} lineWidth={4} color={theme.colors.three.valid}/>}
          </>
        )
      })()}
    </group>
  )
}
