import { useCallback } from 'react'
import { Box3 } from 'three'
import { FAN_3D_MODEL_PARTS } from '~/components/DrawingCanvas/Products/constants'
import {
  AnimateNode,
  TransformNode,
} from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import { isMeshWithGeometry } from '~/components/DrawingCanvas/Products/util'
import { modelToUI } from '~/components/DrawingCanvas/util/units'

export const useTransformRadiantHeaterNode = ({ size }: { size: number }): TransformNode => {
  return useCallback(
    node => {
      const targetWidth = modelToUI(size)
      if (node.name === FAN_3D_MODEL_PARTS.body) {
        node.scale.x = 1
        const { max, min } = new Box3().setFromObject(node)
        const width = Math.max(Math.abs(max.x - min.x), Math.abs(max.y - min.y))
        const scaleFactor = targetWidth / width
        node.scale.x = scaleFactor
      } else if (node.name === FAN_3D_MODEL_PARTS.loop) {
        node.position.x = targetWidth
      }
    },
    [size]
  )
}

const DEFAULT = 'default'

type TransformDirectionalFanNodeProps = {
  isHeightVariable: boolean
  tilt?: number
  isForcedWallMount?: boolean
  isDirectionalOverhead?: boolean
  diameter?: number | typeof DEFAULT
  variation?: string
  tubeLength?: number
}

export const animateDirectionalFanNode: AnimateNode = (_, delta) => node => {
  if (node.name.includes(FAN_3D_MODEL_PARTS.blades)) node.rotation.z += delta
}

export const useTransformDirectionalFanNode = ({
  isHeightVariable,
  diameter = DEFAULT,
  isDirectionalOverhead,
  isForcedWallMount,
  tilt,
  tubeLength = 0,
  variation = DEFAULT,
}: TransformDirectionalFanNodeProps): TransformNode => {
  return useCallback(
    node => {
      const [partName, partVariation, partSize] = node.name.split('-')
      if (!partName || !partVariation || !partSize) return

      if (isHeightVariable) {
        if (partName === FAN_3D_MODEL_PARTS.pedestal) {
          const floorLevel = -modelToUI(tubeLength)
          node.position.setY(floorLevel)
        }
        if (partName === FAN_3D_MODEL_PARTS.tube && isMeshWithGeometry(node)) {
          node.geometry.computeBoundingBox()
          const boundingBox = node.geometry.boundingBox!
          const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
          const scaleFactor = 1 + modelToUI(tubeLength) / assetTubeHeight
          node.scale.setY(scaleFactor)
        }
        if (tilt != null && partName === FAN_3D_MODEL_PARTS.tiltGroup) {
          node.rotation.x = tilt
        }
        const isFloorPart = [
          FAN_3D_MODEL_PARTS.tube,
          FAN_3D_MODEL_PARTS.pedestal,
          FAN_3D_MODEL_PARTS.bracket,
        ].includes(partName)
        const isMatchingSize = partSize === DEFAULT || partSize === diameter.toString()
        const isVisible =
          isForcedWallMount || isDirectionalOverhead
            ? !isFloorPart && isMatchingSize
            : isMatchingSize
        node.visible = isVisible
        return
      }

      const isMatchingVariation = variation === partVariation
      const isMatchingSize = partSize === diameter?.toString()
      const isTiltGroupPart = partName === FAN_3D_MODEL_PARTS.tiltGroup
      if (isForcedWallMount) {
        const isMatchingPart =
          partName === FAN_3D_MODEL_PARTS.hub || partName === FAN_3D_MODEL_PARTS.motor
        node.visible =
          (isMatchingSize || isMatchingPart || isTiltGroupPart) && partVariation === DEFAULT
      } else if (isDirectionalOverhead) {
        const isMatchingPart = [
          FAN_3D_MODEL_PARTS.hub,
          FAN_3D_MODEL_PARTS.motor,
          FAN_3D_MODEL_PARTS.tube,
        ].includes(partName)
        node.visible =
          (isMatchingSize || isMatchingPart || isTiltGroupPart) && partVariation === DEFAULT
        if (partName === FAN_3D_MODEL_PARTS.tube && isMeshWithGeometry(node)) {
          node.geometry.computeBoundingBox()
          const boundingBox = node.geometry.boundingBox!
          const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
          const tubeOffset = modelToUI(tubeLength)
          const scaleFactor = 1 + tubeOffset / assetTubeHeight
          node.scale.setY(scaleFactor)
        }
      } else if (
        partName.includes(FAN_3D_MODEL_PARTS.blades) ||
        partName === FAN_3D_MODEL_PARTS.cage
      ) {
        const isMatchingSize = partSize === DEFAULT || diameter?.toString() === partSize
        node.visible = isMatchingVariation && isMatchingSize
      } else {
        if (partName === FAN_3D_MODEL_PARTS.tube) node.scale.setY(1)
        node.visible = isMatchingVariation
      }
      if (tilt != null && partName === FAN_3D_MODEL_PARTS.tiltGroup) node.rotation.x = tilt
    },
    [
      isHeightVariable,
      isDirectionalOverhead,
      isForcedWallMount,
      diameter,
      tilt,
      tubeLength,
      variation,
    ]
  )
}

export const animateOverheadFanNode: AnimateNode = (_, delta) => node => {
  if (node.name.includes(FAN_3D_MODEL_PARTS.blades)) node.rotation.y -= delta
}

export const useTransformOverheadFanNode = (
  newTubeLength: number | undefined,
  newSize: number,
  tilt?: number
): TransformNode => {
  return useCallback(
    node => {
      const { name } = node
      const [partName, partSize] = name.split('-')
      if (!partName || !partSize || !node) return
      if (name.includes(FAN_3D_MODEL_PARTS.blades)) {
        node.visible = partSize === newSize.toString() || partName.includes(FAN_3D_MODEL_PARTS.hub)
      }
      if (newTubeLength == null) return
      const tubeOffset = modelToUI(newTubeLength)
      if (name.includes(FAN_3D_MODEL_PARTS.tube) && isMeshWithGeometry(node)) {
        node.geometry.computeBoundingBox()
        const boundingBox = node.geometry.boundingBox!
        const assetTubeHeight = boundingBox.max.y - boundingBox.min.y
        const scaleFactor = 1 + tubeOffset / assetTubeHeight
        node.scale.setY(scaleFactor)
      } else if (name.includes(FAN_3D_MODEL_PARTS.mount)) {
        node.position.setY(tubeOffset)
      }
      const isHaikuLowProfileElement = partSize.includes('lowprofile')
      const isHaikuUniversalElement = partSize.includes('universal')
      if (isHaikuLowProfileElement) {
        node.visible = newTubeLength === 0
      } else if (isHaikuUniversalElement) {
        node.visible = newTubeLength !== 0
        node.position.setY(tubeOffset)
      }
      if (tilt != null && partName === FAN_3D_MODEL_PARTS.tiltGroup) {
        node.rotation.x = tilt
      }
    },
    [newTubeLength, newSize, tilt]
  )
}
