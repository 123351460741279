import { useQuery } from '@apollo/client'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { graphql } from '~/gql'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Switch, SwitchProps } from '~/ui/Switch'

export const ForceWallMountSwitch = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { productType, selectedProducts, variationId } = useSelectedProductContext()
  const { data } = useQuery(
    graphql(`
      query ProductVariationFloorClearance($variationId: ID!) {
        ProductVariation(id: $variationId) {
          id
          minFloorClearance
          canMountOnWall
          size
          pedestals {
            id
            height
          }
        }
      }
    `),
    { variables: { variationId: variationId ?? '' }, skip: !variationId }
  )
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isForceMountable = data?.ProductVariation.canMountOnWall

  const handleForceWallMount: SwitchProps['onChange'] = isSelected => {
    if (isLocked || !data) return
    const minFloorClearance = data.ProductVariation.minFloorClearance
    const size = data.ProductVariation.size
    const pedestalHeight = data.ProductVariation.pedestals.reduce(
      (maxHeight, pedestal) => Math.max(maxHeight, pedestal.height ?? 0),
      0
    )
    const updatedProducts = selectedProducts.map(({ product }) => {
      return {
        ...product,
        position: {
          ...product.position,
          z: minFloorClearance + (pedestalHeight || size / 2),
        },
        isMounted: isSelected,
        isForcedWallMount: isSelected,
        isDirectionalOverhead: isSelected ? false : product.isDirectionalOverhead,
      }
    })
    dispatch(updateProducts(updatedProducts))
  }

  return isDirectional && isForceMountable ? (
    <Switch
      isDisabled={isLocked}
      isSelected={selectedProducts.every(({ product }) => product.isForcedWallMount)}
      onChange={handleForceWallMount}
    >
      Force Wall Mount
    </Switch>
  ) : (
    <></>
  )
}
