import { Sphere } from '@react-three/drei'
import { ComponentProps, useEffect, useRef } from 'react'
import { Group, MathUtils, Matrix4, Vector3, Vector3Like } from 'three'
import { Line2 } from 'three/addons/lines/Line2.js'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { ClearanceMesh } from '~/components/DrawingCanvas/Products/components/ClearanceMesh'
import { DirectionArrow } from '~/components/DrawingCanvas/Products/components/DirectionArrow'
import { ErrorIndicators } from '~/components/DrawingCanvas/Products/components/ErrorIndicators'
import { ProductDragControls } from '~/components/DrawingCanvas/Products/components/ProductDragControls'
import { RotationHandle } from '~/components/DrawingCanvas/Products/components/RotationHandle'
import { SnapLine } from '~/components/DrawingCanvas/Products/components/SnapLine'
import { useCardinalSnaplines } from '~/components/DrawingCanvas/Products/hooks/useCardinalSnaplines'
import {
  DimensionError,
  useDimensionErrors,
} from '~/components/DrawingCanvas/Products/hooks/useDimensionErrors'
import { useErrorIndicatorsContext } from '~/components/DrawingCanvas/Products/hooks/useErrorIndicatorsContext'
import { useFanVariationData } from '~/components/DrawingCanvas/Products/hooks/useFanVariationData'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { useProductMesh } from '~/components/DrawingCanvas/Products/hooks/useProductMesh'
import { useSelectProduct } from '~/components/DrawingCanvas/Products/hooks/useSelectProduct'
import {
  animateDirectionalFanNode,
  useTransformDirectionalFanNode,
} from '~/components/DrawingCanvas/Products/hooks/useTransformNode'
import { isTiltingDirectional } from '~/components/DrawingCanvas/Products/modelNames'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
} from '~/components/DrawingCanvas/util/units'
import theme from '~/config/theme'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProduct } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'

const CAGE_DEPTH = 36

const MountingIndicator = (props: ComponentProps<typeof Sphere>) => {
  return (
    <Sphere {...props} args={[0.75, 16, 12]}>
      <meshStandardMaterial
        transparent
        opacity={0.9}
        depthWrite={false}
        color={theme.colors.three.objects.snapRegion.default}
      />
    </Sphere>
  )
}

const skipIgnorableWallErrors = (isForcedWallMount?: boolean) => (error: DimensionError) => {
  return !isForcedWallMount || !error.message.includes('wall')
}

const SNAP_THRESHOLD = 35
const SNAP_OFFSET = 12

export const DirectionalFan = ({
  isHeightVariable,
  ...product
}: Product & { isHeightVariable: boolean }) => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const isVisible = useAppSelector(state => state.layers.layers.PRODUCTS_DIRECTIONAL.visible)
  const selectedObjects = useAppSelector(state => state.selectedObjects)
  const isSnapEnabled = useAppSelector(state => state.tools.isSnapEnabled)
  const dispatch = useAppDispatch()
  const cursorHandlers = useCursor()
  const groupRef = useRef<Group>(null!)
  const snapLine = useRef<Line2>(null!)

  const {
    id,
    position,
    rotation,
    isForcedWallMount,
    isDirectionalOverhead,
    ignoreErrors,
    mountingOptionAdderId,
  } = product
  const isSelected = Boolean(selectedObjects.find(object => object.id === id))

  const selectProduct = useSelectProduct(id)
  const getFanHeightInches = useGetFanHeightInches()
  const snapToCardinals = useCardinalSnaplines(position)

  const checkDimensions = useDimensionErrors(product.variationId)
  const checkResults = checkDimensions({ origin: position, productId: id })
  const errors = checkResults.errors.filter(skipIgnorableWallErrors(isForcedWallMount))
  const { setShowErrorIndicators, showErrorIndicators } = useErrorIndicatorsContext()
  const isErrorsVisible = errors.length > 0 && showErrorIndicators && !ignoreErrors

  const { fullHeight, model, size, tubeLength } = useFanVariationData(product)
  const rotationXOffset = MathUtils.degToRad(rotation.x - 90)
  const transformNode = useTransformDirectionalFanNode({
    isHeightVariable,
    isDirectionalOverhead,
    isForcedWallMount,
    diameter: size,
    tubeLength,
    tilt: isTiltingDirectional(model) || isHeightVariable ? rotationXOffset : undefined,
    variation: mountingOptionAdderId === '46' ? 'lowrider' : undefined,
  })
  const productMesh = useProductMesh(model, transformNode, animateDirectionalFanNode)

  const tiltGroup = productMesh.getObjectByName('tilt_group-default-default')
  const positionZOffset = tiltGroup?.position.z ?? 0
  const SIZE_OFFSET = modelToUI(size / 2)
  const DIRECTION_ARROW_OFFSET = -(SIZE_OFFSET + 1)
  const origin = new Vector3()
  const snapDirectionOffset = new Vector3()
  const snapIntersection = new Vector3()

  useEffect(() => {
    groupRef.current.rotation.y = MathUtils.degToRad(rotation.z)
  }, [rotation.z])

  const handleDrag = (local: Matrix4, isMultipleSelected = false) => {
    setShowErrorIndicators(false)
    origin.setFromMatrixPosition(local)
    scaleUIVectorToModel(origin)
    const cardinalSnaps = snapToCardinals(origin)
    if (cardinalSnaps && !isMultipleSelected) {
      const { point, positions } = cardinalSnaps
      snapLine.current.geometry.setPositions(positions.map(p => modelToUI(p)))
      snapLine.current.visible = true
      origin.copy(point)
    } else if (isSnapEnabled && isForcedWallMount && !isMultipleSelected) {
      const distance = productDistanceEngine.getNearestWallOrColumnPoint(
        origin,
        snapIntersection,
        snapDirectionOffset
      )
      if (distance < SNAP_THRESHOLD) {
        const rotationOffset =
          Math.atan2(snapDirectionOffset.y, snapDirectionOffset.x) + Math.PI / 2
        groupRef.current.rotation.y = rotationOffset
        snapDirectionOffset.negate().multiplyScalar(SNAP_OFFSET)
        snapIntersection.add(snapDirectionOffset)
        origin.copy(snapIntersection)
      } else {
        groupRef.current.rotation.y = MathUtils.degToRad(rotation.z)
      }
    } else if (isDirectionalOverhead) {
      const newHeight = getFanHeightInches({ origin, fullHeight })
      origin.setZ(newHeight)
    }
    scaleModelVectorToUI(origin)
    local.setPosition(origin)
  }

  const handleUpdatePosition = (newPosition: Vector3Like) => {
    setShowErrorIndicators(true)
    const checkResults = checkDimensions({ origin: newPosition, productId: id })
    const errors = checkResults.errors.filter(skipIgnorableWallErrors(isForcedWallMount))
    if (errors.length) dispatch(setStatus({ text: errors[0].message, type: 'error' }))
    if (!checkResults.isValidPosition) return false
    const newRotation = isForcedWallMount
      ? { rotation: { ...product.rotation, z: MathUtils.radToDeg(groupRef.current.rotation.y) } }
      : {}
    dispatch(
      updateProduct({
        product: {
          id,
          position: newPosition,
          ...newRotation,
        },
      })
    )
    return true
  }

  const handleUpdateRotation = (newRotation: number) => {
    dispatch(updateProduct({ product: { id, rotation: { ...product.rotation, z: newRotation } } }))
  }

  return (
    <>
      <ErrorIndicators visible={isSelected && isErrorsVisible} errors={errors} origin={position} />
      <SnapLine ref={snapLine} />
      <ProductDragControls
        product={product}
        dragConfig={{ enabled: !isLocked && isSelected }}
        onDrag={handleDrag}
        onUpdatePosition={handleUpdatePosition}
      >
        <group ref={groupRef} visible={isVisible} onClick={selectProduct} {...cursorHandlers}>
          <MountingIndicator visible={Boolean(isForcedWallMount && isSelected)} />
          <ClearanceMesh
            position-z={positionZOffset}
            rotation-x={tiltGroup ? MathUtils.degToRad(rotation.x) : Math.PI / 2}
            isSelected={isSelected}
            radius={modelToUI(size / 2)}
            height={modelToUI(CAGE_DEPTH)}
            isError={isErrorsVisible}
          />
          <primitive object={productMesh} />
          {isSelected && (
            <RotationHandle
              offset={SIZE_OFFSET}
              onCommit={handleUpdateRotation}
              targetElement={groupRef.current}
            />
          )}
          <DirectionArrow position-z={DIRECTION_ARROW_OFFSET} visible={isSelected} />
        </group>
      </ProductDragControls>
    </>
  )
}
