export const FAN_3D_MODEL_PARTS = {
  blades: 'blades',
  blades_hub: 'blades_hub',
  tube: 'tube',
  mount: 'mount',
  hub: 'hub',
  motor: 'motor',
  cage: 'cage',
  bracket: 'bracket',
  pedestal: 'pedestal',
  tiltGroup: 'tilt_group',
  body: 'body',
  loop: 'loop',
}

export const ARROW_SIZE = 2

export const LINE_COLOR = 0x000000

export const CANVAS_BOUNDS = 5000

export const ERROR_COLOR = 0xff1f1f

// TODO: 3D models are rotated 90 degrees. Bake that rotation into the models to avoid this offset.
// Then update RotationHandle.tsx, if needed. Also check DirectionalFan, UnitHeater, EvaporativeCooler.
export const MODEL_X_ROTATION_OFFSET = Math.PI / 2

export const OVERHEAD_SNAP_THRESHOLD = 1250