import { graphql } from '~/gql'

export const GET_TUBE_LENGTHS_ON_VARIATION = graphql(`
  query GetTubeLengthsOnVariation($variationId: ID!) {
    ProductVariation(id: $variationId) {
      id
      minFloorClearance
      size
      pedestals {
        id
        height
      }
      voltages {
        id
        mountingOptions {
          id
          label
          forWall
          forOverhead
          forColumn
          forPedestal
          tubeLength
          fullHeight
        }
      }
    }
  }
`)
