import { GetAllProductsQuery } from '~/gql/graphql'
import { isTouchDevice } from '~/lib/utils'
import { showAlert } from '~/store/alert'
import { useAppDispatch } from '~/store/hooks'
import { setActiveTool } from '~/store/tools'
import { MenuItem } from '~/ui/Menu'

type Product = NonNullable<GetAllProductsQuery['Products']>[number]

export const ProductOption = (product: Product) => {
  const dispatch = useAppDispatch()

  const handleClick = async () => {
    dispatch(
      setActiveTool({
        tool: 'PRODUCT_TOOL',
        props: product,
      })
    )
    if (isTouchDevice()) dispatch(showAlert({ text: 'Tap the canvas to place the product' }))
  }

  return (
    <MenuItem
      className="cursor-pointer flex items-center gap-2 p-2.5 text-xs"
      onAction={handleClick}
    >
      <div className="relative w-[30px] h-[30px] bg-white">
        <img
          className={`absolute w-[30px] h-[30px] inset-0 object-contain`}
          src={new URL(`/src/assets/thumbnails/${product.model}.jpg`, import.meta.url).href}
          alt={product.model}
        />
      </div>
      {product.model}
    </MenuItem>
  )
}
