import React from 'react'
import { appConnect } from "~/store/hooks";
import ApolloClient from 'client'
import {
  HEATER_VARIATION_FRAGMENT,
  PRODUCT_VARIATION_FRAGMENT,
} from 'client/fragments'
import PropTypes from 'prop-types'

import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'

import { updateProducts, updateUtilityBoxes } from 'store/objects'
import { existingVoltage } from 'components/Panels/SelectedProductPanel'
import InfoTooltip from 'components/UIKit/InfoTooltip'
import Button from 'components/UIKit/Button'
import Tabs, { Tab } from 'components/UIKit/Tabs'
import FanProductsTab from './FanProductsTab'
import HeaterProductsTab from './HeaterProductsTab'
import InstallAddersTab from './InstallAddersTab'
import UtilityBoxesTab from './UtilityBoxesTab'
import { useQuery } from '@apollo/client';

import get from 'lodash-es/get'
import { graphql } from '~/gql';
import Loader from '~/components/UIKit/Loader';

const handleChangeVariation = ({ id, product, onUpdateProducts, isHeater }) => {
  const variation = ApolloClient.readFragment({
    id: `ProductVariation:${id}`,
    fragment: isHeater ? HEATER_VARIATION_FRAGMENT : PRODUCT_VARIATION_FRAGMENT,
  })
  const attrs = {
    ...product,
    variationId: variation.id,
    isMounted: product.isMounted,
    isDirectionalOverhead:
      product.isDirectionalOverhead && product.canMountOverhead,
  }

  const voltages = get(variation, 'voltages', [])
  const curVoltages = product.voltages || []
  const curVoltage = curVoltages.find(({ id }) => id === product.voltageId)
  const newVoltage = existingVoltage(voltages, curVoltage) || {
    mountingOptions: [],
  }

  // Find the new mountingOption that has the same tubeLength as before
  const newMountingOption = newVoltage.mountingOptions.find(
    o => o.tubeLength === product.tubeLength
  )

  if (newVoltage && newVoltage.id) {
    attrs.voltageId = newVoltage.id
  } else {
    const firstOption = voltages[0]
    attrs.voltageId = firstOption && firstOption.id
  }

  if (newMountingOption && newMountingOption.id) {
    attrs.mountingOptionId = newMountingOption.id
  } else if (newVoltage.mountingOptions.length > 0) {
    const firstOption = newVoltage.mountingOptions[0]
    attrs.mountingOptionId = firstOption.id
    attrs.tubeLength = firstOption.tubeLength
  }

  onUpdateProducts([attrs])
}

// will need custom mounting option select for ET
const handleMountingOptionChange = ({ value, product, onUpdateProducts }) => {
  const variation = ApolloClient.readFragment({
    id: `ProductVariation:${product.variationId}`,
    fragment: PRODUCT_VARIATION_FRAGMENT,
  })

  const voltages = variation.voltages || []
  const mountingOptions = get(
    voltages.find(v => v.id === product.voltageId) || {},
    'mountingOptions',
    []
  )
  const selectedMountingOption = mountingOptions.find(o => o.id === value)

  const newProduct = {
    ...product,
    installTube: get(selectedMountingOption, 'tubeLength'),
    mountingOptionId: value,
  }
  onUpdateProducts([newProduct])
}

const InstallationReviewModal = ({
  productsArray,
  parentRoute,
  onUpdateProducts,
  onUpdateUtilityBoxes,
  utilityBoxesArray,
}) => {
  const { data } = useQuery(graphql(`
    query InstallProductVariations($variationIds: [ID!]!) {
      ProductVariations(ids: $variationIds) {
        ...ProductVariationFragment
        ...HeaterVariationFragment 
      }
    }
  `), { variables: {variationIds: productsArray.map(({variationId}) => variationId)} })

  if (!data) return <Loader />

  return (
    <Modal
      title="Installation Review"
      parentRoute={parentRoute}
      size="90%"
      primaryAction={
        productsArray.length && (
          <Button
            primary
            label="Submit"
            to={parentRoute}
            onClick={() => {
              const newInstallAdders = get(
                productsArray[0],
                'installAdders',
                []
              )
              const level = get(productsArray[0], 'level')
              const liftType = get(productsArray[0], 'liftType')
              const fireRelay = get(productsArray[0], 'fireRelay')
              const fireRelayType = get(productsArray[0], 'fireRelayType')
              const newProducts = productsArray.map(p => ({
                ...p,
                installAdders: newInstallAdders,
                level,
                liftType,
                fireRelay,
                fireRelayType,
              }))
              const newUtilityBoxes = utilityBoxesArray
              onUpdateProducts(newProducts)
              onUpdateUtilityBoxes(newUtilityBoxes)
            }}
          />
        )
      }
    >
      {!productsArray.length && (
        <Space bottom="base">
          <b>Please add a product to your facility</b>
        </Space>
      )}
      {productsArray.length !== 0 && (
        <Tabs>
          <Tab
            title={
              <>
                Install Adders{' '}
                <InfoTooltip
                  title={'Changes here will apply to all products'}
                  iconSize={'12px'}
                />
              </>
            }
          >
            <InstallAddersTab
              productsArray={productsArray}
              onUpdateProducts={onUpdateProducts}
            />
          </Tab>
          <Tab title="Fans">
            <FanProductsTab
              productsArray={productsArray.filter(p => p.category !== 'HEAT')}
              onUpdateProducts={onUpdateProducts}
              handleMountingOptionChange={handleMountingOptionChange}
              handleChangeVariation={handleChangeVariation}
            />
          </Tab>
          <Tab title="Heaters">
            <HeaterProductsTab
              productsArray={productsArray.filter(p => p.category === 'HEAT')}
              onUpdateProducts={onUpdateProducts}
              handleMountingOptionChange={handleMountingOptionChange}
              handleChangeVariation={handleChangeVariation}
            />
          </Tab>
          <Tab title="Panels/Controllers">
            <UtilityBoxesTab
              utilityBoxesArray={utilityBoxesArray}
              onUpdateUtilityBoxes={onUpdateUtilityBoxes}
              productsArray={productsArray}
              onUpdateProducts={onUpdateProducts}
            />
          </Tab>
        </Tabs>
      )}
    </Modal>
  )
}

InstallationReviewModal.propTypes = {
  productsArray: PropTypes.array,
  parentRoute: PropTypes.string,
  onUpdateProducts: PropTypes.func,
  onUpdateUtilityBoxes: PropTypes.func,
  utilityBoxesArray: PropTypes.array,
}

const mapStateToProps = ({ objects }) => {
  return {
    productsArray: Object.values(get(objects, 'present.products', {})),
    utilityBoxesArray: Object.values(get(objects, 'present.utilityBoxes', {})),
  }
}

const mapDispatchToProps = dispatch => ({
  onUpdateProducts(products) {
    dispatch(updateProducts(products))
    dispatch({
      type: 'UPDATE_INSTALL',
      payload: products,
    })
  },
  onUpdateUtilityBoxes(utilityBoxes) {
    dispatch(updateUtilityBoxes(utilityBoxes))
  },
})

export default appConnect(
  mapStateToProps,
  mapDispatchToProps
)(InstallationReviewModal)
