import { MeshProps, useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { MeshPhongMaterial } from 'three'

const AMPLITUDE = 0.5
const GRAY = 0x555555

export const LoadingIndicator = (props: MeshProps & { size?: number }) => {
  const { size = 2 } = props
  const material = useRef<MeshPhongMaterial>(null!)
  const time = useRef(0)
  const frequency = 1

  useFrame((_, delta) => {
    time.current = time.current + delta
    material.current.opacity =
      AMPLITUDE * Math.sin(Math.PI * 2 * frequency * time.current) + AMPLITUDE
  })

  return (
    <mesh {...props}>
      <sphereGeometry args={[size, 16, 16]} />
      <meshPhongMaterial ref={material} transparent color={GRAY} flatShading emissive="gray" />
    </mesh>
  )
}
