import { useRef } from 'react'
import { ArrowHelper, Mesh } from 'three'
import { CARDINALS } from '~/components/DrawingCanvas/constants/dimensions'

export const useCardinalArrowConfigs = () => {
  return CARDINALS.map(direction => ({
    arrowHelper: useRef<ArrowHelper>(null),
    div: useRef<HTMLDivElement>(null),
    htmlMesh: useRef<Mesh>(null),
    direction,
  }))
}
