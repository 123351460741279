import { graphql } from '~/gql'

export const GET_EXTENSION_TUBES_ON_VARIATIONS = graphql(`
  query GetExtensionTubesOnVariations($variationIds: [ID!]!) {
    ProductVariations(ids: $variationIds) {
      id
      voltages {
        id
        mountingOptions {
          id
          tubeLength
          fullHeight
        }
      }
    }
  }
`)
