import { useAppDispatch, useAppSelector, useAppStore } from "~/store/hooks"
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import CLASS_NAMES from '~/config/objectClassNames'
import { ElevationPoint } from '~/store/objects/types'
import Panel, { PanelSection } from "~/components/UIKit/Panel"
import { SELECTED_ELEVATION_POINT_PANEL } from "~/store/panel/types"
import { updateElevationLine, updateElevationPoint } from "~/store/objects"
import MetadataSection from "~/components/MetadataSection"
import { DimensionInput } from "~/ui/DimensionInput"
import { modelToUI, uiToModel } from "~/components/DrawingCanvas/util/units"

export default function SelectedElevationPointPanel() {
  const point = useAppSelector(it => mostRecentSelectedObjectOfClassName(CLASS_NAMES.ELEVATION_POINT, it))
  const isTouchUI = useAppSelector(it => it.userInterface.isTouchUI)
  const dispatch = useAppDispatch()
  const store = useAppStore()
  const update = (elevationPoint: ElevationPoint) => dispatch(updateElevationPoint({ elevationPoint }))
  const isLocked = useAppSelector(it => it.layers.layers.ELEVATION_POINT.locked)

  return (
    <Panel title="Elevation Point" alignment="right" docked panelKey={SELECTED_ELEVATION_POINT_PANEL} isTouchUI={isTouchUI}>
      {point &&
        <>
          <PanelSection>
            <DimensionInput label="Height" value={uiToModel(point.position.z)} onCommit={value => {
              const { elevationLines } = store.getState().objects.present
              const parentLine = Object.values(elevationLines).find(line => line.elevationPointIds.find(id => id === point.id))

              // disconnect the parent line's height if we're changing ours explicitly
              if (parentLine !== undefined) {
                dispatch(updateElevationLine({
                  elevationLine: {
                    ...parentLine,
                    height: undefined,
                  }
                }))
              }

              update({ ...point, position: { ...point.position, z: modelToUI(value) } })
            }} />
          </PanelSection>
          <MetadataSection object={point} onBlur={update} disabled={isLocked} />
        </>
      }
    </Panel>
  )
}
