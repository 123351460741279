import { useSuspenseQuery } from '@apollo/client'
import { graphql } from '~/gql'

export const useHeaterVariationData = ({
  variationId,
  isUnitHeater,
}: {
  variationId: string
  isUnitHeater: boolean
}) => {
  const { data } = useSuspenseQuery(
    graphql(`
      query HeaterDimensionsQuery($variationId: ID!, $isUnitHeater: Boolean!) {
        ProductVariation(id: $variationId) {
          id
          size
          product {
            id
            model
          }
          heaterData {
            id
            ...RadiantHeaterDimensions @skip(if: $isUnitHeater)
            ...UnitHeaterDimensions @include(if: $isUnitHeater)
          }
        }
      }

      fragment RadiantHeaterDimensions on HeaterVariationData {
        angle
        minTubeLength
        burnerBoxWidth
        burnerBoxClearanceWidth
        irhClearanceB
        tubeDiameter
        irhClearanceD
        irhClearanceC
      }

      fragment UnitHeaterDimensions on HeaterVariationData {
        boxDepthF
        boxHeightA
        boxWidthB
        uhClearanceNonAccessSide
        uhClearanceAccessPanel
        uhClearanceFlueConnector
        blowerDepthE
        uhClearanceRear
        uhClearanceTop
        uhClearanceBottom
      }
    `),
    { variables: { variationId, isUnitHeater } }
  )
  const { heaterData, size, product } = data.ProductVariation

  return {
    heaterData,
    size,
    model: product.model,
  }
}
