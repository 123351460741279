import { useApolloClient } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { GET_TUBE_LENGTHS_ON_VARIATION } from '~/client/queries'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Switch, SwitchProps } from '~/ui/Switch'

export const OverheadMountedSwitch = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()
  const client = useApolloClient()
  const getFanHeight = useGetFanHeightInches()

  const { productType, selectedProducts } = useSelectedProductContext()

  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isOverheadMountable = selectedProducts.every(({ variation }) => variation.canMountOverhead)

  const handleDirectionalOverheadChange: SwitchProps['onChange'] = async isSelected => {
    if (isLocked) return
    try {
      const updatedProducts = await Promise.all(
        selectedProducts.map(async ({ product }) => {
          const { data } = await client.query({
            query: GET_TUBE_LENGTHS_ON_VARIATION,
            variables: { variationId: product.variationId },
          })
          const defaultVoltage = data.ProductVariation.voltages[0]
          const minFloorClearance = data.ProductVariation.minFloorClearance
          const defaultMountingOption = defaultVoltage?.mountingOptions?.find(
            option => option.forOverhead
          )
          const fullHeight = defaultMountingOption?.fullHeight ?? 0
          const size = data.ProductVariation.size
          const pedestalHeight = data.ProductVariation.pedestals.reduce(
            (maxHeight, pedestal) => Math.max(maxHeight, pedestal.height ?? 0),
            0
          )
          const { position } = product
          const newHeight = isSelected
            ? getFanHeight({ origin: position, fullHeight }) ?? minFloorClearance
            : minFloorClearance + (pedestalHeight || size / 2)
          return {
            ...product,
            rotation: {
              x: isSelected ? 0 : 90,
              y: product.rotation.y,
              z: product.rotation.z,
            },
            isDirectionalOverhead: isSelected,
            isForcedWallMount: isSelected ? false : product.isForcedWallMount,
            isMounted: isSelected ? false : product.isMounted,
            position: { ...product.position, z: newHeight },
          }
        })
      )
      dispatch(updateProducts(updatedProducts))
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Directional overhead toggle failed'
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change mounting option' }))
    }
  }

  return isDirectional && isOverheadMountable ? (
    <Switch
      isDisabled={isLocked}
      isSelected={selectedProducts.every(({ product }) => product.isDirectionalOverhead)}
      onChange={handleDirectionalOverheadChange}
    >
      Overhead Mounted
    </Switch>
  ) : (
    <></>
  )
}
