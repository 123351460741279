import defaultTo from 'lodash-es/defaultTo'
import get from 'lodash-es/get'
import Color from 'color'
import Util from './util'
import Units from './units'
import Primitives from './primitives'
import getRenderOrder from 'config/canvasRenderOrder'
import OBJECT_TYPES from 'config/objectTypes'
import CLASS_NAMES from 'config/objectClassNames'
import LAYER_KEYS from 'config/layerKeys'
import theme from 'config/theme'

import store from '~/store'
import cdt2d from 'cdt2d'
import { objectIsSelected } from 'store/selectedObjects/selectors'
import { clearStatus, setStatus } from 'store/status'
import { getThreeHexFromTheme } from 'lib/utils'

import * as THREE from 'three'
import CLICK_PRIORITY from 'config/clickPriority'
import { modelToUI } from '../util/units'

class Roof {
  static Z_FIGHT_SHIFT = Units.inchesToNative(0.5)

  constructor(model, units) {
    this.id = model.id
    this.className = CLASS_NAMES.ROOF
    this.units = units
    this.layerKey = defaultTo(model.category, model.layerKey)
    this.clickPriority = CLICK_PRIORITY[this.layerKey]
    this.perimeterPoints = model.perimeterPoints
      ? model.perimeterPoints.map(point => [
          Units.unitsToNative(units, point[0]),
          Units.unitsToNative(units, point[1]),
          0,
        ])
      : []
    this.height = Units.unitsToNative(units, model.height) + Roof.Z_FIGHT_SHIFT

    const defaultColor = theme.colors.three.objects.roof.default
    this.color = model.color || defaultColor

    this.obj3d = Roof.createVisual(model)
    this.model = model

    // If the roof geometry is invalid set a status message
    if (this.obj3d.userData.hasInvalidPoints) {
      store.dispatch(
        setStatus({
          text: 'Roof too complex. Try a simpler design.',
          type: 'error',
        })
      )
    } else {
      store.dispatch(clearStatus())
    }

    // Used by Interactifier
    this.selectable = true
    this.draggable = false
    this.obj3d.wrapperId = this.id
    this.obj3d.userData.objectType = OBJECT_TYPES.ROOF
    this.obj3d.renderOrder = getRenderOrder('roofs')

    if (objectIsSelected(this.id)) {
      this.select()
    }
  }

  /**
   * @param {import("~/store/objects/types").Roof} roof
   */
  static createVisual(roof) {
    const { elevationLines: lines, elevationPoints: points } = store.getState().objects.present

    const color = roof.color
    /** @type {THREE.Vector3Tuple[]} */
    const perimeterPoints = roof.perimeterPoints.map(([x, y]) => [modelToUI(x), modelToUI(y), modelToUI(roof.height)])
    perimeterPoints.pop() // Remove duplicate start/end point
    /** @type {[number, number][]} */
    const perimeterEdgeIndices = perimeterPoints.reduce((indices, _, i, { length }) => {
      indices.push([i, (i + 1) % length])
      return indices
    }, /** @type {[number, number][]} */([]))
    const elevationPointIndices = new Map(
      Object.values(points).map(({ id }, i) => [id, i + perimeterPoints.length])
    )
    const edgeIndices = [...perimeterEdgeIndices]
    Object.values(lines).forEach(({ elevationPointIds }) => {
      const [idA, idB] = elevationPointIds
      const indexA = elevationPointIndices.get(idA ?? '')
      const indexB = elevationPointIndices.get(idB ?? '')
      if (!indexA || !indexB) return
      edgeIndices.push([indexA, indexB])
    })
    /** @type {THREE.Vector3Like[]} */
    const transformedPoints = []
    for (const id in points) {
      const { position: ep } = points[id]
      let wasOnPerimeter = false
      let xChange = 0
      let yChange = 0
      let basePerimeter = [0, 0]

      for (let i = 0; i < perimeterPoints.length; i++) {
        const p1Index = i
        const p2Index = (i + 1) % perimeterPoints.length 
        const p1 = perimeterPoints[p1Index]
        const p2 = perimeterPoints[p2Index]

        const distance = Util.distanceToLineSegment(p1[0], p1[1], p2[0], p2[1], ep.x, ep.y)
        if (distance > modelToUI(3)) {
          continue
        }

        const p1x = Math.round(p1[0])
        const p1y = Math.round(p1[1])
        const p2x = Math.round(p2[0])
        const p2y = Math.round(p2[1])
        // If the point is on a perimeter point, its a corner. Set a tiny offset away from the corner
        if (Util.pointsAreEqual2D(p1, ep, Units.inchesToNative(3))) {
          if (p1x === p2x) {
            p1y > p2y ? (yChange += -0.1) : (yChange += 0.1)
          }
          if (p1y === p2y) {
            p1x > p2x ? (xChange += -0.1) : (xChange += 0.1)
          }
          basePerimeter = p1
        } else if (
          Util.pointsAreEqual2D(p2, ep, Units.inchesToNative(3))
        ) {
          if (p1x === p2x) {
            p1y < p2y ? (yChange += -0.1) : (yChange += 0.1)
          }
          if (p1y === p2y) {
            p1x < p2x ? (xChange += -0.1) : (xChange += 0.1)
          }
          basePerimeter = p2
        } else {
          // If the point is on or very close to a perimeter line, find the centroid of the closest
          // triangle in the polygon and move one vector unit towards it.
          const start = new THREE.Vector3().fromArray(p1).setZ(0)
          const end = new THREE.Vector3().fromArray(p2).setZ(0)
          const edge = new THREE.Line3(start, end)
          const closetPointOnEdge = edge.closestPointToPoint(new THREE.Vector3().copy(ep), true, new THREE.Vector3())
          const triangles = cdt2d(perimeterPoints, perimeterEdgeIndices, { exterior: false })
          const closestTriangle = triangles.find((indices) => indices.includes(p1Index) && indices.includes(p2Index))
          const [a, b, c] = closestTriangle
          const centroid = new THREE.Triangle(
            new THREE.Vector3().fromArray(perimeterPoints[a]).setZ(0),
            new THREE.Vector3().fromArray(perimeterPoints[b]).setZ(0),
            new THREE.Vector3().fromArray(perimeterPoints[c]).setZ(0),
          ).getMidpoint(new THREE.Vector3())
          const offset = new THREE.Vector3().subVectors(centroid, closetPointOnEdge).normalize()
          basePerimeter = [closetPointOnEdge.x, closetPointOnEdge.y]
          xChange = Units.inchesToNative(offset.x)
          yChange = Units.inchesToNative(offset.y)
        }
        wasOnPerimeter = true
        break
      }

      if (!wasOnPerimeter) {
        transformedPoints.push(ep)
      } else {
        const interiorPoint = {
          x: (xChange && basePerimeter[0] + xChange) || ep.x,
          y: (yChange && basePerimeter[1] + yChange) || ep.y,
          z: ep.z,
          originalX: ep.x,
          originalY: ep.y,
        }
        // If point is outside of roof polygon after x and y change has been applied,
        // then it is an interior corner inside the bounds of the facility. Reverse the
        // direction of x and y changes to inverse direction of displacement
        if (
          (xChange || yChange) &&
          !Util.isPointInPolygon(interiorPoint, perimeterPoints) // Inside roof bounds
        ) {
          interiorPoint.x =
            (xChange && basePerimeter[0] - xChange) || ep.x
          interiorPoint.y =
            (yChange && basePerimeter[1] - yChange) || ep.y
        }
        transformedPoints.push(interiorPoint)
      }
    }
    /** @type {THREE.Vector3Tuple[]} */
    const elevationPoints = transformedPoints.map(
      (position) => {
        const { x, y, z } = position
        return [x, y, z]
      }
    )

    const allPoints = perimeterPoints.concat(elevationPoints)
    const points3d = allPoints.map(([x, y, z]) => new THREE.Vector3(x, y, z))
    const triangles = cdt2d(allPoints, edgeIndices, { exterior: false })

    console.assert(triangles.length > 0, 'missing triangles from', allPoints, edgeIndices)
    const trianglePoints = triangles.reduce(
      (array, triangle) =>
        array
          .concat([allPoints[triangle[0]]])
          .concat([allPoints[triangle[1]]])
          .concat([allPoints[triangle[2]]]),
      /** @type {THREE.Vector3Tuple[]} */([])
    )

    /** @param {THREE.Vector3Tuple} point */
    const heightForPoint = point => {
      const matchingElevationPoint = elevationPoints.find(
        elevationPoint =>
          Math.abs(elevationPoint[0] - point[0]) < Units.inchesToNative(0.5) &&
          Math.abs(elevationPoint[1] - point[1]) < Units.inchesToNative(0.5)
      )

      if (matchingElevationPoint) {
        return matchingElevationPoint[2] - roof.height
      }
      return 0
    }

    const minimumZ = triangles.flat().reduce((accumulator, triangle) => Math.min(allPoints[triangle][2], accumulator), +Infinity)
    // Flatten the triangle points into an array of floats, while settng correct Z values with heightForPoint(...)
    const flatTriPointsWithHeight = trianglePoints.reduce(
      (array, point) =>
        array
          .concat(point[0])
          .concat(point[1])
          .concat(point[2] - minimumZ),
      /** @type {number[]} */([])
    )

    const geometry = new THREE.BufferGeometry().setFromPoints(points3d)
    geometry.setIndex(triangles.flat())
    geometry.computeVertexNormals()

    const material = new THREE.MeshPhongMaterial({
      color,
      emissive: 0x000000,
      specular: 0x222222,
      emissiveIntensity: 0.6,
      shininess: 10,
      side: THREE.DoubleSide,
      transparent: true,
      opacity: 0.6,
    })

    const mesh = new THREE.Mesh(geometry, material)

    mesh.userData.triPoints = flatTriPointsWithHeight

    const edgesGeometry = new THREE.EdgesGeometry(geometry)
    const linesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color }))

    mesh.add(linesMesh)

    return mesh
  }

  /*
    SceneBuilder event
  */
  visibilityChanged(visible) {
    const isLayerVisible = store.getState().layers.layers.ROOFS.visible
    if (this.obj3d) this.obj3d.visible = isLayerVisible && visible
  }

  /*
    Returns an array of objects which each describe a roof 'panel'. A roof
    panel is a polygonal section of the 3d roof geometry which lies in a single plane.
    Panel objects have the properties:

    polygon: the set of 3d points on the perimeter of the panel
    normal: the surface normal of the panel
    centroid: centroid of the panel's polygon
    rotationParams: rotation axis and angle require to lay the panel flat (z = constant)

    @return [{polygon, normal, centroid, rotationParams}]
  */
  getPanels() {
    const flatPoints = this.obj3d.userData.triPoints
    const trianglePoints = []
    for (let i = 0; i < flatPoints.length; i += 3) {
      trianglePoints.push(
        new THREE.Vector3(flatPoints[i], flatPoints[i + 1], flatPoints[i + 2])
      )
    }

    const triangleGroups = Util.collectCoPlanarTriangleGroups(trianglePoints)
    const centroids = []
    const normals = []
    const rotationParams = []
    const zAxis = new THREE.Vector3(0, 0, 1)

    triangleGroups.forEach(group => {
      const allGroupPoints = group.reduce((array, triangle) => {
        return array.concat([triangle.p1, triangle.p2, triangle.p3])
      }, [])

      const centroid = Util.polygonCentroid(allGroupPoints)
      const normal = Util.surfaceNormalForTriangle(group[0])
      centroids.push(centroid)
      normals.push(normal)

      const angle = normal.angleTo(zAxis)
      const rotationAxis = new THREE.Vector3()
        .crossVectors(normal, zAxis)
        .normalize()
      rotationParams.push({ axis: rotationAxis, angle })
    })

    let panels = triangleGroups.map((group, i) => ({
      polygon: Util.polygonForTriangleGroup(group),
      normal: normals[i],
      centroid: centroids[i],
      rotationParams: rotationParams[i],
    }))

    return panels
  }

  addElevationPoint(elevationPointModel) {
  }

  addElevationLine(elevationLineModel) {
  }

  getElevationLinePoints() {
    return []
  }

  getAllElevationPoints() {
    return []
  }

  toModel() {
    return {
      id: this.id,
      layerKey: this.layerKey,
      perimeterPoints: this.perimeterPoints.map(point => [
        Units.nativeToUnits(this.units, point[0]),
        Units.nativeToUnits(this.units, point[1]),
        0,
      ]),
      elevationPointIds: this.elevationPoints.map(ep => ep.id),
      elevationLineIds: this.elevationLines.map(el => el.id),
      height: Units.nativeToUnits(this.units, this.height) - Roof.Z_FIGHT_SHIFT,
      position: Units.nativeToUnitsV(this.units, this.obj3d.position),
      color: this.color,
    }
  }

  static createModel(
    perimeterPoints,
    position,
    height = Units.feetToInches(12.1),
    color = getThreeHexFromTheme('three.objects.roof.default')
  ) {
    return {
      id: Util.guid(),
      layerKey: LAYER_KEYS.ROOFS,
      perimeterPoints,
      elevationPointIds: [],
      elevationLineIds: [],
      height,
      position,
      color,
    }
  }

  static getDenormalizedModel(model) {
    const currentState = store.getState().objects.present

    // Collect elevation points
    let elevationPoints = []

    const elevationPointIds = get(model, 'elevationPointIds', [])
    if (elevationPointIds && elevationPointIds.length > 0) {
      elevationPoints = elevationPointIds.map(
        elevationPointId => currentState.elevationPoints[elevationPointId]
      )
    }

    // Collect elevation lines
    let elevationLines = []
    const elevationLineIds = get(model, 'elevationLineIds', [])
    if (elevationLineIds && elevationLineIds.length > 0) {
      elevationLines = elevationLineIds
        .map(elevationLineId => currentState.elevationLines[elevationLineId])
        .filter(line => line !== undefined)

      // Collect elevation points belonging to each elevation line
      for (let i = 0; i < elevationLines.length; i += 1) {
        elevationLines[i] = {
          ...elevationLines[i],
          elevationPoints: elevationLines[i].elevationPointIds.map(
            epId => currentState.elevationPoints[epId]
          ),
        }
      }
    }

    return {
      ...model,
      elevationPoints: elevationPoints,
      elevationLines: elevationLines,
    }
  }

  static getAllElevationPointsFromDenormalizedModel(model) {
    const eps = model.elevationPoints.concat(
      model.elevationLines.reduce(
        (array, el) => array.concat(el.elevationPoints),
        []
      )
    )

    return eps
  }

  static isFlat(model) {
    let allPointsAreRoofLevel = true
    Roof.getAllElevationPointsFromDenormalizedModel(model).forEach(point => {
      if (point.height - model.height !== 0) {
        allPointsAreRoofLevel = false
      }
    })

    return allPointsAreRoofLevel
  }

  select() {
    const currentColor = Color(this.color)
    const selectedColor = currentColor.darken(0.2).rgbNumber()
    this.obj3d.material.color.setHex(selectedColor)
  }

  deselect() {
    this.obj3d.material.color.setHex(Color(this.color).rgbNumber())
  }

  // ///
  // Interactable methods
  // ///

  getSnappableEdgePoints() {
    return []
  }

  drop() {}

  drag({ dragDelta }) {
    this.obj3d.position.add(dragDelta)
  }

  snap(snapDelta) {
    this.obj3d.position.add(snapDelta)
  }

  destroy() {}
}

export default Roof
