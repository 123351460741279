import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { useNetwork } from 'networkProvider'

import isEqual from 'lodash-es/isEqual'
import omit from 'lodash-es/omit'

import { updateUtilityBox } from 'store/objects'
import { addLoadingDockUtilityBox } from 'store/loadingDock'
import { mostRecentSelectedObjectOfClassName } from 'store/selectedObjects/selectors'
import { SELECTED_UTILITY_BOX_PANEL } from 'store/panel/types'
import CLASS_NAMES from 'config/objectClassNames'

import Panel, { PanelSection } from 'components/UIKit/Panel'

import AddInstallInfoButton from 'components/AddInstallInfoButton'
import MetadataSection from 'components/MetadataSection'
import SaveConfiguration from 'components/SaveConfiguration'

import * as THREE from 'three'
import { UtilityBox } from '~/store/objects/types';
import { DimensionInput } from '~/ui/DimensionInput';
import { NumberField } from '~/ui/NumberField';
import { TextField } from '~/ui/TextField';
import { ControllerTypeSelect } from "./ControllerTypeSelect";

// These are the utility-box types we need to show the
// "Add Install Info" button for.
const ADD_INSTALL_TYPES = ['electricPanel']

export default function SelectedUtilityBoxPanel() {
  const box = useAppSelector(it => mostRecentSelectedObjectOfClassName(CLASS_NAMES.UTILITY_BOX, it)) as UtilityBox|undefined
  const isTouchUI = useAppSelector(it => it.userInterface.isTouchUI)
  const dispatch = useAppDispatch()
  const updateBox = (utilityBox: UtilityBox) => dispatch(updateUtilityBox({ utilityBox }))
  const isOnline = useNetwork().online

  const isDuplicateConfig = (() => {
    const irrelevantKeys = ['id', 'position', 'layerKey', 'metadata', 'rotation'] as const
    const loadingDockBoxes = useAppSelector(it => it.loadingDock.utilityBoxes)
    const relevantBox = omit(box, irrelevantKeys)
    return Object.values(loadingDockBoxes).some(loadingDockBox => {
      // @ts-expect-error loading dock has no types
      const relevantLoadingDock = omit(loadingDockBox, irrelevantKeys)
      return isEqual(relevantBox, relevantLoadingDock)
    })
  })

  const rotation = THREE.MathUtils.radToDeg(box === undefined ? 0 : 'z' in box.rotation ? box.rotation.z : box.rotation._z)

  return (
    <Panel title={box?.label ?? 'Utility Box'} alignment='right' docked panelKey={SELECTED_UTILITY_BOX_PANEL} hasToolbar={isTouchUI}>
      {box && <>
        <SaveConfiguration selectedObjectId={box.id} isDupeConfig={isDuplicateConfig} onConfigurationSave={(onSuccess: () => void) => {
          dispatch(addLoadingDockUtilityBox(box))
          onSuccess()
        }}/>
        <PanelSection>
          <div className='flex flex-col space-y-2'>
            <DimensionInput label="Height" value={box.height} onCommit={value => updateBox({ ...box, height: value })}/>
            <DimensionInput label="Width" value={box.width} onCommit={value => updateBox({ ...box, width: value })}/>
            <DimensionInput label="Height From Floor" value={box.centerPointHeight} onCommit={value => updateBox({ ...box, centerPointHeight: value })}/>
            <NumberField label="Rotation" formatOptions={{style: 'unit', unit: 'degree', unitDisplay: 'narrow'}} value={rotation} onChange={value => {
              const z = THREE.MathUtils.degToRad(value)
              const newRotation = { x: 0, y: 0, z }
              updateBox({
                ...box,
                rotation: newRotation,
              })
            }}/>
            {box.utilityBoxType === 'controlPanel' && <ControllerTypeSelect label="Controller Type" value={box.controllerType} onChange={key => updateBox({ ...box, controllerType: key })}/>}
            <TextField label="Detailed Label" value={box.detailedLabel ?? ''} onChange={text => updateBox({ ...box, detailedLabel: text })}/>
            {ADD_INSTALL_TYPES.includes(box.utilityBoxType) && <AddInstallInfoButton type={box.utilityBoxType} selectedObject={box} online={isOnline}/>}
          </div>
        </PanelSection>
        <MetadataSection object={box} onBlur={updateBox} />
      </>}
    </Panel>
  )
}
