import { Key } from 'react-aria-components'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'

const isIRH200 = (variationId: string | null) => variationId === '89' || variationId === '90'

const NATURAL_GAS = ['Natural Gas']
const LPG_PROPANE = ['LPG Propane']

export const FuelTypeSelect = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { isHeater, variationId, selectedProducts } = useSelectedProductContext()
  const nonNullSelectedFuelTypes = selectedProducts
    .map(({ product }) => product.fuelType)
    .filter(f => !!f)
  const selectedFuelTypes = Array.from(new Set(nonNullSelectedFuelTypes))
  const selectedFuelType = selectedFuelTypes.length === 1 ? selectedFuelTypes[0] : null
  const fuelOptions = isIRH200(variationId) ? NATURAL_GAS : NATURAL_GAS.concat(LPG_PROPANE)
  const isDisabled = isLocked || !variationId

  const handleFuelTypeChange = (newFuelType: Key) => {
    if (isDisabled || typeof newFuelType !== 'string') return
    dispatch(
      updateProducts(
        selectedProducts.map(({ product }) => ({
          ...product,
          fuelType: newFuelType,
        }))
      )
    )
  }

  return isHeater ? (
    <Select
      isDisabled={isDisabled}
      label="Fuel Type"
      selectedKey={selectedFuelType}
      items={fuelOptions.map(fuel => ({ id: fuel }))}
      onSelectionChange={handleFuelTypeChange}
    >
      {fuelType => <SelectItem value={fuelType}>{fuelType.id}</SelectItem>}
    </Select>
  ) : (
    <></>
  )
}
