import { graphql } from '~/gql'

export const ADD_PRODUCT_QUERY = graphql(`
  query AddProduct($productId: ID!, $isHeater: Boolean!) {
    Product(id: $productId) {
      id
      distinctFanSpeeds @skip(if: $isHeater) {
        id
        speed
        power
      }
      defaultVariation {
        id
        canMountOverhead
        minFloorClearance
        size
        cageHeight
        pedestals {
          id
          height
          fullHeight
        }
        voltages {
          id
          mountingOptions {
            id
            fullHeight
            tubeLength
          }
        }
        mountingOptionAdders {
          id
        }
        heaterData @include(if: $isHeater) {
          id
          boxHeightA
          boxWidthB
          boxDepthF
        }
      }
    }
  }
`)
