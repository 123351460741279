import * as THREE from 'three'
import memoize from 'lodash-es/memoize'
import { type GLTF, GLTFLoader } from 'three/examples/jsm/Addons.js'
import DETAILED_OBSTRUCTIONS from 'config/detailedObstructions'

export const assetCache: Map<string, Promise<GLTF>> = new Map()

export function getMeshAsset(assetPath: string) {
  const asset = assetCache.get(assetPath)
  if (!asset) {
    const loadingAsset = new GLTFLoader().loadAsync(assetPath)
    assetCache.set(assetPath, loadingAsset)
    return loadingAsset
  } else {
    return asset
  }
}

export async function _buildObstructionMesh({ model, root }: { model: string; root: THREE.Object3D }) {
  try {
    // Load gltf asset
    const gltf = await getMeshAsset(`/gltf/${model}.gltf`)

    // Get meshes from model
    let modelMeshes = getModelMeshes(model, gltf).filter(
      mesh => mesh.type !== 'Group'
    )

    // Get parts from mesh
    const parts = {} as Partial<Record<string, THREE.Mesh | THREE.Group>>
    modelMeshes.forEach(mesh => {
      parts[mesh.name] = mesh.clone() as THREE.Mesh | THREE.Group
    })

    // Combine parts with root node
    Object.keys(parts).forEach(function(key) {
      root.add(parts[key]!.clone())
    })

    scaleObstructionBasedOnModel(model, root)

    return gltf
  } catch (err) {
    throw err
  }
}

function scaleObstructionBasedOnModel(model: string, root: THREE.Object3D) {
  const { scale, rotation } =
    DETAILED_OBSTRUCTIONS.find(obs => obs.obstructionType === model) || {}
  if (scale) {
    root.scale.set(scale.x, scale.y, scale.z)
  }
  if (rotation) {
    root.rotation.set(rotation.x, rotation.y, rotation.z)
  }
}

if (window.Map) {
  memoize.Cache = window.Map
}

export const buildObstructionMesh = memoize(_buildObstructionMesh, (...args: any) =>
  JSON.stringify(args)
)

function getModelMeshes(model: string, gltf: GLTF) {
  let meshes: THREE.Object3D[] = []
  let meshNames = []
  model = model.split('.').join('')

  gltf.scene.traverse(e => {
    if (e instanceof THREE.Mesh || e instanceof THREE.Group) {
      e.name = e.name.split('_').join(' ')
      e.name = e.name.split('.').join('')

      if ('material' in e && e.material && e.material instanceof THREE.MeshStandardMaterial) {
        e.material = e.material.clone() as THREE.MeshStandardMaterial
        if (!(e.material instanceof THREE.MeshStandardMaterial))
          throw new Error("invalid material after cloning")
        if (e.material.normalScale) {
          e.material.normalScale = new THREE.Vector2(1, 1)
        }
        if (e.material.map) e.material.map.needsUpdate = true
        e.material.needsUpdate = true
      }

      if ('geometry' in e && e.geometry) e.castShadow = true
      meshes.push(e)
      meshNames.push(e.name)
    }
  })

  const modelMeshes = meshes.slice(0).filter(e => e.name.indexOf(model) === 0)

  return modelMeshes
}
