import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '~/store'
import { useAppSelector } from '~/store/hooks'
import { Product } from '~/store/objects/types'

const selectSelectedProducts = createSelector(
  [
    (state: RootState) => state.objects.present.products,
    (state: RootState) => state.selectedObjects,
  ],
  (products, selectedObjects) =>
    selectedObjects.map(({ id }) => products[id]).filter((product): product is Product => !!product)
)

export const useSelectedProducts = () => useAppSelector(selectSelectedProducts)
