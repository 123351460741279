import { useTexture } from '@react-three/drei'
import { MeshBasicMaterialProps, MeshProps, MeshStandardMaterialProps } from '@react-three/fiber'
import { ReactNode, Suspense, useEffect, useMemo } from 'react'
import { DoubleSide, Texture, Vector2 } from 'three'
import { ERROR_COLOR } from '~/components/DrawingCanvas/Products/constants'
import LayerKeys from '~/config/layerKeys'

const DEFAULT_SEGMENTS = 32
const CYLINDER_SIDES = [...Array(3).keys()]
const CYLINDER_TOP_SIDE = 0
const CYLINDER_SCALE_OFFSET = 2.5
const BOX_SIDES = [...Array(8).keys()]
const BOX_SCALE_OFFSET = 10
const BOX_TOP_SIDE = 2

type CylinderProps = {
  radius: number
  height: number
  segments?: number
  width?: never
  depth?: never
}

type BoxProps = {
  width: number
  depth: number
  height: number
  segments?: number
  radius?: never
}

type ClearanceMeshProps = (CylinderProps | BoxProps) & {
  isError: boolean
  isSelected: boolean
} & MeshProps

const CylinderMesh = (props: { args: CylinderProps; isSelected: boolean; isError: boolean }) => {
  const { args, isError, isSelected } = props
  const { height, radius, segments } = args
  const color = isError ? ERROR_COLOR : 'grey'
  const errorColorOpacity = isSelected ? 0.5 : 0.35
  const errorTextureOpacity = isSelected ? 0.9 : 0.65
  const baseMaterialProps: MeshStandardMaterialProps = {
    transparent: true,
    color,
    opacity: 0.75,
    depthWrite: false,
  }
  return (
    <>
      <cylinderGeometry args={[radius, radius, height, segments]} />
      <Suspense fallback={<meshStandardMaterial {...baseMaterialProps} />}>
        {isError ? (
          <ErrorTexture>
            {texture =>
              CYLINDER_SIDES.map(i => {
                const props: MeshBasicMaterialProps =
                  i === CYLINDER_TOP_SIDE
                    ? { color, opacity: errorColorOpacity }
                    : { map: texture, toneMapped: false, opacity: errorTextureOpacity }
                return <meshBasicMaterial key={i} transparent attach={`material-${i}`} {...props} />
              })
            }
          </ErrorTexture>
        ) : (
          <meshStandardMaterial {...baseMaterialProps} />
        )}
      </Suspense>
    </>
  )
}

const BoxMesh = (props: { args: BoxProps; isSelected: boolean; isError: boolean }) => {
  const { args, isError, isSelected } = props
  const { width, depth, height, segments } = args
  const color = isError ? ERROR_COLOR : 'grey'
  const errorColorOpacity = isSelected ? 0.5 : 0.35
  const errorTextureOpacity = isSelected ? 0.9 : 0.65
  const baseMaterialProps: MeshStandardMaterialProps = {
    transparent: true,
    color,
    opacity: 0.75,
    depthWrite: false,
  }
  return (
    <>
      <boxGeometry args={[width, height, depth, segments]} />
      <Suspense fallback={<meshStandardMaterial {...baseMaterialProps} />}>
        {isError ? (
          <ErrorTexture width={width} length={depth}>
            {texture =>
              BOX_SIDES.map((_, i) => {
                const props: MeshBasicMaterialProps =
                  i === BOX_TOP_SIDE
                    ? { map: texture, toneMapped: false, opacity: errorTextureOpacity }
                    : { color: ERROR_COLOR, opacity: errorColorOpacity, side: DoubleSide }
                return <meshBasicMaterial key={i} transparent attach={`material-${i}`} {...props} />
              })
            }
          </ErrorTexture>
        ) : (
          <meshStandardMaterial {...baseMaterialProps} />
        )}
      </Suspense>
    </>
  )
}

export const ClearanceMesh = ({
  radius,
  height,
  width,
  depth,
  isError,
  isSelected,
  segments = DEFAULT_SEGMENTS,
  ...meshProps
}: ClearanceMeshProps) => {
  const isCylinder = radius !== undefined
  return (
    // TODO: remove userData after migrating all functionality to R3F. Only used to filter out interactions.
    <mesh {...meshProps} visible={isSelected || isError} userData={{ layer: LayerKeys.PRODUCTS }}>
      {isCylinder ? (
        <CylinderMesh args={{ radius, height }} {...{ isSelected, isError }} />
      ) : (
        <BoxMesh args={{ width, height, depth, segments }} {...{ isSelected, isError }} />
      )}
    </mesh>
  )
}

const ErrorTexture = ({
  children,
  length,
  width,
}: {
  children: (texture: Texture) => ReactNode
} & ({ width: number; length: number } | { width?: never; length?: never })) => {
  const texture = useTexture(new URL(`/src/assets/textures/error.png`, import.meta.url).href)
  const textureClone = useMemo(() => {
    const clone = texture.clone()
    clone.center = new Vector2(0.5, 0.5)
    if (width && length) {
      clone.rotation = -Math.PI / 2
      clone.repeat = new Vector2(length / BOX_SCALE_OFFSET, width / BOX_SCALE_OFFSET)
    } else {
      clone.repeat = new Vector2(CYLINDER_SCALE_OFFSET, CYLINDER_SCALE_OFFSET)
    }
    return clone
  }, [texture, width, length])

  useEffect(() => () => textureClone.dispose(), [textureClone])

  return children(textureClone)
}
