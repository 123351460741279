import camelCase from 'lodash-es/camelCase'
import compact from 'lodash-es/compact'
import find from 'lodash-es/find'
import flatMapDeep from 'lodash-es/flatMapDeep'
import flattenDeep from 'lodash-es/flattenDeep'
import get from 'lodash-es/get'
import has from 'lodash-es/has'
import isEmpty from 'lodash-es/isEmpty'
import isEqual from 'lodash-es/isEqual'
import _isFinite from 'lodash-es/isFinite'
import map from 'lodash-es/map'
import sample from 'lodash-es/sample'
import upperFirst from 'lodash-es/upperFirst'
import ApolloClient, { fragments } from '~/client'
import store from 'store'
import CLASS_NAMES from 'config/objectClassNames'
import Util from 'components/DrawingCanvas/lib/util'
import ObstructionUtil from 'components/DrawingCanvas/lib/obstructionUtil'
import Facility from 'components/DrawingCanvas/lib/facility'
import Units from 'components/DrawingCanvas/lib/units'
import theme from 'config/theme'

import * as THREE from 'three'
import { graphql } from '~/gql'

export const COPYABLE_CLASS_NAMES = [
  CLASS_NAMES.OBSTRUCTION,
  CLASS_NAMES.PRODUCT,
  CLASS_NAMES.DOOR,
  CLASS_NAMES.UTILITY_BOX,
  CLASS_NAMES.COMFORT_ZONE,
  CLASS_NAMES.DIMENSION,
  CLASS_NAMES.GRID_BOX,
]

export function utilityBoxes(state) {
  return get(state, 'objects.present.utilityBoxes')
}

export function objectClassNameFromStateKey(stateKey) {
  switch (stateKey) {
    case 'objects': {
      return CLASS_NAMES.WALL
    }
    case 'segments': {
      return CLASS_NAMES.WALL_SEGMENT
    }
    case 'utilityBoxes': {
      return CLASS_NAMES.UTILITY_BOX
    }
    case 'airflow': {
      return CLASS_NAMES.AIRFLOW
    }
    case 'heatMap': {
      return CLASS_NAMES.HEAT_MAP
    }
    default: {
      return upperFirst(camelCase(stateKey)).replace(/s$/, '')
    }
  }
}

export function objectStateKeyFromClassName(className) {
  switch (className) {
    case CLASS_NAMES.WALL: {
      return 'objects'
    }
    case CLASS_NAMES.WALL_SEGMENT: {
      return 'segments'
    }
    case CLASS_NAMES.UTILITY_BOX: {
      return 'utilityBoxes'
    }
    case CLASS_NAMES.AIRFLOW: {
      return 'airflow'
    }
    case CLASS_NAMES.GRID_BOX: {
      return 'gridBox'
    }
    case CLASS_NAMES.HEAT_MAP: {
      return 'heatMap'
    }
    default: {
      return `${camelCase(className)}s`
    }
  }
}

export function getObjectLibrary(state) {
  const present = get(state, 'objects.present')

  const library = flatMapDeep(present, (obj, stateKey) =>
    map(
      obj,
      objProps =>
        has(objProps, 'id') && {
          ...objProps,
          className: objectClassNameFromStateKey(stateKey), // TODO: do we need this?
        }
    )
  )
  return compact(library)
}

export function getObjectImageIds() {
  const objects = get(store.getState(), 'objects.present')

  // Get all of the object keys
  const ids = Object.keys(objects)
    // Filter out any empty objects
    .filter(fo => !isEmpty(objects[fo]))
    // Go through each object
    .map(object => {
      // Go through each nested object
      return Object.keys(objects[object]).map(no => {
        // If there is a nested object *AND* it's an object
        if (objects[object][no] && typeof objects[object][no] === 'object') {
          // If the object has the `metadata.images` key
          if (get(objects[object][no], 'metadata.images')) {
            // Return it
            return get(objects[object][no], 'metadata.images').map(
              image => image.cloudinaryId
            )
          }
        }

        // Otherwise, return `null`
        return null
      })
    })

  return compact(flattenDeep(ids))
}

export function findObject(predicate, { state = store.getState() } = {}) {
  const objectLibrary = getObjectLibrary(state)
  const returnObject = find(objectLibrary, predicate)
  return returnObject
}

// Searches apollo cache
function findProductVariation(variationId) {
  return ApolloClient.readFragment({
    id: `ProductVariation:${variationId}`,
    fragment: graphql(`
      fragment DuplicateVariationFragment on ProductVariation {
        id
        size
        minProductClearance
      }
    `)
  })
}

export function identifyNextDuplicateOffset(
  object,
  stateKey,
  state = store.getState(),
  attempt = 1,
  prevOffset
) {
  const objects = get(state, `objects.present.${stateKey}`)
  const isEvenAttempt = attempt % 2 === 0
  let multiplier = isEvenAttempt ? attempt / 2 : attempt - (attempt - 1) / 2

  const defaultOffset = 50
  const offset = { x: 0, y: 0 }
  let xDiff
  let yDiff
  switch (stateKey) {
    case 'products':
      const variation = findProductVariation(object.variationId)
      offset.x = isEvenAttempt
        ? 0
        : (variation.size + (variation.minProductClearance || 0) / 2) * multiplier
      offset.y = isEvenAttempt
        ? (variation.size + (variation.minProductClearance || 0) / 2) * multiplier
        : 0
      break
    case 'obstructions':
    case 'comfortZones':
      const positions = ObstructionUtil.getRotatedPositions(
        object.positions,
        object.position,
        object.rotation && object.rotation.z
      )
      ;({ xDiff, yDiff } = ObstructionUtil.getPositionDiffs(positions))
      offset.x = isEvenAttempt ? 0 : xDiff * multiplier
      offset.y = isEvenAttempt ? yDiff * multiplier : 0
      break
    case 'doors':
      const rotation = object.rotation
      const doorBuffer = 10
      multiplier = multiplier * (isEvenAttempt ? -1 : 1)
      offset.x =
        multiplier * Math.cos(rotation._z) * (object.width + doorBuffer)
      offset.y =
        multiplier * Math.sin(rotation._z) * (object.width + doorBuffer)
      break
    case 'utilityBoxes':
      const svRotation = object.rotation
      const utilityBoxBuffer = 10
      multiplier = multiplier * (isEvenAttempt ? -1 : 1)
      offset.x =
        multiplier * Math.cos(svRotation._z) * (object.width + utilityBoxBuffer)
      offset.y =
        multiplier * Math.sin(svRotation._z) * (object.width + utilityBoxBuffer)
      break
    case 'dimensions':
      xDiff = Math.abs(object.startPos.x - object.endPos.x)
      yDiff = Math.abs(object.startPos.y - object.endPos.y)

      if (xDiff > yDiff) {
        offset.y = defaultOffset * attempt
      } else {
        offset.x = defaultOffset * attempt
      }
      break
    default:
      offset.x = isEvenAttempt ? 0 : defaultOffset * multiplier
      offset.y = isEvenAttempt ? defaultOffset * multiplier : 0
      break
  }

  let nextOffsetAlreadyExists = false
  if (stateKey === 'dimensions') {
    const startPos = Object.assign({}, object.startPos)
    const endPos = Object.assign({}, object.endPos)
    startPos.x = startPos.x + offset.x
    endPos.x = endPos.x + offset.x
    startPos.y = startPos.y + offset.y
    endPos.y = endPos.y + offset.y

    nextOffsetAlreadyExists = find(
      objects,
      o => isEqual(o.startPos, startPos) && isEqual(o.endPos, endPos)
    )
  } else {
    const newPosition = { ...object.position }
    newPosition.x = object.position.x + offset.x
    newPosition.y = object.position.y + offset.y

    nextOffsetAlreadyExists = find(objects, o =>
      isEqual(o.position, newPosition)
    )
  }
  // Error on infinite loops and bad values
  if (
    isEqual(prevOffset, offset) ||
    !_isFinite(offset.x) ||
    !_isFinite(offset.y)
  )
    throw new Error('Logic Error! Offset is resolving to the same result')
  if (nextOffsetAlreadyExists)
    return identifyNextDuplicateOffset(
      object,
      stateKey,
      state,
      attempt + 1,
      offset
    )

  return offset
}

export function getObjectsForDuplication(objects) {
  // Get offset of all combined objects
  const facilityObjects = objects.map(obj =>
    Facility.current.findObjectWithId(obj.id)
  )
  const container = new THREE.Object3D()
  facilityObjects.forEach(obj => container.add(obj.obj3d.clone()))
  const box = new THREE.Box3().setFromObject(container)
  const offset = Units.nativeToInches(box.getSize(new THREE.Vector3()).x) + 1

  // Create duplicate objects and update their position
  const newObjects = []
  const state = store.getState().objects.present
  objects.forEach(obj => {
    const stateKey = objectStateKeyFromClassName(obj.className)
    const stateObject = state[stateKey][obj.id]

    if (stateObject) {
      const newObject = Object.assign({}, stateObject)
      newObject.id = Util.guid()
      newObject.className = obj.className
      if (stateObject.position) {
        const newPos = {
          x: stateObject.position.x + offset,
          y: stateObject.position.y,
          z: stateObject.position.z,
        }
        newObject.position = { ...newPos }
      }
      if (stateObject.positions) {
        newObject.positions = stateObject.positions.map(pos => ({
          x: pos.x + offset,
          y: pos.y,
          z: pos.z,
        }))
      }

      newObjects.push(newObject)
    }
  })

  return newObjects
}

export function getObjectForDuplication(object, offset) {
  const state = store.getState().objects.present
  const stateKey = objectStateKeyFromClassName(object.className)
  const stateObject = state[stateKey][object.id]

  if (!stateObject) return

  const newObject = Object.assign({}, stateObject)

  switch (object.className) {
    case CLASS_NAMES.COMFORT_ZONE:
      newObject.color = sample(theme.colors.swatches)
      break
    default:
      break
  }

  if (!offset) {
    offset = identifyNextDuplicateOffset(stateObject, stateKey)
  }

  // Offset the new object so it's not on top of the original
  if (stateObject.startPos) {
    const newPos = {
      x: stateObject.startPos.x + offset.x,
      y: stateObject.startPos.y + offset.y,
      z: stateObject.startPos.z,
    }
    newObject.startPos = { ...newPos }
  }
  if (stateObject.endPos) {
    const newPos = {
      x: stateObject.endPos.x + offset.x,
      y: stateObject.endPos.y + offset.y,
      z: stateObject.endPos.z,
    }
    newObject.endPos = { ...newPos }
  }
  if (stateObject.position) {
    const newPos = {
      x: stateObject.position.x + offset.x,
      y: stateObject.position.y + offset.y,
      z: stateObject.position.z,
    }
    newObject.position = { ...newPos }
  }
  if (stateObject.positions) {
    newObject.positions = stateObject.positions.map(pos => ({
      x: pos.x + offset.x,
      y: pos.y + offset.y,
      z: pos.z,
    }))
  }

  newObject.id = Util.guid()

  return newObject
}

export function getCeilings() {
  return store.getState().objects.present.ceilings
}

export function getRoofs() {
  return store.getState().objects.present.roofs
}
