import { getThreeHexFromTheme } from "~/lib/utils"
import { SnapData } from "./snaplines"
import { Line } from "@react-three/drei"

export function SnapLine(props: { snapPoint: SnapData; zOverride?: number }) {
  const { objectLine } = props.snapPoint
  const p1 = objectLine.start.clone().setZ(props.zOverride ?? objectLine.start.z)
  const p2 = objectLine.end.clone().setZ(props.zOverride ?? objectLine.end.z)
  return (
    <Line color={getThreeHexFromTheme('three.valid')} linewidth={2} points={[p1, p2]}/>
  )
}
