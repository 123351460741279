import Util from '~/components/DrawingCanvas/lib/util'
import { Vector3, Matrix3, PlaneGeometry, Mesh, MeshBasicMaterial, MathUtils } from 'three'
import { SIMULATION_TYPES } from '~/config/cfd'
import { PRODUCT_CATEGORIES, PRODUCT_TYPES } from '~/config/product'
import { DEFAULT_COOLING_FAN_SPEED, DEFAULT_DESTRAT_FAN_SPEED } from '~/lib/airflow/airflow'
import { Ceiling, Product } from '~/store/objects/types'
import { modelToUI, vectorModelToUI } from '~/components/DrawingCanvas/util/units'
import client from '~/client'
import { graphql } from '~/gql'
import { isTiltingDirectional } from '~/components/DrawingCanvas/Products/modelNames'

const { EVAP, FAN, HEAT } = PRODUCT_CATEGORIES
const { DIRECTIONAL, OVERHEAD } = PRODUCT_TYPES

const getProductSimulationTypes = ({ category, type }: { category: string; type: string }) => {
  const simulationTypes = new Set<keyof typeof SIMULATION_TYPES>()

  const isOverheadFan = type === OVERHEAD && category === FAN
  const isDirectionalFan = type === DIRECTIONAL && category === FAN
  const isUnitHeater = type === DIRECTIONAL && category === HEAT

  const isDestrat = isOverheadFan
  const isCooling = isDirectionalFan || isOverheadFan || category === EVAP
  const isRadiant = type === OVERHEAD && category === HEAT
  const isUnitHeat = isUnitHeater || isDirectionalFan || isOverheadFan

  if (isDestrat) {
    simulationTypes.add(SIMULATION_TYPES.destrat)
    simulationTypes.add(SIMULATION_TYPES.cooling)
  }
  if (isCooling) simulationTypes.add(SIMULATION_TYPES.cooling)
  if (isRadiant) simulationTypes.add(SIMULATION_TYPES.radiantHeat)
  if (isUnitHeat) simulationTypes.add(SIMULATION_TYPES.unitHeating)

  return simulationTypes
}

export type FormattedProducts = Awaited<ReturnType<typeof formatProducts>>

export const formatProducts = async (
  storeProducts: Record<string, Product>,
  storeCeilings: Record<string, Ceiling>
) => {
  const ceilings = Object.values(storeCeilings).filter(ceiling => ceiling.enabled)
  return await Promise.all(
    Object.values(storeProducts).map(async product => {
      const { data } = await client.query({
        query: graphql(`
          query VariationDataForFormatProducts($variationId: ID!) {
            ProductVariation(id: $variationId) {
              id
              size
              cfdId
              product {
                id
                type
                model
                category
              }
            }
          }
        `),
        variables: { variationId: product.variationId },
      })
      const {
        cfdId,
        size,
        product: { category, model, type },
      } = data.ProductVariation
      const position = new Vector3().copy(product.position)
      // Make sure fan is at least two feet under the ceiling
      const fanCeilingHeight = (() => {
        const ceil = ceilings.find(({ perimeterPoints }) =>
          Util.isPointInPolygon(position, perimeterPoints)
        )
        return ceil ? modelToUI(ceil.height) : undefined
      })()
      const twoFeet = 24
      const ceilingClearance = fanCeilingHeight ? fanCeilingHeight - position.z : -Infinity
      const isUnderCeiling = ceilingClearance > 0
      const isUnderTwoFeetClearance = ceilingClearance < twoFeet
      if (isUnderCeiling && isUnderTwoFeetClearance) {
        position.z = fanCeilingHeight! - twoFeet
      }
      const productHeight = position.z

      const fanPlaceholderMesh = new Mesh(
        new PlaneGeometry(10, 10),
        new MeshBasicMaterial({ color: 0x00ff00 })
      )
      fanPlaceholderMesh.position.copy(position)
      // TODO: uncomment after refactoring ConfigureCFDForm.js. The current uploadToCFD function incorrectly tries to apply rotation to a non-euler object
      // fanPlaceholderMesh.rotation.copy(facilityProduct.obj3d.rotation)

      // Edge case: only Pivot 2 has a tilt group based on being overhead
      const isPivot2 = /pivot.*2/gi.exec(model)
      if (type === 'DIRECTIONAL') {
        if (isPivot2 && product.isDirectionalOverhead) {
          // TODO: This undoes the extra rotation assigned in meshLoader. Handle the additional rotation in the store instead
          fanPlaceholderMesh.rotateX((Math.PI * 3) / 2)
        } else {
          fanPlaceholderMesh.rotateX((-90 * Math.PI) / 180)
        }
      } else {
        fanPlaceholderMesh.rotateX((-180 * Math.PI) / 180)
      }
      // Handle product rotation
      if (type === 'DIRECTIONAL') {
        if (isPivot2 && product.isDirectionalOverhead) {
          fanPlaceholderMesh.rotateY(-product.rotation.z * (Math.PI / 180))
        } else {
          fanPlaceholderMesh.rotateY(((360 - product.rotation.z) * Math.PI) / 180)
        }
      }
      // Handle product tilt
      if (type === 'DIRECTIONAL' && category === 'FAN' && isTiltingDirectional(model)) {
        fanPlaceholderMesh.rotateX(MathUtils.degToRad(product.rotation.x - 90))
      }
      fanPlaceholderMesh.updateMatrixWorld(true)
      const normalMatrix = new Matrix3().getNormalMatrix(fanPlaceholderMesh.matrixWorld)

      const fanMeshNormal = fanPlaceholderMesh?.geometry?.getAttribute('normal')?.array
      if (!fanMeshNormal) throw new Error('Failed to rotate fan during export!')
      const worldNormal = new Vector3(fanMeshNormal[0], fanMeshNormal[1], fanMeshNormal[2])
        .applyMatrix3(normalMatrix)
        .normalize()
      const productNormal = {
        nx: worldNormal.x,
        ny: worldNormal.y,
        nz: worldNormal.z,
      }
      const oneFootPadding = modelToUI(12)
      const positionClone = vectorModelToUI(product.position)
      if (product.isDirectionalOverhead) {
        const isTilted = product.rotation.x !== 0
        if (isTilted) {
          // If the directional overhead is facing perpendicular to
          // the floor put the point 1' in front of the product
          positionClone.y += oneFootPadding
        } else {
          // If the directional overhead is facing down
          // to the floor, put the point 1' below it
          positionClone.z -= oneFootPadding
        }
      } else if (type === 'DIRECTIONAL') {
        // Put point 1' in front of directional products
        positionClone.y += oneFootPadding
      } else {
        // Put point 1' below overhead products
        positionClone.z -= oneFootPadding
      }
      return {
        cfdId,
        simulationTypes: getProductSimulationTypes({ category, type }),
        unscaledPositionData: {
          ...positionClone,
        },
        rotation: product.rotation,
        positionData: {
          ...product.position,
          z: productHeight,
        },
        normals: {
          ...productNormal,
        },
        category,
        size,
        angle: product.angle,
        variationId: product.variationId,
        heightFromFloor: Math.floor(productHeight),
        destratFanSpeed: product.destratFanSpeedId || DEFAULT_DESTRAT_FAN_SPEED,
        coolingFanSpeed: product.coolingFanSpeedId || DEFAULT_COOLING_FAN_SPEED,
      }
    })
  )
}
