// NOTE: The way that this is imported into storybook requires that we
//       do not import anything here.
const HIGH_VELOCITY_COLORS = [
  {
    color: '#ff0000',
    velocity: 0,
  },
  {
    color: '#fa8a8a',
    velocity: 22,
  },
  {
    color: '#ffce00',
    velocity: 44,
  },
  {
    color: '#fefd00',
    velocity: 66,
  },
  {
    color: '#7cf908',
    velocity: 88,
  },
  {
    color: '#01e4c2',
    velocity: 110,
  },
  {
    color: '#00cccc',
    velocity: 132,
  },
  {
    color: '#00b2cc',
    velocity: 154,
  },
  {
    color: '#0099cc',
    velocity: 176,
  },
  {
    color: '#007fcc',
    velocity: 198,
  },
  {
    color: '#0066cc',
    velocity: 220,
  },
  {
    color: '#004ccc',
    velocity: 242,
  },
  {
    color: '#0033cc',
    velocity: 264,
  },
  {
    color: '#0019cc',
    velocity: 286,
  },
  {
    color: '#0000b3',
    velocity: 308,
  },
]

const LOW_VELOCITY_COLORS = [
  {
    color: '#ff0000',
    velocity: 0,
  },
  {
    color: '#fa8a8a',
    velocity: 12,
  },
  {
    color: '#ffce00',
    velocity: 24,
  },
  {
    color: '#fefd00',
    velocity: 36,
  },
  {
    color: '#7cf908',
    velocity: 48,
  },
  {
    color: '#01e4c2',
    velocity: 60,
  },
  {
    color: '#00cccc',
    velocity: 72,
  },
  {
    color: '#00b2cc',
    velocity: 84,
  },
  {
    color: '#0099cc',
    velocity: 96,
  },
  {
    color: '#007fcc',
    velocity: 108,
  },
  {
    color: '#0066cc',
    velocity: 120,
  },
  {
    color: '#004ccc',
    velocity: 132,
  },
  {
    color: '#0033cc',
    velocity: 144,
  },
  {
    color: '#0019cc',
    velocity: 156,
  },
  {
    color: '#0000b3',
    velocity: 168,
  },
]

const VELOCITY_COLORS = [
  {
    color: '#ff0000',
    velocity: 0,
  },
  {
    color: '#ffce00',
    velocity: 21,
  },
  {
    color: '#fefd00',
    velocity: 42,
  },
  {
    color: '#7cf908',
    velocity: 64,
  },
  {
    color: '#01e4c2',
    velocity: 85,
  },
  {
    color: '#00CCCC',
    velocity: 106,
  },
  {
    color: '#00B2CC',
    velocity: 128,
  },
  {
    color: '#0099cc',
    velocity: 149,
  },
  {
    color: '#007FCC',
    velocity: 170,
  },
  {
    color: '#0066cc',
    velocity: 191,
  },
  {
    color: '#004ccc',
    velocity: 212,
  },
  {
    color: '#0033cc',
    velocity: 234,
  },
  {
    color: '#0019cc',
    velocity: 255,
  },
  {
    color: '#0000cc',
    velocity: 276,
  },
  {
    color: '#0000b3',
    velocity: 298,
  },
]

const DRAFT_RISK_VELOCITY_COLORS = [
  {
    color: '#006600',
    velocity: 0,
  },
  {
    color: '#ff0000',
    velocity: 80,
  },
]

const DESTRAT_VELOCITY_COLORS = [
  {
    color: '#ff0000',
    velocity: 0,
  },
  {
    color: '#ff0000',
    velocity: 2,
  },
  {
    color: '#ff0000',
    velocity: 4,
  },
  {
    color: '#fbacac',
    velocity: 6,
  },
  {
    color: '#fbacac',
    velocity: 8,
  },
  {
    color: '#aaaaff',
    velocity: 10,
  },
  {
    color: '#aaaaff',
    velocity: 12,
  },
  {
    color: '#aaaaff',
    velocity: 14,
  },
  {
    color: '#0000ff',
    velocity: 16,
  },
  {
    color: '#0000ff',
    velocity: 18,
  },
]

const HEAT_MAP_COLORS = [
  {
    color: '#daead3',
    power: 20,
  },
  {
    color: '#ecf56a',
    power: 35,
  },
  {
    color: '#ffff00',
    power: 50,
  },
  {
    color: '#ff8000',
    power: 65,
  },
  {
    color: '#ff0000',
    power: 80,
  },
]

const INTENSITY_COLORS = [
  {
    color: '#0000ff',
    intensity: 0,
  },
  {
    color: '#006cff',
    intensity: 1,
  },
  {
    color: '#00d3ff',
    intensity: 2,
  },
  {
    color: '#00ffc8',
    intensity: 3,
  },
  {
    color: '#00ff00',
    intensity: 4,
  },
  {
    color: '#00ff60',
    intensity: 5,
  },
  {
    color: '#6cff00',
    intensity: 6,
  },
  {
    color: '#d1ff00',
    intensity: 7,
  },
  {
    color: '#ffc700',
    intensity: 8,
  },
  {
    color: '#ff5d00',
    intensity: 9,
  },
  {
    color: '#ff0200',
    intensity: 10,
  },
]

const borderWidth = '1px'

const theme = {
  breakpoints: {
    s: '768px',
    m: '1024px',
    l: '1200px',
  },
  // The further to the right, the higher the z-index
  order: [
    'gridLine',
    'step1',
    'step2',
    'step3',
    'floatingElement',
    'metadata',
    'feedbackButton',
    'tools',
    'lock',
    'closeFullscreenButton',
    'loadingOverlay',
    'panelMask',
    'panel',
    'panelToolbar',
    'dropdown',
    'contextMenu',
    'imageIcon',
    'modalOverlay',
    'modal',
    'alert',
  ],
  colors: {
    dark: {
      bg: '#272727',
      fg: '#fff',
      primary: '#ffc527',
      light: '#939393',
      extraLight: '#333',
      subdued: '#444',
    },
    light: {
      bg: '#fff',
      fg: '#272727',
      primary: '#ffc527',
      light: '#939393',
      extraLight: '#f2f2f2',
      subdued: '#cbcbcb',
      error: '#fe5f55',
      success: '#8acb88',
      warning: '#ffc527',
    },
    swatches: [
      '#B80000',
      '#DB3E00',
      '#FCCB00',
      '#008B02',
      '#006B76',
      '#1273DE',
      '#004DCF',
      '#5300EB',
      '#EB9694',
      '#FAD0C3',
      '#FEF3BD',
      '#C1E1C5',
      '#BEDADC',
      '#C4DEF6',
      '#BED3F3',
      '#D4C4FB',
    ],
    airflow: VELOCITY_COLORS,
    lowVelocityAirflow: LOW_VELOCITY_COLORS,
    highVelocityAirflow: HIGH_VELOCITY_COLORS,
    destrat: DESTRAT_VELOCITY_COLORS,
    draftRisk: DRAFT_RISK_VELOCITY_COLORS,
    intensity: INTENSITY_COLORS,
    pmv: {
      cold: '#1372de',
      hot: '#db3d02',
    },
    three: {
      dark: '#000000',
      light: '#ffffff',
      selected: '#d8e3ff',
      valid: '#00ff00',
      validHovered: '#91e882',
      validSelected: '#00ffd4',
      validSelectedHovered: '#85ead9',
      invalid: '#ff0000',
      invalidSelectedProduct: '#f57679',
      invalidSelected: '#ff00ff',
      activeToolCursor: '#ff00ff',
      selectionBox: '#e1ae23',
      selectionBoxOutline: '#ffbf00',
      objects: {
        ceiling: {
          border: '#aaaaff',
        },
        door: {
          border: '#a3a3a3',
          default: '#484848',
          selected: '#a4accc',
          deselected: '#484848',
        },
        elevation: {
          selected: '#ffc626',
          selectedInvalid: '#ff00ff',
        },
        obstruction: {
          selected: '#a4accc',
          deselected: '#a0a0a0',
          swatches: [
            '#ff8000',
            '#FCCB00',
            '#80ff00',
            '#40ff00',
            '#008B02',
            '#006B76',
            '#00bfff',
            '#1273DE',
            '#004DCF',
            '#0000ff',
            '#8000ff',
            '#bf00ff',
            '#ff0080',
            '#EB9694',
            '#C0C0C0',
            '#a0a0a0',
            '#808080',
            '#696969',
            '#353535',
            '#8B6914',
            '#B4946C',
            '#CDAA7D',
            '#DEB887',
            '#FFF8DC',
          ],
        },
        product: {
          directionArrow: '#a4accc',
          selected: '#d4d4ec',
          default: '#808080',
        },
        roof: {
          default: '#4488aa',
        },
        roofSection: {
          default: '#aaaa00',
          beam: {
            default: '#aaaaaa',
          },
          column: {
            default: '#a0a0a0',
          },
        },
        snapRegion: {
          default: '#8fdb8f',
        },
        utilityBox: {
          selected: '#a4accc',
        },
      },
    },
    heat: HEAT_MAP_COLORS,
  },
  pdf: {
    utilityBox: {
      firePanel: '#ff0000',
      electricPanel: '#00ff00',
      controlPanel: '#0000ff',
    },
    door: {
      manDoor: '#000000',
      verticalDoor: '#000000',
      rollbackDoor: '#000000',
    },
  },
  fonts: {
    family: {
      base:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
      heading:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
    },
    lineHeight: {
      base: '1.5',
      heading: '1.2',
    },
    sizes: {
      base: '14px',
      xxs: '8px',
      xs: '10px',
      s: '12px',
      l: '16px',
      xl: '24px',
      h1: '20px',
    },
    weights: {
      light: 200,
      normal: 400,
      semiBold: 600,
      bold: 800,
    },
  },
  radius: {
    base: '3px',
    s: '2px',
    l: '20px',
  },
  shadows: {
    base: '0 2px 3px rgba(0, 0, 0, 0.3)',
    menu: '0 1px 1px 0 rgba(0, 0, 0, 0.3), 0 4px 10px 0px rgba(0, 0, 0, 0.1)',
    menuReversed:
      '0 -1px 1px 0 rgba(0, 0, 0, 0.3), 0 -4px 10px 0px rgba(0, 0, 0, 0.1)',
    subMenu: '0 0 0 0 rgba(0, 0, 0, 0.3), 0 4px 10px 0px rgba(0, 0, 0, 0.1)',
    subMenuBottom: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
  },
  spacing: {
    square: '44px',
    base: '16px',
    xs: '5px',
    s: '10px',
    m: '12px',
    l: '32px',
    xl: '60px',
    xxl: '80px',
  },
  borderWidth,
  dockedPanel: {
    toolbarHeight: '60px',
    width: {
      default: 280,
      s: 200,
    },
  },
  touchUI: { buttonSize: '75px' },
  contextMenu: {
    hoverColor: '#dadce0',
    width: '180px',
  },
}

export type Theme = typeof theme
export type FontSize = keyof Theme['fonts']['sizes']
export type Variant = 'light' | 'dark'
export type Spacing = keyof Theme['spacing']
export type StandardColor = keyof Theme['colors']['light']

export default theme
