import { graphql } from '~/gql'

export const GET_AIR_VELOCITIES_QUERY = graphql(`
  query ProductVariationAirVelocities($id: ID!, $fanSpeedId: String!, $heightFromFloor: Int!) {
    ProductVariation(id: $id) {
      id
      size
      airVelocities {
        id
        variationId
        airfoilHeight
        evaluationHeight
        distanceFromFan
        offsetFromFanCenter
        airVelocity
        angle
      }
      product {
        id
        type
        fanSpeed(speed: $fanSpeedId, heightFromFloor: $heightFromFloor, variationId: $id) {
          id
          speed
          power
          heightFromFloor
        }
      }
    }
  }
`)
