import { dispose, RenderCallback, useFrame, useLoader } from '@react-three/fiber'
import { useEffect, useMemo } from 'react'
import { Object3D, Object3DEventMap } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import LayerKeys from '~/config/layerKeys'

export type TransformNode = (node: Object3D<Object3DEventMap>) => void
export type AnimateNode = (...params: Parameters<RenderCallback>) => TransformNode

export const useProductMesh = (
  model: string,
  transformNode?: TransformNode,
  animateNode?: AnimateNode
) => {
  const gltf = useLoader(
    GLTFLoader,
    new URL(`/src/assets/gltf/products/${model}.glb`, import.meta.url).href
  )

  const { scene, nodes } = useMemo(() => {
    const sceneClone = gltf.scene.clone()
    return {
      scene: sceneClone,
      nodes: Object.keys(gltf.nodes).map(name => sceneClone.getObjectByName(name)!),
    }
  }, [gltf])

  useFrame((...params) => animateNode && nodes.forEach(node => animateNode(...params)(node)))

  const mesh = useMemo(() => {
    if (transformNode) nodes.forEach(transformNode)
    // TODO: remove after migrating all functionality to R3F. Only used to filter out interactions.
    nodes.forEach(node => (node.userData = { layer: LayerKeys.PRODUCTS }))
    return scene
  }, [scene, nodes, transformNode])

  useEffect(() => () => dispose(mesh), [mesh])

  return mesh
}
