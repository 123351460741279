import { Line } from "@react-three/drei"
import { useMemo, useState } from "react"
import { Vector3 } from "three"
import ObjectClassName from "~/config/objectClassNames"
import theme from "~/config/theme"
import { useAppDispatch, useAppSelector } from "~/store/hooks"
import type { ElevationLine } from "~/store/objects/types"
import { selectObjects } from "~/store/selectedObjects"

export function ElevationLines() {
  const elevationLines = useAppSelector(it => it.objects.present.elevationLines)
  const isVisible = useAppSelector(it => it.layers.layers.ELEVATION_LINE.visible)

  return (
    <group visible={isVisible}>
      {Object.entries(elevationLines).map(([key, value]) => <ElevationLine key={key} line={value}/>)}
    </group>
  )
}

function ElevationLine(props: { line: ElevationLine }) {
  const allPoints = useAppSelector(it => it.objects.present.elevationPoints)
  const pointIDs = props.line.elevationPointIds
  const points = useMemo(() => pointIDs.map(id => allPoints[id]), [allPoints, pointIDs])
  const pointVecs = useMemo(() => points.map(pt => new Vector3().copy(pt.position)), [points])
  const dispatch = useAppDispatch()
  const isSelected = useAppSelector(it => it.selectedObjects.some(sel => sel.id === props.line.id))
  const [hovered, setHovered] = useState(false)

  const color =
    isSelected ?
      hovered ? theme.colors.three.validSelectedHovered : theme.colors.three.validSelected :
      hovered ? theme.colors.three.validHovered : theme.colors.three.valid

  return (
    <Line points={pointVecs} lineWidth={4} worldUnits={true} color={color} onPointerEnter={() => setHovered(true)} onPointerLeave={() => setHovered(false)} onClick={(e) => {
      if (!e.object.parent?.visible) return
      dispatch(selectObjects({
        objects: [{ id: props.line.id, className: ObjectClassName.ELEVATION_LINE }]
      }))
    }}/>
  )
}
