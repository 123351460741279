import { useQuery } from '@apollo/client'
import { Box, DragControls, Html } from '@react-three/drei'
import { DragControlsProps } from '@react-three/drei/web/DragControls'
import { captureEvent } from '@sentry/react'
import { Fragment, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react'
import { Box3, Group, Matrix4, Vector3 } from 'three'
import { useCursor } from '~/components/DrawingCanvas/hooks'
import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { LINE_COLOR, ARROW_SIZE } from '~/components/DrawingCanvas/Products/constants'
import { useCardinalArrowConfigs } from '~/components/DrawingCanvas/Products/hooks/useCardinalArrowConfigs'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { getArrowDimensions } from '~/components/DrawingCanvas/Products/util'
import {
  modelToUI,
  scaleModelVectorToUI,
  scaleUIVectorToModel,
  vectorModelToUI,
  vectorUIToModel,
} from '~/components/DrawingCanvas/util/units'
import theme from '~/config/theme'
import { graphql } from '~/gql'
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions'
import { useSelectedProducts } from '~/hooks/useSelectedProducts'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Product } from '~/store/objects/types'
import { setStatus } from '~/store/status'
import { inputStyles } from '~/ui/Field'
import { R3FDimensionInput } from '~/ui/R3FDimensionInput'

declare global {
  interface WindowEventMap {
    multidrag: CustomEvent<{ delta: Vector3 }>
  }
}

const SELECTION_BOX_COLOR = theme.colors.three.selectionBox

const isOverhead = (product: Product) => {
  return product.isDirectionalOverhead || product.layerKey === 'PRODUCTS_OVERHEAD'
}

const useAdjustHeight = (selectedProducts: Product[]) => {
  const getFanHeight = useGetFanHeightInches()
  const overheadProducts = selectedProducts.filter(isOverhead)
  const overheadVariationIds = Array.from(new Set(overheadProducts.map(p => p.variationId)))
  const isSelectedSomeOverhead = overheadVariationIds.length > 0
  const { data } = useQuery(
    graphql(`
      query MultiDragFullHeights($variationIds: [ID!]!) {
        ProductVariations(ids: $variationIds) {
          id
          voltages {
            id
            mountingOptions {
              id
              fullHeight
            }
          }
        }
      }
    `),
    {
      variables: { variationIds: overheadVariationIds },
      skip: !isSelectedSomeOverhead,
      onError: error => captureEvent(error),
    }
  )
  if (!data) return
  const voltage = new Map(data.ProductVariations.map(({ id, voltages }) => [id, voltages]))
  return (product: Product, newPosition: Vector3) => {
    if (!isOverhead(product)) return
    const { variationId, voltageId, mountingOptionId } = product
    const mountingOptions = voltage.get(variationId)?.find(v => v.id === voltageId)?.mountingOptions
    const fullHeight = mountingOptions?.find(m => m.id === mountingOptionId)?.fullHeight ?? 0
    const newHeight = getFanHeight({ origin: newPosition, fullHeight })
    newPosition.setZ(newHeight)
  }
}

export const MultiProductDragControls = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const cursorHandlers = useCursor()
  const dragMatrix = useRef(new Matrix4())
  const deltaAccumulator = useRef(new Vector3())
  const dragStartMatrix = useRef(new Matrix4())
  const [isDragging, setIsDragging] = useState(false)
  const { displayFormattedValue } = useDistanceDimensions()
  const arrowConfigs = useCardinalArrowConfigs()
  const delta = new Vector3()
  const midpoint = new Vector3()
  const arrowOrigin = new Vector3()

  const products = useAppSelector(state => state.objects.present.products)
  const ignoring = new Set(Object.keys(products))
  const selectedProducts = useSelectedProducts()
  const adjustHeight = useAdjustHeight(selectedProducts)
  const isMultipleProductsSelected = selectedProducts.length > 1
  const selectedProductsMeshGroup = selectedProducts.reduce((group, product) => {
    const mesh = productDistanceEngine.productMeshes.get(product.id)
    if (mesh) group.add(mesh.clone())
    return group
  }, new Group())
  const box = new Box3().setFromObject(selectedProductsMeshGroup)
  const position = box.getCenter(new Vector3())
  const dimensions = new Vector3().subVectors(box.max, box.min)
  box.setFromCenterAndSize(new Vector3(), vectorModelToUI(dimensions))

  useLayoutEffect(() => {
    dragMatrix.current.setPosition(vectorModelToUI(position))
  }, [position.x, position.y, position.z])

  const handleDragStart = () => {
    setIsDragging(true)
    dragStartMatrix.current.copy(dragMatrix.current)
  }

  const handleDrag: DragControlsProps['onDrag'] = (local, deltaLocal) => {
    delta.setFromMatrixPosition(deltaLocal)
    deltaAccumulator.current.add(delta)
    window.dispatchEvent(
      new CustomEvent<{ delta: Vector3 }>('multidrag', {
        detail: { delta },
      })
    )
    arrowConfigs.forEach(({ arrowHelper, direction, htmlMesh, div }) => {
      arrowOrigin.setFromMatrixPosition(local)
      scaleUIVectorToModel(arrowOrigin)
      const { length: distance, offset } = getArrowDimensions({
        position: arrowOrigin,
        direction,
        boundingBoxSize: dimensions,
        ignoring,
      })
      if (!distance) return
      const arrowLength = modelToUI(distance)
      arrowHelper.current?.setLength(arrowLength, ARROW_SIZE, ARROW_SIZE)
      scaleModelVectorToUI(offset)
      midpoint.copy(direction).multiplyScalar(arrowLength / 2 + Math.abs(offset.x + offset.y))
      htmlMesh.current?.position.copy(midpoint)
      if (div.current) div.current.textContent = displayFormattedValue(distance)
    })
  }

  const handleDragEnd = () => {
    setIsDragging(false)
    const updatedProducts = selectedProducts.map(product => {
      const { x, y, z } = product.position
      const delta = vectorUIToModel(deltaAccumulator.current)
      const newPosition = new Vector3(x, y, z).add(delta)
      adjustHeight?.(product, newPosition)
      return {
        ...product,
        position: { x: newPosition.x, y: newPosition.y, z: newPosition.z },
      }
    })
    const isValid = updatedProducts.every(({ position }) =>
      productDistanceEngine.isWithinFacility(position)
    )
    if (!isValid) {
      dragMatrix.current.copy(dragStartMatrix.current)
      window.dispatchEvent(
        new CustomEvent<{ delta: Vector3 }>('multidrag', {
          detail: { delta: deltaAccumulator.current.negate() },
        })
      )
      dispatch(setStatus({ type: 'error', text: 'Product must be placed within facility bounds!' }))
    } else {
      dispatch(updateProducts(updatedProducts))
    }
    deltaAccumulator.current.set(0, 0, 0)
  }

  const handleCommit = ({ distance, direction }: { distance: number; direction: Vector3 }) => (
    newValue: number
  ) => {
    const distanceDelta = distance - newValue
    const scaledDirection = new Vector3().copy(direction).multiplyScalar(distanceDelta)
    const updatedProducts = selectedProducts.map(product => {
      const { x, y, z } = product.position
      const newPosition = new Vector3(x, y, z).add(scaledDirection)
      adjustHeight?.(product, newPosition)
      return { ...product, position: { x: newPosition.x, y: newPosition.y, z: newPosition.z } }
    })
    dispatch(updateProducts(updatedProducts))
  }

  return (
    <>
      <DragControls
        matrix={dragMatrix.current}
        axisLock="z"
        {...cursorHandlers}
        onDrag={handleDrag}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        dragConfig={{ enabled: isMultipleProductsSelected && !isLocked }}
      >
        <group visible={isMultipleProductsSelected}>
          {arrowConfigs.map(({ arrowHelper, direction, div, htmlMesh }, i) => {
            const { length: distance, offset } = getArrowDimensions({
              position,
              direction,
              boundingBoxSize: dimensions,
              ignoring,
            })
            if (!distance) return null
            const origin = vectorModelToUI(offset)
            const htmlPosition = new Vector3().copy(direction).multiplyScalar(distance / 2)
            htmlPosition.add(offset)
            scaleModelVectorToUI(htmlPosition)
            return (
              <Fragment key={i}>
                <mesh ref={htmlMesh} position={htmlPosition}>
                  {isDragging ? (
                    <Html center ref={div} className={inputStyles({ isOnCanvas: true })}></Html>
                  ) : (
                    isMultipleProductsSelected && (
                      <R3FDimensionInput
                        center
                        aria-label={`Product distance guideline ${i}`}
                        value={distance}
                        onCommit={handleCommit({ distance, direction })}
                      />
                    )
                  )}
                </mesh>
                <arrowHelper
                  ref={arrowHelper}
                  args={[
                    direction,
                    origin,
                    modelToUI(distance),
                    LINE_COLOR,
                    ARROW_SIZE,
                    ARROW_SIZE,
                  ]}
                />
              </Fragment>
            )
          })}
          <box3Helper args={[box, SELECTION_BOX_COLOR]} />
          <Box args={[modelToUI(dimensions.x), modelToUI(dimensions.y), modelToUI(dimensions.z)]}>
            <meshStandardMaterial
              color={SELECTION_BOX_COLOR}
              transparent
              opacity={0.25}
              depthWrite={false}
            />
          </Box>
        </group>
      </DragControls>
      {children}
    </>
  )
}
