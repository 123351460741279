import { useQuery } from '@apollo/client'
import { captureEvent } from '@sentry/react'
import { Key } from 'react-aria-components'
import { GET_TUBE_LENGTHS_ON_VARIATION } from '~/client/queries'
import { useGetFanHeightInches } from '~/components/DrawingCanvas/Products/hooks/useGetFanHeightInches'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useFormatLength } from '~/hooks/useFormatLength'
import { showAlert } from '~/store/alert'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'

type TubeLengthMap = Map<string, { label: string; length: number; fullHeight: number }>

export const ExtensionTubeSelect = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const {
    selectedProducts,
    productType,
    isDirectionalOverhead,
    isForcedWallMount,
    isHeater,
    variationId,
    voltageId,
  } = useSelectedProductContext()
  const { data } = useQuery(GET_TUBE_LENGTHS_ON_VARIATION, {
    variables: { variationId: variationId ?? '' },
    skip: !variationId,
  })
  const formatTubeLengthLabel = useFormatLength()
  const getFanHeightInches = useGetFanHeightInches()
  
  const mountingOptionIds = Array.from(
    new Set(selectedProducts.map(({ product }) => product.mountingOptionId))
  ).filter((id): id is string => id != null)
  const selectedMountingOptionId = mountingOptionIds.length === 1 ? mountingOptionIds[0] : null

  const isOverhead = productType === 'PRODUCTS_OVERHEAD'
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isDisabled = isLocked || !voltageId
  const tubeLengths = Array.from(
    data?.ProductVariation.voltages
      .find(v => v.id === voltageId)
      ?.mountingOptions?.reduce<TubeLengthMap>((options, current) => {
        const { tubeLength, fullHeight } = current
        if (tubeLength == null || fullHeight == null || options.has(current.id)) return options
        const isValidOverheadOption = isOverhead && current.forOverhead
        const isValidDirectionalOption =
          isDirectional &&
          ((isForcedWallMount && current.forWall) || (isDirectionalOverhead && current.forOverhead))
        if (isValidDirectionalOption || isValidOverheadOption) {
          const label = tubeLength === 0 ? 'No tube' : formatTubeLengthLabel(tubeLength)
          options.set(current.id, { label: current.label ?? label, length: tubeLength, fullHeight })
        }
        return options
      }, new Map()) ?? (new Map() as TubeLengthMap)
  )
    .map(([tubeLength, { label, length, fullHeight }]) => ({ id: tubeLength, label, length, fullHeight }))
    .sort((a, b) => a.length - b.length)

  const label =
    isOverhead || isDirectionalOverhead
      ? 'Drop Tube'
      : isDirectional && !isDirectionalOverhead
      ? 'Pedestal Height'
      : 'Mount'

  const handleMountingOptionChange = (mountingOptionId: Key) => {
    if (!variationId || typeof mountingOptionId !== 'string') return
    const mountingOption = tubeLengths.find(({ id }) => id === mountingOptionId)
    if (mountingOption == null) {
      const message = `Failed to find mounting option for variationId ${variationId}.`
      captureEvent({ message })
      dispatch(showAlert({ text: 'Failed to change tube length.' }))
      return
    }
    dispatch(
      updateProducts(
        selectedProducts.map(({ product }) => {
          const newHeight =
            getFanHeightInches({
              origin: product.position,
              fullHeight: mountingOption.fullHeight,
            }) ?? product.position.z
          return {
            ...product,
            position: { ...product.position, z: newHeight },
            mountingOptionId,
          }
        })
      )
    )
  }

  return tubeLengths.length > 0 && !isHeater ? (
    <Select
      label={label}
      isDisabled={isDisabled}
      selectedKey={selectedMountingOptionId}
      onSelectionChange={handleMountingOptionChange}
      items={tubeLengths}
    >
      {tube => <SelectItem value={tube}>{tube.label}</SelectItem>}
    </Select>
  ) : (
    <></>
  )
}
