import { useSuspenseQuery } from '@apollo/client'
import { graphql } from '~/gql'

export const useEvapVariationData = ({ variationId }: { variationId: string }) => {
  const { data } = useSuspenseQuery(
    graphql(`
      query EvapDimensionsQuery($variationId: ID!) {
        ProductVariation(id: $variationId) {
          id
          size
          product {
            id
            model
          }
        }
      }
    `),
    { variables: { variationId } }
  )

  return {
    size: data.ProductVariation.size,
    model: data.ProductVariation.product.model,
  }
}
