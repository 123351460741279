import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { HeightFromFloor } from '~/components/Panels/SelectedProductPanel/components/MountingDetails/HeightFromFloor'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { clearStatus, setStatus } from '~/store/status'
import { DimensionInput } from '~/ui/DimensionInput'

const ONE_FOOT_PADDING = 12

export const HeightField = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts, isHeater } = useSelectedProductContext()
  const isDirectional = productType === 'PRODUCTS_DIRECTIONAL'
  const isMounted = selectedProducts.every(
    ({ product }) => product.isForcedWallMount || product.isDirectionalOverhead
  )
  const isHornet = selectedProducts.every(({ variation }) => variation.model === 'Hornet')

  const heights = Array.from(new Set(selectedProducts.map(({ product }) => product.position.z)))
  const height = heights.length === 1 ? heights[0] : 0

  const handleInput = (newHeight: number) => {
    const updatedProducts = selectedProducts.map(({ product }) => ({
      ...product,
      position: {
        ...product.position,
        z: newHeight,
      },
    }))
    const isAllUnderRoof = updatedProducts.every(({ position }) => {
      const distanceToRoof = productDistanceEngine.getDistanceToMountPoint(position)
      return distanceToRoof && position.z < distanceToRoof - ONE_FOOT_PADDING
    })
    if (!isAllUnderRoof) {
      dispatch(setStatus({ type: 'error', text: 'Products must stay within facility bounds!' }))
      return
    }
    dispatch(updateProducts(updatedProducts))
    dispatch(clearStatus())
  }

  return isHeater || isHornet || (isDirectional && isMounted) ? (
    <DimensionInput
      minLength={0}
      value={height}
      onCommit={handleInput}
      showStepperButtons
      label="Height From Floor"
      isDisabled={isLocked}
    />
  ) : (
    <HeightFromFloor />
  )
}
