import { Html, Line } from "@react-three/drei";
import { getThreeHexFromTheme } from "~/lib/utils";
import { useAppSelector } from "~/store/hooks";
import { Door } from "~/store/objects/types";
import { UnitTypes, MeasurementSystem } from "~/store/units/constants";
import { convertDistance } from "~/store/units/converters/distance";
import { formatDistance } from "~/store/units/formatters/distance";
import { VectorVisualizer } from "./DoorArrows";
import { SnapData } from "../util/snaplines";
import { modelToUI, objectModelToUI } from "../util/units";
import { orthogonalVectorOfWallSegment, bisectLine } from "../util/walls";
import { useDistanceDimensions } from "~/hooks/useDistanceDimensions";

export function SnapPreview(props: { door: Pick<Door, 'width' | 'height'>; snapPoint: SnapData; }) {
  const { objectID, snapPoint } = props.snapPoint;
  const { x, y } = snapPoint;
  const { width, height } = props.door;

  const wallSegment = useAppSelector(it => it.objects.present.segments[objectID]);
  const { displayFormattedValue } = useDistanceDimensions()
  const thickness = wallSegment.thickness;
  const is3D = useAppSelector(it => it.camera.is3D);

  const doorX = x;
  const doorY = y;
  const doorZ = modelToUI(height) / 2;

  const doorVector = { x: doorX, y: doorY, z: doorZ };

  const doorWidth = modelToUI(width);
  const doorHeight = modelToUI(height);
  const doorThickness = modelToUI(thickness) + 0.2;

  const color = getThreeHexFromTheme('three.valid');

  const rotation = orthogonalVectorOfWallSegment(wallSegment);

  const { v1, v2, v1Half, v2Half } = bisectLine(objectModelToUI(wallSegment.startPoint), objectModelToUI(wallSegment.endPoint), doorVector);

  const d1 = displayFormattedValue(v1.length())
  const d2 = displayFormattedValue(v2.length())

  return (
    <>
      <mesh position={[doorX, doorY, doorZ]} rotation={rotation}>
        <boxGeometry args={[doorThickness, doorWidth, doorHeight]} />
        <meshLambertMaterial color={color} />
      </mesh>
      {!is3D && (
        <mesh position={[doorX, doorY, modelToUI(wallSegment.height)]} rotation={rotation}>
          <boxGeometry args={[doorThickness, doorWidth, 1]} />
          <meshLambertMaterial color={color} />
        </mesh>
      )}
      <group position={[doorX, doorY, modelToUI(wallSegment.height)]}>
        <VectorVisualizer vector={v1} color='black' />
        <Html position={v1Half} center zIndexRange={[0,0]}>
          <div className="w-[8ch] bg-white rounded-lg tabular-nums px-2 py-1">{d1}</div>
        </Html>
        <VectorVisualizer vector={v2} color='black' />
        <Html position={v2Half} center zIndexRange={[0,0]}>
          <div className="w-[8ch] bg-white rounded-lg tabular-nums px-2 py-1">{d2}</div>
        </Html>
      </group>
    </>
  );
}
