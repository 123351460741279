import { Key } from 'react-aria-components'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { LOUVER_ANGLES } from '~/config/louverAngles'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'
import { formatDegrees } from '~/utils/number'

export const LouverAngleSelect = () => {
  const dispatch = useAppDispatch()
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)

  const { productType, selectedProducts } = useSelectedProductContext()
  const isUnitHeater = productType === 'PRODUCTS_UNIT_HEATERS'

  const angleValues = Array.from(new Set(selectedProducts.map(({ product }) => product.angle)))
  const angle = angleValues.length === 1 ? angleValues[0] : null

  const handleAngleSelect = (newAngle: Key) => {
    if (typeof newAngle !== 'number' || isLocked) return
    dispatch(
      updateProducts(
        selectedProducts.map(({ product }) => ({
          ...product,
          angle: newAngle,
        }))
      )
    )
  }

  return isUnitHeater ? (
    <Select
      label="Louver Angle"
      isDisabled={isLocked}
      selectedKey={angle}
      onSelectionChange={handleAngleSelect}
      items={LOUVER_ANGLES}
    >
      {angle => <SelectItem value={angle}>{formatDegrees(angle.id)}</SelectItem>}
    </Select>
  ) : (
    <></>
  )
}
