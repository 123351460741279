import { useCallback } from 'react'
import { Vector3, Vector3Like } from 'three'
import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { useAppSelector } from '~/store/hooks'
import { Edge, isPointInPolygon } from '~/utils/pointInPolygon'

type GetFanHeightInchesProps = {
  origin: Vector3Like
  fullHeight: number
}

export const useGetFanHeightInches = () => {
  const roofSections = useAppSelector(state => state.objects.present.roofSections)
  const affOrigin = new Vector3()

  return useCallback(
    ({ origin, fullHeight }: GetFanHeightInchesProps) => {
      affOrigin.copy(origin)
      const distanceAff = productDistanceEngine.getDistanceToMountPoint(affOrigin) ?? 0

      let primaryStructureOffset = 0
      let secondaryStructureOffset = 0
      Object.values(roofSections).forEach(
        ({
          beamRotation,
          beamModels,
          beamWidth,
          beamDepth,
          primaryStructuresEnabled,
          structureStyle,
          perimeterPoints,
          secondaryStructureDepth,
          secondaryStructureStyle,
          height,
        }) => {
          const edges = perimeterPoints.reduce<Edge[]>((edgeCollection, point, i, arr) => {
            if (i !== 0) {
              const prev = arr[i - 1]
              edgeCollection.push({ p1: [prev[0], prev[1]], p2: [point[0], point[1]] })
            }
            return edgeCollection
          }, [])
          const isUnderRoofSection = isPointInPolygon([affOrigin.x, affOrigin.y], edges)
          if (!isUnderRoofSection) return
          if (primaryStructuresEnabled) {
            const isVertical = beamRotation === 0
            const closestBeam = beamModels?.find(({ position }) =>
              isVertical
                ? Math.abs(position.x - affOrigin.x) < beamWidth
                : Math.abs(position.y - affOrigin.y) < beamWidth
            )
            if (closestBeam) {
              const isRoofConforming = structureStyle === 'roof-conforming'
              primaryStructureOffset = isRoofConforming
                ? beamDepth
                : Math.max(0, distanceAff - height) + beamDepth
            }
          }
          const isRoofConforming = secondaryStructureStyle === 'roof-conforming'
          secondaryStructureOffset = isRoofConforming
            ? secondaryStructureDepth
            : Math.max(0, distanceAff - height) + secondaryStructureDepth
        }
      )

      const heightOffset = Math.max(primaryStructureOffset, secondaryStructureOffset)
      const newHeight = distanceAff - fullHeight - heightOffset
      return newHeight <= 0 ? origin.z : newHeight
    },
    [roofSections]
  )
}
