import routes from '~/config/routes'
import LAYER_KEYS, { LayerKeys } from '~/config/layerKeys'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { setCurrentLayer } from '~/store/layers'
import { setActiveTool } from '~/store/tools'
import { useNavigate } from 'react-router-dom-v5-compat'
import { MenuDropDownButton } from '../components/MenuDropDownButton'
import { MenuItem } from '~/ui/Menu'
import { Icon } from '~/ui/Icon'
import { useRouteMatch } from 'react-router'
import { Vector3 } from 'three'
import { Text } from 'react-aria-components'

const RECTANGLE_THRESHOLD = 1000

const useIsRectangularFacility = () => {
  const walls = Object.values(useAppSelector(state => state.objects.present.objects))[0]
  const segments = useAppSelector(state => state.objects.present.segments)
  if (!walls || walls.segments.length !== 4) return false
  const wallSegments = walls.segments.map(id => segments[id])
  if (wallSegments.some(segment => !segment)) return false
  const [segA, segB, segC, segD] = wallSegments
  const segALength = new Vector3().copy(segA.startPoint).distanceToSquared(segA.endPoint)
  const segBLength = new Vector3().copy(segB.startPoint).distanceToSquared(segB.endPoint)
  const segCLength = new Vector3().copy(segC.startPoint).distanceToSquared(segC.endPoint)
  const segDLength = new Vector3().copy(segD.startPoint).distanceToSquared(segD.endPoint)
  return Math.abs(segALength - segCLength) < RECTANGLE_THRESHOLD && Math.abs(segBLength - segDLength) < RECTANGLE_THRESHOLD
}

const RoofTemplatesMenuItem = () => {
  const dispatch = useAppDispatch()
  const match = useRouteMatch()
  const navigate = useNavigate()
  const isRectangularFacility = useIsRectangularFacility()

  const handleClick = () => {
    navigate(match.url+routes.modals.roofTemplates)
    dispatch(setCurrentLayer({ layerKey: LAYER_KEYS.ROOFS }))
    dispatch(setActiveTool({ tool: 'TEMPLATE_TOOL' }))
  }

  return (
    <MenuItem variant='square' isDisabled={!isRectangularFacility} onAction={handleClick}>
      <Icon name='crop' size="14"/>
      <div className='flex flex-col'>
        <Text slot="label">Roof Templates</Text>
        {!isRectangularFacility && <Text slot="description" className='text-red-400'>Rectangular facilities only</Text>}
      </div>
    </MenuItem>
  )
}

export default function RoofsAndCeilingsMenu() {
  const dispatch = useAppDispatch()
  const action = (layerKey: LayerKeys, tool: string) => () => {
    dispatch(setCurrentLayer({ layerKey }))
    dispatch(setActiveTool({ tool }))
  }

  return (
    <MenuDropDownButton label="Roofs & Ceilings" icon="home">
      <RoofTemplatesMenuItem />
      <MenuItem onAction={action(LAYER_KEYS.ELEVATION_LINE, 'ELEVATION_LINE_TOOL')} variant="square">
        <Icon name='caretUp' size="14"/>
        Elevation Line
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.ELEVATION_POINT, 'ELEVATION_POINT_TOOL')} variant="square">
        <Icon name='elevationPoint' size="14"/>
        Elevation Point
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.ROOF_SECTIONS, 'SELECT_TOOL')} variant="square">
        <Icon name='mountingStructures' size="14"/>
        Mounting Structures
      </MenuItem>
      <MenuItem onAction={action(LAYER_KEYS.CEILINGS, 'SELECT_TOOL')} variant="square">
        <Icon name='ceilings' size="14"/>
        Ceilings
      </MenuItem>
    </MenuDropDownButton>
  )
}
