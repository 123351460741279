import { BufferGeometry, Mesh, Object3D, Object3DEventMap, Vector3 } from 'three'
import { productDistanceEngine } from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { RootState } from '~/store'

export const isMeshWithGeometry = (
  object: Object3D<Object3DEventMap>
): object is Mesh<BufferGeometry> => {
  return object instanceof Mesh && object.geometry instanceof BufferGeometry
}

export const getArrowDimensions = ({
  position,
  direction,
  boundingBoxSize,
  ignoring,
}: {
  position: Vector3
  boundingBoxSize?: Vector3
  direction: Vector3
  ignoring?: Set<string>
}) => {
  const offset = new Vector3()
  if (boundingBoxSize) {
    offset.multiplyVectors(direction, boundingBoxSize).divideScalar(2)
    offset.setZ(0)
  }
  const origin = new Vector3().copy(position).add(offset)
  const length = productDistanceEngine.getNearestCollisionDistance({
    origin,
    direction,
    ignoring,
  })
  return { length, offset }
}

export const getIgnoringIds = (
  products: RootState['objects']['present']['products'],
  productId: string
) => {
  const currentProduct = products[productId]
  return new Set(
    Object.values(products)
      .filter(
        ({ id, layerKey }) =>
          id === currentProduct?.id || (currentProduct && currentProduct.layerKey !== layerKey)
      )
      .map(({ id }) => id)
  )
}
