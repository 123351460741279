import { Html } from '@react-three/drei'
import { forwardRef, Fragment, useImperativeHandle } from 'react'
import { Vector3, Vector3Like } from 'three'
import { LINE_COLOR, ARROW_SIZE } from '~/components/DrawingCanvas/Products/constants'
import { useCardinalArrowConfigs } from '~/components/DrawingCanvas/Products/hooks/useCardinalArrowConfigs'
import { getArrowDimensions, getIgnoringIds } from '~/components/DrawingCanvas/Products/util'
import { inputStyles } from '~/ui/Field'
import { modelToUI, scaleUIVectorToModel } from '~/components/DrawingCanvas/util/units'
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions'
import { R3FDimensionInput } from '~/ui/R3FDimensionInput'
import { useAppSelector } from '~/store/hooks'
import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '~/store'

const ARROW_FLOOR_OFFSET = 30

export type ArrowHandle = {
  setOrigin: (newOriginInUIUnits: Vector3) => void
}

const selectIgnoringProductIds = createSelector(
  [
    (state: RootState) => state.objects.present.products,
    (_: RootState, currentProductId?: string) => currentProductId,
  ],
  (products, currentProductId) => getIgnoringIds(products, currentProductId ?? '')
)

const useIgnoringProductIds = (currentProductId?: string) =>
  useAppSelector(state => selectIgnoringProductIds(state, currentProductId))

export const DimensionGuides = forwardRef<
  ArrowHandle,
  {
    id?: string
    position: Vector3Like
    isDragging: boolean
    onUpdatePosition?: (newPosition: Vector3Like) => void
  }
>(({ id, position, isDragging, onUpdatePosition }, ref) => {
  const arrowConfigs = useCardinalArrowConfigs()
  const { displayFormattedValue } = useDistanceDimensions()
  const ignoring = useIgnoringProductIds(id)
  const { x, y, z } = position
  const midpoint = new Vector3()
  const arrowOrigin = new Vector3()

  const handleCommit = ({ distance, direction }: { distance: number; direction: Vector3 }) => (
    newValue: number
  ) => {
    const distanceDelta = distance - newValue
    const scaledDirection = new Vector3().copy(direction).multiplyScalar(distanceDelta)
    const newPosition = new Vector3(x, y, z).add(scaledDirection)
    onUpdatePosition?.({ x: newPosition.x, y: newPosition.y, z: newPosition.z })
  }

  useImperativeHandle(
    ref,
    () => ({
      setOrigin(newOriginInUIUnits) {
        arrowConfigs.forEach(({ arrowHelper, direction, htmlMesh, div }) => {
          arrowOrigin.copy(newOriginInUIUnits)
          scaleUIVectorToModel(arrowOrigin)
          const { length: distance } = getArrowDimensions({
            position: arrowOrigin,
            direction,
            ignoring,
          })
          if (!distance) {
            arrowHelper.current?.setLength(0, ARROW_SIZE, ARROW_SIZE)
            if (div.current) div.current.style.opacity = '0'
            return
          }
          const arrowLength = modelToUI(distance)
          const zOffset = modelToUI(-(arrowOrigin.z - ARROW_FLOOR_OFFSET))
          arrowHelper.current?.position.setZ(zOffset)
          arrowHelper.current?.setLength(arrowLength, ARROW_SIZE, ARROW_SIZE)
          midpoint.copy(direction).multiplyScalar(arrowLength / 2)
          midpoint.setZ(zOffset)
          htmlMesh.current?.position.copy(midpoint)
          if (div.current) {
            div.current.textContent = displayFormattedValue(distance)
            div.current.style.opacity = '1'
          }
        })
      },
    }),
    [ignoring]
  )

  return (
    <>
      {arrowConfigs.map(({ arrowHelper, div, htmlMesh, direction }, i) => {
        const origin = new Vector3(x, y, z)
        const { length: distance } = getArrowDimensions({
          position: origin,
          direction,
          ignoring,
        })
        if (!distance) return null
        const zOffset = modelToUI(-(z - ARROW_FLOOR_OFFSET))
        const arrowPosition = new Vector3().setZ(zOffset)
        const midpoint = new Vector3().copy(direction).multiplyScalar(modelToUI(distance) / 2)
        midpoint.setZ(zOffset)
        return (
          <Fragment key={i}>
            <mesh ref={htmlMesh} position={midpoint}>
              {isDragging ? (
                <Html
                  center
                  ref={div}
                  className={inputStyles({ isOnCanvas: true, class: 'pointer-events-none' })}
                >
                  {displayFormattedValue(distance)}
                </Html>
              ) : (
                <R3FDimensionInput
                  center
                  aria-label={`Product distance guideline ${i}`}
                  value={distance}
                  onCommit={handleCommit({ distance, direction })}
                />
              )}
            </mesh>
            <arrowHelper
              ref={arrowHelper}
              position={arrowPosition}
              args={[direction, origin, modelToUI(distance), LINE_COLOR, ARROW_SIZE, ARROW_SIZE]}
            />
          </Fragment>
        )
      })}
    </>
  )
})
