export const OVERHEAD_3D_MODELS = new Set([
  'Bravo',
  'Impulse',
  'Mammoth',
  'Speakeasy',
  'Turbo6',
  'Powerfoil X3.0',
  'Powerfoil X4',
  'Powerfoil 8',
  'Powerfoil D',
  'Basic 6',
  'Essence',
  'i6',
  'ES6',
  'Haiku',
  'Existing HVLS',
  'Hornet',
])

export const OVERHEAD_PIVOT_2_0 = 'Pivot 2.0 overhead'

export const HEIGHT_VARIABLE_DIRECTIONAL_3D_MODELS = new Set(['Sweat Bee', 'Yellow Jacket'])

const BLACK_JACK = 'Black Jack'

const SIDEKICK = 'Sidekick'

export const DIRECTIONAL_3D_MODELS = new Set([
  BLACK_JACK,
  'AirEye',
  'AirGo 2.0',
  SIDEKICK,
  'Pivot 2.0',
])

export const isTiltingDirectional = (model: string) => model !== BLACK_JACK && model !== SIDEKICK
