import { Vector3, Raycaster } from "three"
import { memoizedRoofMeshes } from "../lib/productDistanceEngine"

export function computeRoofIntersection(modelX: number, modelY: number, roofs: ReturnType<typeof memoizedRoofMeshes>) {
  const position = new Vector3(modelX, modelY, 0)
  const up = new Vector3(0, 0, 1)
  const raycaster = new Raycaster(position, up)

  let distance = Infinity
  let target = new Vector3()
  let objectID: undefined | string = undefined

  for (const [id, roof] of roofs.entries()) {
    const intersections = raycaster.intersectObject(roof)
    for (const intersection of intersections) {
      if (intersection.distance <= distance) {
        target.copy(intersection.point)
        distance = intersection.distance
        objectID = id
      }
    }
  }

  if (objectID === undefined) {
    return null
  }
  return [objectID, target] as const
}
