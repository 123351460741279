import * as THREE from 'three'

import TouchUtil from './touchUtil'
import compact from 'lodash-es/compact'

import store from 'store'
import { addObject } from 'store/objects'

const MAX_POINTS = 500

class TouchObject {
  constructor(camera, scene) {
    this.camera = camera
    this.scene = scene

    this.obj3d = new THREE.Object3D()
    this.scene.add(this.obj3d)

    this.lines = []
    this.linePositions = []
  }

  addLine() {
    const geometry = new THREE.BufferGeometry()
    const positions = new Float32Array(MAX_POINTS * 3)
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))

    const material = new THREE.LineBasicMaterial({
      color: 0x000000,
    })

    const line = new THREE.Line(geometry, material)
    line.frustumCulled = false
    this.lines.push(line)
    this.linePositions.push([])
    this.obj3d.add(line)
  }

  addPosition(pos) {
    if (!pos || (pos.x === 0 && pos.y === 0)) return

    const index = this.lines.length - 1
    const line = this.lines[index]
    const linePositions = this.linePositions[index]

    linePositions.push(pos)

    const positions = line.geometry.attributes.position.array

    // We don't want to show all the positions, since anything after the most recent is set to (0, 0, 0)
    // So a line would show from the most recent point to (0, 0, 0)
    // So we limit the draw range to only the user-created positions
    line.geometry.setDrawRange(0, linePositions.length)

    for (let i = 0; i < linePositions.length; ++i) {
      const position = linePositions[i]
      const positionIndex = i * 3
      positions[positionIndex] = position.x
      positions[positionIndex + 1] = position.y
      positions[positionIndex + 2] = position.z + 10
    }

    line.geometry.attributes.position.needsUpdate = true
  }

  checkForX() {
    const x = TouchUtil.getX(this.lines, this.linePositions)
    if (x) return x
  }

  checkForRectangle() {
    const singleLineRectangle = TouchUtil.getSingleLineRectangle(this.lines)
    if (singleLineRectangle) return singleLineRectangle

    const multiLineRectangle = TouchUtil.getMultiLineRectangle(
      this.lines,
      this.linePositions
    )
    if (multiLineRectangle) return multiLineRectangle
  }

  checkForCircle() {
    const singleLineCircle = TouchUtil.getSingleLineCircle(this.lines)
    if (singleLineCircle) return singleLineCircle
  }

  removeAllLines() {
    this.lines.forEach(line => this.obj3d.remove(line))

    this.lines = []
    this.linePositions = []
  }

  createWall({ model, lines }) {
    lines.forEach(line => {
      this.obj3d.remove(line.line)
      this.lines[line.index] = null
      this.linePositions[line.index] = null
    })

    this.lines = compact(this.lines)
    this.linePositions = compact(this.linePositions)

    store.dispatch(addObject({ object: { ...model } }))
  }

  finish() {}
}

export default TouchObject
