import auth0 from 'auth0-js'
import decode from 'jwt-decode'
import randomstring from 'randomstring'
import get from 'lodash-es/get'

import { isTesting } from './utils'
import { captureEvent } from '@sentry/react'

class Auth {
  KEY_PREFIX = 'BAF:UI'
  auth0 = new auth0.WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN ?? '',
    clientID: import.meta.env.VITE_AUTH0_CLIENT_ID ?? '',
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirectUri: `${window.location.origin}/callback`,
    responseType: 'token id_token',
    scope: 'openid profile email',
  })

  /**
   * Create one-time use token for session and user auth
   */
  login() {
    const nonce = randomstring.generate()
    localStorage.setItem(this.getKey('nonce'), nonce)
    this.auth0.authorize({
      nonce,
    })
  }

  /**
   * Check nonce token for auth and either resolve or reject
   * @returns promise for auth results
   */
  handleAuthentication() {
    return new Promise((resolve, reject) => {
      const match = isTesting() ? { state: this.state } : { nonce: this.nonce }
      this.auth0.parseHash(match, (err, result) => {
        if (result && result.accessToken && result.idToken) {
          this.setSession(result)
          resolve(result)
        } else {
          reject(err || result)
        }
      })
    })
  }

  /**
   * Set auth key in local storage for session
   * @param {Object} result auth results object
   */
  setSession(result) {
    localStorage.setItem(this.getKey('auth'), JSON.stringify(result))
  }

  /**
   * Attempt to renew auth token and either resolve or reject
   * @returns promise with auth results
   */
  renewToken() {
    return new Promise((resolve, reject) => {
      this.auth0.renewAuth(
        {
          audience: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/userinfo`,
          redirectUri: `${window.location.origin}/silent_auth.html`,
          usePostMessage: true,
          postMessageDataType: 'auth0:silent-authentication',
          timeout: 5000,
          nonce: this.nonce,
          state: this.state,
        },
        (err, result) => {
          if (err) return reject(err)
          this.setSession(result)
          resolve(result)
        }
      )
    })
  }

  /**
   * Clear session auth and nonce token
   */
  logout() {
    localStorage.removeItem(this.getKey('auth'))
    localStorage.removeItem(this.getKey('nonce'))
    window.location.reload()
  }

  /**
   * Decode token and return expiry date
   * @param {string} token encoded session auth token
   * @returns date time at which token expires
   */
  getTokenExpirationDate(token) {
    const decoded = decode(token)

    if (!decoded.exp) {
      return null
    }

    const date = new Date(0)
    date.setUTCSeconds(decoded.exp)

    return date
  }

  /**
   * Check token expiry date and return status
   * @param {string} token encoded session auth token
   * @returns true or false based on token validity
   */
  isTokenExpired(token) {
    const date = this.getTokenExpirationDate(token)

    if (date === null) return false

    return !(date.valueOf() > new Date().valueOf())
  }

  getKey(item) {
    return `${this.KEY_PREFIX}:${item}`
  }

  get expiresAt() {
    return get(this.authData, 'expiresIn', 0) * 1000 + new Date().getTime()
  }

  get isAuthenticated() {
    if (!this.accessToken) {
      // The purpose of this check is to remove tokens that lack a payload. It
      // removes them from local storage and triggers redirects to login. This can
      // be removed after upgrading the auth0 library and refactoring the auth layer
      localStorage.removeItem(this.getKey('auth'))
      localStorage.removeItem(this.getKey('nonce'))
    }
    return new Date().getTime() < this.expiresAt
  }

  get authData() {
    return JSON.parse(localStorage.getItem(this.getKey('auth')))
  }

  get accessToken() {
    try {
      const accessToken = get(this.authData, 'accessToken')
      if (!accessToken) throw new Error('No Access Token found')
      // Access tokens stored before `audience` was added will be lacking a payload
      // If there's no payload, the decode function will throw an error in side this function
      const isExpired = this.isTokenExpired(accessToken)
      if (isExpired) throw new Error('Access Token expired.')
      return accessToken
    } catch (error) {
      const message = error instanceof Error ? error.message : "Failed to get access token from localStorage"
      if (message !== 'No Access Token found' && message !== 'Access Token expired.') captureEvent({ message })
      return null
    }
  }

  get idToken() {
    return get(this.authData, 'idToken')
  }

  get nonce() {
    return localStorage.getItem(this.getKey('nonce'))
  }

  get state() {
    return get(this.authData, 'state')
  }

  get profile() {
    return get(this.authData, 'idTokenPayload')
  }
}

export default new Auth()
