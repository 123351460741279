import { useSuspenseQuery } from '@apollo/client'
import { Vector3, Vector3Like } from 'three'
import { FLOOR } from '~/components/DrawingCanvas/constants/dimensions'
import {
  IntersectionData,
  productDistanceEngine,
} from '~/components/DrawingCanvas/lib/productDistanceEngine'
import { getIgnoringIds } from '~/components/DrawingCanvas/Products/util'
import { uiToModel } from '~/components/DrawingCanvas/util/units'
import { graphql } from '~/gql'
import { useDistanceDimensions } from '~/hooks/useDistanceDimensions'
import { useAppSelector } from '~/store/hooks'

export type DimensionError = {
  message: string
  location?: {
    direction: Vector3
    distance: number
  }
}

const isTooCloseToProduct = (origin: Vector3Like, target: Vector3Like, clearance: number) => {
  const productOrigin = new Vector3().copy(origin).setZ(FLOOR)
  const targetProduct = new Vector3().copy(target).setZ(FLOOR)
  const productDistance = productOrigin.distanceTo(targetProduct)
  return productDistance <= clearance 
}

const useGetCheckDimensions = () => {
  const { displayFormattedValue } = useDistanceDimensions()
  return (origin: Vector3Like) => (
    object: string,
    clearance: number,
    nearestObject: IntersectionData | null,
    condition: (object: IntersectionData) => boolean
  ): DimensionError | undefined => {
    if (!nearestObject || !condition(nearestObject)) return
    const [, , errorPoint] = nearestObject
    const direction = new Vector3().subVectors(errorPoint, origin).normalize()
    const distance = errorPoint.distanceTo(origin)
    return {
      message: `Too close to ${object}! (must be greater than ${displayFormattedValue(clearance)})`,
      location: { direction, distance },
    }
  }
}

const DIMENSION_ERROR_CHECK_QUERY = graphql(`
  query DimensionErrorCheckQuery($variationId: ID!) {
    ProductVariation(id: $variationId) {
      id
      size
      minWallClearance
      minObstructionClearance
      minProductClearance
      minFloorClearance
      minRoofClearance
    }
  }
`)

export const useHeaterDimenionErrors = (
  variationId: string,
  { depth, width, rotation }: { depth: number; width: number; rotation: number }
) => {
  const getCheckDimensions = useGetCheckDimensions()
  const products = useAppSelector(state => state.objects.present.products)
  const { data } = useSuspenseQuery(DIMENSION_ERROR_CHECK_QUERY, { variables: { variationId } })
  const {
    minObstructionClearance,
    minProductClearance,
    minFloorClearance,
    minWallClearance,
    minRoofClearance,
  } = data.ProductVariation
  return ({
    origin,
    productId,
  }: {
    origin: Vector3Like
    productId: string
  }): { isValidPosition: boolean; errors: DimensionError[] } => {
    const checkDimensions = getCheckDimensions(origin)
    const isVerticalOrientation =
      (rotation < 315 && rotation > 215) || (rotation < 135 && rotation > 45)
    const boxDimensions = isVerticalOrientation ? { width: depth, depth: width } : { width, depth }
    const nearestWall = productDistanceEngine.nearestHeaterWallIntersection(origin, boxDimensions)
    const nearestObstruction = productDistanceEngine.nearestObstructionIntersection(origin)
    const nearestRoof = productDistanceEngine.nearestRoofIntersection(origin, boxDimensions)
    const selectedProduct = products[productId]
    const ignoring = getIgnoringIds(products, productId)
    const nearestProduct = productDistanceEngine.nearestProductIntersection(selectedProduct.position, ignoring)
    const inFacility = productDistanceEngine.isWithinFacility(origin)
    const distanceToRoof = productDistanceEngine.getDistanceToMountPoint(origin) ?? 0
    const isUnderRoof = origin.z < distanceToRoof

    const tooCloseToWalls = checkDimensions(
      'a wall',
      minWallClearance,
      nearestWall,
      ([, distance, intersection]) => {
        const direction = new Vector3().subVectors(intersection, origin).normalize()
        const isHorizontalIntersection = direction.y === 0
        const isVerticalIntersection = direction.x === 0
        const condition = isVerticalOrientation ? isHorizontalIntersection : isVerticalIntersection
        const offset = (condition ? depth : width) / 2
        return distance <= minWallClearance + offset
      }
    )
    const tooCloseToObstructions = checkDimensions('an obstruction', minObstructionClearance, nearestObstruction, ([, distance]) => distance <= uiToModel(minObstructionClearance))
    const tooCloseToProducts = checkDimensions('a product', minProductClearance, nearestProduct, ([, , intersection]) => isTooCloseToProduct(origin, intersection, minProductClearance))
    const tooCloseToRoof = checkDimensions('the roof', minRoofClearance, nearestRoof, ([, distance]) => distance <= minRoofClearance)
    const tooCloseToFloor = checkDimensions('the floor', minFloorClearance, ['', origin.z, new Vector3(origin.x, origin.y, 0)], () => origin.z < minFloorClearance)

    return {
      isValidPosition: inFacility && isUnderRoof,
      errors: [
        !inFacility && { message: 'Product must be placed within facility bounds!' },
        tooCloseToWalls,
        tooCloseToObstructions,
        tooCloseToProducts,
        tooCloseToRoof,
        tooCloseToFloor,
      ].filter(error => !!error),
    }
  }
}

export const useDimensionErrors = (variationId: string) => {
  const getCheckDimensions = useGetCheckDimensions()
  const products = useAppSelector(state => state.objects.present.products)
  const { data } = useSuspenseQuery(DIMENSION_ERROR_CHECK_QUERY, { variables: { variationId } })
  const {
    size,
    minObstructionClearance,
    minProductClearance,
    minFloorClearance,
    minWallClearance,
    minRoofClearance,
  } = data.ProductVariation
  return ({
    origin,
    productId,
  }: {
    origin: Vector3Like
    productId: string
  }): { isValidPosition: boolean; errors: DimensionError[] } => {
    const checkDimensions = getCheckDimensions(origin)
    const inFacility = productDistanceEngine.isWithinFacility(origin)
    const nearestWall = productDistanceEngine.nearestWallIntersection(origin)
    const nearestObstruction = productDistanceEngine.nearestObstructionIntersection(origin)
    const fanDimensions = { width: size, depth: size }
    const nearestRoof = productDistanceEngine.nearestRoofIntersection(origin, fanDimensions)
    const selectedProduct = products[productId]
    const ignoring = getIgnoringIds(products, productId)
    const nearestProduct = productDistanceEngine.nearestProductIntersection(selectedProduct.position, ignoring)

    const tooCloseToWalls = checkDimensions('a wall', minWallClearance, nearestWall, ([, distance]) => distance <= minWallClearance + size / 2)
    const tooCloseToObstructions = checkDimensions('an obstruction', minObstructionClearance, nearestObstruction, ([, distance]) => distance <= uiToModel(minObstructionClearance))
    const tooCloseToProducts = checkDimensions('a product', minProductClearance, nearestProduct, ([, , intersection]) => isTooCloseToProduct(origin, intersection, minProductClearance))
    const tooCloseToRoof = checkDimensions('the roof', minRoofClearance, nearestRoof, ([, distance]) => distance <= minRoofClearance)
    const tooCloseToFloor = checkDimensions('the floor', minFloorClearance, ['', origin.z, new Vector3(origin.x, origin.y, 0)], () => origin.z < minFloorClearance)

    return {
      isValidPosition: inFacility,
      errors: [
        !inFacility && { message: 'Product must be placed within facility bounds!' },
        tooCloseToWalls,
        tooCloseToObstructions,
        tooCloseToProducts,
        tooCloseToRoof,
        tooCloseToFloor,
      ].filter(error => !!error),
    }
  }
}
