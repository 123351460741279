export const ObjectClassName = {
  AIRFLOW: 'Airflow',
  AIRFLOW_DATA: 'AirflowData',
  CEILING: 'Ceiling',
  CFD: 'CFD',
  CFD_GRID: 'CFD_GRID',
  COMFORT_ZONE: 'ComfortZone',
  DIMENSION: 'Dimension',
  DOOR: 'Door',
  ELEVATION_LINE: 'ElevationLine',
  ELEVATION_POINT: 'ElevationPoint',
  GRID_BOX: 'GridBox',
  HEAT_MAP: 'HeatMap',
  OBSTRUCTION: 'Obstruction',
  PRODUCT: 'Product',
  ROOF: 'Roof',
  ROOF_SECTION: 'RoofSection',
  UTILITY_BOX: 'UtilityBox',
  WALL: 'Wall',
  WALL_SEGMENT: 'WallSegment',
} as const
export type ObjectClassName = (typeof ObjectClassName)[keyof typeof ObjectClassName]
export default ObjectClassName
export type ObjectStateKeys = {
  [ObjectClassName.WALL]: 'objects',
  [ObjectClassName.WALL_SEGMENT]: 'segments',
  [ObjectClassName.UTILITY_BOX]: 'utilityBoxes',
  [ObjectClassName.AIRFLOW]: 'airflow',
  [ObjectClassName.AIRFLOW_DATA]: 'airflowData',
  [ObjectClassName.GRID_BOX]: 'gridBox',
  [ObjectClassName.HEAT_MAP]: 'heatMap',
  [ObjectClassName.CFD]: 'cfd',
  [ObjectClassName.CFD_GRID]: 'cfdGrid',

  [ObjectClassName.OBSTRUCTION]: 'obstructions',
  [ObjectClassName.COMFORT_ZONE]: 'comfortZones',
  [ObjectClassName.DIMENSION]: 'dimensions',
  [ObjectClassName.ROOF]: 'roofs',
  [ObjectClassName.DOOR]: 'doors',
  [ObjectClassName.ELEVATION_POINT]: 'elevationPoints',
  [ObjectClassName.ELEVATION_LINE]: 'elevationLines',
  [ObjectClassName.ROOF_SECTION]: 'roofSections',
  [ObjectClassName.PRODUCT]: 'products',
  [ObjectClassName.CEILING]: 'ceilings',
}

null! as ObjectStateKeys satisfies Record<ObjectClassName, string>