import { useAppSelector, useAppDispatch } from "~/store/hooks";
import { useHistory } from 'react-router-dom'
import { ApolloError } from '@apollo/client'

import { hasFeature } from 'lib/hasFeature'
import { SYSTEMS } from 'store/units/constants'
import { Distance } from 'store/units/types'

import Button from 'components/UIKit/Button'
import Checkbox from 'components/UIKit/Checkbox'
import Modal from 'components/UIKit/Modal'
import Space from 'components/UIKit/Space'
import TemperatureInput from '~/components/UIKit/TemperatureInput'
import TextField from 'components/UIKit/TextField'

import CheckboxContainer from './styled/CheckboxContainer'

import { UPDATE_FACILITY_TEMPERATURES_MUTATION } from 'client/mutations'
import FormHeader from './styled/FormHeader'
import Subtext from './styled/Subtext'
import EvalHeightRow from './EvalHeightRow'
import DimensionInput from 'components/UIKit/DimensionInput'
import Collapsible from 'components/UIKit/Collapsible'
import { currentUserCanEdit } from 'lib/currentUserIs'
import { CFD_MAIN_TYPES, CFD_STATUS, SIMULATION_TYPES } from 'config/cfd'
import Temperature, { AnyTemperature, ImperialTemperature } from 'store/units/types/temperature'
import { useFacilityMatch } from 'config/routes'
import { Stack } from 'components/UIKit/Stack'
import { useGetUserPermissions } from 'hooks/useGetUser'
import { useFacilityAuthor } from 'hooks/useFacilityAuthor'
import { uploadCFDInput } from 'store/cfd/uploadCFDInput'
import { NOTIFY_CFD_UPLOAD_COMPLETE_MUTATION } from 'client/mutations/notifyCFDUploadComplete'
import { useCFDStatusFragment } from 'hooks/useCFDStatus'
import { useCFDUploadsContext } from '~/hooks/useCFDUploadsContext'
import { defaultEvaluationHeights } from '~/hooks/contexts/CFDUploadsContext'
import { client } from '~/client'
import { showAlert } from '~/store/alert'
import { useState } from 'react'
import { GET_CFD_CHECKSUM_QUERY, useCFDResultChecksumFragment } from '~/hooks/useCFDResultChecksum'
import { AnyDistance } from '~/store/units/types/distance'
import { useNavigate } from "react-router-dom-v5-compat";

const ConfigureCFDForm = (props: { parentRoute: string } = { parentRoute: '' }) => {
  const { versionId, id: facilityId } = useFacilityMatch()
  const history = useHistory()
  const navigate = useNavigate()

  const { DISTANCE, TEMPERATURE } = useAppSelector(({ units }: any) => units)
  const isLocked = useAppSelector(({ layers }: any) => layers.layers.FACILITY.locked)
  const dispatch = useAppDispatch()

  const { user } = useGetUserPermissions()
  const { author } = useFacilityAuthor()
  const { cfdStatus, complete: cfdStatusComplete } = useCFDStatusFragment()
  const { checksumResults, complete: cfdChecksumComplete } = useCFDResultChecksumFragment()
  const { configure, uploads, loading: loadingCfdUploads } = useCFDUploadsContext()

  const {
    setGenerateAirVelocityProfile,
    generateAirVelocityProfile,
    advancedClimate,
    evaluationHeights,
    handleUpdateAdvancedClimate,
    handleUpdateEvaluationHeights,
  } = configure

  const {
    indoorHumidity,
    indoorSummerTemp,
    indoorWinterHumidity,
    indoorWinterTemp,
  } = advancedClimate

  const heights = [
    ...defaultEvaluationHeights,
    ...(evaluationHeights.customHeight !== undefined ? [evaluationHeights.customHeight] : []),
  ]

  // Uncomment when Beam Geometry feature is complete
  // const [includeBeamGeometry, setIncludeBeamGeometry] = useState(false)
  const [isMutating, setIsMutating] = useState(false)

  const canEdit = currentUserCanEdit({ author }, user ?? undefined, !isLocked)
  const isLoading = !cfdStatusComplete || !cfdChecksumComplete || loadingCfdUploads
  const isProcessingCFD = !!cfdStatus?.isSomeInProgress
  const isDisabled = isLoading || !canEdit || !uploads || isMutating || isProcessingCFD

  const candidates =
    uploads && cfdStatus
      ? Object.entries(uploads)
          .map(([simulationType, upload]) => {
            const checksumResult = checksumResults?.find(
              res => res?.internalType === simulationType
            )
            const checksum = checksumResult?.resultChecksum
            const status = cfdStatus.simulationResults[simulationType]?.status
            const isInvalidModel = checksum && upload && checksum !== upload.checksum
            const isFailedStatus = status === CFD_STATUS.CANCELED || status === CFD_STATUS.FAILED
            const isValidCandidate = cfdStatus.isFirstSimulation || isInvalidModel || isFailedStatus
            if (!isValidCandidate || !upload?.checksum) return
            return {
              simulationType,
              ...upload,
              checksumUpdates: {
                ...checksumResult,
                resultChecksum: upload.checksum,
                status: CFD_STATUS.IN_PROGRESS,
              },
            }
          })
          .filter((candidate): candidate is NonNullable<typeof candidate> => !!candidate)
      : undefined

  async function handleSubmit(e: MouseEvent) {
    e.preventDefault()
    if (!candidates?.length || isProcessingCFD || !versionId) return
    setIsMutating(true)
    for (const upload of candidates) {
      const { model, products, building, simulationType, checksumUpdates } = upload
      const type = CFD_MAIN_TYPES[simulationType as keyof typeof SIMULATION_TYPES]
      const files = [model, products, building]
      try {
        const timestamp = await uploadCFDInput({ files, versionId })
        await client.mutate({
          mutation: NOTIFY_CFD_UPLOAD_COMPLETE_MUTATION,
          refetchQueries: ['GetCFDStatuses'],
          awaitRefetchQueries: true,
          variables: {
            versionId,
            timestamp,
            outputUnits: DISTANCE,
            type,
            internalType: simulationType,
          },
          update(cache) {
            cache.writeQuery({
              query: GET_CFD_CHECKSUM_QUERY,
              data: {
                Version: {
                  id: versionId,
                  cfd: {
                    __typename: 'CFD',
                    ...checksumUpdates,
                  },
                } as any,
              },
              variables: {
                versionId,
              },
            })
          },
        })
      } catch (error) {
        console.error(error)
        const text =
          error instanceof ApolloError
            ? 'Failed to update some CFD simulation statuses'
            : 'Failed to upload CFD input'
        dispatch(showAlert({ text, type: 'error' }))
        setIsMutating(false)
        return
      }
    }
    client.mutate({
      mutation: UPDATE_FACILITY_TEMPERATURES_MUTATION,
      errorPolicy: 'ignore',
      variables: {
        id: facilityId!,
        indoorSummerTemp,
        indoorHumidity,
        indoorWinterTemp,
        indoorWinterHumidity,
      },
    })
    setIsMutating(false)
    history.push(props.parentRoute)
  }

  return (
    <Modal
      onSubmit={handleSubmit}
      primaryAction={
        <Button
          primary
          disabled={isDisabled}
          onClick={handleSubmit}
          label={isLoading ? 'Preparing...' : isMutating ? 'Uploading...' : 'Generate CFD'}
        />
      }
      secondaryAction={<Button disabled={isMutating} to={props.parentRoute} label="Cancel" />}
      onClose={() => navigate(props.parentRoute)}
      size="500px"
      {...props}
    >
      <Stack alignItems="start">
        {hasFeature('CFD Air Velocity Profile', user) && (
          <CheckboxContainer style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              label="Generate Air Velocity Profile"
              name="generateAirVelocityProfile"
              onChange={() => setGenerateAirVelocityProfile(!generateAirVelocityProfile)}
              value={generateAirVelocityProfile.toString()}
              checked={generateAirVelocityProfile}
            />
          </CheckboxContainer>
        )}
        {/* {hasFeature('CFD Beam Geometry', user) && (
          <CheckboxContainer style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              label="Include Beam Geometry"
              name="includeBeamGeometry"
              onChange={() => setIncludeBeamGeometry(!includeBeamGeometry)}
              value={includeBeamGeometry}
              checked={includeBeamGeometry}
            />
          </CheckboxContainer>
        )} */}
        <Collapsible
          noBorder
          trigger={<FormHeader>Advanced Climate Settings</FormHeader>}
          withCaret
        >
          <Stack alignItems="start" spacing={1.2}>
            <TemperatureInput
              inline
              showFormattedUnits
              label="Indoor Summer Temp"
              name="indoorSummerTemp"
              degrees={new ImperialTemperature(indoorSummerTemp ?? null)}
              units={TEMPERATURE}
              onChange={({ degrees }: { degrees: AnyTemperature }) => {
                const { value, system } = degrees
                const newIndoorSummerTemp = new Temperature({ value, system }).imperial()
                handleUpdateAdvancedClimate({ indoorSummerTemp: newIndoorSummerTemp })
              }}
              labelWidth="180px"
              width="90px"
            />
            <TemperatureInput
              inline
              showFormattedUnits
              label="Indoor Winter Temp"
              name="indoorWinterTemp"
              degrees={new ImperialTemperature(indoorWinterTemp ?? null)}
              units={TEMPERATURE}
              onChange={({ degrees }: { degrees: AnyTemperature }) => {
                const { value, system } = degrees
                const newWinterTemp = new Temperature({ value, system }).imperial()
                handleUpdateAdvancedClimate({ indoorWinterTemp: newWinterTemp })
              }}
              labelWidth="180px"
              width="90px"
            />
            <TextField
              inline
              name="indoorHumidity"
              label="Summer Humidity (%)"
              onChange={({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                handleUpdateAdvancedClimate({ indoorHumidity: parseInt(target.value) || 50 })
              }
              value={indoorHumidity}
              type="number"
              labelWidth="180px"
              // @ts-ignore
              width="90px"
            />
            <Space bottom="l">
              <TextField
                inline
                name="indoorWinterHumidity"
                label="Winter Humidity (%)"
                onChange={({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  handleUpdateAdvancedClimate({
                    indoorWinterHumidity: parseInt(target.value) || 50,
                  })
                }
                value={indoorWinterHumidity}
                type="number"
                labelWidth="180px"
                // @ts-ignore
                width="90px"
              />
            </Space>
          </Stack>
        </Collapsible>
        <Collapsible noBorder trigger={<FormHeader>Evaluation Heights</FormHeader>} withCaret>
          <Stack alignItems="start" spacing={1}>
            <DimensionInput
              inline
              name="height"
              width="90px"
              label="Floor level"
              labelWidth="180px"
              distance={
                new Distance({
                  value: evaluationHeights.startingHeight,
                  system: SYSTEMS.IMPERIAL,
                })
              }
              tabIndex={2}
              units={DISTANCE}
              onChange={({ distance }: { distance: AnyDistance }) =>
                handleUpdateEvaluationHeights({ startingHeight: distance.imperial() || 0 })
              }
            />
            <Subtext>Heights Above Floor Level: </Subtext>
            <Stack alignItems="start">
              {heights.map((height, i) => (
                <EvalHeightRow
                  key={i}
                  height={height}
                  index={i}
                  units={DISTANCE}
                  handleRemove={() => handleUpdateEvaluationHeights({ customHeight: undefined })}
                  handleChange={height => handleUpdateEvaluationHeights({ customHeight: height })}
                />
              ))}
            </Stack>
            {evaluationHeights.customHeight === undefined && (
              <Button
                label="Add Additional Custom Height"
                icon="plus"
                noBorder
                responsive
                onClick={() => handleUpdateEvaluationHeights({ customHeight: 0 })}
              />
            )}
          </Stack>
        </Collapsible>
      </Stack>
    </Modal>
  )
}

export default ConfigureCFDForm
