import pick from 'lodash-es/pick'
import { graphql } from '~/gql'
import * as status from '../status/actions'
import { LOAD_FACILITY, RESET_FACILITY, ADD_OBJECT, UPDATE_OBJECT, UPDATE_OBJECTS, UPDATE_OBJECTS_PASSIVELY, FORCE_UPDATE_OBJECT, UPDATE_WALL, UPDATE_SEGMENT, UPDATE_SEGMENTS, DELETE_OBJECTS, DUPLICATE_OBJECTS, ADD_GRID, REMOVE_GRID, DUPLICATE_GRID, ADD_ROOF, UPDATE_ROOF, UPDATE_ROOF_STRUCTURE, ADD_ROOF_SECTION, UPDATE_ROOF_SECTION, ADD_MOUNTING_BEAM, REMOVE_MOUNTING_BEAM, UPDATE_MOUNTING_BEAM, ADD_COLUMN_LINE, REMOVE_COLUMN_LINE, UPDATE_COLUMN_LINE, DELETE_ROOF_SECTION, REVERT_ROOF_SECTIONS, ADD_ELEVATION_POINT, UPDATE_ELEVATION_POINT, ADD_ELEVATION_LINE, UPDATE_ELEVATION_LINE, REMOVE_ELEVATION_LINE_HEIGHT, ADD_DOOR, UPDATE_DOOR, DISTRIBUTE_DOORS, ADD_UTILITY_BOX, UPDATE_UTILITY_BOX, ADD_PRODUCT, DISTRIBUTE_PRODUCTS, UPDATE_PRODUCT, ADD_OBSTRUCTION, DISTRIBUTE_OBSTRUCTIONS, UPDATE_OBSTRUCTION, ADD_COMFORT_ZONE, UPDATE_COMFORT_ZONE, ADD_DIMENSION, UPDATE_DIMENSION, DELETE_DIMENSION, ADD_CEILING, UPDATE_CEILING, DELETE_CEILING, REQUEST_AIRFLOW, RECEIVE_AIRFLOW, UPDATE_AIRFLOW_STATUS, SET_AIRFLOW_LAYER, ADD_BACKGROUND_IMAGE, DELETE_BACKGROUND_IMAGE, UPDATE_BACKGROUND_IMAGE, UPDATE_PRODUCT_HEIGHT, ADD_METADATA_IMAGE, REQUEST_HEAT_MAP, RECEIVE_HEAT_MAP, UPDATE_HEAT_MAP_STATUS, SET_HEAT_MAP_LAYER } from './action_types'
export * from './action_types'
import { patchupImport } from '../../lib/segment-patchup'
import type { AppDispatch, AppGetState } from '~/store'
import { BackgroundImage, Dimension, Product } from '~/store/objects/types'
import { patchupBackgroundImage } from '~/lib/backgroundImage-patchup'
import { guid } from '~/lib/utils'

export const loadFacility = (payload: any) => ({
  type: LOAD_FACILITY,
  payload: { ...payload, data: patchupImport(patchupBackgroundImage(payload.data)) },
})

export const resetFacility = (payload: any) => ({
  type: RESET_FACILITY,
  payload,
})

export const updateObject = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_OBJECT,
  payload,
  globalState: getState(),
})

export const updateObjects = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_OBJECTS,
  payload,
  globalState: getState(),
})

export const updateObjectsPassively = (payload: any) => ({
  type: UPDATE_OBJECTS_PASSIVELY,
  payload,
})

// NOTE: Specific is better and will ensure we make the most
// out of the data that is cached
const VARIATIONS_VOLTAGES_AND_MOUNTING_OPTIONS = graphql(`
  fragment VoltagesAndMountingOptionsFragment on ProductVariation {
    id
    product {
      id
      distinctFanSpeeds {
        speed
        overheadOnly
      }
    }
    voltages {
      id
      mountingOptions {
        id
        tubeLength
        label
        forOverhead
        forWall
        forColumn
        forPedestal
      }
    }
  }
`)

const HEATER_VARIATIONS_VOLTAGES_AND_MOUNTING_OPTIONS = graphql(`
  fragment HeaterVoltagesAndMountingOptionsFragment on ProductVariation {
    id
    product {
      id
    }
    voltages {
      id
      mountingOptions {
        id
        tubeLength
        label
        forOverhead
        forWall
        forColumn
        forPedestal
      }
    }
  }
`)

export const updateUtilityBoxes = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => {
  // TODO: any extra logic really needed?
  // const stateUtilityBoxes = selectors.utilityBoxes(getState());
  // const utilityBoxes = payload.map(utilityBox => {
  //   const stateUtilityBox = stateUtilityBoxes[utilityBoxes.id];
  //   if (!stateUtilityBox) return utilityBox;
  // });
  const utilityBoxes = payload
  dispatch({
    type: UPDATE_OBJECTS,
    payload: utilityBoxes,
  })
}

export const updateProducts = (payload: Product[]) => (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch({
    type: UPDATE_OBJECTS,
    payload,
  })
  dispatch(status.clearStatus())
}

// Update a parent object without adding an item to the undo history:
export const forceUpdateObject = (payload: any) => ({
  type: FORCE_UPDATE_OBJECT,
  payload,
})

export const addObject = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_OBJECT,
  payload,
  globalState: getState(),
})

export const addRoof = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_ROOF,
  payload,
  globalState: getState(),
})

export const updateRoof = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_ROOF,
  payload,
  globalState: getState(),
})

export const updateRoofStructure = (payload: any) => ({
  type: UPDATE_ROOF_STRUCTURE,
  payload,
})

export const addRoofSection = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_ROOF_SECTION,
  payload,
  globalState: getState(),
})

export const updateRoofSection = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_ROOF_SECTION,
  payload,
  globalState: getState(),
})

export const addMountingBeam = (payload: any) => ({
  type: ADD_MOUNTING_BEAM,
  payload,
})

export const removeMountingBeam = (payload: any) => ({
  type: REMOVE_MOUNTING_BEAM,
  payload,
})

export const updateMountingBeam = (payload: { roofSectionId: string; beamId: number; beamPosition: import('three').Vector3Like; direction: import('three').Vector3Like }) => ({
  type: UPDATE_MOUNTING_BEAM,
  payload,
})

export const addColumnLine = (payload: any) => ({
  type: ADD_COLUMN_LINE,
  payload,
})

export const removeColumnLine = (payload: any) => ({
  type: REMOVE_COLUMN_LINE,
  payload,
})

export const updateColumnLine = (payload: { roofSectionId: string; columnLineId: number; columnLinePosition: import('three').Vector3Like; direction: import('three').Vector3Like }) => ({
  type: UPDATE_COLUMN_LINE,
  payload,
})

export const deleteRoofSection = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DELETE_ROOF_SECTION,
  payload,
  globalState: getState(),
})

export const revertRoofSections = (payload: any) => ({
  type: REVERT_ROOF_SECTIONS,
  payload,
})

export const addElevationPoint = (payload: any) => ({
  type: ADD_ELEVATION_POINT,
  payload,
})

export const updateElevationPoint = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_ELEVATION_POINT,
  payload,
  globalState: getState(),
})

export const addElevationLine = (payload: any) => ({
  type: ADD_ELEVATION_LINE,
  payload,
})

export const updateElevationLine = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_ELEVATION_LINE,
  payload,
  globalState: getState(),
})

export const removeElevationLineHeight = (payload: any) => ({
  type: REMOVE_ELEVATION_LINE_HEIGHT,
  payload,
})

export const updateWall = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_WALL,
  payload,
  globalState: getState(),
})

export const updateSegment = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_SEGMENT,
  payload,
  globalState: getState(),
})

export const updateSegments = (ids: any[], updates: Record<string, any>) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_SEGMENTS,
  payload: {
    ids,
    updates: pick(updates, [
      'thickness',
      'height',
      'isFullHeight',
      'materialIndex',
    ]),
  },
  globalState: getState(),
})

export const deleteObjects = (payload: any, clearGridBox = false) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DELETE_OBJECTS,
  payload,
  clearGridBox,
  globalState: getState(),
})

export const duplicateObjects = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DUPLICATE_OBJECTS,
  payload,
  globalState: getState(),
})

export const addDoor = (payload: any) => ({
  type: ADD_DOOR,
  payload,
})

export const updateDoor = (payload: any) => ({
  type: UPDATE_DOOR,
  payload,
})

export const distributeDoors = (payload: any) => ({
  type: DISTRIBUTE_DOORS,
  payload,
})

export const addUtilityBox = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_UTILITY_BOX,
  payload,
  globalState: getState(),
})

export const updateUtilityBox = (payload: any) => ({
  type: UPDATE_UTILITY_BOX,
  payload,
})

export const addProduct = ({ product }: { product: Omit<Product, 'id'> }) => (dispatch: AppDispatch, getState: AppGetState) => {
  dispatch({
    type: ADD_PRODUCT,
    payload: {
      product: {
        ...product,
        id: guid()
      },
    },
    globalState: getState()
  })
  dispatch(status.clearStatus())
}

export const distributeProducts = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DISTRIBUTE_PRODUCTS,
  payload,
  globalState: getState(),
})

export const duplicateGrid = (payload: any) => ({
  type: DUPLICATE_GRID,
  payload,
})

export const addGrid = (payload: any) => ({
  type: ADD_GRID,
  payload,
})

export const updateProduct = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_PRODUCT,
  payload,
  globalState: getState(),
})

export const addObstruction = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_OBSTRUCTION,
  payload,
  globalState: getState(),
})

export const distributeObstructions = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DISTRIBUTE_OBSTRUCTIONS,
  payload,
  globalState: getState(),
})

export const updateObstruction = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_OBSTRUCTION,
  payload,
  globalState: getState(),
})

export const addComfortZone = (payload: any) => ({
  type: ADD_COMFORT_ZONE,
  payload,
})

export const updateComfortZone = (payload: any) => ({
  type: UPDATE_COMFORT_ZONE,
  payload,
})

export const addDimension = (payload: { dimension: Dimension }) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_DIMENSION,
  payload,
  globalState: getState(),
})

export const updateDimension = (payload: { dimension: Partial<Dimension> }) => ({
  type: UPDATE_DIMENSION,
  payload,
})

export const deleteDimension = (payload: any) => ({
  type: DELETE_DIMENSION,
  payload,
})

export const addCeiling = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: ADD_CEILING,
  payload,
  globalState: getState(),
})

export const updateCeiling = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: UPDATE_CEILING,
  payload,
  globalState: getState(),
})

export const deleteCeiling = (payload: any) => (dispatch: AppDispatch, getState: AppGetState) => dispatch({
  type: DELETE_CEILING,
  payload,
  globalState: getState(),
})

export const requestAirflow = (payload: any) => ({
  type: REQUEST_AIRFLOW,
  payload,
})

export const receiveAirflow = (payload: any) => ({
  type: RECEIVE_AIRFLOW,
  payload,
})

export const updateAirflowStatus = (payload: any) => ({
  type: UPDATE_AIRFLOW_STATUS,
  payload,
})

export const setAirflowLayer = (payload: any) => ({
  type: SET_AIRFLOW_LAYER,
  payload,
})

export const requestHeatMap = (payload: any) => ({
  type: REQUEST_HEAT_MAP,
  payload,
})

export const receiveHeatMap = (payload: any) => ({
  type: RECEIVE_HEAT_MAP,
  payload,
})

export const updateHeatMapStatus = (payload: any) => ({
  type: UPDATE_HEAT_MAP_STATUS,
  payload,
})

export const setHeatMapLayer = (payload: any) => ({
  type: SET_HEAT_MAP_LAYER,
  payload,
})

export const addBackgroundImage = (payload: any) => ({
  type: ADD_BACKGROUND_IMAGE,
  payload,
})

export const deleteBackgroundImage = (payload: any) => ({
  type: DELETE_BACKGROUND_IMAGE,
  payload,
})

export const updateBackgroundImage = (payload: { backgroundImage: Partial<BackgroundImage> }) => ({
  type: UPDATE_BACKGROUND_IMAGE,
  payload,
})

export const updateProductHeight = (payload: any) => ({
  type: UPDATE_PRODUCT_HEIGHT,
  payload,
})

export const addMetadataImage = (payload: any) => ({
  type: ADD_METADATA_IMAGE,
  payload,
})
