import React from 'react'
import PropTypes from 'prop-types'
import { useRouteMatch } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Icon from 'components/UIKit/Icon'
import Switch from 'components/UIKit/Switch'
import Control from 'components/Panels/CFDPanel/styled/Control'
import UISelect from 'components/UIKit/Select'
import { useAppSelector, useAppDispatch } from "~/store/hooks";
import { Alert } from 'components/PerformanceMetrics/subcomponents/Alert'
import { setCFDLayer } from 'store/cfd'
import { toggleLayerVisibility } from 'store/layers'
import LAYER_KEYS from 'config/layerKeys'
import { camelToTitleCase } from 'utils/string'
import { GET_VERSION_CFD_RESULTS } from 'client/queries'
import routes from 'config/routes'
import { Distance } from 'store/units/types'
import { SYSTEMS } from 'store/units/constants'
import { SIMULATION_TYPES } from 'config/cfd'
import Controls from 'components/Panels/CFDPanel/styled/Controls'
import { Skeleton } from 'components/Panels/CFDPanel/styled/Skeleton'
import { Stack } from 'components/UIKit/Stack'
import { HeadingText } from 'components/Panels/CFDPanel/styled/Typography'

const destratLabels = {
  overhead: 'Draft Risk',
  destrat: 'Coverage',
  streamlines: 'Streamlines',
}

const getViewLabel = (internalType, view) => {
  return internalType === SIMULATION_TYPES.destrat
    ? destratLabels[view] ?? 'Destrat'
    : camelToTitleCase(view)
}

const isRelevantVtkFile = (internalType, { fileProps, fileExtension }) => {
  const isVtk = fileExtension === 'vtk'
  if (!isVtk) return false
  switch (internalType) {
    case SIMULATION_TYPES.cooling: {
      return fileProps.type !== 'track'
    }
    case SIMULATION_TYPES.destrat: {
      return Object.keys(destratLabels).includes(fileProps.type)
    }
    case SIMULATION_TYPES.radiantHeat: {
      return fileProps.type === 'overhead' && !!fileProps.level
    }
    case SIMULATION_TYPES.unitHeating: {
      return fileProps.type !== 'track'
    }
    default: {
      console.warn(`Unexpected simulation type in VisualizationToggle: ${internalType}`)
      return false
    }
  }
}

const isDigit = input => !!input && /\d+/.test(input)

const useVtkFiles = () => {
  const { versionId } = useRouteMatch(routes.facility.full)?.params
  const { data, loading, error } = useQuery(GET_VERSION_CFD_RESULTS, {
    variables: { versionId },
    skip: !versionId,
  })
  return {
    loading,
    error,
    data: data?.Version?.cfd
      ?.filter(res => !!res)
      .map(({ internalType, URLs, status }) => ({
        type: internalType,
        status,
        files: URLs.filter(({ fileExtension, fileProps }) =>
          isRelevantVtkFile(internalType, { fileProps, fileExtension })
        ).reduce((prev, { fileProps, url }) => {
          const typeValues = prev.get(fileProps.type) ?? new Map()
          typeValues.set((fileProps.level ?? '').toString(), url)
          prev.set(fileProps.type, typeValues)
          return prev
        }, new Map()),
      })),
  }
}

const useFormatHeight = () => {
  const distanceUnits = useAppSelector(({ units }) => units.DISTANCE)
  return height =>
    new Distance({ value: height, system: SYSTEMS.IMPERIAL }).formattedValue(distanceUnits, {
      both: true,
      roundCentimeters: true,
      round: true,
    })
}

const Select = selectProps => (
  <UISelect inline disablePlaceholder labelWidth="150px" name="select" {...selectProps} />
)

const VisualizationToggle = ({ selectedGoal, setSelectedGoal }) => {
  const selectedLayer = useAppSelector(({ cfd }) => cfd.selectedLayer)
  const isVisibleCFDLayer = useAppSelector(({ layers }) => layers.layers.CFD.visible)
  const formatHeight = useFormatHeight()
  const dispatch = useAppDispatch()
  const { data, loading, error } = useVtkFiles()

  if (loading) {
    return <Skeleton />
  } else if (error || !data) {
    return (
      <Controls minWidth="300px" flexGrow={1}>
        <Alert label="Failed to get metrics files" />
      </Controls>
    )
  }

  const goalOptions = data.filter(({ type }) => !!type).map(({ type }) => ({
    value: type,
    label: camelToTitleCase(type),
  }))

  const vtkFiles = data.find(({ type }) => type === selectedGoal)?.files ?? new Map()

  const viewOptions = Array.from(vtkFiles.keys()).map(view => ({
    label: getViewLabel(selectedGoal, view),
    value: view,
  }))

  const levelOptions = Array.from(vtkFiles.get(selectedLayer.type)?.keys() ?? []).map(level => {
    const value = level?.toString() ?? ''
    if (selectedLayer.type === 'overhead' && isDigit(level)) {
      const label = formatHeight(level)
      return { label, value }
    } else if (['sideX', 'sideY'].includes(selectedLayer.type)) {
      return { label: `Slice ${level}`, value }
    } else {
      const label = !!level ? camelToTitleCase(level) : 'N/A'
      return { label, value }
    }
  })

  const isVisualSwitchDisabled = !vtkFiles.size

  const handleGoalChange = e => {
    const newGoal = e.target.value
    setSelectedGoal(newGoal)
    const vtkFiles = data.find(({ type }) => type === newGoal)?.files ?? new Map()
    const defaultView = vtkFiles?.keys().next().value
    const defaultLevels = vtkFiles?.get(defaultView)
    const [defaultLevel, defaultUrl] = defaultLevels?.entries().next().value ?? [null, null]
    if (!defaultView || !defaultLevel || !defaultUrl) return
    const payload = {
      goal: newGoal,
      level: defaultLevel,
      type: defaultView,
      url: defaultUrl,
    }
    dispatch(setCFDLayer(payload))
  }

  const handleViewChange = e => {
    const levelsMap = vtkFiles.get(e.target.value)
    const defaultValue = levelsMap?.entries().next().value
    if (!defaultValue) return
    const [defaultLevel, defaultUrl] = defaultValue
    const payload = {
      type: e.target.value,
      level: defaultLevel,
      url: defaultUrl,
      goal: selectedGoal,
    }
    dispatch(setCFDLayer(payload))
  }

  const handleLevelChange = e => {
    const url = vtkFiles.get(selectedLayer.type)?.get(e.target.value)
    if (!url) {
      console.warn(`No url found for view ${selectedLayer.type} at level ${e.target.value}`)
      return
    }
    const payload = {
      ...selectedLayer,
      level: e.target.value,
      url,
    }
    dispatch(setCFDLayer(payload))
  }

  const handleToggle = () => dispatch(toggleLayerVisibility({ layerKey: LAYER_KEYS.CFD }))

  return (
    <Controls>
      <Stack margin={[0, 0, 1]} direction="row" justifyContent="space-between">
        <div>
          <Icon name="wand" />
          <HeadingText>Visualization</HeadingText>
        </div>
        <Switch
          name="cfd"
          disabled={isVisualSwitchDisabled}
          isChecked={!isVisualSwitchDisabled && isVisibleCFDLayer}
          onClick={handleToggle}
        />
      </Stack>
      <Control>
        <Select
          label="Goal"
          value={selectedGoal}
          options={goalOptions}
          onChange={handleGoalChange}
        />
      </Control>
      <Control>
        <Select
          label="View"
          value={selectedLayer.type}
          options={viewOptions}
          onChange={handleViewChange}
          disabled={viewOptions.length < 2}
        />
      </Control>
      <Control>
        <Select
          label="Level"
          value={selectedLayer.level}
          disabled={levelOptions.length < 1}
          options={levelOptions}
          onChange={handleLevelChange}
        />
      </Control>
    </Controls>
  )
}

VisualizationToggle.propTypes = {
  selectedGoal: PropTypes.string,
  setSelectedGoal: PropTypes.func,
}

export { VisualizationToggle }
