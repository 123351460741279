import React from 'react'
import hasIn from 'lodash-es/hasIn'
import get from 'lodash-es/get'
import uniq from 'lodash-es/uniq'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { appConnect, useAppSelector } from "~/store/hooks";
import omit from 'lodash-es/omit'
import { tools } from '~/store/tools/reducer'
import { object, string, func, arrayOf, bool } from 'prop-types'

import withUser from 'client/decorators/withUser'
import ToolPanel from 'components/UIKit/ToolPanel'
import LAYER_KEYS from 'config/layerKeys'
import CATEGORY_KEYS from 'config/toolCategoryKeys'
import CLASS_NAMES from 'config/objectClassNames'
import Facility from 'components/DrawingCanvas/lib/facility'
import Util from 'components/DrawingCanvas/lib/util'

import { appendURL } from 'lib/utils'
import { currentUserIsCustomer } from 'lib/currentUserIs'

import { COPYABLE_CLASS_NAMES } from 'store/objects/selectors'
import { isContinuousModeEnabled } from 'store/tools/selectors'
import { isTouchUI } from 'store/userInterface/selectors'

import store from 'store'
import { isHorizontal, isVertical } from '../DrawingCanvas/util/walls'

const UNDELETABLE_CLASS_NAMES = [CLASS_NAMES.ROOF]

const DISTRIBUTION_CLASS_NAMES = [
  CLASS_NAMES.OBSTRUCTION,
  CLASS_NAMES.PRODUCT,
  CLASS_NAMES.DOOR,
]

const parents = new Map([
  [LAYER_KEYS.PRODUCTS_OVERHEAD, LAYER_KEYS.PRODUCTS],
  [LAYER_KEYS.PRODUCTS_DIRECTIONAL, LAYER_KEYS.PRODUCTS],
  [LAYER_KEYS.PRODUCTS_HEATERS, LAYER_KEYS.PRODUCTS],
  [LAYER_KEYS.PRODUCTS_EVAP, LAYER_KEYS.PRODUCTS],
  [LAYER_KEYS.ELEVATION_LINE, LAYER_KEYS.ROOFS],
  [LAYER_KEYS.ELEVATION_POINT, LAYER_KEYS.ROOFS],
])

/** @param {string} layer */
function getTools(layer) {
  let currentLayer = layer
  while (currentLayer !== undefined) {
    if (tools[currentLayer]) {
      return tools[currentLayer]
    }
    currentLayer = parents.get(currentLayer)
  }
  return null
}

const DrawingTools = ({
  currentLayer,
  activeTool,
  activeToolCategory,
  dispatch,
  selectedObjects,
  objects,
  match,
  isTouchUI,
  online,
  user,
}) => {
  const doors = useAppSelector(state => state.objects.present.doors)
  const wallSegments = useAppSelector(state => state.objects.present.segments)
  const hasExteriorWalls = useAppSelector(
    ({ objects }) =>
      !!Object.values(objects.present.objects).find(obj => obj.layerKey === 'EXTERIOR_WALLS')
  )
  const allTools = getTools(currentLayer)
  // Return `null` *IF*:
  //   - There are not any tools available.
  //   - The user is offline.
  //   - The user is a `Customer`.
  if (!allTools || !online || currentUserIsCustomer(user)) return null
  let toolObjects = null

  const availableTools = {}
  const EXCLUDE_FROM_TOUCH_UI = ['DRAW_TOOL']
  const EXCLUDE_FROM_DEFAULT_UI = ['MULTI_SELECT_TOUCH_TOOL']
  if (allTools) {
    Object.keys(allTools).forEach(toolId => {
      if (isTouchUI || !EXCLUDE_FROM_DEFAULT_UI.includes(toolId)) {
        availableTools[toolId] = allTools[toolId]
      }
      const nestedTools = get(availableTools, `[${toolId}].tools`)
      if (nestedTools) {
        // We don't want to show the "Draw" tool on the Default UI.
        if (!isTouchUI) {
          EXCLUDE_FROM_TOUCH_UI.forEach(tool => {
            availableTools[toolId].tools = omit(availableTools[toolId].tools, [
              `${tool}`,
            ])
          })
        }
      }
    })
  }

  const getTooltipMessage = toolId => {
    if (
      toolId === 'TEMPLATE_TOOL' &&
      currentLayer === LAYER_KEYS.EXTERIOR_WALLS
    ) {
      if (hasExteriorWalls) {
        return 'This facility already contains exterior walls'
      }
    }
  }

  const isEnabled = (toolId, selectedObjs, objects) => {
    switch (toolId) {
      case 'DELETE_TOOL':
        // On mounting structures layer, only enable the 'revert' button if
        // the wall has more than one roof section
        if (currentLayer === LAYER_KEYS.ROOF_SECTIONS && objects) {
          let roofSectionsCanBeReverted = false
          const roofSections = selectedObjs.filter(
            obj => obj.className === CLASS_NAMES.ROOF_SECTION
          )
          if (roofSections.length) {
            const section = objects.roofSections[roofSections[0].id]
            if (section) {
              const wall = objects.objects[section.wallId]
              roofSectionsCanBeReverted = wall.roofSectionIds.length > 1
            }
          }

          return roofSectionsCanBeReverted
        }

        const containsUnDeleteables =
          selectedObjs.length > 0 &&
          !selectedObjs.some(o => UNDELETABLE_CLASS_NAMES.includes(o.className))

        let isLocked = store.getState().layers.layers[currentLayer].locked

        return isLocked
          ? !isLocked
          : containsUnDeleteables ||
              currentLayer === LAYER_KEYS.BACKGROUND_IMAGE
      case 'DISTRIBUTION_TOOL':
        if (!Facility.current || !selectedObjs.length) return false

        if (selectedObjs[0].className === CLASS_NAMES.DOOR) {
          const selectedDoor = Object.values(doors)
            .find(door => door.id === selectedObjs[0].id)
          const wallSegment = Object.values(wallSegments)
            .find(segment => segment.id === selectedDoor?.wallSegmentId)
          const horizontal =
            wallSegment && isHorizontal(wallSegment)
          const vertical =
            wallSegment && isVertical(wallSegment)

          return !!(horizontal || vertical)
        }

        return (
          selectedObjs.length === 1 &&
          DISTRIBUTION_CLASS_NAMES.includes(selectedObjs[0].className)
        )
      case 'DUPLICATION_TOOL':
        return (
          selectedObjs.length > 0 &&
          selectedObjs.every(o => COPYABLE_CLASS_NAMES.includes(o.className))
        )
      case 'TEMPLATE_TOOL':
        if (currentLayer === LAYER_KEYS.EXTERIOR_WALLS) {
          return !hasExteriorWalls
        }
        if (!Facility.current) return false
        return Util.isFacilityRectangle()
      default:
        return true
    }
  }

  const buildTool = (tool, toolId) => ({
    to:
      tool.to && isEnabled(toolId, selectedObjects)
        ? appendURL(match.url, tool.to)
        : undefined,
    onClick: () => {
      let selectedObject
      if (selectedObjects.length === 1 && isContinuousModeEnabled()) {
        selectedObject = Object.values(objects.products).find(
          obj => obj.id === selectedObjects[0].id
        )
      }
      return tool.onClick && isEnabled(toolId, selectedObjects)
        ? tool.onClick({ dispatch, tool: toolId, selectedObject })
        : null
    },
    // We don't want the high-level categories (DRAW, EDIT, etc.)
    // to be in a selected state.
    isActive:
      activeTool === toolId && !Object.keys(CATEGORY_KEYS).includes(toolId),
    disabled: !isEnabled(toolId, selectedObjects, objects) || tool.disabled,
    text: tool.text,
    icon: tool.icon,
    onHover: getTooltipMessage(toolId),
  })

  if (availableTools) {
    toolObjects = Object.keys(availableTools).map(toolId => {
      const tool = availableTools[toolId]
      const object = {
        ...buildTool(tool, toolId),
      }
      if (tool.tools) {
        object.tools = {
          ...Object.keys(tool.tools).map((nestedTool, index) =>
            buildTool(tool.tools[nestedTool], nestedTool)
          ),
        }
      }
      return object
    })

    // If more then one type of object is selected filter out irrelevant tools
    const selectedObjectTypes = uniq(selectedObjects.map(obj => obj.className))
    if (selectedObjectTypes.length > 1) {
      toolObjects = toolObjects.filter(obj => {
        return obj.text !== 'Draw' && obj.text !== 'Add'
      })
    }
  }

  return (
    <ToolPanel
      tools={toolObjects}
      currentLayer={parents.get(currentLayer) ?? currentLayer}
      activeToolCategory={activeToolCategory}
      isTouchUI={isTouchUI}
    />
  )
}

DrawingTools.propTypes = {
  tools: object,
  currentLayer: string,
  activeTool: string,
  activeToolCategory: string,
  dispatch: func,
  selectedObjects: arrayOf(object),
  objects: object,
  match: object,
  history: object,
  isTouchUI: bool,
  online: bool,
  user: object,
}

const mapStateToProps = ({
  layers,
  tools,
  selectedObjects,
  objects,
  ...store
}) => ({
  currentLayer: layers.currentLayer,
  activeTool: tools.activeTool,
  activeToolCategory: tools.activeToolCategory,
  selectedObjects,
  objects: objects.present,
  isTouchUI: isTouchUI(store),
})

export default compose(
  withRouter,
  withUser,
  appConnect(mapStateToProps)
)(DrawingTools)
