import { useQuery } from '@apollo/client'
import { Key } from 'react-aria-components'
import { GET_MOUNTING_OPTIONS_ON_VARIATION } from '~/client/queries'
import { useSelectedProductContext } from '~/components/Panels/SelectedProductPanel/hooks/useSelectedProductContext'
import { useAppDispatch, useAppSelector } from '~/store/hooks'
import { updateProducts } from '~/store/objects'
import { Select, SelectItem } from '~/ui/Select'

export const MountingStructureSelect = () => {
  const isLocked = useAppSelector(state => state.layers.layers.PRODUCTS.locked)
  const dispatch = useAppDispatch()

  const { selectedProducts, variationId } = useSelectedProductContext()
  const { data, loading } = useQuery(GET_MOUNTING_OPTIONS_ON_VARIATION, {
    variables: { variationId: variationId ?? '' },
    skip: !variationId,
  })

  const isMultipleVariations =
    new Set(selectedProducts.map(({ product }) => product.variationId)).size > 1
  const mountingOptions = [...(data?.ProductVariation.mountingOptionAdders ?? [])].sort((a, b) => {
    return a.mountingType.localeCompare(b.mountingType) ?? 0
  })
  const isDisabled = loading || isLocked || !mountingOptions.length || isMultipleVariations

  const nonNullMountingOptionIds = selectedProducts
    .filter(({ product }) => !!product.mountingOptionAdderId)
    .map(({ product }) => product.mountingOptionAdderId)
  const mountingOptionAdders = Array.from(new Set(nonNullMountingOptionIds))
  const mountingOptionAdderId = mountingOptionAdders.length === 1 ? mountingOptionAdders[0] : null

  const handleMountingStructureChange = (newMountingOptionAdderId: Key) => {
    if (isMultipleVariations || typeof newMountingOptionAdderId !== 'string') return
    const updatedProducts = selectedProducts.map(({ product }) => {
      const isLowRiderMount = newMountingOptionAdderId === '46'
      return {
        ...product,
        rotation: {
          ...product.rotation,
          x: isLowRiderMount ? 90 : product.rotation.x,
        },
        mountingOptionAdderId: newMountingOptionAdderId,
      }
    })
    dispatch(updateProducts(updatedProducts))
  }

  return (
    <Select
      label="Structure"
      selectedKey={mountingOptionAdderId}
      isDisabled={isDisabled}
      placeholder={
        loading
          ? 'Loading...'
          : isMultipleVariations
          ? 'Multiple variations selected'
          : 'Select a mounting option'
      }
      items={mountingOptions}
      onSelectionChange={handleMountingStructureChange}
    >
      {option => <SelectItem value={option}>{option.mountingType}</SelectItem>}
    </Select>
  )
}
